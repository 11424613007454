import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';

import { Client } from '../../../models/client';
import { ClientService } from '../../../services/client.service';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
  @ViewChild('basicModal', { static: true }) showModalOnClick: ModalDirective;

  modalRef;
  isLoading = true;
  clients: Client[];
  client = new Client();

  constructor(private clientService: ClientService, private authenticationService: AuthenticationService,
    private router: Router, private mdbSpinningPreloader: MDBSpinningPreloader) { }

  ngOnInit() {
    this.mdbSpinningPreloader.start();
    this.getClients();
  }

  openToAdd(): void {
    this.client = new Client();
    this.showModalOnClick.show();
  }

  openToEdit(client: Client): void {
    this.client._id = client._id;
    this.client.fname = client.fname;
    this.client.lname = client.lname;
    this.client.email = client.email;
    this.client.phone = client.phone;
    this.client.uname = client.uname;
    this.client.Linkedin = client.Linkedin;
    this.showModalOnClick.show();
  }

  reset(): void {
    this.client = new Client();
  }

  getClients(): void {
    this.clientService.getClients().subscribe(clients => {
      this.isLoading = false;
      this.clients = clients;
      this.mdbSpinningPreloader.stop();

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get clients: ', err);
    });
  }

  remove(client: Client): void {
    if (confirm('Are you sure you want to delete this client?')) {
      this.clientService.deleteClient(client).subscribe((res) => this.getClients(), (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to delete client: ', err);
      });
    }
  }

  saveClient(): void {
    if (!this.client.fname) {
      alert('Please enter client first name');
      return;
    }
    if (!this.client.lname) {
      alert('Please enter client last name');
      return;
    }
    if (!this.client.email) {
      alert('Please enter client email');
      return;
    }
    if (!this.client.phone) {
      alert('Please enter client phone');
      return;
    }
    if (!this.client.uname) {
      alert('Please enter client user name');
      return;
    }
    if (!this.client.Linkedin) {
      alert('Please enter client LinkedIn ID');
      return;
    }

    if (!this.client._id) {
      this.clientService.addClient(this.client).subscribe(client => {
        this.reset();
        this.getClients();
        this.showModalOnClick.hide();
      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to add client: ', err);
      });
    } else {
      this.clientService.editClient(this.client).subscribe(client => {
        this.reset();
        this.getClients();
        this.showModalOnClick.hide();
      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to edit client: ', err);
      });
    }
  }
}