import { EventEmitter, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var StepFourComponent = /** @class */ (function () {
    function StepFourComponent(fb, strategyService, router) {
        this.fb = fb;
        this.strategyService = strategyService;
        this.router = router;
        this.setLoading = new EventEmitter();
        this.isLoading = false;
        this.dropdownList = [];
        this.clickByDoneButton = false;
        this.editMode = localStorage.getItem("EDIT_STRATEGY") ? true : false;
        this.limitResearchYearsOptions = [
            "Less than 1 year",
            "1-2",
            "3-5",
            "6-10",
            "More than 10"
        ];
        this.stepFourForm = this.fb.group({
            userId: localStorage.getItem('USER_KEY'),
            jobTitles: this.fb.control([], Validators.required),
            jobTitlesMore: this.fb.control(''),
            companyTenureEnable: this.fb.control(false),
            companyTenure: this.fb.control([]),
            positionTenureEnable: this.fb.control(false),
            positionTenure: this.fb.control([])
        });
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'positionCode',
            textField: 'positionName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }
    StepFourComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.strategyService.getPositions().subscribe(function (positions) {
            _this.dropdownList = positions.sort(function (a, b) {
                if (a.positionName.toLowerCase() < b.positionName.toLowerCase()) {
                    return -1;
                }
                if (a.positionName.toLowerCase() > b.positionName.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }, function (err) {
            alert('error');
            console.log(err);
        });
        if (this.strategy) {
            this.stepFourForm = this.fb.group({
                userId: localStorage.getItem('USER_KEY'),
                strategyId: this.fb.control(this.strategy._id, Validators.required),
                jobTitles: this.fb.control(this.strategy.jobTitles, Validators.required),
                jobTitlesMore: this.fb.control(this.strategy.jobTitlesMore),
                companyTenureEnable: this.fb.control(this.strategy.companyTenureEnable),
                companyTenure: this.fb.control(this.strategy.companyTenure),
                positionTenureEnable: this.fb.control(this.strategy.positionTenureEnable),
                positionTenure: this.fb.control(this.strategy.positionTenure)
            });
        }
        this.jobTitlesChange();
    };
    StepFourComponent.prototype.jobTitlesChange = function () {
        if (this.stepFourForm.value.jobTitles.length === 0 && !this.stepFourForm.value.jobTitlesMore) {
            this.stepFourForm.get('jobTitles').setValidators([Validators.required]);
        }
        else {
            this.stepFourForm.get('jobTitles').clearValidators();
        }
        this.stepFourForm.get('jobTitlesMore').clearValidators();
        this.stepFourForm.controls["jobTitles"].updateValueAndValidity();
        this.stepFourForm.controls["jobTitlesMore"].updateValueAndValidity();
    };
    StepFourComponent.prototype.stepFourSubmit = function () {
        var _this = this;
        console.log(this.stepFourForm);
        if (this.stepFourForm.valid && (!this.stepFourForm.value.companyTenureEnable || this.stepFourForm.value.companyTenure.length > 0) && (!this.stepFourForm.value.positionTenureEnable || this.stepFourForm.value.positionTenure.length > 0)) {
            this.setLoading.emit(true);
            this.strategyService.submitStrategy(this.stepFourForm.value).subscribe(function (res) {
                console.log(res);
                _this.setLoading.emit(false);
                if (_this.clickByDoneButton) {
                    _this.router.navigate(['campaigns']);
                }
                else {
                    _this.router.navigate(['intro-message-sequence']);
                }
            }, function (err) {
                alert('error');
                console.log(err);
                _this.setLoading.emit(false);
            });
        }
    };
    StepFourComponent.prototype.onItemSelect = function (item) {
        // console.log(item);
    };
    StepFourComponent.prototype.onSelectAll = function (items) {
        // console.log(items);
    };
    StepFourComponent.prototype.getCompanyCheckboxesValue = function (event, limit) {
        if (!this.stepFourForm.value.companyTenure.includes(limit)) {
            this.stepFourForm.value.companyTenure.push(limit);
        }
        else {
            var index = this.stepFourForm.value.companyTenure.indexOf(limit);
            if (index > -1) {
                this.stepFourForm.value.companyTenure.splice(index, 1);
            }
        }
    };
    StepFourComponent.prototype.getPositionCheckboxesValue = function (event, limit) {
        if (!this.stepFourForm.value.positionTenure.includes(limit)) {
            this.stepFourForm.value.positionTenure.push(limit);
        }
        else {
            var index = this.stepFourForm.value.positionTenure.indexOf(limit);
            if (index > -1) {
                this.stepFourForm.value.positionTenure.splice(index, 1);
            }
        }
    };
    StepFourComponent.prototype.cancelNewCampaign = function () {
        this.router.navigate(['campaigns']);
    };
    StepFourComponent.prototype.clickDoneButton = function () {
        this.clickByDoneButton = true;
    };
    return StepFourComponent;
}());
export { StepFourComponent };
