import { OnInit } from '@angular/core';
var SpecialAvdataComponent = /** @class */ (function () {
    function SpecialAvdataComponent(userService, papa, HttpClient) {
        this.userService = userService;
        this.papa = papa;
        this.HttpClient = HttpClient;
        this.accept = '*';
        this.pxDataArray = [];
        this.isLoading = false;
        this.processedFiles = 0;
        this.files = [];
        this.warningMessages = [];
        this.hasBaseDropZoneOver = false;
    }
    SpecialAvdataComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.successMessage = '';
        this.isLoading = true;
        this.userService.getUsers().subscribe(function (users) {
            _this.users = users;
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get users: ', err);
        });
    };
    SpecialAvdataComponent.prototype.cancel = function () {
        this.progress = 0;
        if (this.httpEmitter) {
            this.httpEmitter.unsubscribe();
        }
    };
    SpecialAvdataComponent.prototype.uploadFiles = function (files) {
        var _this = this;
        if (files && files.length > 0) {
            this.isLoading = true;
            this.warningMessages = [];
            var _loop_1 = function (i) {
                var reader = new FileReader();
                reader.readAsText(files[i]);
                reader.onload = function (data) {
                    _this.papa.parse(reader.result.toString(), {
                        header: true,
                        complete: function (parsedData) {
                            var selectedUser;
                            _this.isLoading = true;
                            var found = false;
                            for (var j = 0; j < _this.users.length; j++) {
                                if (files[i].name.split(' ')[0] === _this.users[j].linkedInProfileId || files[i].name.split(' ')[0] === _this.users[j].memberId) {
                                    selectedUser = _this.users[j];
                                    found = true;
                                }
                            }
                            var fileName = files[i].name;
                            var regExp = /\(([^)]+)\)/;
                            var listName = regExp.exec(fileName) ? regExp.exec(fileName)[1] : 'List from ' + fileName.substr(-12, 8);
                            if (!found) {
                                _this.warningMessages.push({ filename: files[i].name, message: "This file cannot be imported. Please check the LinkedIn ID in the file name is correct." });
                            }
                            else if (!files[i].name.includes(' AV ')) {
                                _this.warningMessages.push({ filename: files[i].name, message: "This file cannot be imported. Please make sure the filename contains AV with spaces on both sides." });
                            }
                            else if (!listName) {
                                _this.warningMessages.push({ filename: files[i].name, message: "This file cannot be imported. Please make sure the filename contains list Name with brackets on both sides." });
                            }
                            else {
                                for (var k = 0; k < parsedData.data.length; k++) {
                                    if (!parsedData.data[k].public_id) {
                                        _this.warningMessages.push({ filename: files[i].name, message: "This file cannot be imported. Please confirm the file was exported from LH2." });
                                        break;
                                    }
                                }
                            }
                            if (_this.warningMessages.length === 0) {
                                _this.userService.updateAVData(parsedData.data, selectedUser._id, listName, 'special').subscribe(function (res) {
                                    _this.processedFiles++;
                                    console.log("processed file #" + _this.processedFiles);
                                    if (_this.processedFiles === files.length) {
                                        _this.isLoading = false;
                                        _this.pxDataArray = null;
                                        _this.files = [];
                                        _this.warningMessages = [];
                                        _this.processedFiles = 0;
                                    }
                                }, function (err) {
                                    _this.warningMessages = [];
                                    _this.isLoading = false;
                                    alert('error');
                                    console.log('error to update avdata: ', err);
                                });
                            }
                            else {
                                _this.isLoading = false;
                            }
                        }
                    });
                };
            };
            for (var i = 0; i < files.length; i++) {
                _loop_1(i);
            }
            this.files = [];
        }
        else {
            alert('Please select data file.');
        }
    };
    SpecialAvdataComponent.prototype.getDate = function () {
        return new Date();
    };
    return SpecialAvdataComponent;
}());
export { SpecialAvdataComponent };
