import { AVLists } from "./AVLists";

export class listUser {
    _id: string;
    name: string;
}

export class listsProps {
    users: listUser[];
    avlists: AVLists[];
    companies: string[];
}