import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild } from '@angular/core';

import { dateFilterParams } from '../../../helpers/dateFilterParams';

import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { validateImage } from '../../../helpers/common';

@Component({
  selector: 'app-ca-connections',
  templateUrl: './ca-connections.component.html',
  styleUrls: ['./ca-connections.component.scss']
})
export class CaConnectionsComponent implements OnInit {
 
  @ViewChild('basicModal', { static: true }) showModalOnClick: ModalDirective;
  gridApi: any;
  rows = [];
  select = '';
  users: User[];
  isLoading = true;
  selectedRow = undefined;
  noDataText = 'No connections right now';
  leadStatusOptions=[];
  columnDefs = [
    {
      width: 200,
      sortable: true,
      field: 'companyName',
      headerName: 'Company Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        if (!valueA) return -1;
        if (!valueB) return 1;
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
        if (valueA == valueB) return 0;
        if (valueA > valueB) return 1;
        return -1;
      },
      cellRenderer: function (params) {
        return `<a href="${params.data.companyProfileURL}" target="_blank">${params.value}</a>`;
      }
    },
    {
      width: 100,
      sortable: true,
      resizable: true,
      editable: false,
      field: 'status',
      headerName: 'Lead Status',
      headerTooltip: "Lead Status",
      cellEditor: 'agSelectCellEditor',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      cellEditorParams: this.getLeadOptions.bind(this),
      cellRenderer: this.cellLeadCodeRenderer.bind(this)
    },
    {
      width: 100,
      sortable: true,
      field: 'firstName',
      headerName: 'First Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
      width: 100,
      sortable: true,
      field: 'lastName',
      headerName: 'Last Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
 
    {
      width: 200,
      sortable: true,
      field: 'title',
      headerName: 'Title',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
      width: 75,
      field: 'Avatar',
      headerName: 'Avatar',
      cellRenderer: this.avatarShow.bind(this),
    },
    {
      width: 75,
      headerName: 'View',
      field: 'profileURL',
      cellRenderer: function (params) {
        return `<a href="${params.data.profileURL}" target="_blank">View</a>`;
      }
    },
    // {
    //   width: 75,
    //   sortable: true,
    //   field: 'since1',
    //   headerName: 'Tenure',
    //   filter: 'agNumberColumnFilter',
    //   filterParams: {
    //     buttons: ['reset', 'apply'],
    //     closeOnApply: true
    //   },
    //   comparator: function (number1, number2) {
    //     if (number1 === null && number2 === null) {
    //       return 0;
    //     }
    //     if (number1 === null) {
    //       return -1;
    //     }
    //     if (number2 === null) {
    //       return 1;
    //     }
    //     return number1 - number2;
    //   },
    //   cellRenderer: function (params) {
    //     if (params.data.since1 === 0) {
    //       return `<span><1</span>`
    //     }
    //     return `<span>${params.data.since1}</span>`;
    //   }
    // },
    {
      width: 200,
      field: 'email',
      sortable: true,
      headerName: 'Email',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      cellRenderer: function (params) {
        if (params.value) return `<a href="mailto:${params.value}">${params.value}<a>`;
        return '';
      }
    },
    {
      width: 125,
      field: 'phone',
      sortable: true,
      headerName: 'Phone',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
      width: 150,
      sortable: true,
      field: 'location',
      headerName: 'Location',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    // {
    //   width: 150,
    //   sortable: true,
    //   field: 'mutualConnections',
    //   headerName: 'Mutual Connections',
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ['reset', 'apply'],
    //     closeOnApply: true
    //   },
    // },
    {
      width: 150,
      sort: "desc",
      sortable: true,
      field: 'connectedOnDate',
      headerName: 'Connected on',
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        if (!valueA) return -1;
        if (!valueB) return 1;
        if (new Date(valueA) > new Date(valueB)) return 1;
        if (new Date(valueA) < new Date(valueB)) return -1;
        else return 0;
      },
    },
    // {
    //   width: 150,
    //   sortable: true,
    //   field: 'lastMessageDate1',
    //   headerName: 'Last Msg Date',
    //   filter: "agDateColumnFilter",
    //   filterParams: dateFilterParams,
    //   comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
    //     if (!valueA) return -1;
    //     if (!valueB) return 1;
    //     if (new Date(valueA) > new Date(valueB)) return 1;
    //     if (new Date(valueA) < new Date(valueB)) return -1;
    //     else return 0;
    //   },
    //   cellRenderer: function (params) {
    //     return `${params.data.lastMessageDate}`;
    //   }
    // },
    // {
    //   width: 125,
    //   field: 'convoUrl',
    //   headerName: 'View Convo',
    //   cellRenderer: function (params) {
    //     if (params.value) return `<a target="_blank" href="${params.value}">view convo</a>`;
    //     return '';
    //   }
    // },
    // {
    //   width: 150,
    //   sortable: true,
    //   field: 'lastMessage',
    //   headerName: 'Last Incoming Msg',
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ['reset', 'apply'],
    //     closeOnApply: true
    //   },
    // }
  ];
  

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.userService.getUserByKey(localStorage.getItem('USER_KEY')).subscribe(user => {
      if(user.leadStatusOptions.length==0){
        this.leadStatusOptions = ["",0,1,2,3,4];
      }
      else this.leadStatusOptions = [""].concat(user.leadStatusOptions);
    })
    this.userService.getCAUsers().subscribe((res) => {
      this.users = res.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      if (localStorage.getItem('CA_CONNECTIONS_KEY')) {
        this.select = localStorage.getItem('CA_CONNECTIONS_KEY') + ',' + localStorage.getItem('CA_CONNECTIONS_LINKEDINID');
        this.selectUser();
      } else {
        this.isLoading = false;
      }
      validateImage()
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get ca users: ', err);
    });
  }
  cellLeadCodeRenderer(params){
    return (this.leadStatusOptions||[""])[params.data.status]||params.data.status
  }
  getLeadOptions(){
    return {values: this.leadStatusOptions||[0,1,2,3,4]}
  }
  onPaginationChanged($evt) {validateImage()}
  onGridReady(params) {
    this.gridApi = params.api;

    this.gridApi.sizeColumnsToFit();

  }
  exportData() {
    this.gridApi!.exportDataAsCsv({
      processCellCallback: (params) => {
        const colDef = params.column.getColDef()
        if (colDef.valueFormatter) {
          const valueFormatterParams = {
            ...params,
            data: params.node.data,
            node: params.node!,
            colDef: params.column.getColDef()
          };
          return colDef.valueFormatter(valueFormatterParams);
        }
        return params.value;
      },
    })
  }
  selectUser() {
    if (this.select) {
      this.isLoading = true;
      const split = this.select.split(',');
      const v1 = split[0];
      const v2 = split[1];

      this.userService.getUserPXdata(v1).subscribe((connections) => {
      this.rows = connections.map(con=>({...con,status: con.status!=0 ? (this.leadStatusOptions[con.status]||con.status):''}));

        console.log(connections)
        localStorage.setItem('CA_CONNECTIONS_KEY', '');
        localStorage.setItem('CA_CONNECTIONS_NAME', '');
        localStorage.setItem('CA_CONNECTIONS_LINKEDINID', '');
        localStorage.removeItem('USER_PXDATA_CACHED_AT');
        localStorage.removeItem('USER_PXDATA');
        this.isLoading = false;
        validateImage();
      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to get user px data: ', err);
      });
    }
  }

  avatarShow(params) {
    const eDiv = document.createElement('div');

    if (!params.data.avatar) {
      eDiv.innerHTML = '';
    } else {
      eDiv.innerHTML =
        '<img src="' + params.data.avatar + '" class="avatar-img liImg"/>';
    }

    eDiv.addEventListener('click', () => {
      this.selectedRow = params.data;
      this.showModalOnClick.show();
    });

    return eDiv;
  }
}
