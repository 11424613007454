import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, ViewChild, OnInit } from '@angular/core';

import { getUserRole } from 'src/app/helpers/getUserRole';

import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-ca-dashboard',
  templateUrl: './ca-dashboard.component.html',
  styleUrls: ['./ca-dashboard.component.scss']
})
export class CaDashboardComponent implements OnInit {

  @ViewChild('modal', { static: true }) modal: ModalDirective;

  users: User[];
  userValue = '';
  isLoading = true;
  iscAdmin = false;
  selectedUser: User;
  filteredUsers: User[];

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {
    if (getUserRole() === '3') {
      this.iscAdmin = true;

      this.userService.getCADashboard().subscribe((res) => {
        this.users = res.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        this.isLoading = false;
        this.filteredUsers = this.users;

      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to get ca dashboard: ', err);
      });

    } else {
      this.isLoading = false;
      this.iscAdmin = false;
    }
  }

  openModal(user) {
    this.selectedUser = user;
    this.modal.show();
  }

  userReview(user) {
    if (user._id != localStorage.getItem('USER_REVIEW_KEY')) {
      localStorage.removeItem('USER_AVDATA_CACHED_AT');
    }
    localStorage.setItem('USER_REVIEW_KEY', user._id);
    localStorage.setItem('USER_REVIEW_NAME', user.name);

    this.router.navigate(['/userReview']);
  }

  userMessage(user) {
    localStorage.setItem('CA_MESSAGE_KEY', user._id);
    localStorage.setItem('CA_MESSAGE_NAME', user.name);
    localStorage.setItem('CA_MESSAGE_LINKEDINID', user.linkedInProfileId);

    this.router.navigate(['/caMessages']);
  }

  userLists(user) {
    localStorage.setItem('USER_LISTS_KEY', user._id);

    this.router.navigate(['/lists']);
  }

  userConnections(user) {
    localStorage.setItem('CA_CONNECTIONS_KEY', user._id);
    localStorage.setItem('CA_CONNECTIONS_NAME', user.name);
    localStorage.setItem('CA_CONNECTIONS_LINKEDINID', user.linkedInProfileId);

    this.router.navigate(['/caConnections']);
  }

  userReviewed(user) {
    localStorage.setItem('USER_REVIEWED_KEY', user._id);
    localStorage.setItem('USER_REVIEWED_NAME', user.name);

    this.router.navigate(['/userReviewed']);
  }

  isApprovalOld(lastApprovedOn: number, avUpdatedOn: number): boolean {
    if (lastApprovedOn === 0) {

      return false;
    }

    let diff = lastApprovedOn - avUpdatedOn;

    if (diff < 0) { return true; }

    return false;
  }

  filter() {
    if (this.userValue) {

      return this.filteredUsers = this.users.filter((item: User) => item._id === this.userValue);
    }

    return this.filteredUsers = this.users
  }

}
