/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./incomingrequests.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./incomingrequests.component";
import * as i4 from "../../../services/user.service";
import * as i5 from "ng-uikit-pro-standard";
var styles_IncomingConnectionsComponent = [i0.styles];
var RenderType_IncomingConnectionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IncomingConnectionsComponent, data: {} });
export { RenderType_IncomingConnectionsComponent as RenderType_IncomingConnectionsComponent };
function View_IncomingConnectionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading\u2026"]))], null, null); }
function View_IncomingConnectionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAction("ACCEPT", _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Accept"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAction("IGNORE", _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Reject"]))], null, null); }
function View_IncomingConnectionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "flex align-end user-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["style", "margin-left: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["style", "width: 80%;margin-left: 20px;margin-right: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " ", " lastName invited you to connect on ", ""])), i1.ɵppd(7, 2), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "headline"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncomingConnectionsComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_6 = !_v.context.$implicit.actionTaken; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "https://www.linkedin.com/in/", _v.context.$implicit.publicIdentifier, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.photo, ""); _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.firstName; var currVal_3 = _v.context.$implicit.lastName; var currVal_4 = i1.ɵunv(_v, 6, 2, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.inviteSentOn, "M/d/yyyy")); _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.occupation; _ck(_v, 9, 0, currVal_5); }); }
export function View_IncomingConnectionsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncomingConnectionsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "userInfo"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncomingConnectionsComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.rows; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_IncomingConnectionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-incomingrequests", [], null, null, null, View_IncomingConnectionsComponent_0, RenderType_IncomingConnectionsComponent)), i1.ɵdid(1, 114688, null, 0, i3.IncomingConnectionsComponent, [i2.DOCUMENT, i4.UserService, i5.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IncomingConnectionsComponentNgFactory = i1.ɵccf("app-incomingrequests", i3.IncomingConnectionsComponent, View_IncomingConnectionsComponent_Host_0, {}, {}, []);
export { IncomingConnectionsComponentNgFactory as IncomingConnectionsComponentNgFactory };
