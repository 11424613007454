import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SequenceService {

  constructor(private http: HttpClient) { }

  public getBaseUrl(): string {
    return environment.baseUrl;
  }

  protected getUrl(relativeUrl: string) {
    return this.getBaseUrl() + relativeUrl;
  }

  protected getOptions(): any {
    const AuthorizeToken = localStorage.getItem('USER_TOKEN');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + AuthorizeToken
    });

    const options = {
      headers: headers
    };

    return options;
  }

  submitSequence(formValue): Observable<any> {
    const url = this.getUrl('/z-messages');

    return this.http.post(url, formValue, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

  getMessageTemplates(): Observable<any[]> {
    const url = this.getUrl('/z-message-templates');

    return this.http.get(url, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

  getMessages(sequenceId: string): Observable<any[]> {
    const url = this.getUrl('/z-messages/' + sequenceId);

    return this.http.get(url, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

  deleteMessage(_id: string): Observable<any> {
    const url = this.getUrl('/z-messages/' + _id);

    return this.http.delete(url, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

  getMessage(_id: string): Observable<any> {
    const url = this.getUrl('/z-messages/individual/' + _id);

    return this.http.get(url, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

}
