import { Subscription } from 'rxjs';
import { Papa } from 'ngx-papaparse';
import { Component, OnInit } from '@angular/core';
import { HttpEvent, HttpClient } from '@angular/common/http';

import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-avdata',
  templateUrl: './avdata.component.html',
  styleUrls: ['./avdata.component.scss']
})

export class AvdataComponent implements OnInit {

  csv: string;
  user: string;
  accept = '*';
  users: User[];
  successMessage;
  lastFileAt: Date;
  progress: number;
  pxDataArray = [];
  isLoading = false;
  files: File[] = [];
  processedFiles = 0;
  warningMessages = [];
  httpEmitter: Subscription;
  sendableFormData: FormData;
  hasBaseDropZoneOver = false;
  httpEvent: HttpEvent<Event>;

  constructor(private userService: UserService, private papa: Papa, public HttpClient: HttpClient) { }

  ngOnInit() {
    this.successMessage = '';
    this.isLoading = true;

    this.userService.getUsers().subscribe((users) => {
      this.users = users;
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get users: ', err);
    });
  }

  cancel() {
    this.progress = 0;

    if (this.httpEmitter) {
      this.httpEmitter.unsubscribe();
    }
  }

  uploadFiles(files: File[]): void {
    if (files && files.length > 0) {
      this.isLoading = true;
      this.warningMessages = [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.readAsText(files[i]);

        reader.onload = (data) => {
          this.papa.parse(reader.result.toString(), {
            header: true,

            complete: (parsedData) => {
              let selectedUser;
              this.isLoading = true;
              let found = false;

              for (let j = 0; j < this.users.length; j++) {
                if (files[i].name.split(' ')[0] === this.users[j].linkedInProfileId || files[i].name.split(' ')[0] === this.users[j].memberId) {
                  selectedUser = this.users[j];
                  found = true;
                }
              }

              const fileName = files[i].name;
              var regExp = /\(([^)]+)\)/;
              var listName = regExp.exec(fileName) ? regExp.exec(fileName)[1] : 'List from ' + fileName.substr(-12, 8);

              if (!found) {
                this.warningMessages.push({ filename: files[i].name, message: "This file cannot be imported. Please check the LinkedIn ID in the file name is correct." });
              } else if (!files[i].name.includes(' AV ')) {
                this.warningMessages.push({ filename: files[i].name, message: "This file cannot be imported. Please make sure the filename contains AV with spaces on both sides." });
              } else if (!listName) {
                this.warningMessages.push({ filename: files[i].name, message: "This file cannot be imported. Please make sure the filename contains list Name with brackets on both sides." });
              } else {
                for (let k = 0; k < parsedData.data.length; k++) {
                  if (!parsedData.data[k].member_id) {
                    this.warningMessages.push({ filename: files[i].name, message: "This file cannot be imported. Please confirm the file was exported from LH2." });
                    break;
                  }
                }
              }

              if (this.warningMessages.length === 0) {
                this.userService.updateAVData(parsedData.data, selectedUser._id, listName, 'regular').subscribe((res: any) => {
                  this.processedFiles++;
                  console.log(`processed file #${this.processedFiles}`);

                  if (this.processedFiles === files.length) {
                    this.isLoading = false;
                    this.pxDataArray = null;
                    this.files = [];
                    this.warningMessages = [];
                    this.processedFiles = 0;
                  }

                }, (err) => {
                  this.warningMessages = [];
                  this.isLoading = false;
                  alert('error');
                  console.log('error to update avdata: ', err);
                });

              } else {
                this.isLoading = false;
              }
            }
          });
        };
      }

      this.files = [];

    } else {
      alert('Please select data file.');
    }

  }

  getDate() {
    return new Date();
  }

}
