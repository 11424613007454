import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { editorConfig } from 'src/app/helpers/ckeditor';

import { SettingService } from '../../../services/setting.service';

@Component({
   selector: 'app-setting',
   templateUrl: './setting.component.html',
   styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

   message: any;
   isLoading = false;
   Editor = ClassicEditor;
   editorConfig = editorConfig;

   constructor(private formBuilder: FormBuilder,
      private adminsetting: SettingService) { }

   ngOnInit() {
      this.getSettingValue();
   }

   admin_setting = this.formBuilder.group({
      approved_7day: ['', [Validators.required]],
      approved_30day: ['', [Validators.required]],
      message_7day: ['', [Validators.required]],
      message_30day: ['', [Validators.required]],
      connection_7day: ['', [Validators.required]],
      connection_30day: ['', [Validators.required]]
   });

   // save setting
   onSubmit() {
      this.adminsetting.updateSetting(this.admin_setting.value).subscribe((result) => {
         if (result == true) {
            alert('Setting change successfully');
         } else {
            alert('Some Thing want worng');
         }

      }, (err) => {
         alert('error');
         console.log('error to update setting: ', err);
      })
   }

   // get setting value
   getSettingValue() {
      this.isLoading = true;

      this.adminsetting.getSettingValue().subscribe((result) => {
         if (result != false) {
            this.isLoading = false;
            this.admin_setting.get('approved_7day').setValue(result.approved_7day);
            this.admin_setting.get('approved_30day').setValue(result.approved_30day);
            this.admin_setting.get('message_7day').setValue(result.message_7day);
            this.admin_setting.get('message_30day').setValue(result.message_30day);
            this.admin_setting.get('connection_7day').setValue(result.connection_7day);
            this.admin_setting.get('connection_30day').setValue(result.connection_30day);

            this.message = result.message;
         }

      }, (err) => {
         this.isLoading = false;
         alert('error');
         console.log('error to get setting value: ', err);
      });
   }

   // save message
   saveMessage() {
      this.adminsetting.updateMessage(this.message).subscribe((result) => {
         if (result == true) {
            alert('Message change successfully');
         } else {
            alert('Some Thing want worng');
         }

      }, (err) => {
         alert('error');
         console.log('error to update setting: ', err);
      })
   }
}
