import jwt_decode from 'jwt-decode';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http) {
        this.http = http;
        this.loginStatus = new BehaviorSubject(false);
        this.sharedLoginStatus = this.loginStatus.asObservable();
    }
    AuthenticationService.prototype.toggleLoginStatus = function (status) {
        this.loginStatus.next(status);
    };
    AuthenticationService.prototype.getBaseUrl = function () {
        return environment.baseUrl;
    };
    AuthenticationService.prototype.getUrl = function (relativeUrl) {
        return this.getBaseUrl() + relativeUrl;
    };
    AuthenticationService.prototype.getOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    AuthenticationService.prototype.sendResetPasswordEmail = function (email) {
        var url = this.getUrl('/users/forgot-password');
        return this.http.post(url, { email: email }, this.getOptions())
            .pipe(map(function (res) {
            return true;
        }));
    };
    AuthenticationService.prototype.changeUserPassword = function (password) {
        var url = this.getUrl('/users/update-password');
        return this.http.post(url, { password: password }, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    AuthenticationService.prototype.resetUserPassword = function (password, reset_token) {
        var url = this.getUrl('/users/reset-password');
        return this.http.post(url, { password: password, token: reset_token }, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    AuthenticationService.prototype.login = function (email, password) {
        var url = this.getUrl('/users/login');
        return this.http.post(url, { email: email, password: password }, this.getOptions())
            .pipe(map(function (querySnapshot) {
            localStorage.setItem('USER_TOKEN', querySnapshot.token);
            var data = jwt_decode(localStorage.getItem('USER_TOKEN'));
            var user = new User();
            user._id = data._id;
            user.name = data.name;
            user.company = data.company;
            user.email = data.email;
            user.password = data.password;
            user.role = data.role;
            user.linkedInProfileId = data.linkedInProfileId;
            user.memberId = data.memberId;
            user.googleDocURL = data.googleDocURL;
            user.isHidden = data.isHidden;
            user.selfserve = data.selfserve;
            return user;
        }));
    };
    AuthenticationService.prototype.check = function () {
        return new Observable(function (observer) {
            if (localStorage.getItem('USER_TOKEN')) {
                // User is signed in.
                var data = jwt_decode(localStorage.getItem('USER_TOKEN'));
                var user = new User();
                user._id = data._id;
                user.name = data.name;
                user.company = data.company;
                user.email = data.email;
                user.password = data.password;
                user.role = data.role;
                user.linkedInProfileId = data.linkedInProfileId;
                user.googleDocURL = data.googleDocURL;
                user.isHidden = data.isHidden;
                user.groups = data.groups;
                user.isViewedprofilesActive = data.isViewedprofilesActive;
                user.selfserve = data.selfserve;
                observer.next(user);
            }
            else {
                // No user is signed in.
                observer.next(null);
            }
        });
    };
    AuthenticationService.prototype.logout = function () {
        return new Observable(function (observer) {
            localStorage.clear();
            localStorage.clear();
            observer.next(true);
        });
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
