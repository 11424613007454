import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { getUserRole } from 'src/app/helpers/getUserRole';

import { Group } from 'src/app/models/groups';
import { GroupsService } from 'src/app/services/groups.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  groups: Group[] = [];
  filteredGroups: Group[] = [];

  memberId = '';
  pageSize = 25;
  isLoading = true;
  sortBy = 'groupName';
  sortOrder = 'asc';
  statusValue = 'MEMBER';

  constructor(private router: Router, public groupsService: GroupsService) { }

  ngOnInit() {
    localStorage.removeItem('CAMPAIGN_INFO');
    this.memberId = (localStorage.getItem('USER_GROUPS_MEMBERID') && getUserRole() === '1') ? localStorage.getItem('USER_GROUPS_MEMBERID') : localStorage.getItem('USER_MEMBER_ID');

    this.groupsService.getGroups(this.memberId).subscribe((res: Group[]) => {
      this.groups = res;
      this.filter();
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log(err);
    });
  }

  getAgeOfGroup(groupCreatedOn) {
    return new Date().getFullYear() - new Date(groupCreatedOn).getFullYear();
  }

  filter() {
    this.filteredGroups = this.groups.filter((item) => item.status.includes(this.statusValue));
  }

  exportData() {
    const con = [];
    let csvContent = 'data:text/csv;charset=utf-8,';

    Object.keys(this.filteredGroups).forEach((data) => {
      const val = this.groups[data];
      con.push(val);
    });

    const title = `ID, Name, Description, Status, Joined Date, Member Count`;
    csvContent += title + '\r\n';

    con.forEach(function (rowArray) {
      const joinedAt = new Date(rowArray.joinedAt);
      const joinedAt_ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(joinedAt);
      const joinedAt_mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(joinedAt);
      const joinedAt_da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(joinedAt);
      const joinedAt_d = `${joinedAt_mo}/${joinedAt_da}/${joinedAt_ye}`;

      const row = '"' + rowArray.groupId + '","' + rowArray.groupName.replace(/"/g, '""') + '","' + rowArray.groupDescription.replace(/"/g, '""') + '","'
        + rowArray.status + '","' + joinedAt_d + '","'
        + rowArray.memberCount + '"';
      csvContent += row + '\r\n';
    });

    csvContent = csvContent.replace(/#/g, '');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);

    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    const _d = `${mo}-${da}-${ye}`;

    link.setAttribute('download', `${localStorage.getItem("USER_NAME")} - Group Export - ${_d}.csv`);

    document.body.appendChild(link); // Required for FF
    link.click();
    this.isLoading = false;
  }

}
