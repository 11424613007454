<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="d-flex align-items-center">
    <span class="mr-3">Company</span>
    <select class="browser-default custom-select" style="width: 250px;" [(ngModel)]="companyValue" (change)="filter()">
        <option selected value=""> All</option>
        <option *ngFor="let company of companies" value="{{company}}">{{company}}</option>
    </select>
    <span class="ml-5 mr-3">User</span>
    <select class="browser-default custom-select" style="width: 250px;" [(ngModel)]="userValue" (change)="filter()">
        <option selected value=""> All</option>
        <option *ngFor="let user of dropdownUsers" value="{{user._id}}">{{user.name}}</option>
    </select>
    <span class="ml-5 mr-3">Role</span>
    <select class="browser-default custom-select" style="width: 250px;" id="role" [(ngModel)]="roleValue"
        (change)="filter()">
        <option selected value="">Select User Role</option>
        <option value="1">RMD Superadmin (RSA)</option>
        <option value="2">RMD Admin (RA)</option>
        <option value="3">Company Admin (CA)</option>
        <option value="4">Company End User (CE)</option>
    </select>
    <span class="ml-5 mr-3">Status</span>
    <select class="browser-default custom-select" style="width: 250px;" [(ngModel)]="statusValue" (change)="filter()">
        <option selected value=""> All</option>
        <option value="active"> Active</option>
        <option value="inactive"> Inactive</option>
    </select>
    <fa name="fas fa-user-plus" class="ml-3 new-user" (click)="openToAdd()"></fa>
</div>

<div class="table-responsive" *ngIf="filteredUsers">
    <table class="table table-striped" [mfData]="filteredUsers" #mf="mfDataTable">
        <thead>
            <tr>
                <th>
                    <mfDefaultSorter by="company">Company</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="name">User Name</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="initials">Researcher </mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="linkedInProfileId">LI Profile ID</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="memberId">Member ID</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="email">Email</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="role">Role</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="isHidden">Hidden</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter>Action</mfDefaultSorter>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of mf.data">
                <td>{{user.company}}</td>
                <td>{{user.name}}</td>
                <td>{{user.reseacher}}</td>
                <td> {{user.linkedInProfileId}} </td>
                <td>
                    {{user.memberId}}
                </td>
                <td>{{user.email}}</td>
                <td class="text-center">
                    <span *ngIf="user.role == 1">RSA</span>
                    <span *ngIf="user.role == 2">RA</span>
                    <span *ngIf="user.role == 3">CA</span>
                    <span *ngIf="user.role == 4">CE</span>
                </td>
                <td class="text-center">
                    <span *ngIf="user.isHidden" style="color: #32cd32; font-weight: bold">Y</span>
                    <span *ngIf="!user.isHidden" style="color: red; font-weight: bold">N</span>
                </td>
                <td class="text-center">
                    <fa name='fas fa-edit' (click)="openToEdit(user)" style="cursor: pointer;"></fa>
                    <!-- <i class="fas fa-trash-alt" (click)="remove(user)" style="cursor: pointer;"></i> -->
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Material auto-sizing form -->

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">User Information</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-sm-6">
                        <input type="text" class="form-control" id="name" [(ngModel)]="user.name" placeholder="Name">
                    </div>
                    <div class="form-group col-sm-6">
                        <input type="text" class="form-control" id="initials" [(ngModel)]="user.initials"
                            placeholder="Initials" maxlength="3">
                    </div>
                    <div class="form-group col-sm-12">
                        <input type="text" class="form-control" id="company" [(ngModel)]="user.company"
                            placeholder="Company">
                    </div>
                    <div class="form-group col-sm-6">
                        <input type="text" class="form-control" id="linkedInProfileId"
                            [(ngModel)]="user.linkedInProfileId" placeholder="Linkedin Profile ID">
                    </div>
                    <div class="form-group col-sm-6">
                        <input type="text" class="form-control" id="myId" [(ngModel)]="user.myId" placeholder="LH2 ID">
                    </div>
                    <div class="form-group col-sm-12">
                        <input type="text" class="form-control" id="memberId" [(ngModel)]="user.memberId"
                            placeholder="Member ID">
                    </div>
                    <div class="form-group col-sm-12">
                        <input type="text" class="form-control" id="googleDocURL" [(ngModel)]="user.googleDocURL"
                            placeholder="Google Doc URL">
                    </div>
                    <div class="form-group col-sm-12">
                        <input type="email" class="form-control" id="email" [(ngModel)]="user.email"
                            placeholder="Email Address">
                    </div>
                    <div *ngIf="modal_type === 'add'" class="form-group col-sm-12">
                        <input type="text" class="form-control" id="password" [(ngModel)]="user.password"
                            placeholder="Password">
                    </div>
                    <div class="form-group col-sm-12">
                        <div class="input-group">
                            <select class="browser-default custom-select" id="role" [(ngModel)]="user.role">
                                <option selected value="">Select User Role</option>
                                <option value="1">RMD Superadmin (RSA)</option>
                                <option value="2">RMD Admin (RA)</option>
                                <option value="3">Company Admin (CA)</option>
                                <option value="4">Company End User (CE)</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group col-sm-12" *ngIf="user.role === '1' || user.role === '2'">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="user.isResearcher"
                                id="isResearcher">
                            <label class="form-check-label" for="isResearcher">
                                Is this user a researcher?
                            </label>
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <div class="input-group">
                            <select class="browser-default custom-select" id="role"
                                [(ngModel)]="user.assignedResearcher">
                                <option selected value="">Assigned Researcher</option>
                                <option *ngFor="let user of assignedResearchers" value="{{user._id}}">{{user.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group col-sm-12">
                        <input type="text" class="form-control" id="leadStatusOptions" [(ngModel)]="user.leadStatusOptions"
                            placeholder="Lead Status Code">
                            <p class="info">Lead Status Code(Comma Separated) will read as 0,1,2,3,4 respectively</p>
                    </div>

                    <div class="col-sm-12">
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="user.selfserve"
                                        id="selfserve">
                                    <label class="form-check-label" for="selfserve">
                                        Self Serve
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="user.isLila"
                                        id="isLila">
                                    <label class="form-check-label" for="isLila">
                                        LiLa
                                    </label>
                                </div>
                            </div>


                            <div class="form-group col-sm-6" *ngIf="modal_type === 'edit'">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="update_password"
                                        id="updatePassword" (click)="clearNewPassword()">
                                    <label class="form-check-label" for="updatePassword">
                                        Update Password
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-sm-6" *ngIf="modal_type === 'edit'">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="user.notifications"
                                        id="notifications">
                                    <label class="form-check-label" for="notifications">
                                        Notifications
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="user.groups" id="groups">
                                    <label class="form-check-label" for="groups">
                                        Groups
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="user.isViewedprofilesActive" id="isViewedprofilesActive">
                                    <label class="form-check-label" for="isViewedprofilesActive">
                                        Viewed Profiles
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-sm-6">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="user.isHidden"
                                        id="isHidden">
                                    <label class="form-check-label" for="isHidden">
                                        Is hidden
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="modal_type === 'edit' && update_password" class="form-group col-sm-12">
                        <input type="text" class="form-control" id="newpassword" [(ngModel)]="user.password"
                            placeholder="New Password">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
                    (click)="basicModal.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect
                    (click)="saveUser()">Save changes</button>
            </div>
        </div>
    </div>
</div>