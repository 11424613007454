import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var StrategyService = /** @class */ (function () {
    function StrategyService(http) {
        this.http = http;
    }
    StrategyService.prototype.getBaseUrl = function () {
        return environment.baseUrl;
    };
    StrategyService.prototype.getUrl = function (relativeUrl) {
        return this.getBaseUrl() + relativeUrl;
    };
    StrategyService.prototype.getOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    StrategyService.prototype.getFormDataOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    //Get industries list
    StrategyService.prototype.getIndustries = function () {
        var url = this.getUrl('/codes-industries');
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            return querySnapshot;
        }));
    };
    //Get locations list
    StrategyService.prototype.getLocations = function () {
        var url = this.getUrl('/codes-locations');
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            return querySnapshot;
        }));
    };
    //Get positions list
    StrategyService.prototype.getPositions = function () {
        var url = this.getUrl('/codes-positions');
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            return querySnapshot;
        }));
    };
    // Get Strategy Date
    StrategyService.prototype.getStrategyDate = function (_id) {
        var url = this.getUrl('/users/' + _id);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            return querySnapshot;
        }));
    };
    // Action Massage
    StrategyService.prototype.submitMessagingStrategy = function (InvationMasssage, NurtureMasssage, ActionMasssage, _id) {
        var url = this.getUrl('/users/' + _id);
        return this.http.put(url, {
            strategy_invation_massage: InvationMasssage,
            strategy_nurture_massage: NurtureMasssage,
            strategy_action_massage: ActionMasssage,
        }, this.getOptions())
            .pipe(map(function (res) {
            return (true);
        }));
    };
    StrategyService.prototype.submitMessagingStrategyLog = function (_id, data) {
        var url = this.getUrl('/logs');
        data['user_id'] = _id;
        return this.http.post(url, data, this.getOptions())
            .pipe(map(function (res) {
            return true;
        }));
    };
    StrategyService.prototype.submitStrategy = function (formValue) {
        var url = this.getUrl('/z-strategies');
        return this.http.post(url, formValue, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    StrategyService.prototype.getStrategy = function (strategyId) {
        var url = this.getUrl('/z-strategies/' + strategyId);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    StrategyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StrategyService_Factory() { return new StrategyService(i0.ɵɵinject(i1.HttpClient)); }, token: StrategyService, providedIn: "root" });
    return StrategyService;
}());
export { StrategyService };
