<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="d-flex align-items-center" *ngIf="!isLoading">
  <span class="mr-3">Show</span>
  <select
    class="browser-default custom-select"
    style="width: 200px"
    [(ngModel)]="showValue"
    (change)="filter()"
  >
    <option value="">All</option>
    <option value="in_progress">Research In Progress</option>
    <option value="research_finalized">Research Finalized</option>
  </select>
  <span class="ml-5 mr-3">Company</span>
  <select
    class="browser-default custom-select"
    style="width: 200px"
    [(ngModel)]="companyValue"
    (change)="filter()"
  >
    <option selected value="">All</option>
    <option *ngFor="let company of companies" value="{{ company }}">
      {{ company }}
    </option>
  </select>
  <span class="ml-5 mr-3">User</span>
  <select
    class="browser-default custom-select"
    style="width: 200px"
    [(ngModel)]="userValue"
    (change)="filter()"
  >
    <option selected value="">All</option>
    <option *ngFor="let user of users" value="{{ user._id }}">
      {{ user.name }}
    </option>
  </select>

  <fa
    name="fas fa-undo"
    class="ml-3 reset-filter"
    (click)="resetFilter()"
    title="Reset filters"
  ></fa>
</div>

<div class="table-responsive">
  <table
    class="table table-striped table-padding"
    [mfData]="filteredAvlists"
    #mf="mfDataTable"
    (mfSort)="onSort($event)"
  >
    <thead>
      <tr>
        <th>
          <mfDefaultSorter by="company">Company</mfDefaultSorter>
        </th>
        <th>
          <mfDefaultSorter by="name">Client</mfDefaultSorter>
        </th>
        <th>
          <mfDefaultSorter by="listName">List Name</mfDefaultSorter>
        </th>
        <th class="text-center custom-margin-right">
          <mfDefaultSorter by="status">Status</mfDefaultSorter>
        </th>
        <th class="text-center custom-margin-right">
          <mfDefaultSorter by="ext_rec_count">Count</mfDefaultSorter>
        </th>
        <th class="text-center custom-margin-right">
          <mfDefaultSorter
            by="emptyFields"
            mdbTooltip="Empty Fields"
            placement="bottom"
            mdbWavesEffect
          >
            EF
          </mfDefaultSorter>
        </th>
        <th>
          <mfDefaultSorter by="researcher">Researcher</mfDefaultSorter>
        </th>
        <th class="text-center custom-margin-right">
          <mfDefaultSorter by="createdAt">Created On</mfDefaultSorter>
        </th>
        <th class="text-center custom-margin-right">
          <mfDefaultSorter by="finalized_on">Finalized On</mfDefaultSorter>
        </th>
        <th class="custom-margin-right">
          <mfDefaultSorter by="finalizedNote">Finalized Note</mfDefaultSorter>
        </th>
        <th class="custom-margin-right">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let avlist of mf.data">
        <td>{{ avlist.company }}</td>
        <td>{{ avlist.name }}</td>
        <td>
          <!-- <span mdbTooltip="{{avlist.listDescription}}" placement="bottom" mdbWavesEffect>{{avlist.listName}}</span> -->
          <span mdbTooltip="{{ avlist.listName }}" placement="bottom">
            {{
              avlist.listName.length > 20
                ? (avlist.listName | slice : 0 : 20) + "..."
                : avlist.listName
            }}
          </span>
        </td>
        <td
          class="d-flex justify-content-center align-items-center listPrepComplete"
        >
          <div
            *ngIf="!avlist.research_finalized && !avlist.listPrepComplete"
            class="pointer"
            style="background-color: #d5d5d5"
            (click)="
              avlist.ext_rec_count > 0
                ? updateListPrep(avlist, true)
                : $event.preventDefault()
            "
            mdbTooltip="{{
              avlist.ext_rec_count === 0
                ? 'Sorry, this list cannot be finalized as there are no prospects in the list.'
                : 'Click to Finalize the List'
            }}"
            placement="top"
          ></div>
          <div
            *ngIf="avlist.research_finalized && !avlist.listPrepComplete"
            class="pointer"
            style="background-color: #d5fab8"
            (click)="updateListPrep(avlist,false)"
            mdbTooltip="Click to Release the list for the client"
            placement="top"
          ></div>
          <div
            *ngIf="avlist.research_finalized && avlist.listPrepComplete"
            class="pointer"
            style="background-color: #609d62"
            mdbTooltip="List is finalized and released"
            placement="top"
          >
            >
          </div>
        </td>
        <td class="text-center">{{ avlist.ext_rec_count }}</td>
        <td class="text-center">
          <span>{{ avlist.emptyFields }}</span>
        </td>
        <td class="text-left">
          <span>{{ avlist.researcher }}</span>
        </td>
        <td class="text-center">
          <span>{{ avlist.createdAt | date : "M/dd/yy" }}</span>
        </td>
        <td class="text-center">
          <span>{{ avlist.finalized_on | date : "M/dd/yy h:mm aaa" }}</span>
        </td>
        <td class="text-left">
          <span class="truncate" title="{{ avlist.research_finalized_note }}">
            {{ avlist.research_finalized_note }}
          </span>
        </td>
        <td class="d-flex">
          <a
            data-toggle="editModal"
            data-target="#basicExample"
            (click)="openEditModal(avlist)"
          >
            <fa name="fas fa-edit"></fa>
          </a>
          <a
            data-toggle="deleteModal"
            data-target="#basicExample"
            (click)="openDeleteModal(avlist._id)"
          >
            <i class="fas fa-trash-alt"></i>
          </a>
          <a
            *ngIf="avlist.uploadedCount > 0"
            href="javascript:void(0)"
            (click)="listContacts(avlist, 'all')"
            title="View All"
          >
            <fa name="fas fa-users"></fa>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div
  mdbModal
  style="overflow-y: auto"
  #editModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Edit List</p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="editModal.hide()"
        >
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="form-group">
          <label for="listName">List Name</label>
          <input
            type="text"
            class="form-control"
            id="listName"
            name="listName"
            [(ngModel)]="listName"
          />
        </div>
        <div class="form-group form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="listPrepComplete"
            name="listPrepComplete"
            [(ngModel)]="listPrepComplete"
          />
          <label class="form-check-label" for="listPrepComplete"
            >List Preparation Complete</label
          >
        </div>
        <div class="form-group">
          <label for="listDescription">Description</label>
          <textarea
            class="form-control"
            id="listDescription"
            name="listDescription"
            [(ngModel)]="listDescription"
            rows="5"
          ></textarea>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-primary" (click)="editList()">
          Save
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="editModal.hide()"
        >
          Close
        </button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<div
  mdbModal
  style="overflow-y: auto"
  #deleteModal="mdbModal"
  class="modal fade top"
  id="frameModalTop"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Delete List</p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="deleteModal.hide()"
        >
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body edit-list" *ngIf="deleteId">
        Are you sure you want to delete this list and all the records associated
        with it?
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a
          type="button"
          mdbBtn
          color="primary"
          outline="true"
          class="waves-effect"
          mdbWavesEffect
          (click)="deleteList()"
          >Yes</a
        >
        <a
          type="button"
          mdbBtn
          color="primary"
          outline="true"
          class="waves-effect"
          mdbWavesEffect
          (click)="deleteModal.hide()"
          >No</a
        >
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
