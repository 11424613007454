<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="container campaigns" *ngIf="!isLoading">
    <h3>Messaging Sequence</h3>
    <div *ngIf="dropdownList.length > 0 && index != -1">
        <span [innerHTML]="dropdownList[index].bigMessage"></span><br>
        <span [innerHTML]="dropdownList[index].smallMessage"></span>
    </div>

    <form [formGroup]="stepTwoForm" (ngSubmit)="stepTwoSubmit()" autocomplete="off" novalidate #formRef="ngForm">

        <div class="input">
            <label>Message Type </label>
            <select class="browser-default custom-select custom-select-lg" formControlName="messageType" (change)="changeMessageType()">
                <option value=""></option>
                <option *ngFor="let template of dropdownList | slice:0:3" value="{{template.messageTypeName}}">
                    {{template.messageTypeName}}
                </option>
            </select>
            <span class="error" *ngIf="stepTwoForm.controls['messageType'].hasError('required') && formRef.submitted">
                Please select the type of message. This is used internally to view the sequence.
            </span>
        </div>

        <div class="input">
            <label>Message </label>
            <textarea class="form-control" formControlName="message" rows="7" maxlength="1990"></textarea>
            <span>{{1990 - stepTwoForm.value.message.length}} characters remaining</span>
            <br>
            <span class="error" *ngIf="stepTwoForm.controls['message'].hasError('required') && formRef.submitted">
                Please enter a message
            </span>
        </div>

        <div class="input">
            <mdb-checkbox formControlName="draft" (change)="getCheckboxesValue($event)">I'd like to come back and edit this message later</mdb-checkbox>
        </div>

        <div class="input my-4" *ngIf="previewMessages.length > 0">
            <span>Send this message </span>
            <select class="fancyscroll" formControlName="delay" [value]="defaultDelay">
                <option *ngFor='let in of counter(21) ;let i = index' value="{{i+1}}">{{i+1}}</option>
            </select>
            <span *ngIf="previewMessages.length === 1 && previewMessages[0].messageType === 'Connection Request'; else templateName">
                days after the connection is accepted
            </span>
            <ng-template #templateName>
                <span>days after the previous message</span>
            </ng-template>
        </div>

        <div class="buttons">
            <button type="submit" *ngIf="editMode" class="previous" (click)="clickDoneButton()">Done</button>
            <button type="button" *ngIf="!editMode" class="previous" (click)="cancelNewCampaign()">Cancel</button>
            <button type="button" class="previous" (click)="back()">Previous</button>
            <button type="submit">Next</button>
        </div>

    </form>
</div>