import { OnInit } from '@angular/core';
import { User } from '../../../models/user';
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(userService, authenticationService, router) {
        this.userService = userService;
        this.authenticationService = authenticationService;
        this.router = router;
        this.isLoading = true;
        this.skills = [{ value: 'Security' }, { value: 'Network Security' }, { value: 'Strategic Planning' }];
    }
    ProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.getUserByKey(localStorage.getItem('USER_KEY')).subscribe(function (res) {
            _this._id = res._id;
            _this.userName = res.name;
            _this.email = res.email;
            _this.notifications = res.notifications;
            _this.isLoading = false;
        });
    };
    ProfileComponent.prototype.saveChanges = function () {
        var _this = this;
        if (!this.userName) {
            alert('Please enter user name.');
            return;
        }
        else if (!this.email) {
            alert('Please enter email address.');
            return;
        }
        else if (!this.validateEmail(this.email)) {
            alert('Please enter valid email.');
            return;
        }
        else if (this.password && this.password.length < 6) {
            alert('password must be at least 6 characters length.');
            return;
        }
        else if (this.password && this.password != this.confirmPassword) {
            alert('password and confirm password do not match.');
            return;
        }
        this.isLoading = true;
        var user = new User();
        user._id = this._id;
        user.name = this.userName;
        user.email = this.email;
        this.userService.updateProfile(user).subscribe(function (result) {
            localStorage.setItem('USER_NAME', user.name);
            localStorage.setItem('USER_EMAIL', user.email);
            if (_this.password) {
                _this.authenticationService.changeUserPassword(_this.password).subscribe(function (result) {
                    _this.isLoading = false;
                    _this.successModal.show();
                }, function (err) {
                    _this.isLoading = false;
                    alert('error');
                    console.log('error to change use password: ', err);
                });
            }
            else {
                _this.isLoading = false;
                _this.successModal.show();
            }
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to update profile: ', err);
        });
    };
    ProfileComponent.prototype.validateEmail = function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    ProfileComponent.prototype.toggleNotifications = function () {
        var _this = this;
        this.notifications = !this.notifications;
        this.isLoading = true;
        this.userService.toggleNotifications(this._id, this.notifications).subscribe(function (res) {
            _this.isLoading = false;
        });
    };
    return ProfileComponent;
}());
export { ProfileComponent };
