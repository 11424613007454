/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/angular-font-awesome/dist/angular-font-awesome.ngfactory";
import * as i4 from "angular-font-awesome";
import * as i5 from "../../../../../node_modules/ag-grid-angular/ag-grid-angular.ngfactory";
import * as i6 from "ag-grid-angular";
import * as i7 from "./user-message.component";
import * as i8 from "../../../services/user.service";
var styles_UserMessageComponent = [i0.styles];
var RenderType_UserMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserMessageComponent, data: {} });
export { RenderType_UserMessageComponent as RenderType_UserMessageComponent };
function View_UserMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading\u2026"]))], null, null); }
export function View_UserMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserMessageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fa", [["name", "user"]], null, null, null, i3.View_AngularFontAwesomeComponent_0, i3.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(4, 114688, null, 0, i4.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null), (_l()(), i1.ɵted(5, null, [" ", " - Messages\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["id", "gridContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-alpine"], ["style", "width: 100%; height: calc(100% - 17px)"]], [[8, "enableColResize", 0], [8, "enableSorting", 0], [8, "enableFilter", 0]], [[null, "columnResized"], [null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("columnResized" === en)) {
        var pd_0 = (_co.onColumnResized($event) !== false);
        ad = (pd_0 && ad);
    } if (("gridReady" === en)) {
        var pd_1 = (_co.onGridReady($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_AgGridAngular_0, i5.RenderType_AgGridAngular)), i1.ɵprd(512, null, i6.AngularFrameworkOverrides, i6.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i6.AngularFrameworkComponentWrapper, i6.AngularFrameworkComponentWrapper, []), i1.ɵdid(10, 4898816, null, 1, i6.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i6.AngularFrameworkOverrides, i6.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"], overlayNoRowsTemplate: [2, "overlayNoRowsTemplate"], suppressHorizontalScroll: [3, "suppressHorizontalScroll"], pagination: [4, "pagination"], paginationAutoPageSize: [5, "paginationAutoPageSize"] }, { columnResized: "columnResized", gridReady: "gridReady" }), i1.ɵqud(603979776, 1, { columns: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = "user"; _ck(_v, 4, 0, currVal_1); var currVal_6 = _co.rows; var currVal_7 = _co.columnDefs; var currVal_8 = _co.noDataText; var currVal_9 = true; var currVal_10 = true; var currVal_11 = true; _ck(_v, 10, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.userMessageName; _ck(_v, 5, 0, currVal_2); var currVal_3 = true; var currVal_4 = true; var currVal_5 = true; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_UserMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-message", [], null, null, null, View_UserMessageComponent_0, RenderType_UserMessageComponent)), i1.ɵdid(1, 114688, null, 0, i7.UserMessageComponent, [i8.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserMessageComponentNgFactory = i1.ɵccf("app-user-message", i7.UserMessageComponent, View_UserMessageComponent_Host_0, {}, {}, []);
export { UserMessageComponentNgFactory as UserMessageComponentNgFactory };
