import { Message } from './message';
export class Message_Beta {
  body: string;
  id: string;
  thread: string;
  tstamp: any;
  contact: string;
  contactURL: string;
  company_url: string;
  company: string = "";
  pxDataId:string;
  pxDataStatus:any="";
  stopmsg: boolean = false;
  from_avdata: boolean;
  subRows: Array<Message>;
}
