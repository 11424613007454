<!-- Material form register -->
<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div id="mainDiv" class="form-row" *ngIf="!isLoggedIn">
    <div class="col-sm-6 offset-sm-3">
        <mdb-card>
            <mdb-card-header class="primary-color white-text text-center py-6">
                <h5>
                    <strong>Login Form</strong>
                </h5>
            </mdb-card-header>
            <!--Card content-->
            <mdb-card-body class="px-lg-5 pt-0">
                <!-- Form -->
                <form style="color: #757575;" [formGroup]="validationForm">
                    <div class="md-form">
                        <mdb-icon fas icon="envelope" class="prefix"></mdb-icon>
                        <input mdbInput mdbValidate formControlName="emailFormEx" type="email" class="form-control" id="email" (keypress)="keyPressed($event)" [(ngModel)]="email" name="email" aria-describedby="email">
                        <label for="email">E-mail</label>
                        <mdb-error *ngIf="emailFormEx.invalid && (emailFormEx.dirty || emailFormEx.touched)">
                        </mdb-error>
                        <mdb-success *ngIf="emailFormEx.valid && (emailFormEx.dirty || emailFormEx.touched)">Input valid
                        </mdb-success>
                    </div>

                    <div class="md-form">
                        <mdb-icon fas icon="lock" class="prefix"></mdb-icon>
                        <input mdbInput mdbValidate formControlName="passwordFormEx" type="password" class="form-control" id="password" (keypress)="keyPressed($event)" [(ngModel)]="password" name="password" aria-describedby="password">
                        <label for="password">Type your password</label>
                        <mdb-error *ngIf="passwordFormEx.invalid && (passwordFormEx.dirty || passwordFormEx.touched)">
                            Input invalid
                        </mdb-error>
                        <mdb-success *ngIf="passwordFormEx.valid && (passwordFormEx.dirty || passwordFormEx.touched)">
                            Input valid
                        </mdb-success>
                    </div>
                    <div class="text-center">
                        <a style="color:#33b5e5" class="relative waves-light" (click)="resetPassword()" mdbWavesEffect>Reset
                            password</a>
                    </div>
                    <!-- Sign up button -->
                    <button mdbBtn color="info" outline="true" rounded="true" (click)="login()" block="true" class="my-4 waves-effect z-depth-0" mdbWavesEffect type="button">login</button>
                    <hr>
                </form>
                <!-- Form -->
            </mdb-card-body>
        </mdb-card>
    </div>
</div>

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">Reset password</h4>
            </div>
            <div class="modal-body">
                <div class="form-group col-sm-12">
                    <label for="email2">Email Address</label>
                    <input type="email2" class="form-control" id="email2" name="email2" [(ngModel)]="email2" placeholder="Enter email">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" (click)="sendToReset()" class="relative waves-light" mdbWavesEffect>Send</button>
            </div>
        </div>
    </div>
</div>

<!-- Material form register -->