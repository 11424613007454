import jwt_decode from 'jwt-decode';
import { OnInit } from '@angular/core';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(authenticationService, router, route) {
        this.authenticationService = authenticationService;
        this.router = router;
        this.route = route;
        this.isLoading = true;
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authenticationService.sharedLoginStatus.subscribe(function (status) { return _this.isLoggedIn = status; });
        this.authenticationService.logout().subscribe(function (res) {
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to logout: ', err);
        });
        this.reset_token = this.route.snapshot.paramMap.get('reset-token');
        var data = jwt_decode(this.reset_token);
        if (data) {
            this.email = data.email;
        }
    };
    ResetPasswordComponent.prototype.save = function () {
        var _this = this;
        if (!this.password) {
            alert('Please enter your new password.');
            return false;
        }
        this.isLoading = true;
        this.authenticationService.resetUserPassword(this.password, this.reset_token).subscribe(function (res) {
            _this.isLoading = false;
            if (res) {
                alert("Password changed. You can now sign in with your new password");
                _this.router.navigate(['/login']);
                return true;
            }
            else {
                alert('Error resetting password!');
                return false;
            }
        }, function (err) {
            _this.isLoggedIn = false;
            _this.isLoading = false;
            alert('Error resetting password!');
            console.log('Error resetting password: ', err);
            return false;
        });
        // Add a default return value
        return true;
    };
    ResetPasswordComponent.prototype.keyPressed = function (event) {
        if (event.which === 13 || event.keyCode === 13) {
            this.save();
        }
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
