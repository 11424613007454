import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ca-reports',
  templateUrl: './ca-reports.component.html',
  styleUrls: ['./ca-reports.component.scss']
})
export class CaReportsComponent implements OnInit {
constructor() { }
  isLoading = false;
  ngOnInit() {
    
  }

}
