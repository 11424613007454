import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var ConnectionRequestComponent = /** @class */ (function () {
    function ConnectionRequestComponent(fb, sequenceService, router) {
        this.fb = fb;
        this.sequenceService = sequenceService;
        this.router = router;
        this.isLoading = true;
        this.dropdownList = [];
        this.clickByDoneButton = false;
        this.editMode = localStorage.getItem("EDIT_SEQUENCE") ? true : false;
        this.stepTwoForm = this.fb.group({
            messageType: this.fb.control('Connection Request'),
            sequenceId: this.fb.control('', Validators.required),
            message: this.fb.control('', Validators.required),
            draft: this.fb.control(false)
        });
    }
    ConnectionRequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        var campaignInfo = localStorage.getItem('CAMPAIGN_INFO');
        if (!campaignInfo) {
            this.router.navigate(['campaigns']);
            return;
        }
        var messageInfo = localStorage.getItem('CONNECTION_MESSAGE_INFO');
        var message = "";
        if (messageInfo) {
            message = JSON.parse(messageInfo).message;
        }
        this.stepTwoForm = this.fb.group({
            messageType: this.fb.control('Connection Request'),
            sequenceId: this.fb.control(JSON.parse(campaignInfo).sequenceId),
            message: this.fb.control(message, Validators.required),
            draft: this.fb.control(false)
        });
        this.sequenceService.getMessageTemplates().subscribe(function (messageTemplates) {
            _this.isLoading = false;
            _this.dropdownList = messageTemplates;
        }, function (err) {
            alert('error');
            console.log(err);
        });
    };
    ConnectionRequestComponent.prototype.stepTwoSubmit = function () {
        var _this = this;
        console.log(this.stepTwoForm);
        if (this.stepTwoForm.valid) {
            this.isLoading = true;
            this.sequenceService.submitSequence(this.stepTwoForm.value).subscribe(function (res) {
                console.log(res);
                if (_this.clickByDoneButton) {
                    _this.router.navigate(['campaigns']);
                }
                else {
                    localStorage.setItem("CONNECTION_MESSAGE_INFO", JSON.stringify(res));
                    _this.isLoading = false;
                    _this.router.navigate(['message-template']);
                }
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log(err);
            });
        }
    };
    ConnectionRequestComponent.prototype.cancelNewCampaign = function () {
        this.router.navigate(['campaigns']);
    };
    ConnectionRequestComponent.prototype.gotoIntroMessage = function () {
        this.router.navigate(['intro-message-sequence']);
    };
    ConnectionRequestComponent.prototype.getCheckboxesValue = function (event) {
        this.stepTwoForm.value.draft = event.checked;
        if (!event.checked) {
            this.stepTwoForm.get('message').setValidators([Validators.required]);
        }
        else {
            this.stepTwoForm.get('message').clearValidators();
        }
        this.stepTwoForm.controls["message"].updateValueAndValidity();
    };
    ConnectionRequestComponent.prototype.clickDoneButton = function () {
        this.clickByDoneButton = true;
    };
    return ConnectionRequestComponent;
}());
export { ConnectionRequestComponent };
