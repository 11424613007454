import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { getUserRole } from 'src/app/helpers/getUserRole';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(userService, router) {
        this.userService = userService;
        this.router = router;
        this.userValue = '';
        this.isAdmin = false;
        this.isLoading = true;
        this.companyValue = '';
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        localStorage.removeItem('USER_GROUPS_MEMBERID');
        if (getUserRole() === '1' || getUserRole() === '2') {
            this.isAdmin = true;
            this.userService.getUsersDashboard().subscribe(function (res) {
                _this.dropdownUsers = tslib_1.__spread(res.users).sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
                _this.users = tslib_1.__spread(res.users).sort(function (a, b) {
                    if (a.company.toLowerCase() < b.company.toLowerCase()) {
                        return -1;
                    }
                    if (a.company.toLowerCase() > b.company.toLowerCase()) {
                        return 1;
                    }
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
                _this.filteredUsers = _this.users;
                _this.companies = res.companies.sort(function (a, b) {
                    if (a.toLowerCase() < b.toLowerCase()) {
                        return -1;
                    }
                    if (a.toLowerCase() > b.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
                _this.isLoading = false;
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to get dashboard: ', err);
            });
        }
        else {
            this.isLoading = false;
            this.isAdmin = false;
        }
    };
    HomeComponent.prototype.openModal = function (user) {
        this.selectedUser = user;
        this.modal.show();
    };
    HomeComponent.prototype.userReview = function (user) {
        if (user._id != localStorage.getItem('USER_REVIEW_KEY')) {
            localStorage.removeItem('USER_AVDATA_CACHED_AT');
        }
        localStorage.setItem('USER_REVIEW_KEY', user._id);
        localStorage.setItem('USER_REVIEW_NAME', user.name);
        this.router.navigate(['/userReview']);
    };
    HomeComponent.prototype.userMessage = function (user) {
        if (user._id != localStorage.getItem('USER_MESSAGE_KEY')) {
            localStorage.removeItem('USER_MESSAGES_CACHED_AT');
        }
        localStorage.setItem('USER_MESSAGE_KEY', user._id);
        localStorage.setItem('USER_MESSAGE_NAME', user.name);
        this.router.navigate(['/userMessage']);
    };
    HomeComponent.prototype.userLists = function (user) {
        localStorage.setItem('USER_LISTS_KEY', user._id);
        localStorage.setItem('LISTS_COMPANY_FILTER', user.company);
        localStorage.setItem('LISTS_USER_FILTER', user._id);
        this.router.navigate(['/lists']);
    };
    HomeComponent.prototype.userConnections = function (user) {
        if (user._id != localStorage.getItem('USER_CONNECTIONS_KEY')) {
            localStorage.removeItem('USER_PXDATA_CACHED_AT');
        }
        localStorage.setItem('USER_CONNECTIONS_KEY', user._id);
        localStorage.setItem('USER_CONNECTIONS_NAME', user.name);
        localStorage.setItem('USER_CONNECTIONS_LINKEDINID', user.linkedInProfileId);
        this.router.navigate(['/userConnections']);
    };
    HomeComponent.prototype.userGroups = function (user) {
        localStorage.setItem('USER_GROUPS_MEMBERID', user.memberId);
        this.router.navigate(['/groups']);
    };
    HomeComponent.prototype.userReviewed = function (user) {
        if (user._id != localStorage.getItem('USER_REVIEWED_KEY')) {
            localStorage.removeItem('USER_REVIEWED_AVDATA_CACHED_AT');
        }
        localStorage.setItem('USER_REVIEWED_KEY', user._id);
        localStorage.setItem('USER_REVIEWED_NAME', user.name);
        this.router.navigate(['/userReviewed']);
    };
    HomeComponent.prototype.userTargetProspect = function (user) {
        localStorage.setItem('USER_KEY', user._id);
        this.router.navigate(['/target-prospects']);
    };
    HomeComponent.prototype.userMassagingStrategy = function (user) {
        localStorage.setItem('USER_KEY', user._id);
        this.router.navigate(['/message-strategy']);
    };
    HomeComponent.prototype.userCampaigns = function (user) {
        localStorage.setItem('USER_CAMPAIGNS_KEY', user._id);
        localStorage.setItem('USER_CAMPAIGNS_NAME', user.name);
        this.router.navigate(['/client-campaigns']);
    };
    HomeComponent.prototype.isEightDayGapCheck = function (date) {
        if (date === 0) {
            return false;
        }
        var diff = Math.abs(new Date().getTime() - new Date(date).getTime()) / 3600000;
        if (diff > 192) {
            return true;
        }
        return false;
    };
    HomeComponent.prototype.isTwoDayGapCheck = function (date) {
        if (!date) {
            return false;
        }
        var diff = Math.abs(new Date().getTime() - new Date(date).getTime()) / 3600000;
        if (diff > 48) {
            return true;
        }
        return false;
    };
    HomeComponent.prototype.isTwoDayGapWithR4RCheck = function (lastApprovedOn, r4rCount) {
        if (lastApprovedOn === 0 || r4rCount === 0) {
            return false;
        }
        else {
            var diff = Math.abs(new Date().getTime() - new Date(lastApprovedOn).getTime()) / 3600000;
            if (diff > 192) {
                return true;
            }
            return false;
        }
    };
    HomeComponent.prototype.filter = function () {
        var _this = this;
        this.filteredUsers = this.users;
        if (this.companyValue) {
            this.filteredUsers = this.filteredUsers.filter(function (item) { return item.company === _this.companyValue; });
        }
        if (this.userValue) {
            this.filteredUsers = this.filteredUsers.filter(function (item) { return item._id === _this.userValue; });
        }
        return this.filteredUsers;
    };
    return HomeComponent;
}());
export { HomeComponent };
