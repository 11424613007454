import { OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';
import * as moment from 'moment';
import { map, tap } from 'rxjs/operators';
var MessagesComponentBeta = /** @class */ (function () {
    function MessagesComponentBeta(slackService, document, userService, router, cdr) {
        this.slackService = slackService;
        this.document = document;
        this.userService = userService;
        this.router = router;
        this.cdr = cdr;
        this.rows = [];
        this.totalRecords = 0;
        this.skip = 0;
        this.count = 25;
        this.conversations = [];
        this.isLoading = true;
        this.userLatestMessage = [];
        this.noDataText = 'No messages right now';
        this.leadStatusOptions = [];
        this.cols = [
            { field: 'prospect', header: 'Prospect' },
            { field: 'last_incoming_message', header: 'Last Incoming Message' },
            { field: 'createdAt', header: 'Date/Time' },
        ];
    }
    MessagesComponentBeta.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.getUserByKey(localStorage.getItem('USER_KEY')).subscribe(function (user) {
            if (user.leadStatusOptions.length == 0) {
                _this.leadStatusOptions = ["", 0, 1, 2, 3, 4];
            }
            else {
                _this.leadStatusOptions = [""].concat(user.leadStatusOptions);
            }
        });
        this.fetchConversations();
    };
    MessagesComponentBeta.prototype.ngAfterViewInit = function () {
        this.cdr.detectChanges();
    };
    MessagesComponentBeta.prototype.updateStatus = function (newValue, id) {
        var status, newVal = this.leadStatusOptions.findIndex(function (x) { return x == newValue; });
        if (newVal == 0)
            status = '';
        this.userService.updatePXDataStatus(id, newVal != -1 ? newVal : newValue).subscribe(function (response) {
            console.log(response);
        });
    };
    MessagesComponentBeta.prototype.fetchConversations = function () {
        var _this = this;
        this.isLoading = true;
        this.userService.getIncomingMessages(localStorage.getItem('USER_KEY'), localStorage.getItem('USER_MEMBER_ID'), this.skip, this.count).subscribe(function (data) {
            _this.isLoading = false;
            _this.conversations = data;
            console.log(_this.conversations);
        }, function (err) {
            _this.isLoading = false;
            alert('Error fetching conversations');
            console.log('Error getting user latest messages: ', err);
        });
    };
    MessagesComponentBeta.prototype.fetchAllThreads = function (thread, expanded) {
        var _this = this;
        if (!expanded)
            return;
        this.subRows = [];
        this.isLoading = true;
        this.userService.getThreadMessages(localStorage.getItem('USER_KEY'), thread).subscribe(function (data) {
            _this.isLoading = false;
            _this.subRows = data;
            for (var i in _this.conversations) {
                if (_this.conversations[i].thread == data[0].thread) {
                    _this.conversations[i].subRows = data;
                }
            }
            console.log(data);
        }, function (err) {
            _this.isLoading = false;
            alert('Error fetching thread messages');
            console.log('Error getting thread messages: ', err);
        });
    };
    MessagesComponentBeta.prototype.sendStopMessageSlack = function (message) {
        this.slackService.stopSlackMessage({ "text": message, "channel": 'messaging-alerts', "username": 'Messaging Bot' }).subscribe();
    };
    MessagesComponentBeta.prototype.onPage = function (evt) {
        console.log(evt);
        this.skip = parseInt(evt.first);
        this.count = parseInt(evt.rows);
        this.fetchConversations();
    };
    MessagesComponentBeta.prototype.stopMessaging = function (rowData) {
        this.rowData = rowData;
        console.log(rowData);
        if (!this.rowData.stopmsg) {
            this.showConfirmDialog();
        }
        else {
            this.showInformDialog();
        }
    };
    MessagesComponentBeta.prototype.showConfirmDialog = function () {
        if (this.confirmModal && typeof this.confirmModal.show === 'function') {
            this.confirmModal.show();
        }
        else {
            console.error('Confirm modal not available');
            // Fallback to browser confirm if modal is not available
            if (confirm('Are you sure you want to stop messaging?')) {
                this.processStopMessaging();
            }
        }
    };
    MessagesComponentBeta.prototype.showInformDialog = function () {
        if (this.informModal && typeof this.informModal.show === 'function') {
            this.informModal.show();
        }
        else {
            alert('The message sequence for this person has already been stopped.');
        }
    };
    MessagesComponentBeta.prototype.onConfirmModalAction = function (isConfirmed) {
        if (isConfirmed) {
            this.processStopMessaging();
        }
        this.hideConfirmModal();
    };
    MessagesComponentBeta.prototype.processStopMessaging = function () {
        var elem = this.document.getElementById(this.rowData.id);
        var data = {};
        data.clientCompany = localStorage.getItem('USER_COMPANY');
        data.clientName = localStorage.getItem('USER_NAME');
        data.prospectName = this.rowData.contact;
        data.prospectLinkedinUrl = this.rowData.contactURL;
        if (elem) {
            elem.classList.add('msg-red');
        }
        var msg = "*** Stop Messaging Sequence ***\n    " + data.clientCompany + " - " + data.clientName + "\n    " + data.prospectName + " - " + data.prospectLinkedinUrl + "\n    Requested at: " + moment().format('MM/DD/YYYY hh:mm:ss A');
        this.sendStopMessageSlack(msg);
        this.rowData.stopmsg = true;
        this.userService.stopMessage(this.rowData.pxDataId)
            .pipe(tap(function (response) { return console.log('Raw API response:', response); }), map(function (response) {
            if (response && response.success) {
                return {
                    success: true,
                    message: 'Message sequence stopped successfully'
                };
            }
            else {
                return {
                    success: false,
                    message: response.message || 'Failed to stop message sequence'
                };
            }
        }))
            .subscribe(function (res) {
            console.log('Processed stop message response:', res);
            if (res.success) {
                console.log('Message sequence stopped successfully');
            }
            else {
                console.warn('Unexpected API response:', res);
            }
        }, function (err) {
            console.error('Error stopping message:', err);
        });
    };
    MessagesComponentBeta.prototype.hideConfirmModal = function () {
        if (this.confirmModal && typeof this.confirmModal.hide === 'function') {
            this.confirmModal.hide();
        }
    };
    return MessagesComponentBeta;
}());
export { MessagesComponentBeta };
