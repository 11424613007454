import { Subscription } from 'rxjs';
import { Papa } from 'ngx-papaparse';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';

import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-messagedata',
  templateUrl: './messagedata.component.html',
  styleUrls: ['./messagedata.component.css']
})
export class MessagedataComponent implements OnInit {

  accept = '*';
  users: User[];
  successMessage;
  lastFileAt: Date;
  progress: number;
  isLoading = false;
  processedFiles = 0;
  files: File[] = [];
  warningMessages = [];
  messageDataArray = [];
  httpEmitter: Subscription;
  sendableFormData: FormData;
  hasBaseDropZoneOver = false;
  httpEvent: HttpEvent<Event>;

  constructor(private userService: UserService, private papa: Papa, public HttpClient: HttpClient) { }

  cancel() {
    this.progress = 0;

    if (this.httpEmitter) {
      console.log('cancelled');
      this.httpEmitter.unsubscribe();
    }
  }

  uploadFiles(files: File[]): void {
    if (files && files.length > 0) {
      this.isLoading = true;
      this.warningMessages = [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.readAsText(files[i]);

        reader.onload = (data) => {
          this.papa.parse(reader.result.toString(), {
            header: true,

            complete: (parsedData) => {
              if (!files[i].name.includes('messages-')) {
                this.warningMessages.push({ filename: files[i].name, message: "This file cannot be imported. Please make sure the filename contains messages-." });
              }

              if (this.warningMessages.length === 0) {
                this.userService.updateMessageData(parsedData.data).subscribe((res) => {
                  if (res.message === 'no found user in messages') {
                    this.warningMessages.push({ filename: files[i].name, message: "This file could not be imported since there is not user related to the messages" });
                  }

                  this.processedFiles++;
                  console.log(`processed file #${this.processedFiles}`);

                  if (this.processedFiles === files.length) {
                    this.isLoading = false;
                    this.messageDataArray = null;
                    this.files = [];
                    this.processedFiles = 0;
                  }

                }, (err) => {
                  this.isLoading = false;
                  alert('error');
                  console.log('error to update messagesdata: ', err);
                });
              } else {
                this.isLoading = false;
              }
            }
          });
        };
      }
    } else {
      alert('Please select data file.');
    }

  }

  getDate() {
    return new Date();
  }

  ngOnInit() {
    this.successMessage = '';
  }

}
