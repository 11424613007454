import { ngfModule } from 'angular-file';
import { AgmCoreModule } from '@agm/core';
import { TableModule } from 'primeng/table'
import { ChartModule } from 'primeng/chart';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AgGridModule } from 'ag-grid-angular';
import { PapaParseModule } from 'ngx-papaparse';
import { NgStepperModule } from 'angular-ng-stepper';
import { DataTableModule } from 'angular-6-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { UiModule } from './ui/ui.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { OnlyNumber } from './directives/onlynumber.directive';
import { UserComponent } from './pages/rsa/user/user.component';
import { HomeComponent } from './pages/rsa/home/home.component';
import { ListsComponent } from './pages/eu/lists/lists.component';
import { GroupsComponent } from './pages/eu/groups/groups.component';
import { ReviewComponent } from './pages/eu/review/review.component';
import { ClientComponent } from './pages/rsa/client/client.component';
import { LoginComponent } from './pages/shared/login/login.component';
import { PxdataComponent } from './pages/rsa/pxdata/pxdata.component';
import { AvdataComponent } from './pages/rsa/avdata/avdata.component';
import { SettingComponent } from './pages/rsa/setting/setting.component';
import { MessagesComponent } from './pages/eu/messages/messages.component';
import { ProfileComponent } from './pages/shared/profile/profile.component';
import { CampaignsComponent } from './pages/eu/campaigns/campaigns.component';
import { CompaniesComponent } from './pages/rsa/companies/companies.component';
import { DashboardComponent } from './pages/rsa/dashboard/dashboard.component';
import { CaMessagesComponent } from './pages/ca/ca-messages/ca-messages.component';
import { ConnectionsComponent } from './pages/eu/connections/connections.component';
import { UserReviewComponent } from './pages/rsa/user-review/user-review.component';
import { MessagedataComponent } from './pages/rsa/messagedata/messagedata.component';
import { NewStrategyComponent } from './pages/eu/new-strategy/new-strategy.component';
import { CaDashboardComponent } from './pages/ca/ca-dashboard/ca-dashboard.component';
import { EuDashboardComponent } from './pages/eu/eu-dashboard/eu-dashboard.component';
import { StepOneComponent } from './pages/eu/new-strategy/step-one/step-one.component';
import { StepTwoComponent } from './pages/eu/new-strategy/step-two/step-two.component';
import { UserMessageComponent } from './pages/rsa/user-message/user-message.component';
import { StrategyTypeComponent } from './pages/eu/strategy-type/strategy-type.component';
import { MessagesComponentBeta } from './pages/eu/messages-beta/messages-beta.component';
import { ListContactsComponent } from './pages/eu/list-contacts/list-contacts.component';
import { ListsReportsComponent } from './pages/eu/lists-reports/lists-reports.component';
import { StepFiveComponent } from './pages/eu/new-strategy/step-five/step-five.component';
import { UserReviewedComponent } from './pages/rsa/user-reviewed/user-reviewed.component';
import { StepFourComponent } from './pages/eu/new-strategy/step-four/step-four.component';
import { CaConnectionsComponent } from './pages/ca/ca-connections/ca-connections.component';
import { CompanyPxdataComponent } from './pages/rsa/company-pxdata/company-pxdata.component';
import { SpecialAvdataComponent } from './pages/rsa/special-avdata/special-avdata.component';
import { StepThreeComponent } from './pages/eu/new-strategy/step-three/step-three.component';
import { ResetPasswordComponent } from './pages/shared/reset-password/reset-password.component';
import { MessageSendWhoComponent } from './pages/eu/message-send-who/message-send-who.component';
import { UnapprovedListsComponent } from './pages/eu/unapproved-lists/unapproved-lists.component';
import { UserConnectionsComponent } from './pages/rsa/user-connections/user-connections.component';
import { ClientCampaignsComponent } from './pages/rsa/client-campaigns/client-campaigns.component';
import { CampaignsSummaryComponent } from './pages/eu/campaigns-summary/campaigns-summary.component';
import { MessagePreviewComponent } from './pages/eu/message-sequence/message-preview/message-preview.component';
import { MessageTemplateComponent } from './pages/eu/message-sequence/message-template/message-template.component';
import { CreateNewCampaignComponent } from './pages/eu/campaigns/create-new-campaign/create-new-campaign.component';
import { ConnectionRequestComponent } from './pages/eu/message-sequence/connection-request/connection-request.component';
import { IntroMessageSequenceComponent } from './pages/eu/message-sequence/intro-message-sequence/intro-message-sequence.component';
import { ViewedProfilesComponent } from './pages/eu/viewedprofiles/viewedprofiles.component';
import { CaMessagesNewComponent } from './pages/ca/ca-messages-new/ca-messages-new.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CaReportsComponent } from './pages/ca/ca-reports/ca-reports.component';
import { CaReportsSentMsgComponent } from "./pages/ca/ca-reports-sent-msg/ca-reports-sent-msg.component"
import { IncomingConnectionsComponent } from './pages/eu/incoming-requests/incomingrequests.component';
import { EcEmailDataComponent } from './pages/rsa/ecemaildata/ecemaildata.component';
import { RequestedEmailComponent } from './pages/rsa/requested-email/requestedEmail.component';
import { IncomingMessagesComponent } from './pages/eu/incoming-messages/incoming-messages.component';
import { ListPrepComponent } from './pages/rsa/list-prep/list-prep.component';
import { ListPrepProspectComponent } from './pages/rsa/list-prep-prospects/list-prep-prospects.component';
import { ReviewListComponent } from './pages/eu/review-list/review-list.component';

@NgModule({
  declarations: [
    OnlyNumber,
    AppComponent,
    UserComponent,
    IncomingMessagesComponent,
    HomeComponent,
    ListsComponent,
    ListPrepComponent,
    LoginComponent,
    ClientComponent,
    PxdataComponent,
    AvdataComponent,
    ReviewComponent,
    ReviewListComponent,
    GroupsComponent,
    ViewedProfilesComponent,
    StepOneComponent,
    StepTwoComponent,
    SettingComponent,
    ProfileComponent,
    StepFiveComponent,
    StepFourComponent,
    MessagesComponent,
    CampaignsComponent,
    StepThreeComponent,
    DashboardComponent,
    CompaniesComponent,
    CaMessagesComponent,
    CaMessagesNewComponent,
    CaReportsComponent,
    CaReportsSentMsgComponent,
    UserReviewComponent,
    NewStrategyComponent,
    MessagedataComponent,
    EcEmailDataComponent,
    RequestedEmailComponent,
    ConnectionsComponent,
    IncomingConnectionsComponent,
    UserMessageComponent,
    CaDashboardComponent,
    EuDashboardComponent,
    StrategyTypeComponent,
    ListContactsComponent,
    ListsReportsComponent,
    MessagesComponentBeta,
    UserReviewedComponent,
    CaConnectionsComponent,
    CompanyPxdataComponent,
    SpecialAvdataComponent,
    ResetPasswordComponent,
    MessageSendWhoComponent,
    MessagePreviewComponent,
    UserConnectionsComponent,
    UnapprovedListsComponent,
    MessageTemplateComponent,
    ClientCampaignsComponent,
    CampaignsSummaryComponent,
    CreateNewCampaignComponent,
    ConnectionRequestComponent,
    IntroMessageSequenceComponent,
    ListPrepProspectComponent
  ],
  imports: [
    NgbTooltipModule,
    UiModule,
    ngfModule,
    ChartModule,
    TableModule,
    FormsModule,
    BrowserModule,
    UiSwitchModule,
    NgSelectModule,
    CKEditorModule,
    NgStepperModule,
    DataTableModule,
    PapaParseModule,
    HttpClientModule,
    AppRoutingModule,
    CdkStepperModule,
    RadioButtonModule,
    ReactiveFormsModule,
    ToastModule.forRoot(),
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
    AgGridModule.withComponents([]),
    MDBBootstrapModulesPro.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'Your_api_key',
    })
  ],

  providers: [PapaParseModule],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})

export class AppModule { }
