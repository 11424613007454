<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="d-flex align-items-center" *ngIf="!isLoading">
    <span class="mr-3">Show</span>
    <select class="browser-default custom-select" style="width: 200px;" [(ngModel)]="showValue" (change)="filter()">
        <option value=""> All</option>
        <option value="reviewed">Reviewed</option>
        <option selected value="r4r">Ready for Review</option>
        <option *ngIf="isRSA" value="research_finalized">Research Finalized</option>
    </select>
    <span *ngIf="isRSA" class="ml-5 mr-3">Company</span>
    <select *ngIf="isRSA" class="browser-default custom-select" style="width: 200px;" [(ngModel)]="companyValue" (change)="filter()">
        <option selected value=""> All</option>
        <option *ngFor="let company of companies" value="{{company}}">{{company}}</option>
    </select>
    <span *ngIf="isRSA || isCA" class="ml-5 mr-3">User</span>
    <select *ngIf="!isEU" class="browser-default custom-select" style="width: 200px;" [(ngModel)]="userValue" (change)="filter()">
        <option selected value=""> All</option>
        <option *ngFor="let user of users" value="{{user._id}}">{{user.name}}</option>
    </select>
    <span class="ml-5 mr-3">Date Range</span>
    <select class="browser-default custom-select" style="width: 100px;" [(ngModel)]="dateValue" (change)="filter(true)">
        <option selected value=""> All</option>
        <option value="7">7 days</option>
        <option value="14">14 days</option>
        <option value="30">30 days</option>
        <option value="60">60 days</option>
        <option value="90">90 days</option>
    </select>
    <div class="ml-4 form-check" *ngIf="!isEU">
        <input class="form-check-input" type="checkbox" id="excludemostrecentlists" [checked]="excludemostrecentlists" (click)="toggleExcludemostrecentlists()">
        <label class="form-check-label mr-3" for="excludemostrecentlists">
            Exclude most recent lists
        </label>
    </div>
    <fa name="fas fa-undo" class="ml-3 reset-filter" (click)="resetFilter()" title="Reset filters"></fa>
</div>

<div class="table-responsive">
    <table class="table table-striped" [mfData]="filteredAvlists" #mf="mfDataTable" [(mfSortBy)]="sortBy" [(mfSortOrder)]="sortOrder">
        <thead>
            <tr>
                <th *ngIf="isRSA">
                    <mfDefaultSorter by="company">Company</mfDefaultSorter>
                </th>
                <th *ngIf="!isEU">
                    <mfDefaultSorter by="name">User Name</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="listName">List Name</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="status">Status</mfDefaultSorter>
                </th>
                <th *ngIf="isRSA" class="text-center custom-margin-right">
                    <mfDefaultSorter by="status">Ready</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="uploadedOn">Uploaded On</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="uploadedCount">Count</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="approvedOn">Approved On</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="approvedCount">Approved</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="notApproved">Not Approved</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="approvedPercentage">Approved %</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="invitedCount">Invited</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="connectedCount">Connected</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="connectedPercentage" style="    padding: 0px 10px;">&nbsp;&nbsp;%</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="respondedCount">Responded</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right" *ngIf="!isEU">
                    <mfDefaultSorter by="positiveCount">Positive</mfDefaultSorter>
                </th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let avlist of mf.data">
                <td *ngIf="isRSA">{{avlist.company}}</td>
                <td *ngIf="!isEU">{{avlist.name}}</td>
                <td>
                    <span mdbTooltip="{{avlist.listDescription}}" placement="bottom" mdbWavesEffect>{{avlist.listName}}</span>
                </td>
                <td class="text-center">
                    <div *ngIf="avlist.approvedOn > 0">
                        <i class="fas fa-check-double" style="color: #219421"></i>
                    </div>
                    <div *ngIf="avlist.approvedOn === 0">
                        <i class="fas fa-check-double" style="color: #c0c3c0"></i>
                    </div>
                </td>
                <td *ngIf="isRSA" class="text-center listPrepComplete">
                    <div *ngIf="avlist.listPrepComplete" class="green pointer" [ngClass]=""></div>
                    <div *ngIf="!avlist.listPrepComplete" class="light-green pointer" [ngClass]=""
                        (click)="updateListPrep(avlist,false)">
                    </div>
                </td>
                <td class="text-center" [ngClass]="{'highlighted':isTwoDayGapCheck(avlist.uploadedOn)}" [title]="avlist.uploadedOn | date : 'MM/dd/yy hh:mm aaa'">
                    <span *ngIf="avlist.uploadedOn > 0">{{avlist.uploadedOn | date : 'MM/dd/yy'}}</span>
                </td>
                <td class="text-center">{{avlist.uploadedCount}}</td>
                <td class="text-center" [ngClass]="{'highlighted':isTwoDayGapCheck(avlist.approvedOn)}" [title]="avlist.approvedOn | date : 'MM/dd/yy hh:mm aaa'">
                    <span *ngIf="avlist.approvedOn > 0">{{avlist.approvedOn | date : 'MM/dd/yy'}}</span>
                </td>
                <td class="text-center"><span *ngIf="avlist.approvedCount > 0 && avlist.approvedOn > 0" (click)="openProfileModal(avlist)" [style.cursor]="isRSA? 'pointer': ''">{{avlist.approvedCount}}</span></td>
                <td class="text-center"><span *ngIf="avlist.notApproved > 0 && avlist.approvedOn > 0">{{avlist.notApproved}}</span></td>
                <td class="text-center"><span *ngIf="avlist.approvedPercentage > 0">{{avlist.approvedPercentage}} %</span></td>
                <td class="text-center"><span>{{avlist.invitedCount}}</span></td>
                <td class="text-center"><span>{{avlist.connectedCount}}</span></td>
                <td class="text-center"><span>{{avlist.connectedPercentage}} %</span></td>
                <td class="text-center"><span>{{avlist.respondedCount}}</span></td>
                <td class="text-center" *ngIf="!isEU"><span>{{avlist.positiveCount}}</span></td>
                <td class="d-flex">
                    <a *ngIf="isRSA" vdata-toggle="editModal" data-target="#basicExample" (click)="openEditModal(avlist)">
                        <fa name="fas fa-edit"></fa>
                    </a>
                    <a *ngIf="isRSA" data-toggle="deleteModal" data-target="#basicExample" (click)="openDeleteModal(avlist._id)">
                        <i class="fas fa-trash-alt"></i>
                    </a>
                    <a *ngIf="avlist.uploadedCount>0" href="javascript:void(0)" (click)="listContacts(avlist, 'all')" title="View All">
                        <fa name="fas fa-users"></fa>
                    </a>
                    <a *ngIf="avlist.approvedOn>0" href="javascript:void(0)" (click)="exportList(avlist)" title="Export List">
                        <fa name="fas fa-download"></fa>
                    </a>
                    <a *ngIf="avlist.approvedOn === 0" href="javascript:void(0)" (click)="listReviewbylist(avlist)" title="Ready for Review">
                        <fa name="user-o"></fa>
                    </a>
                    <a *ngIf="avlist.approvedOn>0" href="javascript:void(0)" (click)="listContacts(avlist, 'approved')" title="View Approved">
                        <fa name="fas fa-user-check" style="color: #32cd32"></fa>
                    </a>
                    <a *ngIf="avlist.approvedOn>0" href="javascript:void(0)" (click)="listContacts(avlist, 'not-approved')" title="View Not Approved">
                        <fa name="fas fa-user-times" style="color: red"></fa>
                    </a>
                </td>
            </tr>
            <tr class="total-results" *ngIf="mf.data.length">
                <th *ngIf="isRSA"></th>
                <th *ngIf="!isEU"></th>
                <th></th>
                <th></th>
                <th *ngIf="isRSA"></th>
                <th>Totals</th>
                <th>{{ stats.totalCount | number }}</th>
                <th></th>
                <th>{{ stats.totalApproved | number }}</th>
                <th>{{ stats.totalNotApproved | number }}</th>
                <th>{{ stats.percentageApproved.toFixed(0) }}%</th>
                <th>{{ stats.totalInvited | number }}</th>
                <th>{{ stats.totalConnected | number }}</th>
                <th>{{ stats.totalConnectedPercentage.toFixed(0) }}%</th>
                <th>{{ stats.totalResponded | number }}</th>
                <th *ngIf="!isEU">{{ stats.totalPositive }}</th>
                <th *ngIf="!isEU" colspan="2"></th>
                <th *ngIf="!isRSA && isEU" colspan="2"></th>

            </tr>
        </tbody>
    </table>
</div>

<div mdbModal style="overflow-y: auto" #editModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Edit List </p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="editModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body edit-list">
                <label for="listName" class="mt-2">List Name</label>
                <input type="text" class="form-control" id="listName" name="listName" [(ngModel)]="listName" />
            </div>
            <div class="modal-body edit-list-cb-panel">
                <label for="listName" class="mt-2">Ready</label>
                <input type="checkbox" class="" id="listPrepComplete" name="listPrepComplete" [(ngModel)]="listPrepComplete" />
            </div>
            <div class="modal-body edit-list">
                <label for="listDescription">Desciption</label>
                <textarea class="form-control" id="listDescription" name="listDescription" [(ngModel)]="listDescription" rows="5"></textarea>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="editList()">Save</a>
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="editModal.hide()">Close</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<div mdbModal style="overflow-y: auto" #deleteModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Delete List </p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body edit-list" *ngIf="deleteId">
                Are you sure you want to delete this list and all the records associated with it?
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="deleteList()">Yes</a>
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="deleteModal.hide()">No</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<div mdbModal style="overflow-y: auto" #profileModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-fluid modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead" *ngIf="selectedList">Approved Profile URLs from {{selectedList.listName}} for {{selectedList.company}} - {{selectedList.name}}
                </p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="profileModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body" *ngIf="profileURLs">
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let url of profileURLs">{{url}}</li>
                </ul>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="profileModal.hide()">Close</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>