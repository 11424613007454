import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Client } from '../models/client';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  public getBaseUrl(): string {
    return environment.baseUrl;
  }

  protected getUrl(relativeUrl: string) {
    return this.getBaseUrl() + relativeUrl;
  }

  protected getOptions(): any {
    const AuthorizeToken = localStorage.getItem('USER_TOKEN');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + AuthorizeToken
    });

    const options = {
      headers: headers
    };

    return options;
  }

  getClients(): Observable<Client[]> {
    const url = this.getUrl('/clients');

    return this.http.get(url, this.getOptions())
      .pipe(
        map((querySnapshot: any) => {
          const clients: Client[] = [];
          querySnapshot.forEach((data: any) => {
            clients.push({
              _id: data._id,
              fname: data.fname,
              lname: data.lname,
              email: data.email,
              phone: data.phone,
              uname: data.uname,
              Linkedin: data.Linkedin
            });
          });
          return clients;
        }));
  }

  deleteClient(client: Client): Observable<any> {
    const url = this.getUrl('/clients/' + client._id);

    return this.http.delete(url, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

  editClient(client: Client): Observable<Client> {
    const url = this.getUrl('/clients/' + client._id);

    return this.http.put(url, {
      fname: client.fname,
      lname: client.lname,
      email: client.email,
      phone: client.phone,
      uname: client.uname,
      Linkedin: client.Linkedin
    }, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  addClient(client: Client): Observable<Client> {
    const url = this.getUrl('/clients');

    return this.http.post(url, client, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
