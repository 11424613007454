<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="d-flex align-items-center" *ngIf="!isLoading">
    <span *ngIf="isRSA" class="mr-3">Company</span>
    <select *ngIf="isRSA" class="browser-default custom-select" style="width: 200px;" [(ngModel)]="companyValue" (change)="filter()">
        <option selected value=""> All</option>
        <option *ngFor="let company of companies" value="{{company}}">{{company}}</option>
    </select>
    <span *ngIf="isRSA || isCA" class="ml-5 mr-3">User</span>
    <select *ngIf="!isEU" class="browser-default custom-select" style="width: 200px;" [(ngModel)]="userValue" (change)="filter()">
        <option selected value=""> All</option>
        <option *ngFor="let user of users" value="{{user._id}}">{{user.name}}</option>
    </select>
</div>

<div class="table-responsive" *ngIf="filteredAvlists">
    <table class="table table-striped" [mfData]="filteredAvlists" #mf="mfDataTable" [(mfSortBy)]="sortBy" [(mfSortOrder)]="sortOrder">
        <thead>
            <tr>
                <th *ngIf="isRSA">
                    <mfDefaultSorter by="company">Company</mfDefaultSorter>
                </th>
                <th *ngIf="!isEU">
                    <mfDefaultSorter by="name">User Name</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="listName">List Name</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="status">Status</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="uploadedOn">Uploaded On</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="daysSinceUpload">Days since upload</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="uploadedCount">Count</mfDefaultSorter>
                </th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let avlist of mf.data">
                <td *ngIf="isRSA">{{avlist.company}}</td>
                <td *ngIf="!isEU">{{avlist.name}}</td>
                <td>{{avlist.listName}}</td>
                <td class="text-center">
                    <div *ngIf="avlist.approvedOn > 0">
                        <i class="fas fa-check-double" style="color: #219421"></i>
                    </div>
                    <div *ngIf="avlist.approvedOn === 0">
                        <i class="fas fa-check-double" style="color: #c0c3c0"></i>
                    </div>
                </td>
                <td class="text-center" [ngClass]="{'highlighted':isTwoDayGapCheck(avlist.uploadedOn)}" [title]="avlist.uploadedOn | date : 'MM/dd/yy hh:mm aaa'">
                    <span *ngIf="avlist.uploadedOn > 0">{{avlist.uploadedOn | date : 'MM/dd/yy'}}</span>
                </td>
                <td class="text-center">{{avlist.daysSinceUpload}}</td>
                <td class="text-center">{{avlist.uploadedCount}}</td>
                <td class="d-flex">
                    <a *ngIf="isRSA" vdata-toggle="editModal" data-target="#basicExample" (click)="openEditModal(avlist)">
                        <fa name="fas fa-edit"></fa>
                    </a>
                    <a *ngIf="isRSA" data-toggle="deleteModal" data-target="#basicExample" (click)="openDeleteModal(avlist._id)">
                        <i class="fas fa-trash-alt"></i>
                    </a>
                    <a *ngIf="avlist.uploadedCount>0" href="javascript:void(0)" (click)="listContacts(avlist, 'all')" title="View All">
                        <fa name="fas fa-users"></fa>
                    </a>
                    <a *ngIf="avlist.approvedOn === 0" href="javascript:void(0)" (click)="listReviewbylist(avlist)" title="Ready for Review">
                        <fa name="user-o"></fa>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div mdbModal style="overflow-y: auto" #editModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Edit List </p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="editModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body edit-list" *ngIf="listName">
                <label for="email">List Name</label>
                <input type="text" class="form-control" id="search" name="search" [(ngModel)]="listName" />
            </div>
            <div class="modal-body edit-list">
                <label for="listDescription">Desciption</label>
                <textarea class="form-control" id="listDescription" name="listDescription" [(ngModel)]="listDescription" rows="5"></textarea>
            </div>
            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="editList()">Save</a>
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="editModal.hide()">Close</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<div mdbModal style="overflow-y: auto" #deleteModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Delete List </p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body edit-list" *ngIf="deleteId">
                Are you sure you want to delete this list and all the records associated with it?
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="deleteList()">Yes</a>
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="deleteModal.hide()">No</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>