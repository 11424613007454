import { OnInit } from '@angular/core';
var ClientCampaignsComponent = /** @class */ (function () {
    function ClientCampaignsComponent(router, campaignService) {
        this.router = router;
        this.campaignService = campaignService;
        this.campaigns = [];
        this.campaignId = "";
        this.statusValue = "";
        this.isLoading = true;
        this.sortOrder = 'asc';
        this.filteredCampaigns = [];
        this.sortBy = 'campaignName';
        this.userId = localStorage.getItem('USER_CAMPAIGNS_KEY');
        this.userName = localStorage.getItem('USER_CAMPAIGNS_NAME');
    }
    ClientCampaignsComponent.prototype.ngOnInit = function () {
        this.loadCampaign();
    };
    ClientCampaignsComponent.prototype.loadCampaign = function () {
        var _this = this;
        localStorage.removeItem('CAMPAIGN_INFO');
        localStorage.removeItem('CONNECTION_MESSAGE_INFO');
        localStorage.removeItem('TEMPLATE_MESSAGE_INFO');
        localStorage.removeItem('EDIT_CAMPAIGN');
        localStorage.removeItem('EDIT_STRATEGY');
        localStorage.removeItem('EDIT_SEQUENCE');
        this.campaignService.getCampaigns(this.userId).subscribe(function (res) {
            _this.campaigns = res;
            console.log(res);
            _this.filter();
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log(err);
        });
    };
    ClientCampaignsComponent.prototype.filter = function () {
        this.filteredCampaigns = this.campaigns;
        if (this.statusValue) {
            var flag_1 = this.statusValue === "active" ? true : false;
            this.filteredCampaigns = this.campaigns.filter(function (item) { return item.status === flag_1; });
        }
    };
    ClientCampaignsComponent.prototype.gotoCreateNewCampaign = function () {
        this.router.navigate(["create-new-campaign"]);
    };
    ClientCampaignsComponent.prototype.doAction = function (actionValue, campaign) {
        this.campaignId = campaign._id;
        if (actionValue === "Start Campaign") {
            this.openStartCampaignModal();
        }
        if (actionValue === "Stop Campaign") {
            this.openStopCampaignModal();
        }
        if (actionValue === "Delete Campaign") {
            this.openDeleteCampaignModal();
        }
        if (actionValue === "Edit Campaign") {
            localStorage.setItem('EDIT_CAMPAIGN', "true");
            localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(campaign));
            this.router.navigate(["create-new-campaign"]);
        }
        if (actionValue === "Edit Strategy") {
            localStorage.setItem('EDIT_STRATEGY', "true");
            localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(campaign));
            this.router.navigate(["strategy-type"]);
        }
        if (actionValue === "Edit Sequence") {
            localStorage.setItem('EDIT_SEQUENCE', "true");
            localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(campaign));
            this.router.navigate(["message-preview"]);
        }
    };
    ClientCampaignsComponent.prototype.openStartCampaignModal = function () {
        this.startCampaignModal.show();
    };
    ClientCampaignsComponent.prototype.startCampaign = function () {
        var _this = this;
        this.campaignService.updateCampaign(this.campaignId, { status: true }).subscribe(function (res) {
            console.log(res);
            _this.startCampaignModal.hide();
            _this.loadCampaign();
        }, function (err) {
            alert('error');
            console.log(err);
        });
    };
    ClientCampaignsComponent.prototype.openStopCampaignModal = function () {
        this.stopCampaignModal.show();
    };
    ClientCampaignsComponent.prototype.stopCampaign = function () {
        var _this = this;
        this.campaignService.updateCampaign(this.campaignId, { status: false }).subscribe(function (res) {
            console.log(res);
            _this.stopCampaignModal.hide();
            _this.loadCampaign();
        }, function (err) {
            alert('error');
            console.log(err);
        });
    };
    ClientCampaignsComponent.prototype.openDeleteCampaignModal = function () {
        this.deleteCampaignModal.show();
    };
    ClientCampaignsComponent.prototype.deleteCampaign = function () {
        var _this = this;
        this.campaignService.updateCampaign(this.campaignId, { deleted: true }).subscribe(function (res) {
            console.log(res);
            _this.deleteCampaignModal.hide();
            _this.loadCampaign();
        }, function (err) {
            alert('error');
            console.log(err);
        });
    };
    ClientCampaignsComponent.prototype.viewCampaignsSummary = function (campaign) {
        localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(campaign));
        this.router.navigate(["campaigns-summary"]);
    };
    return ClientCampaignsComponent;
}());
export { ClientCampaignsComponent };
