import { OnInit } from '@angular/core';
import { getUserRole } from 'src/app/helpers/getUserRole';
var ListPrepProspectComponent = /** @class */ (function () {
    function ListPrepProspectComponent(userService) {
        this.userService = userService;
        this.rows = [];
        this.deleteId = '';
        this.isLoading = false;
        this.defaultColDef = { resizable: true };
        this.isEU = getUserRole() === '4' ? true : false;
        this.isRSA = getUserRole() === '1' ? true : false;
        this.noRowsTemplate = "\"<span\">no rows to show</span>\"";
        this.viewType = localStorage.getItem('LIST_CONTACTS_VIEW_TYPE');
        this.listContactsName = localStorage.getItem('LIST_CONTACTS_NAME');
        this.listContactsCompany = localStorage.getItem('LIST_CONTACTS_COMPANY');
        this.listContactsListName = localStorage.getItem('LIST_CONTACTS_LISTNAME');
        this.loadingTemplate = "<span class=\"ag-overlay-loading-center\">data is loading...</span>";
        this.columnDefs = [
            {
                sortable: true,
                width: 100,
                resizable: true,
                field: 'companyName',
                headerName: 'Company Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                cellRenderer: function (params) {
                    return "<a href=\"" + params.data.companyProfileURL + "\" target=\"_blank\">" + params.data.companyName + "</a>";
                }
            },
            {
                sortable: true,
                width: 100,
                field: 'fullName',
                headerName: 'Full Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                valueGetter: function (params) {
                    return params.data.firstName + " " + params.data.lastName;
                },
                cellRenderer: function (params) {
                    return "<a href=\"" + params.data.profileURL + "\" title=\"View Profile\" target=\"_blank\">" + params.value + "</a>";
                }
            },
            {
                field: 'title',
                sortable: true,
                width: 100,
                headerName: 'Title',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                width: 70,
                field: 'location',
                headerName: 'Prospect Location',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                }
            },
            {
                sortable: true,
                width: 60,
                field: 'industry',
                headerName: 'Industry',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                }
            },
            {
                sortable: true,
                width: 100,
                field: 'organization_location_1',
                headerName: 'Company HQ',
                headerTooltip: 'Company Headquarters',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            // {
            //   sortable: true,
            //   width: 70,
            //   field: 'organization_headcount_1',
            //   headerTooltip: 'HC',
            //   filter: "agTextColumnFilter",
            //   filterParams: {
            //     buttons: ['reset', 'apply'],
            //     closeOnApply: true
            //   },
            //   valueFormatter: (params) => {
            //     return (params.value||"").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            //   }
            // },
            {
                field: 'organization_headcount_1',
                width: 70,
                maxWidth: 70,
                sortable: true,
                headerName: 'HC',
                headerTooltip: 'Organization Headcount',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                cellRenderer: function (params) {
                    if (params.value || params.value === 0) {
                        return Number(params.value).toLocaleString();
                    }
                    else {
                        return '';
                    }
                },
                cellStyle: {
                    textAlign: 'right',
                    paddingRight: '10px' // Add padding to the right
                }
            },
            // {
            //   field: 'followers',
            //   headerTooltip: 'Connections',
            //   sortable: true,
            //   width: 70,
            //   headerName: 'Conn.',
            //   filter: "agTextColumnFilter",
            //   filterParams: {
            //     buttons: ['reset', 'apply'],
            //     closeOnApply: true
            //   },
            //   valueFormatter: (params) => {
            //     return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            //   }
            // },
            {
                field: 'followers',
                width: 70,
                maxWidth: 70,
                sortable: true,
                headerTooltip: 'Connections',
                headerName: 'Conn.',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                valueFormatter: function (params) {
                    return params.value ? params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
                },
                cellStyle: {
                    textAlign: 'right',
                    paddingRight: '10px' // Add padding to the right
                }
            },
            {
                colId: 'delete',
                width: 50,
                field: '',
                headerName: 'Delete',
                cellRenderer: this.trashIconRender.bind(this),
            }
        ];
    }
    ListPrepProspectComponent.prototype.ngOnInit = function () {
        this.loadData();
        if (!this.isRSA) {
            this.columnDefs = this.columnDefs.filter(function (col) { return col.colId !== 'delete'; }); // Remove the delete column if the user is not RSA
        }
    };
    ListPrepProspectComponent.prototype.loadData = function () {
        var _this = this;
        this.userService.getAVDataByListId(localStorage.getItem('LIST_CONTACTS_KEY'), localStorage.getItem('USER_KEY')).subscribe(function (res) {
            var sortedRes = res.sort(function (a, b) {
                if (a.companyName < b.companyName) {
                    return -1;
                }
                if (a.companyName > b.companyName) {
                    return 1;
                }
                if (a.firstName < b.firstName) {
                    return -1;
                }
                if (a.firstName > b.firstName) {
                    return 1;
                }
                return 0;
            });
            console.log(sortedRes);
            _this.rows = sortedRes.filter(function (item) { return item.approved.includes(_this.viewType); });
            console.log(_this.rows, _this.viewType);
            if (_this.rows.length === 0) {
                _this.gridApi.showNoRowsOverlay();
            }
        }, function (err) {
            alert('error');
            _this.gridApi.showNoRowsOverlay();
            console.log('error to get user av data: ', err);
        });
    };
    // autoSizeAll() {
    //   var allColumnIds = [];
    //   this.gridColumnApi.getAllColumns().forEach(function (column) {
    //     allColumnIds.push(column.colId);
    //   });
    //   this.gridColumnApi.autoSizeColumns(allColumnIds);
    //   // this.gridApi.sizeColumnsToFit();
    // }
    // onGridReady(params) {
    //   this.gridApi = params.api;
    //   this.gridColumnApi = params.columnApi;
    //   // this.gridApi.sizeColumnsToFit();
    //   this.gridApi.showLoadingOverlay();
    // }
    ListPrepProspectComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
        this.gridApi.sizeColumnsToFit();
    };
    ListPrepProspectComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.gridColumnApi = params.columnApi;
    };
    ListPrepProspectComponent.prototype.trashIconRender = function (params) {
        var _this = this;
        var eDiv = document.createElement('span');
        eDiv.style.cursor = 'pointer';
        eDiv.innerHTML = "<i class=\"fa fa-trash-o\"></i>";
        eDiv.addEventListener('click', function () {
            _this.openDeleteModal(params.data.id);
        });
        return eDiv;
    };
    ListPrepProspectComponent.prototype.openDeleteModal = function (deleteId) {
        this.deleteId = deleteId;
        this.deleteModal.show();
    };
    ListPrepProspectComponent.prototype.deleteRecord = function () {
        var _this = this;
        this.isLoading = true;
        this.userService.deleteRecord(this.deleteId).subscribe(function (res) {
            if (res) {
                localStorage.removeItem('LIST_AVDATA_CACHED_AT');
                _this.loadData();
            }
            ;
            _this.isLoading = false;
            _this.deleteModal.hide();
        }, function (err) {
            _this.isLoading = false;
            _this.deleteModal.hide();
            alert('error');
            console.log('error to delete list: ', err);
        });
    };
    ListPrepProspectComponent.prototype.exportData = function () {
        var _this = this;
        var con = [];
        var csvContent = 'data:text/csv;charset=utf-8,';
        Object.keys(this.rows).forEach(function (data) {
            var val = _this.rows[data];
            con.push(val);
        });
        var title = "Status, Rejection Reason, Company Name, First Name, Last Name, Title, Profile Url, Email, Phone, Location, Company Start Date, Tenure, Connected On, Industry, Mutual Connections";
        csvContent += title + '\r\n';
        con.forEach(function (rowArray) {
            var row = '"' + rowArray.approved + '","' + (rowArray.rejectionReason || "N/A") + '","' + rowArray.companyName + '","' + rowArray.firstName + '","'
                + rowArray.lastName + '","' + rowArray.title + '","'
                + rowArray.profileURL + '","' + rowArray.email + '","'
                + rowArray.phone + '","' + rowArray.location + '","'
                + rowArray.yearsAtCompany + '","' + rowArray.since1 + '","' + rowArray.connectedOn
                + '","' + rowArray.industry + '","' + rowArray.mutualConnections + '"';
            csvContent += row + '\r\n';
        });
        csvContent = csvContent.replace(/#/g, '');
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        var d = new Date();
        var ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        var mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        var da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        var _d = mo + "-" + da + "-" + ye;
        if (this.viewType === 'Y') {
            link.setAttribute('download', this.listContactsCompany + " - " + this.listContactsName + " (" + this.listContactsListName + ") - Approved - Exported on " + _d + ".csv");
        }
        else if (this.viewType === 'N') {
            link.setAttribute('download', this.listContactsCompany + " - " + this.listContactsName + " (" + this.listContactsListName + ") - Rejected - Exported on " + _d + ".csv");
        }
        else {
            link.setAttribute('download', this.listContactsCompany + " - " + this.listContactsName + " (" + this.listContactsListName + ") - Exported on " + _d + ".csv");
        }
        document.body.appendChild(link); // Required for FF
        link.click();
        this.isLoading = false;
    };
    return ListPrepProspectComponent;
}());
export { ListPrepProspectComponent };
