import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild } from '@angular/core';

import { company } from '../../../models/company';
import { CompaniesService } from '../../../services/companies.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  @ViewChild('basicModal', { static: true }) showModalOnClick: ModalDirective;

  modalRef;
  isLoading = true;
  searchByCompany = '';
  company = new company();
  companies: company[] = [];

  constructor(private companiesService: CompaniesService, private router: Router) { }

  ngOnInit() {
    this.isLoading = true;
    this.getCompanies();
  }

  openToAdd(): void {
    this.company = new company();
    this.showModalOnClick.show();
  }

  reset(): void {
    this.company = new company();
  }

  openToEdit(company: company): void {
    this.company._id = company._id;
    this.company.companyName = company.companyName;
    this.showModalOnClick.show();
  }

  getCompanies(): void {
    this.companiesService.getCompanies().subscribe(result => {
      this.companies = result;
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get companies: ', err);
    });
  }

  remove(company: company): void {
    if (confirm('Are you sure you want to delete this company?')) {
      this.companiesService.deleteCompany(company).subscribe(() => this.getCompanies(),
        (err) => {
          this.isLoading = false;
          alert('error');
          console.log('error to delete company: ', err);
        });

      this.isLoading = true;
      this.getCompanies();
      this.isLoading = false;
    }
  }

  search(): void {
    this.isLoading = true;

    this.companiesService.searchCompany(this.searchByCompany).subscribe(result => {
      this.companies = result;
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to search company: ', err);
    });
  }

  saveCompany(): void {
    if (!this.company.companyName) {
      alert('Please enter company name');
      return;
    }

    if (!this.company._id) {
      this.companiesService.addCompany(this.company).subscribe(company => {
        this.reset();
        this.getCompanies();
        this.showModalOnClick.hide();

      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to add company: ', err);
      });
    } else {
      this.companiesService.editCompany(this.company).subscribe(company => {
        this.reset();
        this.isLoading = true;
        this.getCompanies();
        this.isLoading = false;
        this.showModalOnClick.hide();

      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to edit company: ', err);
      });
    }
  }

  keyPressed(event: KeyboardEvent): void {
    if (event.which === 13 || event.keyCode === 13) {
      this.search();
    }
  }
}