import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild } from '@angular/core';

import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @ViewChild('successModal', { static: true }) successModal: ModalDirective;

  _id: string;
  email: string;
  userName: string;
  isLoading = true;
  password: string;
  notifications: boolean;
  confirmPassword: string;
  skills = [{ value: 'Security' }, { value: 'Network Security' }, { value: 'Strategic Planning' }]

  constructor(private userService: UserService, private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {
    this.userService.getUserByKey(localStorage.getItem('USER_KEY')).subscribe((res: User) => {
      this._id = res._id;
      this.userName = res.name;
      this.email = res.email;
      this.notifications = res.notifications;
      this.isLoading = false;
    });
  }

  saveChanges(): void {
    if (!this.userName) {
      alert('Please enter user name.');
      return;
    }
    else if (!this.email) {
      alert('Please enter email address.');
      return;
    } else if (!this.validateEmail(this.email)) {
      alert('Please enter valid email.');
      return;
    } else if (this.password && this.password.length < 6) {
      alert('password must be at least 6 characters length.');
      return;
    } else if (this.password && this.password != this.confirmPassword) {
      alert('password and confirm password do not match.');
      return;
    }

    this.isLoading = true;
    var user = new User();
    user._id = this._id;
    user.name = this.userName;
    user.email = this.email;

    this.userService.updateProfile(user).subscribe((result) => {
      localStorage.setItem('USER_NAME', user.name);
      localStorage.setItem('USER_EMAIL', user.email);

      if (this.password) {
        this.authenticationService.changeUserPassword(this.password).subscribe((result) => {
          this.isLoading = false;
          this.successModal.show();

        }, (err) => {
          this.isLoading = false;
          alert('error');
          console.log('error to change use password: ', err);
        });

      } else {
        this.isLoading = false;
        this.successModal.show();
      }

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to update profile: ', err);
    });
  }

  validateEmail(email: string): boolean {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  toggleNotifications(): void {
    this.notifications = !this.notifications;
    this.isLoading = true;

    this.userService.toggleNotifications(this._id, this.notifications).subscribe((res: any) => {
      this.isLoading = false;
    });
  }

}