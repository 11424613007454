import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SlackService{
    constructor(private http: HttpClient) {
    }
    public getBaseUrl(): string {
        return environment.baseUrl;
    }
    protected getUrl(relativeUrl: string) {
        return this.getBaseUrl() + relativeUrl;
    }

    protected getOptions(): any {
        const AuthorizeToken = localStorage.getItem('USER_TOKEN');

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });

        const options = {
            headers: headers
        };

        return options;
    }
    public stopSlackMessage(data):Observable<any> {
        const url = this.getUrl('/slack/stop-slack-msg');
        return this.http.put(url, data, this.getOptions()).pipe(
            map((res: any) => {
                return res;
            })
        )
            
    }
    public sendSlackMessage(data): Observable<any>{
        const url = this.getUrl('/slack/send-slack-msg');
        return this.http.put(url, data, this.getOptions()).pipe(
            map((res: any) => {
                return res;
            })
        )

    }
}