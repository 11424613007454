<div class="container campaigns">
    <h3>Message Sequence</h3>

    <span>We will now work on creating your messaging sequence</span><br />

    <span></span><br />

    <span>There are two typical approaches.</span><br />

    <span></span><br />

    <span>
        The <span style="font-weight: bold; text-decoration: underline;">Direct Approach</span> - includes a connection message and then a follow up and a call to action message.
    </span><br />

    <span></span><br />

    <span>
        The <span style="font-weight: bold; text-decoration: underline;">Nurture Approach</span> - includes a connection message and then a series of messages to nurture the relationship (this may include referencing an article, blog, or video.) followed
    by a call to action message.
    </span><br />

    <span></span><br />

    <span>Always try to follow the 5 P’s (Be Polite, Pertinent, Personalized, Professional, Praiseful).</span>

    <div class="buttons">
        <!-- <button type="button" class="previous" (click)="cancelNewCampaign()">Cancel</button> -->
        <!-- <button type="button" class="previous" (click)="back()">Previous</button> -->
        <button type=" submit" (click)="gotoConnectionRequest()">Let's go!</button>
    </div>

    <div class="come-back">
        <span (click)="cancelNewCampaign()">I'll come back to this later</span>
    </div>

</div>