import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Group } from '../models/groups';

@Injectable({
    providedIn: 'root'
})
export class GroupsService {

    constructor(private http: HttpClient) { }

    public getBaseUrl(): string {
        return environment.baseUrl;
    }

    protected getUrl(relativeUrl: string) {
        return this.getBaseUrl() + relativeUrl;
    }

    protected getOptions(): any {
        const AuthorizeToken = localStorage.getItem('USER_TOKEN');

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });

        const options = {
            headers: headers
        };

        return options;
    }

    getGroups(memberId: string): Observable<Group[]> {
        const url = this.getUrl('/groups/' + memberId);

        return this.http.get(url, this.getOptions())
            .pipe(
                map((querySnapshot: any) => {
                    const groups: Group[] = [];
                    querySnapshot.forEach((data: any) => {
                        groups.push({
                            _id: data._id,
                            source: data.source,
                            joinedAt: data.joinedAt,
                            status: data.status,
                            groupId: data.groupId,
                            joinedOn: data.joinedOn,
                            memberId: data.memberId,
                            groupLogo: data.groupLogo,
                            groupName: data.groupName,
                            groupRules: data.groupRules,
                            memberCount: data.memberCount,
                            groupCreatedAt: data.groupCreatedAt,
                            groupCreatedOn: data.groupCreatedOn,
                            groupIndustries: data.groupIndustries,
                            groupDescription: data.groupDescription,
                            campaignDescription: data.campaignDescription,
                        });
                    });
                    return groups;
                }));
    }

}
