/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./review.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-uikit-pro-standard/ng-uikit-pro-standard.ngfactory";
import * as i3 from "ng-uikit-pro-standard";
import * as i4 from "../../../../../node_modules/ag-grid-angular/ag-grid-angular.ngfactory";
import * as i5 from "ag-grid-angular";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "./review.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../services/avlists.service";
import * as i11 from "../../../services/user.service";
var styles_ReviewComponent = [i0.styles];
var RenderType_ReviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReviewComponent, data: {} });
export { RenderType_ReviewComponent as RenderType_ReviewComponent };
function View_ReviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading\u2026"]))], null, null); }
function View_ReviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedListName; _ck(_v, 1, 0, currVal_0); }); }
function View_ReviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["class", "float-btns approve"], ["data-target", "#basicExample"], ["data-toggle", "modal"], ["href", "javascript:void(0)"], ["title", "Approve Selected Prospects"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 14).show() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [["mdbBtn", ""], ["mdbWavesEffect", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).click($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MdbBtnDirective_0, i2.RenderType_MdbBtnDirective)), i1.ɵdid(2, 638976, null, 0, i3.MdbBtnDirective, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(3, 16384, null, 0, i3.WavesDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "mdb-icon", [["class", "mr-1"], ["fas", ""], ["icon", "check"]], null, null, null, i2.View_MdbIconComponent_0, i2.RenderType_MdbIconComponent)), i1.ɵdid(5, 114688, null, 0, i3.MdbIconComponent, [i1.ElementRef, i1.Renderer2], { icon: [0, "icon"], class: [1, "class"] }, null), i1.ɵdid(6, 16384, null, 0, i3.FasDirective, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵted(-1, 0, ["Approve "]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_1 = "check"; var currVal_2 = "mr-1"; _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedCount === 0); _ck(_v, 0, 0, currVal_0); }); }
function View_ReviewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["class", "float-btns save"], ["href", "javascript:void(0)"], ["title", "Save Selected Prospects"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveTempAvdata() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [["color", "primary"], ["mdbBtn", ""], ["mdbWavesEffect", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).click($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MdbBtnDirective_0, i2.RenderType_MdbBtnDirective)), i1.ɵdid(2, 638976, null, 0, i3.MdbBtnDirective, [i1.ElementRef, i1.Renderer2], { color: [0, "color"] }, null), i1.ɵdid(3, 16384, null, 0, i3.WavesDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "mdb-icon", [["class", "mr-1"], ["fas", ""], ["icon", "save"]], null, null, null, i2.View_MdbIconComponent_0, i2.RenderType_MdbIconComponent)), i1.ɵdid(5, 114688, null, 0, i3.MdbIconComponent, [i1.ElementRef, i1.Renderer2], { icon: [0, "icon"], class: [1, "class"] }, null), i1.ɵdid(6, 16384, null, 0, i3.FasDirective, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵted(-1, 0, ["Save "]))], function (_ck, _v) { var currVal_1 = "primary"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "save"; var currVal_3 = "mr-1"; _ck(_v, 5, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedCount === 0); _ck(_v, 0, 0, currVal_0); }); }
function View_ReviewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-alpine"], ["style", "width: 100%; height: calc(100% - 75px)"]], [[8, "enableColResize", 0], [8, "enableSorting", 0], [8, "enableFilter", 0]], [[null, "gridReady"], [null, "firstDataRendered"], [null, "rowSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } if (("firstDataRendered" === en)) {
        var pd_1 = (_co.autoSizeAll() !== false);
        ad = (pd_1 && ad);
    } if (("rowSelected" === en)) {
        var pd_2 = (_co.onSelectionChanged($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_AgGridAngular_0, i4.RenderType_AgGridAngular)), i1.ɵprd(512, null, i5.AngularFrameworkOverrides, i5.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i5.AngularFrameworkComponentWrapper, i5.AngularFrameworkComponentWrapper, []), i1.ɵdid(3, 4898816, null, 1, i5.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i5.AngularFrameworkOverrides, i5.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"], defaultColDef: [2, "defaultColDef"], rowSelection: [3, "rowSelection"], overlayNoRowsTemplate: [4, "overlayNoRowsTemplate"], suppressRowClickSelection: [5, "suppressRowClickSelection"], pagination: [6, "pagination"], rowMultiSelectWithClick: [7, "rowMultiSelectWithClick"] }, { rowSelected: "rowSelected", gridReady: "gridReady", firstDataRendered: "firstDataRendered" }), i1.ɵqud(603979776, 2, { columns: 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.rows; var currVal_4 = _co.columnDefs; var currVal_5 = _co.defaultColDef; var currVal_6 = _co.rowSelection; var currVal_7 = _co.noDataText; var currVal_8 = true; var currVal_9 = false; var currVal_10 = true; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = true; var currVal_2 = true; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ReviewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { approveModal: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "control-bar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewComponent_3)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewComponent_4)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewComponent_5)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 16777216, null, null, 19, "div", [["aria-hidden", "true"], ["aria-labelledby", "myModalLabel"], ["class", "modal fade top"], ["id", "frameModalTop"], ["mdbModal", ""], ["role", "dialog"], ["tabindex", "-1"]], null, [[null, "keydown"], [null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.esc" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14).onEsc() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ModalDirective_0, i2.RenderType_ModalDirective)), i1.ɵdid(14, 4898816, [[1, 4], ["approveModal", 4]], 0, i3.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i3.ɵdj], null, null), (_l()(), i1.ɵeld(15, 0, null, 0, 17, "div", [["class", "modal-dialog modal-notify"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 16, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 15, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 8, "div", [["class", "approval-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(20, null, ["You are about to approve ", " of ", " records."])), (_l()(), i1.ɵeld(21, 0, null, null, 5, "textarea", [["class", "form-control"], ["placeholder", "You can provide additional feedback here."]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 22).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 22)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 22)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.note = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(24, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(26, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵeld(27, 0, null, null, 5, "div", [["class", "button-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 2, "a", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.approve() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Approve "])), (_l()(), i1.ɵeld(30, 0, null, null, 0, "i", [["class", "fa fa-check ml-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "a", [["class", "btn btn-outline-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.selectedListName; _ck(_v, 5, 0, currVal_1); var currVal_2 = (((_co.rows && (_co.rows.length > 0)) && (_co.selectedCount > 0)) && _co.showGrid); _ck(_v, 8, 0, currVal_2); var currVal_3 = ((_co.rows && (_co.rows.length > 0)) && _co.showGrid); _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.showGrid; _ck(_v, 12, 0, currVal_4); var currVal_14 = _co.note; _ck(_v, 24, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.selectedCount; var currVal_6 = _co.rows.length; _ck(_v, 20, 0, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 26).ngClassUntouched; var currVal_8 = i1.ɵnov(_v, 26).ngClassTouched; var currVal_9 = i1.ɵnov(_v, 26).ngClassPristine; var currVal_10 = i1.ɵnov(_v, 26).ngClassDirty; var currVal_11 = i1.ɵnov(_v, 26).ngClassValid; var currVal_12 = i1.ɵnov(_v, 26).ngClassInvalid; var currVal_13 = i1.ɵnov(_v, 26).ngClassPending; _ck(_v, 21, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); }); }
export function View_ReviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-review", [], null, null, null, View_ReviewComponent_0, RenderType_ReviewComponent)), i1.ɵdid(1, 114688, null, 0, i8.ReviewComponent, [i9.ActivatedRoute, i10.AVListService, i11.UserService, i3.ToastService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReviewComponentNgFactory = i1.ɵccf("app-review", i8.ReviewComponent, View_ReviewComponent_Host_0, {}, {}, []);
export { ReviewComponentNgFactory as ReviewComponentNgFactory };
