import { OnInit } from '@angular/core';
var EuDashboardComponent = /** @class */ (function () {
    function EuDashboardComponent(userService, router) {
        this.userService = userService;
        this.router = router;
        this.userDashboardMessage = '';
        this.isLoading = true;
        this.connectionsOptionsObject = {
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                        ticks: {
                            callback: function (value, index, values) {
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                        }
                    }]
            }
        };
        this.whoViewedOptionsObject = {
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        var percentage = data.datasets[1].data[tooltipItem.index];
                        var operator = percentage > 0 ? '+' : '-';
                        var label = data.datasets[0].data[tooltipItem.index] + " views, " + operator + percentage + "% from last week";
                        return label;
                    }
                }
            }
        };
    }
    EuDashboardComponent.prototype.selectConnectionData = function (event) {
        this.connectionsTooltip = [];
        this.connectionsTooltip.push({ severity: 'info', summary: 'Data Selected', 'detail': this.connectionsData.datasets[event.element._datasetIndex].data[event.element._index] });
    };
    EuDashboardComponent.prototype.selectWhoViewedData = function (event) {
        this.whoViewedTooltip = [];
        this.whoViewedTooltip.push({ severity: 'info', summary: 'Data Selected', 'detail': this.whoViewedData.datasets[event.element._datasetIndex].data[event.element._index] });
    };
    EuDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.getEUDashboard(localStorage.getItem('USER_KEY'), localStorage.getItem('USER_MEMBER_ID')).subscribe(function (data) {
            _this.latestConnections = data.latestConnections;
            var connectionsLabels = [];
            for (var i = 0; i < 9; i++) {
                connectionsLabels.push(new Date(Date.now() - i * 604800000).getMonth() + 1 + '/' + new Date(Date.now() - i * 604800000).getDate());
            }
            connectionsLabels.reverse();
            _this.connectionsData = {
                labels: connectionsLabels,
                datasets: [
                    {
                        label: ' Connections',
                        data: data.connectionsData,
                        fill: false,
                        borderColor: '#4bc0c0'
                    }
                ]
            };
            var whoViewedData = data.whoViewedData.chartData.sort(function (a, b) {
                if (a.endTimestamp < b.endTimestamp) {
                    return -1;
                }
                if (a.endTimestamp > b.endTimestamp) {
                    return 1;
                }
                return 0;
            });
            ;
            var whoViewedLabels = [];
            var whoViewedChartData = [];
            var whoViewedChangePercentage = [];
            for (var i = 0; i < whoViewedData.length; i++) {
                whoViewedLabels.push(new Date(whoViewedData[i].endTimestamp).getMonth() + 1 + '/' + new Date(whoViewedData[i].endTimestamp).getDate());
                whoViewedChartData.push(whoViewedData[i].numViews);
                whoViewedChangePercentage.push(whoViewedData[i].changePercentage);
            }
            _this.totalNumViews = data.whoViewedData.totalNumViews;
            _this.overallChangePercentage = whoViewedChangePercentage[whoViewedChangePercentage.length - 1] || 0;
            _this.whoViewedData = {
                labels: whoViewedLabels,
                datasets: [
                    {
                        label: ' Viewed',
                        data: whoViewedChartData,
                        fill: true,
                        borderColor: '#4bc0c0',
                        backgroundColor: 'rgba(75, 192, 192, 0.3)',
                    },
                    {
                        label: ' Percentage',
                        data: whoViewedChangePercentage,
                        fill: false,
                        borderColor: '#4bc0c0',
                        hidden: true,
                    }
                ]
            };
            _this.latestViewedProfileData = data.latestViewedProfileData;
            _this.latestPxdataDataWithCompany = data.latestPxdataDataWithCompany;
            for (var i = 0; i < _this.latestPxdataDataWithCompany.length; i++) {
                var imageStatus = _this.imageExists(_this.latestPxdataDataWithCompany[i].organization_domain_1);
                _this.latestPxdataDataWithCompany[i].imageStatus = imageStatus;
            }
            var filteredArr = _this.latestPxdataDataWithCompany.reduce(function (acc, current) {
                var x = acc.find(function (item) { return item.organization_domain_1 === current.organization_domain_1; });
                if (!x) {
                    return acc.concat([current]);
                }
                else {
                    return acc;
                }
            }, []);
            _this.latestPxdataDataWithCompany = filteredArr.filter(function (item) { return item.imageStatus; }).slice(0, 9);
            _this.userDashboardMessage = data.userDashboardMessage;
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user pxdata: ', err);
        });
    };
    EuDashboardComponent.prototype.gotoConnectionsPage = function (company) {
        localStorage.setItem('ORGANIZATION_1', company.organization_1);
        this.router.navigate(['/connections']);
    };
    EuDashboardComponent.prototype.getDistance = function (distance) {
        var dis = '';
        switch (distance) {
            case 'DISTANCE_1':
                dis = '- 1st';
                break;
            case 'DISTANCE_2':
                dis = '- 2nd';
                break;
            case 'DISTANCE_3':
                dis = '- 3rd';
                break;
            default:
                dis = '';
        }
        return dis;
    };
    EuDashboardComponent.prototype.getReferrer = function (referrer) {
        var ref = '';
        switch (referrer) {
            case 'Homepage':
                ref = 'Found you via LinkedIn Homepage';
                break;
            case 'Groups':
                ref = 'Found you in a Group';
                break;
            case 'LinkedIn Search':
                ref = 'Found you via LinkedIn Search';
                break;
            case 'LinkedIn Company Pages':
                ref = 'Found you via LinkedIn Search';
                break;
            case 'LinkedIn Profile':
                ref = 'Found you via LinkedIn Profile';
                break;
            case 'Messaging':
                ref = 'Found you via Messaging';
                break;
            default:
                ref = '';
        }
        return ref;
    };
    EuDashboardComponent.prototype.imageExists = function (image_url) {
        var http = new XMLHttpRequest();
        try {
            http.open('HEAD', 'https://logo.clearbit.com/' + image_url, false);
            http.send();
            return http.status != 404;
        }
        catch (_a) {
            return false;
        }
    };
    return EuDashboardComponent;
}());
export { EuDashboardComponent };
