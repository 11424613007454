import { Router } from '@angular/router';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { Message } from '../../../models/message';
import { UserService } from '../../../services/user.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { SlackService } from 'src/app/services/slack.services';

@Component({
  selector: 'app-incoming-messages',
  templateUrl: './incoming-messages.component.html',
  styleUrls: ['./incoming-messages.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class IncomingMessagesComponent implements OnInit {
  @ViewChild('confirmModal', { static: true }) showConfirmModalOnClick: ModalDirective;
  @ViewChild('informModal', { static: true }) showInformModalOnClick: ModalDirective;
  gridApi;
  rows = [];
  totalRecords=0;
  skip=0;count=25;
  gridColumnApi;
  cars: any = [];
  isLoading = true;
  rowGroupMetadata: any;
  userLatestMessage: Message[] = [];
  noDataText = 'No messages right now';
  rowData: any;
  leadStatusOptions=[];
  cols = [
    { field: 'prospect', header: 'Prospect' },
    { field: 'last_incoming_message', header: 'Last Incoming Message' },
    { field: 'createdAt', header: 'Date/Time' },
  ];

  constructor(private slackService: SlackService, @Inject(DOCUMENT) document: Document, private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.userService.getUserByKey(localStorage.getItem('USER_KEY')).subscribe(user => {
      if(user.leadStatusOptions.length==0){
        this.leadStatusOptions = ["",0,1,2,3,4];
      }
      else this.leadStatusOptions = [""].concat(user.leadStatusOptions);
    });;
    this.fetchRecords()  
  }
  updateStatus(newValue,id){
    let status,newVal = this.leadStatusOptions.findIndex(x=>x==newValue);
    if(newVal==0)status = '';
    this.userService.updatePXDataStatus(id,newVal!=-1?newVal:newValue).subscribe((response)=>{
      console.log(response)
    })
  }
  fetchRecords(){
    this.isLoading = true;
    this.userService.getIncomingMessages(localStorage.getItem('USER_KEY'), localStorage.getItem('USER_MEMBER_ID'),this.skip,this.count).subscribe((data:any) => {
      this.isLoading = false;
      this.cars = data;
      //this.cars = data.map(con=>({...data,pxDataStatus: con.pxDataStatus!=0 ? (this.leadStatusOptions[con.pxDataStatus]||con.pxDataStatus):''}));
      console.log(this.cars)
      //this.totalRecords = parseInt(data.totalRecords);
      
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get user lastest messages: ', err);
    });
  }
  sendStopMessageSlack(message: string) {
    this.slackService.stopSlackMessage({ "text": message, "channel": 'messaging-alerts', "username": 'Messaging Bot' }).subscribe()
  }
  onPage(evt){
    console.log(evt)
    this.skip = parseInt(evt.first);
    this.count = parseInt(evt.rows);
    this.fetchRecords();
  }
  actionOnStopMessage(isYes) {
    if (isYes) {
      let elem = document.getElementById(this.rowData.id);
      let data: any = {};
      data.clientCompany = localStorage.getItem('USER_COMPANY');
      data.clientName = localStorage.getItem('USER_NAME');
      data.prospectName = this.rowData.contact;
      data.prospectLinkedinUrl = this.rowData.contactURL;
      elem.classList.add('msg-red');
      let msg = `*** Stop Messaging Sequence ***
      ${data.clientCompany} - ${data.clientName}
      ${data.prospectName} - ${data.prospectLinkedinUrl}
      Requested at: ${moment().format('MM/DD/YYYY hh:mm:ss A')}`;
      this.sendStopMessageSlack(msg);
      this.rowData.stopmsg = true;
      this.userService.stopMessage(this.rowData.pxDataId).subscribe(res => {
        console.log(res)
      })
    }
    this.showConfirmModalOnClick.hide();
  }
  stopMessaging(rowData) {
    this.rowData = rowData;
    console.log(rowData)
    if (!this.rowData.stopmsg)
      this.showConfirmModalOnClick.show();
    else {
      this.showInformModalOnClick.show();
    }
  }
}
