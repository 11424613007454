<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<h2>Campaigns - {{userName}}</h2>

<span class="mr-3">Status</span>
<select class="browser-default custom-select" style="width: 250px;" [(ngModel)]="statusValue" (change)="filter()">
    <option selected value=""> All</option>
    <option value="active"> Active</option>
    <option value="inactive"> Inactive</option>
</select>

<div class="table-responsive" *ngIf="filteredCampaigns">
    <table class="table table-striped" [mfData]="filteredCampaigns" #mf="mfDataTable" [(mfSortBy)]="sortBy" [(mfSortOrder)]="sortOrder">
        <thead>
            <tr>
                <th>
                    <mfDefaultSorter by="campaignName">Campaign </mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="campaignType">Type</mfDefaultSorter>
                </th>
                <th class="text-center text-red">
                    <mfDefaultSorter by="strategy_complete">Strategy</mfDefaultSorter>
                </th>
                <th class="text-center text-red">
                    <mfDefaultSorter by="msg_complete">Msg Sequence</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="createdAt">Created On</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="updatedAt">Last Modified</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="campaignDescription">Status</mfDefaultSorter>
                </th>
                <th class="text-center">
                    View
                </th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let campaign of mf.data; let i = index">
                <td>{{campaign.campaignName}}</td>
                <td>{{campaign.campaignType}}</td>
                <td class="text-center">
                    <i *ngIf="campaign.strategy_complete" class="fas fa-check" style="color: #219421"></i>
                    <i *ngIf="!campaign.strategy_complete" class="fas fa-warning" style="color: red;"></i>
                </td>
                <td class="text-center">
                    <i *ngIf="campaign.msg_complete" class="fas fa-check" style="color: #219421"></i>
                    <i *ngIf="!campaign.msg_complete" class="fas fa-warning" style="color: red;"></i>
                </td>
                <td>{{campaign.createdAt | date : 'MM/dd/yy'}}</td>
                <td>{{campaign.updatedAt | date : 'MM/dd/yy'}}</td>
                <td>
                    <span *ngIf="campaign.status" class="status-active">Active</span>
                    <span *ngIf="!campaign.status">Inactive</span>
                </td>
                <td class="text-center">
                    <i class="far fa-file-alt" style="cursor: pointer;" (click)="viewCampaignsSummary(campaign)"></i>
                </td>
                <td class="d-flex">
                    <select (change)="doAction($event.target.value, campaign)">
                        <option>Select an action</option>
                        <!--                         <option value="Edit Campaign" *ngIf="!campaign.status">Edit Campaign</option>
                        <option value="Edit Strategy"
                            *ngIf="!campaign.status && campaign.campaignType === 'Invite, Connect and Message'">
                            Edit Strategy
                        </option>
                        <option value="Edit Sequence" *ngIf="!campaign.status">Edit Sequence</option>
                        <option value="Stop Campaign" *ngIf="campaign.status">Stop Campaign</option>
                        <option value="Start Campaign" *ngIf="!campaign.status">Start Campaign</option>
                        <option value="Delete Campaign" *ngIf="!campaign.status">Delete Campaign</option> -->
                    </select>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div mdbModal style="overflow-y: auto" #startCampaignModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Start A Campaign </p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="startCampaignModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body" *ngIf="campaignId">
                Are you ready to go live with this campaign?
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="startCampaign()">Yes</a>
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="startCampaignModal.hide()">No</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<div mdbModal style="overflow-y: auto" #stopCampaignModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Start A Campaign </p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="stopCampaignModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body" *ngIf="campaignId">
                Are you sure you want to stop this campaign?
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="stopCampaign()">Yes</a>
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="stopCampaignModal.hide()">No</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>

<div mdbModal style="overflow-y: auto" #deleteCampaignModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Delete A Campaign </p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteCampaignModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body" *ngIf="campaignId">
                Are you sure you want to delete this campaign?
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="deleteCampaign()">Yes</a>
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="deleteCampaignModal.hide()">No</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>