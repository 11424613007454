<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="d-flex align-items-center" *ngIf="!isLoading">
    <span *ngIf="isRSA" class="mr-3">Company</span>
    <select *ngIf="isRSA" class="browser-default custom-select" style="width: 200px;" [(ngModel)]="companyValue" (change)="filter()">
        <option selected value=""> All</option>
        <option *ngFor="let company of companies" value="{{company}}">{{company}}</option>
    </select>
    <span *ngIf="isRSA || isCA" class="ml-5 mr-3">User</span>
    <select *ngIf="!isEU" class="browser-default custom-select" style="width: 200px;" [(ngModel)]="userValue" (change)="filter()">
        <option selected value=""> All</option>
        <option *ngFor="let user of users" value="{{user._id}}">{{user.name}}</option>
    </select>
    <span class="ml-5 mr-3">Date Range</span>
    <select class="browser-default custom-select" style="width: 100px;" [(ngModel)]="dateValue" (change)="filter()">
        <option selected value=""> All</option>
        <option value="7">7 days</option>
        <option value="14">14 days</option>
        <option value="30">30 days</option>
        <option value="60">60 days</option>
        <option value="90">90 days</option>
    </select>
    <div class="ml-4 form-check">
        <input class="form-check-input" type="checkbox" id="excludemostrecentlists" [checked]="excludemostrecentlists" (click)="toggleExcludemostrecentlists()">
        <label class="form-check-label mr-3" for="excludemostrecentlists">
            Exclude most recent lists
        </label>
    </div>
    <fa name="fas fa-undo" class="ml-3 reset-filter" (click)="resetFilter()" title="Reset filters"></fa>
</div>

<div class="table-responsive" *ngIf="filteredAvlists">
    <table class="table table-striped" [mfData]="filteredAvlists" #mf="mfDataTable" [(mfSortBy)]="sortBy" [(mfSortOrder)]="sortOrder">
        <thead>
            <tr>
                <th *ngIf="isRSA">
                    <mfDefaultSorter by="company">Company</mfDefaultSorter>
                </th>
                <th *ngIf="!isEU">
                    <mfDefaultSorter by="name">User Name</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="listCount">Lists</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="uploadedCount">Prospects</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="approvedPercentage">Approved</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="unApprovedPercentage">Not Approved</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="invitedPercentage">Invited</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="connectedPercentage">Connected</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="respondedPercentage">Responded</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="positivePercentage">Positive</mfDefaultSorter>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let avlist of mf.data">
                <td *ngIf="isRSA">{{avlist.company}}</td>
                <td *ngIf="!isEU">{{avlist.name}}</td>
                <td class="text-center">
                    <a href="javascript:void(0)" (click)="gotoListsWithCount(avlist)" style="text-decoration: underline;">{{avlist.listCount}}</a>
                </td>
                <td class="text-center">{{avlist.uploadedCount}}</td>
                <td class="text-center">
                    <span [ngStyle]="{backgroundColor: avlist.approvedPercentage<50? '#fed6dd': avlist.approvedPercentage<80 ? '#eeff41' : 'null'}">{{avlist.approvedPercentage}}%
                        ({{avlist.approvedCount}})</span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="{backgroundColor: avlist.approvedPercentage<50? '#fed6dd': avlist.approvedPercentage<80 ? '#eeff41' : 'null'}">{{avlist.unApprovedPercentage}}%
                        ({{avlist.notApproved}})</span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="{backgroundColor: avlist.invitedPercentage<90? '#eeff41' : 'null'}">{{avlist.invitedPercentage}}%
                        ({{avlist.invitedCount}})</span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="{backgroundColor: avlist.connectedPercentage<10? '#fed6dd': avlist.connectedPercentage<15 ? '#eeff41' : 'null'}">{{avlist.connectedPercentage}}%
                        ({{avlist.connectedCount}})</span>
                </td>
                <td class="text-center">
                    <span [ngStyle]="{backgroundColor: avlist.respondedPercentage<20? '#fed6dd': avlist.respondedPercentage<25 ? '#eeff41' : 'null'}">{{avlist.respondedPercentage}}%
                        ({{avlist.respondedCount}})</span>
                </td>
                <td class="text-center"><span>{{avlist.positivePercentage}}% ({{avlist.positiveCount}})</span></td>
            </tr>
        </tbody>
    </table>
</div>