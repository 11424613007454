<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<button type="button" mdbBtn color="primary" class="relative waves-light" (click)="openToAdd()" mdbWavesEffect>Add
    Client</button>

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">Client Information</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-sm-6">
                        <label for="fname">First Name</label>
                        <input type="text" class="form-control" id="fname" [(ngModel)]="client.fname" placeholder="Enter client first  name">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="lname">Last Name</label>
                        <input type="text" class="form-control" id="lname" [(ngModel)]="client.lname" placeholder="Enter client last name">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="email">Email</label>
                        <input type="email" class="form-control" id="email" [(ngModel)]="client.email" placeholder="Enter client email">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="phone">Phone</label>
                        <input type="text" class="form-control" id="phone" [(ngModel)]="client.phone" placeholder="Enter client phone number">
                    </div>

                    <div class="form-group col-sm-6">
                        <label for="uname">User Name</label>
                        <input type="uname" class="form-control" id="uname" [(ngModel)]="client.uname" placeholder="Enter client user name">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="Linkedin">LinkedIn ID</label>
                        <input type="text" class="form-control" id="Linkedin" [(ngModel)]="client.Linkedin" placeholder="Enter client LinkedIn ID">
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect (click)="saveClient()">Save</button>
            </div>
        </div>
    </div>
</div>

<br /><br />
<div class="table-responsive">
    <table class="table">
        <thead id="tablebackground">
            <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>User Name</th>
                <th>Email</th>
                <th>phone</th>
                <th>LinkedIn ID</th>
                <th>Action</th>
            </tr>
        </thead>
        <tr *ngFor="let client of clients">
            <td>{{client.fname}}</td>
            <td>{{client.lname}}</td>
            <td>{{client.uname}}</td>
            <td>{{client.email}}</td>
            <td>{{client.phone}}</td>
            <td>{{client.Linkedin}}</td>
            <td>
                <button type="button" class="btn btn-warning " (click)="openToEdit(client)" style="margin-right: 5px;">Edit</button>
                <button type="button" class="btn btn-danger" (click)="remove(client)">Delete</button>
            </td>
        </tr>
    </table>

</div>











































<!--  <h4 mdbModal #basicModal="mdbModal" class="modal fade" id="modal-basic-title"aria-labelledby="myBasicModalLabel" aria-hidden="true">Client Information</h4>
    <button type="button" class="close" aria-label="Close" (click)="basicModal.hide()">

    </button>



<button class="btn btn-lg btn-success" (click)="openToAdd(content)" mdbWavesEffect>Add Client</button>

<br/><br/>
<div class="table-responsive">

</div>
-->