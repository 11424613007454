import jwt_decode from 'jwt-decode';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private loginStatus = new BehaviorSubject(false);
  sharedLoginStatus = this.loginStatus.asObservable();

  constructor(private http: HttpClient) { }

  toggleLoginStatus(status: boolean) {
    this.loginStatus.next(status)
  }

  public getBaseUrl(): string {
    return environment.baseUrl;
  }

  protected getUrl(relativeUrl: string) {
    return this.getBaseUrl() + relativeUrl;
  }

  protected getOptions(): any {
    const AuthorizeToken = localStorage.getItem('USER_TOKEN');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + AuthorizeToken
    });

    const options = {
      headers: headers
    };

    return options;
  }

  sendResetPasswordEmail(email: string): Observable<boolean> {
    const url = this.getUrl('/users/forgot-password');

    return this.http.post(url, { email: email }, this.getOptions())
      .pipe(
        map((res: any) => {
          return true;
        })
      );
  }

  changeUserPassword(password: string): Observable<boolean> {
    const url = this.getUrl('/users/update-password');

    return this.http.post(url, { password: password }, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  resetUserPassword(password: string, reset_token: string): Observable<boolean> {
    const url = this.getUrl('/users/reset-password');

    return this.http.post(url, { password: password, token: reset_token }, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  login(email: string, password: string): Observable<User> {
    const url = this.getUrl('/users/login');

    return this.http.post(url, { email: email, password: password }, this.getOptions())
      .pipe(
        map((querySnapshot: any) => {
          localStorage.setItem('USER_TOKEN', querySnapshot.token);
          const data: User = jwt_decode(localStorage.getItem('USER_TOKEN'));
          const user = new User();
          user._id = data._id;
          user.name = data.name;
          user.company = data.company;
          user.email = data.email;
          user.password = data.password;
          user.role = data.role;
          user.linkedInProfileId = data.linkedInProfileId;
          user.memberId = data.memberId;
          user.googleDocURL = data.googleDocURL;
          user.isHidden = data.isHidden;
          user.selfserve = data.selfserve;
          return user;
        })
      );
  }

  check(): Observable<any> {
    return new Observable((observer) => {
      if (localStorage.getItem('USER_TOKEN')) {
        // User is signed in.
        const data: User = jwt_decode(localStorage.getItem('USER_TOKEN'));
        const user = new User();
        user._id = data._id;
        user.name = data.name;
        user.company = data.company;
        user.email = data.email;
        user.password = data.password;
        user.role = data.role;
        user.linkedInProfileId = data.linkedInProfileId;
        user.googleDocURL = data.googleDocURL;
        user.isHidden = data.isHidden;
        user.groups = data.groups;
        user.isViewedprofilesActive = data.isViewedprofilesActive;
        user.selfserve = data.selfserve;
        observer.next(user);
      } else {
        // No user is signed in.
        observer.next(null);
      }
    });
  }

  logout(): Observable<boolean> {
    return new Observable((observer) => {
      localStorage.clear();
      localStorage.clear();
      observer.next(true);
    });
  }

}
