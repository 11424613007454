import { Component, OnInit } from '@angular/core';

import { Message } from '../../../models/message';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  gridApi;
  rows = [];
  gridColumnApi;
  isLoading = true;
  userLatestMessage: Message[] = [];
  noDataText = 'No messages right now';

  columnDefs = [
    {
      width: 150,
      sort: "desc",
      maxWidth: 150,
      minWidth: 150,
      field: 'createdAt',
      headerName: 'Date/Time',
      cellRenderer: function (params) {
        return `${params.data.tstamp}`;
      }
    },
    {
      width: 200,
      maxWidth: 200,
      minWidth: 200,
      field: 'contact',
      headerName: 'Contact',
      cellRenderer: function (params) {
        return `<a href="${params.data.contactURL}" target="_blank">${params.data.contact}</a>`;
      }
    },
    {
      width: 200,
      maxWidth: 200,
      minWidth: 200,
      field: 'company',
      headerName: 'Company',
      comparator: function (valueA, valueB,) {
        if (!valueA) return -1;
        if (!valueB) return 1;
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
        if (valueA == valueB) return 0;
        if (valueA > valueB) return 1;
        return -1;
      },
      cellRenderer: function (params) {
        if (params.data.company_url) {
          return `<a href="${params.data.company_url}" target="_blank">${params.data.company}</a>`;
        } else {
          return `<span>${params.data.company}</span>`;
        }
      }
    },
    {
      width: 75,
      maxWidth: 75,
      minWidth: 75,
      headerName: '',
      field: 'convoUrl',
      cellRenderer: function (params) {
        return `<a style="color:black;text-decoration:none;" href="https://www.linkedin.com/messaging/thread/${params.data.thread}/"
        target="_blank"><i class="fa fa-comments-o"></i></a>`;
      }
    },
    {
      field: 'body',
      minWidth: 200,
      autoHeight: true,
      headerName: 'Message',
      cellClass: "cell-wrap-text",
    }
  ];

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.userService.getUserMessages(localStorage.getItem('USER_KEY')).subscribe((messages) => {
      this.rows = messages;
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get user lastest messages: ', err);
    });
  }

  onColumnResized(event) {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();

    setTimeout(function () {
      params.api.resetRowHeights();
    }, 500);
  }

}