import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var MessageSendWhoComponent = /** @class */ (function () {
    function MessageSendWhoComponent(fb, strategyService, campaignService, router) {
        this.fb = fb;
        this.strategyService = strategyService;
        this.campaignService = campaignService;
        this.router = router;
        this.campaigns = [];
        this.isLoading = true;
        this.clickByDoneButton = false;
        this.editMode = localStorage.getItem("EDIT_STRATEGY") ? true : false;
        this.msgTargetType_options = [
            {
                id: "msgTargetCampaign",
                value: "Connections from a campaign"
            },
            {
                id: "msgTargetList",
                value: "Connections from a list I created"
            },
            {
                id: "msgTargetNote",
                value: "Other"
            }
        ];
        this.strategyForm = this.fb.group({
            userId: localStorage.getItem('USER_KEY'),
            msgTargetType: this.fb.control('msgTargetCampaign'),
            msgTargetCampaign: this.fb.control('', [Validators.required]),
            msgTargetList: this.fb.control(''),
            msgTargetNote: this.fb.control(''),
        });
    }
    MessageSendWhoComponent.prototype.ngOnInit = function () {
        var _this = this;
        var campaignInfo = localStorage.getItem('CAMPAIGN_INFO');
        if (!campaignInfo) {
            this.router.navigate(['campaigns']);
            return;
        }
        this.strategyService.getStrategy(JSON.parse(campaignInfo).strategyId).subscribe(function (res) {
            console.log('existing strategy: ', res);
            if (res) {
                _this.strategyForm = _this.fb.group({
                    strategyId: res._id,
                    userId: localStorage.getItem('USER_KEY'),
                    msgTargetType: _this.fb.control(res.msgTargetType ? res.msgTargetType : 'msgTargetCampaign'),
                    msgTargetCampaign: _this.fb.control(res.msgTargetCampaign),
                    msgTargetList: _this.fb.control(res.msgTargetList),
                    msgTargetNote: _this.fb.control(res.msgTargetNote),
                });
            }
            _this.handleChange();
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user latest messages: ', err);
        });
        this.campaignService.getCampaigns(localStorage.getItem('USER_KEY')).subscribe(function (res) {
            _this.campaigns = res;
            console.log(res);
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log(err);
        });
    };
    MessageSendWhoComponent.prototype.strategyFormSubmit = function () {
        var _this = this;
        if (this.strategyForm.valid) {
            this.strategyService.submitStrategy(this.strategyForm.value).subscribe(function (res) {
                console.log(res);
                if (_this.clickByDoneButton) {
                    _this.router.navigate(['campaigns']);
                }
                else {
                    _this.router.navigate(['message-template']);
                }
            }, function (err) {
                alert('error');
                console.log(err);
            });
        }
    };
    MessageSendWhoComponent.prototype.handleChange = function () {
        var value = this.strategyForm.value.msgTargetType;
        if (value === 'msgTargetCampaign') {
            this.strategyForm.get('msgTargetCampaign').setValidators([Validators.required]);
            this.strategyForm.get('msgTargetList').clearValidators();
            this.strategyForm.get('msgTargetNote').clearValidators();
        }
        if (value === 'msgTargetList') {
            this.strategyForm.get('msgTargetList').setValidators([Validators.required]);
            this.strategyForm.get('msgTargetCampaign').clearValidators();
            this.strategyForm.get('msgTargetNote').clearValidators();
        }
        if (value === 'msgTargetNote') {
            this.strategyForm.get('msgTargetNote').setValidators([Validators.required]);
            this.strategyForm.get('msgTargetCampaign').clearValidators();
            this.strategyForm.get('msgTargetList').clearValidators();
        }
        this.strategyForm.controls["msgTargetCampaign"].updateValueAndValidity();
        this.strategyForm.controls["msgTargetList"].updateValueAndValidity();
        this.strategyForm.controls["msgTargetNote"].updateValueAndValidity();
    };
    MessageSendWhoComponent.prototype.cancelNewCampaign = function () {
        this.router.navigate(['campaigns']);
    };
    MessageSendWhoComponent.prototype.gotoCreateNewCampaign = function () {
        this.router.navigate(['create-new-campaign']);
    };
    MessageSendWhoComponent.prototype.clickDoneButton = function () {
        this.clickByDoneButton = true;
    };
    return MessageSendWhoComponent;
}());
export { MessageSendWhoComponent };
