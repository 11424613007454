import jwt_decode from 'jwt-decode';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { User } from '../../../models/user';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  email: string;
  password: string;
  isLoading = true;
  isLoggedIn: boolean;
  reset_token: string;

  constructor(private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.authenticationService.sharedLoginStatus.subscribe(status => this.isLoggedIn = status);

    this.authenticationService.logout().subscribe((res) => {
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to logout: ', err)
    });

    this.reset_token = this.route.snapshot.paramMap.get('reset-token');
    const data: User = jwt_decode(this.reset_token);

    if (data) {
      this.email = data.email;
    }
  }

  save(): boolean {
    if (!this.password) {
        alert('Please enter your new password.');
        return false;
    }

    this.isLoading = true;

    this.authenticationService.resetUserPassword(this.password, this.reset_token).subscribe((res: any) => {
        this.isLoading = false;

        if (res) {
            alert("Password changed. You can now sign in with your new password");
            this.router.navigate(['/login']);
            return true;
        } else {
            alert('Error resetting password!');
            return false;
        }

    }, (err) => {
        this.isLoggedIn = false;
        this.isLoading = false;
        alert('Error resetting password!');
        console.log('Error resetting password: ', err);
        return false;
    });

    // Add a default return value
    return true;
}

  keyPressed(event) {
    if (event.which === 13 || event.keyCode === 13) {
      this.save();
    }
  }
}
