<div class="container campaigns">
    <h3>Company / Industry Information</h3>
    <span>What industries should we focus on for our research?</span>

    <form [formGroup]="stepThreeForm" (ngSubmit)="stepThreeSubmit()" autocomplete="off" novalidate #formRef="ngForm">

        <div class="input">
            <label for="industries">Select the industries you want to focus on.</label>
            <ng-multiselect-dropdown [placeholder]="'Select options'" [settings]="dropdownSettings" [data]="dropdownList" formControlName="industries" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
            </ng-multiselect-dropdown>
            <span class="error" *ngIf="stepThreeForm.controls['industries'].hasError('required') && formRef.submitted">
                Please select at least one industry.
            </span>
        </div>

        <div class="input">
            <label for="companySizeEnable">
                Would you like to specify the size of the companies?
            </label>
            <br>
            <ui-switch uncheckedLabel="No" checkedLabel="Yes" color="#306bff" formControlName="companySizeEnable">
            </ui-switch>
        </div>

        <div class="input" *ngIf="stepThreeForm.value.companySizeEnable">
            <label for="companySize">
                Select the size of companies you would like to focus on.
            </label>
            <div class="company-size">
                <mdb-checkbox *ngFor="let company of companySizeOptions" (change)="getCheckboxesValue($event, company)" [checked]="stepThreeForm.value.companySize.includes(company)">
                    {{company}}
                </mdb-checkbox>
            </div>
            <span class="error" *ngIf="stepThreeForm.value.companySize.length === 0 && formRef.submitted">
                Please make at least one selection or disable this option.
            </span>
        </div>

        <div class="buttons">
            <ng-template #templateNameDoneButton>
                <button type="submit" class="previous" *ngIf="!stepThreeForm.value.companySizeEnable || stepThreeForm.value.companySize.length > 0; else templateNameDone" (click)="clickDoneButton()">Done</button>
                <ng-template #templateNameDone>
                    <button type="submit" class="previous" (click)="clickDoneButton()">Done</button>
                </ng-template>
            </ng-template>
            <button type="button" *ngIf="!editMode; else templateNameDoneButton" class="previous" (click)="cancelNewCampaign()">Cancel</button>
            <button type="button" class="previous" cdkStepperPrevious>Previous</button>
            <button type="submit" *ngIf="!stepThreeForm.value.companySizeEnable || stepThreeForm.value.companySize.length > 0; else templateName" cdkStepperNext>Next</button>
            <ng-template #templateName>
                <button type="submit">Next</button>
            </ng-template>
        </div>

    </form>
</div>