import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('basicModal', { static: true }) showModalOnClick: ModalDirective;

  email: string;
  email2: string;
  password: string;
  isLoading = true;
  isLoggedIn: boolean;
  validationForm: FormGroup;

  constructor(private authenticationService: AuthenticationService,
    private router: Router, public fb: FormBuilder) {
    this.validationForm = fb.group({
      emailFormEx: [null, [Validators.required, Validators.email]],
      passwordFormEx: [null, Validators.required],
    });
  }

  get emailFormEx() {
    return this.validationForm.get('emailFormEx');
  }
  get passwordFormEx() {
    return this.validationForm.get('passwordFormEx');
  }

  ngOnInit() {
    this.authenticationService.sharedLoginStatus.subscribe(status => {
      this.isLoggedIn = status;
      this.isLoading = status;
    });

    this.authenticationService.check().subscribe((user) => {
      if (user) {
        if (user.role === '1' || user.role === '2') {
          this.router.navigate(['/dashboard']);
        } else if (user.role === '3') {
          this.router.navigate(['/caDashboard']);
        } else {
          // this.router.navigate(['/euDashboard']);
          this.router.navigate(['/messages']);
        }
      }
    });
  }

  sendToReset(): void {
    if (!this.email2) {
      alert('Please double check your email address.');
      return;
    } else if (!this.validateEmail(this.email2)) {
      alert('Please double check your email address');
      return;
    } else {
      this.showModalOnClick.hide();

      this.authenticationService.sendResetPasswordEmail(this.email2).subscribe((result) => {
        if (result) {
          alert('A password reset link has been sent to your email address.');
        } else {
          alert('Something went wrong.');
        }

      }, (err) => {
        alert('error');
        console.log('error to reset password: ', err);
      });
    }
  }

  login(): void {
    if (!this.email) {
      alert('Please enter your login email.');
      return;
    } else if (!this.validateEmail(this.email)) {
      alert('Please enter valid email');
      return;
    } else if (!this.password) {
      alert('Please enter your password.');
      return;
    }

    this.isLoading = true;

    this.authenticationService.login(this.email, this.password).subscribe((user: any) => {
      this.authenticationService.toggleLoginStatus(true);
      this.isLoggedIn = true;

      localStorage.setItem('USER_KEY', user._id);
      localStorage.setItem('USER_NAME', user.name);
      localStorage.setItem('USER_EMAIL', user.email);
      localStorage.setItem('USER_COMPANY', user.company);
      localStorage.setItem('USER_LI_PID', user.linkedInProfileId);
      localStorage.setItem('USER_MEMBER_ID', user.memberId);
      localStorage.setItem('USER_GOOGLE_DOC_URL', user.googleDocURL);

      if (user.role === '1' || user.role === '2') {
        this.router.navigate(['/dashboard']);
      } else if (user.role === '3') {
        this.router.navigate(['/caDashboard']);
      } else {
        localStorage.setItem('USER_LISTS_KEY', user._id);
        // this.router.navigate(['/euDashboard']);
        this.router.navigate(['/messages']);
      }

    }, (err) => {
      this.isLoggedIn = false;
      this.isLoading = false;
      alert('error');
      console.log('error to login: ', err);
    });
  }

  resetPassword(): void {
    this.showModalOnClick.show();
  }

  validateEmail(email: string): boolean {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  keyPressed(event: KeyboardEvent): void {
    if (event.which === 13 || event.keyCode === 13) {
      this.login();
    }
  }
}