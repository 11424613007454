<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<p-table *ngIf="cars.length" 

[columns]="cols" 
[value]="cars" 
dataKey="thread" 
[paginator]="true" 
[rows]="25" 
[rowsPerPageOptions]="[5,10,15,20,25,50]">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th style="width: 3em"></th>
            <th style="width: 3em"></th>
            <th style="width: 20%">Prospect</th>
            <th style="width: 10em">Lead Status</th>
            <th>Last Incoming Message</th>
            <th style="width: 10em">Date/Time</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
        <tr>
            <td>
                <a href="#" [pRowToggler]="rowData">
                    <i (click)="fetchAllThreads(rowData.thread,!expanded)" [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                </a>
            </td>
            <td>
                <a style="color:black;text-decoration:none;" href="https://www.linkedin.com/messaging/thread/{{rowData?.thread}}/" target="_blank"><i
                        class="fa fa-comments-o"></i></a>
            </td>
            <td>
                <a href="{{rowData.contactURL}}" class="contact">{{rowData.contact}}</a>
                <span *ngIf="rowData?.company" class="ml-1 mr-1">-</span>
                <a href="{{rowData.company_url}}" target="_blank"><span>{{rowData?.company}}</span></a> 
                <i *ngIf="rowData.pxDataId" [id]="rowData.id" [ngbTooltip]="rowData.stopmsg?'Message sequence for this user has been stopped.':'Click to have RMD stop sending messages'" [ngClass]="{'msg-red': rowData.stopmsg}" class="comment-slash fa-solid fa-comment-slash" (click)="stopMessaging(rowData)"></i>
            </td>
            <td>
                <select class="custom-select" (change)="updateStatus(rowData.pxDataStatus,rowData.pxDataId)" [(ngModel)]="rowData.pxDataStatus">
                    <option *ngFor="let status of leadStatusOptions;let i = index" value="{{i}}">{{status}}</option>
                </select>
            </td>
            <td>
                {{rowData.body}}
            </td>
            <td>
                <span>{{rowData.tstamp| date: 'MM/dd/yy hh:mm a'}}</span>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
        <tr>
            <td [attr.colspan]="columns.length + 2">
                <p-table [value]="rowData.subRows">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 188px;">Date/Time</th>
                            <th style="width: 188px;">Prospect</th>
                            <th>Message</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-msg>
                        <tr>
                            <td>{{msg.tstamp}}</td>
                            <td>{{msg.firstName_from}} {{msg.lastName_from}}</td>
                            <td>{{msg.body}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </td>
        </tr>
    </ng-template>
</p-table>
<div mdbModal #confirmModal="mdbModal" class="modal fade" tabindex="+1" role="dialog" aria-labelledby="myConfirmModalLabel"
    aria-hidden="true" style="z-index: 10000;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title bold" id="exampleModalLabel" style="font-weight: 400;">Stop Messaging Sequence</h5>
                <button type="button" class="close pull-right" aria-label="Close" (click)="confirmModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" style="    text-align: center;
            font-weight: 500;
            font-size: 20px;">
                <p>Stop the messaging sequence for this prospect?</p>
            </div>
            <div class="modal-footer justify-content-center">
                <a
                    type="button"
                    mdbBtn
                    color="primary"
                    class="waves-light"
                    mdbWavesEffect
                    (click)="actionOnStopMessage(true)"
                >Yes
                    <i class="fa fa-check ml-1"></i>
                </a>
                <a
                    type="button"
                    mdbBtn
                    color="primary"
                    outline="true"
                    class="waves-effect"
                    mdbWavesEffect
                    (click)="actionOnStopMessage(false)"
                >No</a>
            </div>
        </div>
    </div>
</div>
<div mdbModal #informModal="mdbModal" class="modal fade" tabindex="+1" role="dialog" aria-labelledby="myConfirmModalLabel"
    aria-hidden="true" style="z-index: 10000;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title bold" id="exampleModalLabel" style="font-weight: 400;">Information!</h5>
                <button type="button" class="close pull-right" aria-label="Close" (click)="informModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" style="    text-align: center;
            font-weight: 500;
            font-size: 20px;">
                <p>Please contact RMD if you wish to continue messaging this prospect.</p>
            </div>
            <div class="modal-footer">
                <button (click)="informModal.hide()" class="btn btn-primary">Ok</button>
            </div>
        </div>
    </div>
</div>
<div style="height: 17px;"></div>