import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var StepTwoComponent = /** @class */ (function () {
    function StepTwoComponent(fb, strategyService, router) {
        this.fb = fb;
        this.strategyService = strategyService;
        this.router = router;
        this.hasError = false;
        this.clickByDoneButton = false;
        this.editMode = localStorage.getItem("EDIT_STRATEGY") ? true : false;
        this.stepTwoForm = this.fb.group({
            userId: localStorage.getItem('USER_KEY'),
            geoCountry: this.fb.control('', Validators.required),
            geoLocations: this.fb.control([]),
            geoLocationsMore: this.fb.control('', Validators.required)
        });
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'geoCode',
            textField: 'geoName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }
    StepTwoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.strategyService.getLocations().subscribe(function (locations) {
            _this.dropdownList = locations;
        }, function (err) {
            alert('error');
            console.log(err);
        });
        if (this.strategy) {
            this.stepTwoForm = this.fb.group({
                userId: localStorage.getItem('USER_KEY'),
                strategyId: this.fb.control(this.strategy._id, Validators.required),
                geoCountry: this.fb.control(this.strategy.geoCountry, Validators.required),
                geoLocations: this.fb.control(this.strategy.geoLocations),
                geoLocationsMore: this.fb.control(this.strategy.geoLocationsMore, Validators.required)
            });
        }
        this.geoCountryChange();
    };
    StepTwoComponent.prototype.geoCountryChange = function () {
        var value = this.stepTwoForm.value.geoCountry;
        if (value === 'North America') {
            if (this.stepTwoForm.value.geoLocations.length === 0 && !this.stepTwoForm.value.geoLocationsMore) {
                this.stepTwoForm.get('geoLocations').setValidators([Validators.required]);
            }
            else {
                this.stepTwoForm.get('geoLocations').clearValidators();
            }
            this.stepTwoForm.get('geoLocationsMore').clearValidators();
        }
        else {
            this.stepTwoForm.patchValue({ geoLocations: [] });
            this.stepTwoForm.get('geoLocations').clearValidators();
            this.stepTwoForm.get('geoLocationsMore').setValidators([Validators.required]);
        }
        this.stepTwoForm.controls["geoLocations"].updateValueAndValidity();
        this.stepTwoForm.controls["geoLocationsMore"].updateValueAndValidity();
    };
    StepTwoComponent.prototype.stepTwoSubmit = function () {
        var _this = this;
        console.log(this.stepTwoForm);
        if (this.stepTwoForm.valid) {
            this.strategyService.submitStrategy(this.stepTwoForm.value).subscribe(function (res) {
                console.log(res);
                if (_this.clickByDoneButton) {
                    _this.router.navigate(['campaigns']);
                }
            }, function (err) {
                alert('error');
                console.log(err);
            });
        }
    };
    StepTwoComponent.prototype.onItemSelect = function (item) {
        // console.log(item);
        console.log(item);
    };
    StepTwoComponent.prototype.onSelectAll = function (items) {
        // console.log(items);
    };
    StepTwoComponent.prototype.back = function () {
        this.router.navigate(['strategy-type']);
    };
    StepTwoComponent.prototype.cancelNewCampaign = function () {
        this.router.navigate(['campaigns']);
    };
    StepTwoComponent.prototype.clickDoneButton = function () {
        this.clickByDoneButton = true;
    };
    return StepTwoComponent;
}());
export { StepTwoComponent };
