import { PXData } from 'src/app/models/PXData';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';


import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-user-review',
  templateUrl: './user-review.component.html',
  styleUrls: ['./user-review.component.scss']
})

export class UserReviewComponent implements OnInit {

  @ViewChild('approveModal', { static: true }) approveModal: ModalDirective;

  rows = [];
  private gridApi;
  isLoading = true;
  selectedRows = [];
  selectedCount = 0;
  allChecked = false;
  private gridColumnApi;
  rowSelection = 'multiple';
  noDataText = 'Nothing to approve right now';
  userReviewName = localStorage.getItem('USER_REVIEW_NAME');

  columnDefs = [
    {
      sortable: true,
      field: 'companyName',
      checkboxSelection: true,
      headerName: 'Company Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      comparator: function (valueA, valueB,) {
        if (!valueA) return -1;
        if (!valueB) return 1;
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
        if (valueA == valueB) return 0;
        if (valueA > valueB) return 1;
        return -1;
      },
      cellRenderer: function (params) {
        if (params.data.tempSelected) {
          params.node.setSelected(true);
        }
        return `<a href="${params.data.companyProfileURL}" target="_blank">${params.data.companyName}</a>`;
      },
    },
    {
      field: 'organization_website_1',
      // width: 20,
      // sortable: true,
      headerName: 'Web',
      headerTooltip: 'Company Website',
      // filter: "agTextColumnFilter",

      cellRenderer: function (params) {
        const iconStyle = `
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        `;
        if (params.value) {
          return `<a href="${params.value}" target="_blank" style="color: #007bff; ${iconStyle}">
                    <i class="fas fa-globe"></i>
                  </a>`;
        } else {
          return `<div style="${iconStyle}">
                    <i class="fas fa-globe" style="color: #ccc;"></i>
                  </div>`;
        }
      }
    },
    {
      sortable: true,
      field: 'firstName',
      headerName: 'First Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
      sortable: true,
      field: 'lastName',
      headerName: 'Last Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
      field: 'title',
      sortable: true,
      headerName: 'Title',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
      field: 'View',
      headerName: 'View',
      cellRenderer: function (params) {
        return `<a href="${params.data.profileURL}" target="_blank">View</a>`;
      }
    },
    {
      field: 'since1',
      sortable: true,
      headerName: 'Tenure',
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      comparator: function (number1, number2) {
        if (number1 === null && number2 === null) {
          return 0;
        }
        if (number1 === null) {
          return -1;
        }
        if (number2 === null) {
          return 1;
        }
        return number1 - number2;
      },
      cellRenderer: function (params) {
        if (params.data.since1 === 0) {
          return `<span><1</span>`
        }
        return `<span>${params.data.since1}</span>`;
      }
    },
    {
      sortable: true,
      field: 'location',
      headerName: 'Location',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
      sortable: true,
      field: 'industry',
      headerName: 'Industry',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
      sortable: true,
      field: 'mutualConnections',
      headerName: 'Mutual Connections',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    }
  ];

  constructor(private userService: UserService, private toastrService: ToastService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    const listKey = localStorage.getItem('USER_REVIEW_LISTKEY') ? localStorage.getItem('USER_REVIEW_LISTKEY') : 'all';

    this.userService.getUserAVdata(localStorage.getItem('USER_REVIEW_KEY'), listKey).subscribe((res) => {
      this.rows = res.sort((a, b) => {
        if (a.companyName.toLowerCase() < b.companyName.toLowerCase()) {
          return -1;
        }
        if (a.companyName.toLowerCase() > b.companyName.toLowerCase()) {
          return 1;
        }

        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }

        return 0;
      });
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get av data: ', err);
    });
  }

  autoSizeAll() {
    var allColumnIds = [];

    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });

    this.gridColumnApi.autoSizeColumns(allColumnIds);
    // this.gridApi.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged(event: any) {
    this.selectedCount = event.api.getSelectedNodes().length;
    this.selectedRows = [];

    event.api.getSelectedNodes().forEach(element => {
      this.selectedRows.push(element.data);
    });
  }

  saveTempAvdata() {
    this.isLoading = true;
    const selected_ids = this.selectedRows.map((item: PXData) => { return item.id });
    const all_ids = this.rows.map((item: PXData) => { return item.id });

    this.userService.saveTempAvdata(selected_ids, all_ids).subscribe((res) => {
      this.isLoading = false;
      const options = { positionClass: 'toast-bottom-right', closeButton: true, timeOut: 20000 };
      this.toastrService.success(`Your selections have been saved. They have NOT been submitted for approval. When you are ready to approve the selections, please click on the green Approve Button.`, '', options);

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to save temperary avdata: ', err);
    })
  }

  approve() {
    // hide approve modal
    this.approveModal.hide();
    const user = new User();
    this.isLoading = true;

    this.rows.forEach((row) => {
      this.selectedRows.forEach(selectedRow => {
        if (row.id === selectedRow.id) {
          row.checked = true;
        }
      });
    });

    this.userService.approveAVData(this.rows, localStorage.getItem('USER_REVIEW_KEY'), localStorage.getItem('USER_KEY'), '').subscribe((res) => {
      user._id = localStorage.getItem('USER_REVIEW_KEY');
      user.lastApprovedCount = this.selectedCount;
      user.lastApprovedOn = new Date().getTime();
      user.lastApprovedPercentage = Math.round((this.selectedCount / this.rows.length) * 100);

      this.userService.setLastApproved(user).subscribe((result) => {
        localStorage.removeItem('USER_AVDATA_CACHED_AT');
        this.loadData();

      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to set last approved: ', err);
      });

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to approve av data: ', err);
    });
  }

}
