import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, Inject, OnInit,AfterViewInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { dateFilterParams } from 'src/app/helpers/dateFilterParams';

import { ViewedProfileService } from '../../../services/viewedprofile.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-connections',
  templateUrl: './viewedprofiles.component.html',
  styleUrls: ['./viewedprofiles.component.scss']
})

export class ViewedProfilesComponent implements OnInit {
  @ViewChild('basicModal', { static: true }) showModalOnClick: ModalDirective;
  @ViewChild('graphModal', { static: true }) showGraphModalOnClick: ModalDirective;
  gridApi: any;

  rows = [];
  isLoading = true;
  selectedRow = undefined;
  graphData: any;
  modalBorder= "#dee2e6";
  noDataText = 'No viewed profiles right now';
  discTypeLabels = {
    D: { color: "#db3c48" },
    Di: { color: "#e5561f" },
    DI: { color: "#f48119" },
    Id: { color: "#ffb727" },
    I: { color: "#f7cf0d" },
    Is: { color: "#d3e000" },
    IS: { color: "#afd80a" },
    Si: { color: "#88c100" },
    S: { color: "#11b21b" },
    Sc: { color: "#51b48e" },
    SC: { color: "#00b6bc" },
    Cs: { color: "#007fb6" },
    C: { color: "#2f5fa5" },
    Cd: { color: "#6756b2" },
    CD: { color: "#93359b" },
    Dc: { color: "#c62e85" },
  };
  tendsTitle = "";
  columnDefs = [
    {
      width: 150,
      sort: "desc",
      resizable: true,
      sortable: true,
      field: 'viewedAt',
      headerName: 'Viewed On',
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      cellRenderer: function (params) {
        return moment(params.data.viewedAt).format('MM/DD/YYYY');
      },
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        if (!valueA) return -1;
        if (!valueB) return 1;
        if (new Date(valueA) > new Date(valueB)) return 1;
        if (new Date(valueA) < new Date(valueB)) return -1;
        else return 0;
      },
    },
    {
      width: 150,
      sortable: true,resizable: true,
      field: 'firstName',
      headerName: 'First Name',
      filter: "agTextColumnFilter",
    },
    {
      width: 150,
      sortable: true,resizable: true,
      field: 'lastName',
      headerName: 'Last Name',
      filter: "agTextColumnFilter",
    },
    {
      width: 840,
      field: 'occupation',resizable: true,
      sortable: true,
      headerName: 'Title',
      filter: "agTextColumnFilter",
      
    },
    {
      width: 75,
      field: 'picture',resizable: true,
      headerName: 'Avatar',
      cellRenderer: this.avatarShow.bind(this)
    },
    {
      width: 75,
      headerName: 'View',resizable: true,
      field: 'publicIdentifier',
      cellRenderer: function (params) {
        return `<a href="https://www.linkedin.com/in/${params.data.publicIdentifier}" target="_blank">View</a>`;
      }
    },
    {
      width: 75,
      sortable: true,resizable: true,
      field: 'numConnectionsInCommon',
      headerTooltip: 'Connections In Common',
      headerName: 'CC',
      customClass:"amlik",
      filter: "agTextColumnFilter",
      // cellRenderer: function (params) {
      //   return `<div class="cc_count">${params.data.numConnectionsInCommon}</div>`;
      // }
    },
    {
      width: 75,
      sortable: true,resizable: true,
      field: 'distance',
      headerName: 'Distance',
      filter: "agTextColumnFilter",
      cellRenderer: function (params) {
        return (params.data.distance||"").replace("DISTANCE_",'');
      }
    },
    {
      width: 50,
      sortable: true,resizable: true,
      field: 'pendingInvitee',
      headerName: 'Invited',
      filter: "agTextColumnFilter",
      cellRenderer: function (params) {
        return params.data.pendingInvitee ? "Y" :" N";
      }
    },
  ];

  constructor(@Inject(DOCUMENT) document: Document,private vieweUserService: ViewedProfileService) {
    
  }
  ngAfterViewInit(){
    let elem = document.querySelector('div[col-id="numConnectionsInCommon"][role="columnheader"]');
        elem.addEventListener('mouseover',function(){
          document.querySelectorAll('.cc_count').forEach((e:HTMLElement)=>e.style.display = 'initial')
        });
        elem.addEventListener('mouseout',function(){
          document.querySelectorAll('.cc_count').forEach((e:HTMLElement)=>e.style.display = 'none')
        })
  }
  ngOnInit() {
    this.vieweUserService.getAll(localStorage.getItem('USER_MEMBER_ID')).subscribe((connections) => {
      this.rows = connections;
      this.isLoading = false;
      setTimeout(()=>{
        
      },4000)
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get user pxdata: ', err);
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;

    this.gridApi.sizeColumnsToFit();

  }
  

  // avatarShow(params) {
  //   const eDiv = document.createElement('div');

  //   if (!params.data.picture) {
  //     eDiv.innerHTML = '';
  //   } else {
  //     eDiv.innerHTML =
  //       '<img src="' + params.data.picture + '" style="width: 25px; height:25px; margin-top: -4.5px; cursor: pointer"/>';
  //   }
  //   return eDiv;
  // }

  avatarShow(params) {
    const eDiv = document.createElement('div');

    if (!params.data.picture) {
      eDiv.innerHTML = '';
    } else {
      eDiv.innerHTML =
        `<a href="https://www.linkedin.com/in/${params.data.publicIdentifier}" target="_blank"><img src="${params.data.picture}" class="avatar-img liImg"/></a>`

    }

    eDiv.addEventListener('click', () => {
      this.selectedRow = params.data;
      //this.showModalOnClick.show();
    });

    return eDiv;
  }


}