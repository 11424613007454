import { OnInit } from '@angular/core';
import { getUserRole } from 'src/app/helpers/getUserRole';
var CaDashboardComponent = /** @class */ (function () {
    function CaDashboardComponent(userService, router) {
        this.userService = userService;
        this.router = router;
        this.userValue = '';
        this.isLoading = true;
        this.iscAdmin = false;
    }
    CaDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (getUserRole() === '3') {
            this.iscAdmin = true;
            this.userService.getCADashboard().subscribe(function (res) {
                _this.users = res.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
                _this.isLoading = false;
                _this.filteredUsers = _this.users;
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to get ca dashboard: ', err);
            });
        }
        else {
            this.isLoading = false;
            this.iscAdmin = false;
        }
    };
    CaDashboardComponent.prototype.openModal = function (user) {
        this.selectedUser = user;
        this.modal.show();
    };
    CaDashboardComponent.prototype.userReview = function (user) {
        if (user._id != localStorage.getItem('USER_REVIEW_KEY')) {
            localStorage.removeItem('USER_AVDATA_CACHED_AT');
        }
        localStorage.setItem('USER_REVIEW_KEY', user._id);
        localStorage.setItem('USER_REVIEW_NAME', user.name);
        this.router.navigate(['/userReview']);
    };
    CaDashboardComponent.prototype.userMessage = function (user) {
        localStorage.setItem('CA_MESSAGE_KEY', user._id);
        localStorage.setItem('CA_MESSAGE_NAME', user.name);
        localStorage.setItem('CA_MESSAGE_LINKEDINID', user.linkedInProfileId);
        this.router.navigate(['/caMessages']);
    };
    CaDashboardComponent.prototype.userLists = function (user) {
        localStorage.setItem('USER_LISTS_KEY', user._id);
        this.router.navigate(['/lists']);
    };
    CaDashboardComponent.prototype.userConnections = function (user) {
        localStorage.setItem('CA_CONNECTIONS_KEY', user._id);
        localStorage.setItem('CA_CONNECTIONS_NAME', user.name);
        localStorage.setItem('CA_CONNECTIONS_LINKEDINID', user.linkedInProfileId);
        this.router.navigate(['/caConnections']);
    };
    CaDashboardComponent.prototype.userReviewed = function (user) {
        localStorage.setItem('USER_REVIEWED_KEY', user._id);
        localStorage.setItem('USER_REVIEWED_NAME', user.name);
        this.router.navigate(['/userReviewed']);
    };
    CaDashboardComponent.prototype.isApprovalOld = function (lastApprovedOn, avUpdatedOn) {
        if (lastApprovedOn === 0) {
            return false;
        }
        var diff = lastApprovedOn - avUpdatedOn;
        if (diff < 0) {
            return true;
        }
        return false;
    };
    CaDashboardComponent.prototype.filter = function () {
        var _this = this;
        if (this.userValue) {
            return this.filteredUsers = this.users.filter(function (item) { return item._id === _this.userValue; });
        }
        return this.filteredUsers = this.users;
    };
    return CaDashboardComponent;
}());
export { CaDashboardComponent };
