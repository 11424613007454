<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="company-logo-section" *ngIf="!isLoading">
    <div class="card">
        <div class="card-header white">
            Most Recent Companies
        </div>
        <div class="card-body">
            <div class="grid-container">
                <img class="grid-item" *ngFor="let company of latestPxdataDataWithCompany" src="https://logo.clearbit.com/{{company.organization_domain_1}}" (click)="gotoConnectionsPage(company)">
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header white">
            Connections
        </div>
        <div class="card-body">
            <p-chart type="line" [data]="connectionsData" (onDataSelect)="selectConnectionData($event)" [options]="connectionsOptionsObject" height="100%"> </p-chart>
        </div>
    </div>
    <div class="card">
        <div class="card-header white">
            <span class="text-primary">{{totalNumViews}}</span> profile viewers in the past 90 days
        </div>
        <div class="card-body">
            <p-chart type="line" [data]="whoViewedData" (onDataSelect)="selectWhoViewedData($event)" [options]="whoViewedOptionsObject" height="100%"></p-chart>
        </div>
    </div>
</div>

<div class="text-center" [innerHTML]="userDashboardMessage"></div>

<div class="eu-dashboard-container" *ngIf="!isLoading">
    <div class="card mb-3">
        <div class="card-header">
            Last 5 Connections
        </div>
        <div class="card-body border-bottom" *ngFor="let connection of latestConnections">
            <div class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                    <div class="align-self-center" class="avatar-contatiner">
                        <img src="{{connection.avatar}}" class="connection-avatar" alt="avatar" />
                    </div>
                    <div class="align-self-center ml-3">
                        <h6>{{connection.fullName}} ({{connection.companyName}})</h6>
                        <p class="mb-0">{{connection.title}}</p>
                    </div>
                </div>
                <div class="align-self-center">
                    <a target="_blank" rel="noopener noreferrer" href="{{connection.profileURL}}">
                        <i class="fa fa-linkedin-square fa-2x icon-color mr-2"></i>
                    </a>
                    <!-- <a target="_blank" rel="noopener noreferrer" href="{{connection.convo_url}}">
                        <i class="fa fa-comments-o fa-2x icon-color"></i>
                    </a> -->
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header">
            Last 5 Profile Views
        </div>
        <div class="card-body border-bottom" *ngFor="let connection of latestViewedProfileData">
            <div class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                    <div class="align-self-center" class="avatar-contatiner">
                        <img src="{{connection.picture}}" class="connection-avatar" alt="avatar" />
                    </div>
                    <div class="align-self-center ml-3">
                        <h6>{{connection.firstName}} {{connection.lastName}} {{getDistance(connection.distance)}}
                        </h6>
                        <p class="mb-0">{{ (connection.occupation.length>100)? (connection.occupation | slice:0:100)+'..':(connection.occupation) }}</p>
                        <p class="mb-0 font-italic text-muted">{{getReferrer(connection.referrer)}}</p>
                    </div>
                </div>
                <div class="align-self-center">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/{{connection.publicIdentifier}}">
                        <i class="fa fa-linkedin-square fa-2x icon-color mr-2"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>