import { OnInit } from '@angular/core';
var RequestedEmailComponent = /** @class */ (function () {
    function RequestedEmailComponent(toastrService, userService, papa, HttpClient) {
        this.toastrService = toastrService;
        this.userService = userService;
        this.papa = papa;
        this.HttpClient = HttpClient;
        this.accept = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
        this.isLoading = false;
        this.processedFiles = 0;
        this.files = [];
        this.warningMessages = [];
        this.messageDataArray = [];
        this.hasBaseDropZoneOver = false;
    }
    RequestedEmailComponent.prototype.ngOnInit = function () {
        this.successMessage = "";
        // this.userService.getUsers().subscribe((users) => {
        //   this.users = users;
        //   this.isLoading = false;
        // }, (err) => {
        //   this.isLoading = false;
        //   alert('error');
        //   console.log('error to get users: ', err);
        // });
    };
    RequestedEmailComponent.prototype.cancel = function () {
        this.progress = 0;
        if (this.httpEmitter) {
            console.log('cancelled');
            this.httpEmitter.unsubscribe();
        }
    };
    RequestedEmailComponent.prototype.uploadFiles = function (files) {
        var _this = this;
        if (files && files.length > 0) {
            this.isLoading = true;
            this.warningMessages = [];
            var _loop_1 = function (i) {
                var reader = new FileReader();
                reader.readAsText(files[i]);
                reader.onload = function (data) {
                    _this.papa.parse(reader.result.toString(), {
                        header: true,
                        complete: function (parsedData) {
                            //if (!files[i].name.includes('messages-')) {
                            //this.warningMessages.push({ filename: files[i].name, message: "This file cannot be imported. Please make sure the filename contains messages-." });
                            //}
                            if (_this.warningMessages.length === 0) {
                                var filteredData_1 = parsedData.data.filter(function (x) { return (x.memberID); });
                                console.log(filteredData_1);
                                if (filteredData_1.length) {
                                    _this.userService.updateRequestedEmailData(filteredData_1).subscribe(function (res) {
                                        if (res.code != 200) {
                                            _this.toastrService.error(res.message, "Error!", { opacity: 1 });
                                            _this.warningMessages.push({ filename: files[i].name, message: "This file could not be imported since there is not user related to the messages" });
                                        }
                                        else
                                            _this.toastrService.success(filteredData_1.length + " records were imported.", "Success", { opacity: 1 });
                                        _this.processedFiles++;
                                        console.log("processed file #" + _this.processedFiles);
                                        if (_this.processedFiles === files.length) {
                                            _this.isLoading = false;
                                            _this.messageDataArray = null;
                                            _this.files = [];
                                            _this.processedFiles = 0;
                                        }
                                    }, function (err) {
                                        _this.isLoading = false;
                                        _this.toastrService.error("Error found!", "", { opacity: 1 });
                                        alert('error');
                                        console.log('error to update messagesdata: ', err);
                                    });
                                }
                                else {
                                    _this.toastrService.error("Please confirm the csv file contains memberID", "", { opacity: 1 });
                                    _this.isLoading = false;
                                }
                            }
                            else {
                                _this.isLoading = false;
                            }
                        }
                    });
                };
            };
            for (var i = 0; i < files.length; i++) {
                _loop_1(i);
            }
        }
        else {
            alert('Please select data file.');
        }
    };
    RequestedEmailComponent.prototype.getDate = function () {
        return new Date();
    };
    return RequestedEmailComponent;
}());
export { RequestedEmailComponent };
