import { OnInit } from '@angular/core';
import * as moment from 'moment';
var IncomingMessagesComponent = /** @class */ (function () {
    function IncomingMessagesComponent(slackService, document, userService, router) {
        this.slackService = slackService;
        this.userService = userService;
        this.router = router;
        this.rows = [];
        this.totalRecords = 0;
        this.skip = 0;
        this.count = 25;
        this.cars = [];
        this.isLoading = true;
        this.userLatestMessage = [];
        this.noDataText = 'No messages right now';
        this.leadStatusOptions = [];
        this.cols = [
            { field: 'prospect', header: 'Prospect' },
            { field: 'last_incoming_message', header: 'Last Incoming Message' },
            { field: 'createdAt', header: 'Date/Time' },
        ];
    }
    IncomingMessagesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.getUserByKey(localStorage.getItem('USER_KEY')).subscribe(function (user) {
            if (user.leadStatusOptions.length == 0) {
                _this.leadStatusOptions = ["", 0, 1, 2, 3, 4];
            }
            else
                _this.leadStatusOptions = [""].concat(user.leadStatusOptions);
        });
        ;
        this.fetchConversations();
    };
    IncomingMessagesComponent.prototype.updateStatus = function (newValue, id) {
        var status, newVal = this.leadStatusOptions.findIndex(function (x) { return x == newValue; });
        if (newVal == 0)
            status = '';
        this.userService.updatePXDataStatus(id, newVal != -1 ? newVal : newValue).subscribe(function (response) {
            console.log(response);
        });
    };
    IncomingMessagesComponent.prototype.fetchConversations = function () {
        var _this = this;
        this.isLoading = true;
        this.userService.getIncomingMessages(localStorage.getItem('USER_KEY'), localStorage.getItem('USER_MEMBER_ID'), this.skip, this.count).subscribe(function (data) {
            _this.isLoading = false;
            _this.cars = data;
            //this.cars = data.map(con=>({...data,pxDataStatus: con.pxDataStatus!=0 ? (this.leadStatusOptions[con.pxDataStatus]||con.pxDataStatus):''}));
            console.log(_this.cars);
            //this.totalRecords = parseInt(data.totalRecords);
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user lastest messages: ', err);
        });
    };
    IncomingMessagesComponent.prototype.sendStopMessageSlack = function (message) {
        this.slackService.stopSlackMessage({ "text": message, "channel": 'messaging-alerts', "username": 'Messaging Bot' }).subscribe();
    };
    IncomingMessagesComponent.prototype.onPage = function (evt) {
        console.log(evt);
        this.skip = parseInt(evt.first);
        this.count = parseInt(evt.rows);
        this.fetchConversations();
    };
    IncomingMessagesComponent.prototype.actionOnStopMessage = function (isYes) {
        if (isYes) {
            var elem = document.getElementById(this.rowData.id);
            var data = {};
            data.clientCompany = localStorage.getItem('USER_COMPANY');
            data.clientName = localStorage.getItem('USER_NAME');
            data.prospectName = this.rowData.contact;
            data.prospectLinkedinUrl = this.rowData.contactURL;
            elem.classList.add('msg-red');
            var msg = "*** Stop Messaging Sequence ***\n      " + data.clientCompany + " - " + data.clientName + "\n      " + data.prospectName + " - " + data.prospectLinkedinUrl + "\n      Requested at: " + moment().format('MM/DD/YYYY hh:mm:ss A');
            this.sendStopMessageSlack(msg);
            this.rowData.stopmsg = true;
            this.userService.stopMessage(this.rowData.pxDataId).subscribe(function (res) {
                console.log(res);
            });
        }
        this.showConfirmModalOnClick.hide();
    };
    IncomingMessagesComponent.prototype.stopMessaging = function (rowData) {
        this.rowData = rowData;
        console.log(rowData);
        if (!this.rowData.stopmsg)
            this.showConfirmModalOnClick.show();
        else {
            this.showInformModalOnClick.show();
        }
    };
    return IncomingMessagesComponent;
}());
export { IncomingMessagesComponent };
