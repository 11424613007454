import { OnInit } from '@angular/core';
var CampaignsComponent = /** @class */ (function () {
    function CampaignsComponent(router, campaignService, sequenceService) {
        this.router = router;
        this.campaignService = campaignService;
        this.sequenceService = sequenceService;
        this.campaigns = [];
        this.isLoading = true;
        this.sortOrder = 'asc';
        this.campaignId = "";
        this.sortBy = 'campaignName';
    }
    CampaignsComponent.prototype.ngOnInit = function () {
        this.loadCampaign();
    };
    CampaignsComponent.prototype.loadCampaign = function () {
        var _this = this;
        localStorage.removeItem('CAMPAIGN_INFO');
        localStorage.removeItem('CONNECTION_MESSAGE_INFO');
        localStorage.removeItem('TEMPLATE_MESSAGE_INFO');
        localStorage.removeItem('EDIT_CAMPAIGN');
        localStorage.removeItem('EDIT_STRATEGY');
        localStorage.removeItem('EDIT_SEQUENCE');
        this.campaignService.getCampaigns(localStorage.getItem('USER_KEY')).subscribe(function (res) {
            _this.campaigns = res;
            console.log(res);
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log(err);
        });
    };
    CampaignsComponent.prototype.gotoCreateNewCampaign = function () {
        this.router.navigate(["create-new-campaign"]);
    };
    CampaignsComponent.prototype.doAction = function (actionValue, campaign) {
        var _this = this;
        this.campaignId = campaign._id;
        if (actionValue === "Start Campaign") {
            this.openStartCampaignModal();
        }
        if (actionValue === "Stop Campaign") {
            this.openStopCampaignModal();
        }
        if (actionValue === "Delete Campaign") {
            this.openDeleteCampaignModal();
        }
        if (actionValue === "Edit Campaign") {
            localStorage.setItem('EDIT_CAMPAIGN', "true");
            localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(campaign));
            this.router.navigate(["create-new-campaign"]);
        }
        if (actionValue === "Edit Strategy") {
            localStorage.setItem('EDIT_STRATEGY', "true");
            localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(campaign));
            if (campaign.campaignType === 'Invite, Connect and Message') {
                this.router.navigate(["strategy-type"]);
            }
            else if (campaign.campaignType === "Message Only") {
                this.router.navigate(["message-send-who"]);
            }
        }
        if (actionValue === "Edit Sequence") {
            localStorage.setItem('EDIT_SEQUENCE', "true");
            localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(campaign));
            if (campaign.campaignType === "Invite, Connect and Message") {
                this.sequenceService.getMessages(campaign.sequenceId).subscribe(function (res) {
                    if (res.length === 0) {
                        _this.router.navigate(["connection-request"]);
                    }
                    else {
                        _this.router.navigate(["message-preview"]);
                    }
                }, function (err) {
                    alert('error');
                    console.log(err);
                });
            }
            else {
                this.router.navigate(["message-preview"]);
            }
        }
    };
    CampaignsComponent.prototype.openStartCampaignModal = function () {
        this.startCampaignModal.show();
    };
    CampaignsComponent.prototype.startCampaign = function () {
        var _this = this;
        this.campaignService.startStopCampaign(this.campaignId, { status: true }).subscribe(function (res) {
            console.log(res);
            _this.startCampaignModal.hide();
            _this.loadCampaign();
        }, function (err) {
            alert('error');
            console.log(err);
        });
    };
    CampaignsComponent.prototype.openStopCampaignModal = function () {
        this.stopCampaignModal.show();
    };
    CampaignsComponent.prototype.stopCampaign = function () {
        var _this = this;
        this.campaignService.startStopCampaign(this.campaignId, { status: false }).subscribe(function (res) {
            console.log(res);
            _this.stopCampaignModal.hide();
            _this.loadCampaign();
        }, function (err) {
            alert('error');
            console.log(err);
        });
    };
    CampaignsComponent.prototype.openDeleteCampaignModal = function () {
        this.deleteCampaignModal.show();
    };
    CampaignsComponent.prototype.deleteCampaign = function () {
        var _this = this;
        this.campaignService.updateCampaign(this.campaignId, { deleted: true }).subscribe(function (res) {
            console.log(res);
            _this.deleteCampaignModal.hide();
            _this.loadCampaign();
        }, function (err) {
            alert('error');
            console.log(err);
        });
    };
    CampaignsComponent.prototype.viewCampaignsSummary = function (campaign) {
        localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(campaign));
        this.router.navigate(["campaigns-summary"]);
    };
    return CampaignsComponent;
}());
export { CampaignsComponent };
