import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { StrategyService } from 'src/app/services/strategy.service';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit {

  @Input() strategy: any;

  dropdownList = [];
  stepThreeForm: FormGroup;
  clickByDoneButton = false;
  dropdownSettings: IDropdownSettings;
  editMode = localStorage.getItem("EDIT_STRATEGY") ? true : false;

  companySizeOptions = [
    "Self-employed",
    "1-10 employees",
    "11-50 employees",
    "51-200 employees",
    "201-500 employees",
    "501-1000 employees",
    "1001-5000 employees",
    "5001-10,000 employees",
    "10,001+ employees",
  ];

  constructor(private fb: FormBuilder, public strategyService: StrategyService, private router: Router) {
    this.stepThreeForm = this.fb.group({
      userId: localStorage.getItem('USER_KEY'),
      industries: this.fb.control([], Validators.required),
      companySizeEnable: this.fb.control(false),
      companySize: this.fb.control([])
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'industryCode',
      textField: 'industryName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  ngOnInit(): void {
    this.strategyService.getIndustries().subscribe((industries) => {
      this.dropdownList = industries;
    }, (err) => {
      alert('error');
      console.log(err);
    });

    if (this.strategy) {
      this.stepThreeForm = this.fb.group({
        userId: localStorage.getItem('USER_KEY'),
        strategyId: this.fb.control(this.strategy._id, Validators.required),
        industries: this.fb.control(this.strategy.industries, Validators.required),
        companySizeEnable: this.fb.control(this.strategy.companySizeEnable),
        companySize: this.fb.control(this.strategy.companySize)
      });
    }
  }

  stepThreeSubmit(): void {
    console.log(this.stepThreeForm);

    if (this.stepThreeForm.valid) {
      this.strategyService.submitStrategy(this.stepThreeForm.value).subscribe((res) => {
        console.log(res);
        if (this.clickByDoneButton) {
          this.router.navigate(['campaigns']);
        }
      }, (err) => {
        alert('error');
        console.log(err);
      });
    }
  }

  onItemSelect(item: any): void {
    // console.log(item);
  }

  onSelectAll(items: any): void {
    // console.log(items);
  }

  getCheckboxesValue(event, company): void {
    if (!this.stepThreeForm.value.companySize.includes(company)) {
      this.stepThreeForm.value.companySize.push(company);
    } else {
      const index = this.stepThreeForm.value.companySize.indexOf(company);
      if (index > -1) {
        this.stepThreeForm.value.companySize.splice(index, 1);
      }
    }
  }

  cancelNewCampaign(): void {
    this.router.navigate(['campaigns']);
  }

  clickDoneButton(): void {
    this.clickByDoneButton = true;
  }

}