import { Subscription } from 'rxjs';
import { Papa } from 'ngx-papaparse';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';

import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-requestedEmail',
  templateUrl: './requestedEmail.component.html',
  styleUrls: ['./requestedEmail.component.css']
})
export class RequestedEmailComponent implements OnInit {
  csv: string;
  user: string;
  accept = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
  users: User[];
  successMessage;
  lastFileAt: Date;
  progress: number;
  isLoading = false;
  processedFiles = 0;
  files: File[] = [];
  warningMessages = [];
  messageDataArray = [];
  httpEmitter: Subscription;
  sendableFormData: FormData;
  hasBaseDropZoneOver = false;
  httpEvent: HttpEvent<Event>;

  constructor(public toastrService:ToastService,private userService: UserService, private papa: Papa, public HttpClient: HttpClient) { }

  ngOnInit() {
    this.successMessage = "";
    // this.userService.getUsers().subscribe((users) => {
    //   this.users = users;
    //   this.isLoading = false;

    // }, (err) => {
    //   this.isLoading = false;
    //   alert('error');
    //   console.log('error to get users: ', err);
    // });
  }
  cancel() {
    this.progress = 0;

    if (this.httpEmitter) {
      console.log('cancelled');
      this.httpEmitter.unsubscribe();
    }
  }

  uploadFiles(files: File[]): void {
    if (files && files.length > 0) {
      this.isLoading = true;
      this.warningMessages = [];

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.readAsText(files[i]);

        reader.onload = (data) => {
          this.papa.parse(reader.result.toString(), {
            header: true,

            complete: (parsedData) => {
              //if (!files[i].name.includes('messages-')) {
                //this.warningMessages.push({ filename: files[i].name, message: "This file cannot be imported. Please make sure the filename contains messages-." });
              //}
              if (this.warningMessages.length === 0) {
                let filteredData = parsedData.data.filter(x=> (x.memberID) );
                console.log(filteredData)
                if(filteredData.length){
                  this.userService.updateRequestedEmailData(filteredData).subscribe((res) => {
                  if (res.code !=200) {
                    this.toastrService.error(res.message,"Error!",{opacity:1})
                    this.warningMessages.push({ filename: files[i].name, message: "This file could not be imported since there is not user related to the messages" });
                  }else
                  this.toastrService.success(filteredData.length+" records were imported.","Success",{opacity:1})

                  this.processedFiles++;
                  console.log(`processed file #${this.processedFiles}`);

                  if (this.processedFiles === files.length) {
                    this.isLoading = false;
                    this.messageDataArray = null;
                    this.files = [];
                    this.processedFiles = 0;
                  }

                }, (err) => {
                  this.isLoading = false;
                  this.toastrService.error("Error found!","",{opacity:1})
                  alert('error');
                  console.log('error to update messagesdata: ', err);
                  });
                }else{
                  this.toastrService.error("Please confirm the csv file contains memberID","",{opacity:1})
                  this.isLoading = false;
                }
              } else {
                this.isLoading = false;
              }
            }
          });
        };
      }
    } else {
      alert('Please select data file.');
    }

  }

  getDate() {
    return new Date();
  }

}
