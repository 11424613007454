import { OnInit } from '@angular/core';
import { User } from '../../../models/user';
var UserReviewComponent = /** @class */ (function () {
    function UserReviewComponent(userService, toastrService) {
        this.userService = userService;
        this.toastrService = toastrService;
        this.rows = [];
        this.isLoading = true;
        this.selectedRows = [];
        this.selectedCount = 0;
        this.allChecked = false;
        this.rowSelection = 'multiple';
        this.noDataText = 'Nothing to approve right now';
        this.userReviewName = localStorage.getItem('USER_REVIEW_NAME');
        this.columnDefs = [
            {
                sortable: true,
                field: 'companyName',
                checkboxSelection: true,
                headerName: 'Company Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                comparator: function (valueA, valueB) {
                    if (!valueA)
                        return -1;
                    if (!valueB)
                        return 1;
                    valueA = valueA.toLowerCase();
                    valueB = valueB.toLowerCase();
                    if (valueA == valueB)
                        return 0;
                    if (valueA > valueB)
                        return 1;
                    return -1;
                },
                cellRenderer: function (params) {
                    if (params.data.tempSelected) {
                        params.node.setSelected(true);
                    }
                    return "<a href=\"" + params.data.companyProfileURL + "\" target=\"_blank\">" + params.data.companyName + "</a>";
                },
            },
            {
                field: 'organization_website_1',
                // width: 20,
                // sortable: true,
                headerName: 'Web',
                headerTooltip: 'Company Website',
                // filter: "agTextColumnFilter",
                cellRenderer: function (params) {
                    var iconStyle = "\n          display: flex;\n          justify-content: center;\n          align-items: center;\n          height: 100%;\n        ";
                    if (params.value) {
                        return "<a href=\"" + params.value + "\" target=\"_blank\" style=\"color: #007bff; " + iconStyle + "\">\n                    <i class=\"fas fa-globe\"></i>\n                  </a>";
                    }
                    else {
                        return "<div style=\"" + iconStyle + "\">\n                    <i class=\"fas fa-globe\" style=\"color: #ccc;\"></i>\n                  </div>";
                    }
                }
            },
            {
                sortable: true,
                field: 'firstName',
                headerName: 'First Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'lastName',
                headerName: 'Last Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                field: 'title',
                sortable: true,
                headerName: 'Title',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                field: 'View',
                headerName: 'View',
                cellRenderer: function (params) {
                    return "<a href=\"" + params.data.profileURL + "\" target=\"_blank\">View</a>";
                }
            },
            {
                field: 'since1',
                sortable: true,
                headerName: 'Tenure',
                filter: "agNumberColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                comparator: function (number1, number2) {
                    if (number1 === null && number2 === null) {
                        return 0;
                    }
                    if (number1 === null) {
                        return -1;
                    }
                    if (number2 === null) {
                        return 1;
                    }
                    return number1 - number2;
                },
                cellRenderer: function (params) {
                    if (params.data.since1 === 0) {
                        return "<span><1</span>";
                    }
                    return "<span>" + params.data.since1 + "</span>";
                }
            },
            {
                sortable: true,
                field: 'location',
                headerName: 'Location',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'industry',
                headerName: 'Industry',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'mutualConnections',
                headerName: 'Mutual Connections',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            }
        ];
    }
    UserReviewComponent.prototype.ngOnInit = function () {
        this.loadData();
    };
    UserReviewComponent.prototype.loadData = function () {
        var _this = this;
        var listKey = localStorage.getItem('USER_REVIEW_LISTKEY') ? localStorage.getItem('USER_REVIEW_LISTKEY') : 'all';
        this.userService.getUserAVdata(localStorage.getItem('USER_REVIEW_KEY'), listKey).subscribe(function (res) {
            _this.rows = res.sort(function (a, b) {
                if (a.companyName.toLowerCase() < b.companyName.toLowerCase()) {
                    return -1;
                }
                if (a.companyName.toLowerCase() > b.companyName.toLowerCase()) {
                    return 1;
                }
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1;
                }
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get av data: ', err);
        });
    };
    UserReviewComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
        // this.gridApi.sizeColumnsToFit();
    };
    UserReviewComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    UserReviewComponent.prototype.onSelectionChanged = function (event) {
        var _this = this;
        this.selectedCount = event.api.getSelectedNodes().length;
        this.selectedRows = [];
        event.api.getSelectedNodes().forEach(function (element) {
            _this.selectedRows.push(element.data);
        });
    };
    UserReviewComponent.prototype.saveTempAvdata = function () {
        var _this = this;
        this.isLoading = true;
        var selected_ids = this.selectedRows.map(function (item) { return item.id; });
        var all_ids = this.rows.map(function (item) { return item.id; });
        this.userService.saveTempAvdata(selected_ids, all_ids).subscribe(function (res) {
            _this.isLoading = false;
            var options = { positionClass: 'toast-bottom-right', closeButton: true, timeOut: 20000 };
            _this.toastrService.success("Your selections have been saved. They have NOT been submitted for approval. When you are ready to approve the selections, please click on the green Approve Button.", '', options);
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to save temperary avdata: ', err);
        });
    };
    UserReviewComponent.prototype.approve = function () {
        var _this = this;
        // hide approve modal
        this.approveModal.hide();
        var user = new User();
        this.isLoading = true;
        this.rows.forEach(function (row) {
            _this.selectedRows.forEach(function (selectedRow) {
                if (row.id === selectedRow.id) {
                    row.checked = true;
                }
            });
        });
        this.userService.approveAVData(this.rows, localStorage.getItem('USER_REVIEW_KEY'), localStorage.getItem('USER_KEY'), '').subscribe(function (res) {
            user._id = localStorage.getItem('USER_REVIEW_KEY');
            user.lastApprovedCount = _this.selectedCount;
            user.lastApprovedOn = new Date().getTime();
            user.lastApprovedPercentage = Math.round((_this.selectedCount / _this.rows.length) * 100);
            _this.userService.setLastApproved(user).subscribe(function (result) {
                localStorage.removeItem('USER_AVDATA_CACHED_AT');
                _this.loadData();
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to set last approved: ', err);
            });
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to approve av data: ', err);
        });
    };
    return UserReviewComponent;
}());
export { UserReviewComponent };
