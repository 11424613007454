<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="row">
    <div class="col-sm-3">
        <div class="input-group mb-3">
            <select class="browser-default custom-select" id="select" [(ngModel)]="select">
                <option selected value="">Select Company</option>
                <option *ngFor="let user of uniqueData">{{user.company}}</option>
            </select>
        </div>
    </div>
    <div class="col-sm-3">
        <input type="date" class="form-control" id="connectedAfter" [(ngModel)]="connectedAfter" placeholder="As of date">
    </div>
</div>

<div class="row">
    <div class="col-12">
        <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="download()" mdbWavesEffect>Export
            Company PX Data</button>
        <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="downloadApprovedContacts()" mdbWavesEffect>Export Approved Contacts</button>
    </div>
</div>    
<hr/>
<div class="row">
    <div class="col-12">
        <h4>Corporate Email Manager</h4>
    </div>
   
    <div class="col-sm-12">
        <div class="row">
            <div class="form-group col-sm-6">
                <div class="form-check">
                    <input class="form-check-input" name="options" type="radio"
                        id="data1">
                    <label class="form-check-label" for="data1">
                        Connections w/o Corporate Email Addresses
                    </label>
                </div>
            </div>
            
        </div>
        <div class="row">
            <div class="form-group col-sm-6">
                <div class="form-check">
                    <input class="form-check-input" name="options" type="radio" 
                        id="data2">
                    <label class="form-check-label" for="data2">
                        Something else later
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-1">Date Range </div>
    <div class="col-sm-2">
        <input type="date" class="form-control" id="connectedAfter" [(ngModel)]="startDate" placeholder="As of date">
    </div>
    - 
    <div class="col-sm-2">
        <input type="date" class="form-control" id="connectedAfter" [(ngModel)]="endDate" placeholder="As of date">
    </div>
</div>
<div class="row">
    <div class="col-12">
        <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="exportCorporateEmails()" mdbWavesEffect>Export</button>
    </div>
</div>  