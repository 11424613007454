import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { User } from '../models/user';
import { PXData } from '../models/PXData';
import { Message } from '../models/message';
import { AVLists } from '../models/AVLists';
import { Message_Beta } from '../models/message_beta';
import { DashboardProps } from '../models/dashboardProps';
import { listsProps, listUser } from '../models/listsProps';
import { environment } from '../../environments/environment';
import { SlackService } from './slack.services';

declare var require: any
const NodeCache = require("node-cache");

@Injectable({
	providedIn: 'root'
})
export class UserService {
	myCache = new NodeCache();

	constructor(private http: HttpClient, private slackService: SlackService) {
	}

	public getBaseUrl(): string {
		return environment.baseUrl;
	}

	protected getUrl(relativeUrl: string) {
		return this.getBaseUrl() + relativeUrl;
	}

	protected getOptions(): any {
		const AuthorizeToken = localStorage.getItem('USER_TOKEN');

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache',
			'Authorization': 'Bearer ' + AuthorizeToken
		});

		const options = {
			headers: headers
		};

		return options;
	}

	getUsers(): Observable<User[]> {
		const url = this.getUrl('/users/documents/not-hidden');

		return this.http.get(url, this.getOptions())
			.pipe(
				map((response: any) => {
					const users: User[] = [];
					response.forEach((data) => {
						const user = new User();
						user._id = data._id;
						user.name = data.name;
						user.lastApprovedCount = data.lastApprovedCount;
						user.lastApprovedOn = data.lastApprovedOn;
						user.lastApprovedPercentage = Math.round(data.lastApprovedPercentage);
						user.company = data.company;
						user.email = data.email;
						user.password = data.password;
						user.role = data.role;
						user.linkedInProfileId = data.linkedInProfileId;
						user.memberId = data.memberId;
						user.googleDocURL = data.googleDocURL;
						user.isHidden = data.isHidden;
						user.selfserve = data.selfserve; //code here
						users.push(user);
					});
					return users
				})
			)
	}

	searchAVLists(_id: string, searchText: string): Observable<AVLists[]> {
		console.log('Searching user AVLists from database...');
		const url = this.getUrl('/avlists/documents/' + _id);

		return this.http.get(url, this.getOptions())
			.pipe(map((querySnapshot: any) => {
				const dataArray: AVLists[] = [];

				querySnapshot.forEach((data: any) => {
					if (data.listName && data.listName.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
						const object = new AVLists();
						object._id = data._id;
						object.listName = data.listName;
						object.listFor = data.listFor;
						object.uploadedCount = data.uploadedCount;
						object.uploadedOn = data.uploadedOn;
						object.approvedOn = data.approvedOn;
						object.approvedBy = data.approvedBy;
						object.approvedCount = data.approvedCount;
						object.notApproved = data.uploadedCount - data.approvedCount;
						object.approvedPercentage = Math.round((data.approvedCount / data.uploadedCount) * 100);
						object.company = data.company;
						object.name = data.name;
						dataArray.push(object);
					}
				});

				return dataArray;
			}));
	}

	getAllUsers(): Observable<DashboardProps> {
		const url = this.getUrl('/users');

		return new Observable((observer) => {
			this.http.get(url, this.getOptions())
				.subscribe((response: any) => {
					const users: User[] = [];
					const companies: string[] = [];

					response.forEach((data) => {
						const user = new User();
						user._id = data._id;
						user.name = data.name;
						user.lastApprovedCount = data.lastApprovedCount;
						user.lastApprovedOn = data.lastApprovedOn;
						user.lastApprovedPercentage = Math.round(data.lastApprovedPercentage);
						user.company = data.company;
						if (!companies.includes(data.company)) {
							companies.push(data.company);
						}
						user.email = data.email;
						user.password = data.password;
						user.role = data.role;
						user.linkedInProfileId = data.linkedInProfileId;
						user.myId = data.myId;
						user.memberId = data.memberId;
						user.googleDocURL = data.googleDocURL;
						user.isHidden = data.isHidden;
						user.isLila = data.isLila;
						user.isViewedprofilesActive = data.isViewedprofilesActive;
						user.groups = data.groups;
						user.selfserve = data.selfserve;
						user.notifications = data.notifications;
						user.isResearcher = data.isResearcher;
						const researcher = response.filter((user: User) => user._id === data.assignedResearcher);
						if (researcher.length > 0) {
							user.reseacher = researcher[0].initials;
						}
						user.assignedResearcher = data.assignedResearcher;
						user.initials = data.initials;
						user.leadStatusOptions = data.leadStatusOptions||[];
						users.push(user);
					});

					observer.next({ users: users, companies: companies });
				})
		});
	}
	actionOnRequest(USER_MEMBER_ID,data:any) { 
		const url = this.getUrl('/connection-requests/action-on-request/' + USER_MEMBER_ID);
		return this.http.post(url, data, this.getOptions())
			.pipe(map((res: any) => {
				return res;
		}));
	}
	getUserIncomingRequests(USER_MEMBER_ID) { 
		const url = this.getUrl('/connection-requests/get-incoming-requests/'+USER_MEMBER_ID);
		return this.http.get(url, this.getOptions())
			.pipe(
				map((querySnapshot: any) => {
					return querySnapshot;
				}));
	}
	getUsersDashboard(): Observable<DashboardProps> {
		const url = this.getUrl('/users/documents/get-dashboard');

		return this.http.get(url, this.getOptions())
			.pipe(
				map((querySnapshot: any) => {
					const users: User[] = [];
					const companies: string[] = [];

					querySnapshot.forEach((data) => {
						const user = new User();
						user._id = data._id;
						user.name = data.name;
						user.memberId = data.memberId;
						user.lastApprovedCount = data.lastApprovedCount;
						user.lastApprovedOn = data.lastApprovedOn;
						user.lastApprovedPercentage = Math.round(data.lastApprovedPercentage);
						user.company = data.company;
						if (!companies.includes(data.company)) {
							companies.push(data.company);
						}
						user.email = data.email;
						user.password = data.password;
						user.role = data.role;
						user.linkedInProfileId = data.linkedInProfileId;
						user.connectionsCount = data.connectionsCount;
						user.pxUpdatedOn = data.pxUpdatedOn;
						user.avUpdatedOn = data.avUpdatedOn;
						user.r4rCount = data.r4rCount;
						user.msgUpdateOn = data.msgUpdateOn;
						user.lastMsgCheck = data.lastMsgCheck;
						user.incomingMsgs = data.incomingMsgs;
						user.selfserve = data.selfserve;
						user.unReviewedListsNumber = data.unReviewedListsNumber;
						users.push(user);
					});

					return { users: users, companies: companies };
				}));
	}

	getUsersSimpleDashboard(): Observable<DashboardProps> {
		const url = this.getUrl('/users/documents/get-simple-dashboard');

		return this.http.get(url, this.getOptions())
			.pipe(
				map((querySnapshot: any) => {
					const users: User[] = [];
					const companies: string[] = [];

					querySnapshot.forEach((data) => {
						const user = new User();
						user._id = data._id;
						user.name = data.name;
						user.lastApprovedCount = data.lastApprovedCount;
						user.lastApprovedOn = data.lastApprovedOn;
						user.lastApprovedPercentage = Math.round(data.lastApprovedPercentage);
						user.company = data.company;
						if (!companies.includes(data.company)) {
							companies.push(data.company);
						}
						user.email = data.email;
						user.password = data.password;
						user.role = data.role;
						user.linkedInProfileId = data.linkedInProfileId;
						user.connectionsCount = data.connectionsCount;
						user.avUpdatedOn = data.avUpdatedOn;
						user.r4rCount = data.r4rCount;
						user.unReviewedListsNumber = data.unReviewedListsNumber;
						user.lastIncomingMsg = data.lastIncomingMsg;
						user.lastSentMsg = data.lastSentMsg;
						user.selfserve = data.selfserve;
						users.push(user);
					});

					return { users: users, companies: companies };
				}));
	}

	getCAUsers(): Observable<User[]> {
		const url = this.getUrl('/users/documents/company/' + localStorage.getItem('USER_COMPANY'));

		return this.http.get(url, this.getOptions())
			.pipe(
				map((querySnapshot: any) => {
					const users: User[] = [];

					querySnapshot.forEach((data) => {
						const user = new User();
						user._id = data._id;
						user.name = data.name;
						user.lastApprovedCount = data.lastApprovedCount;
						user.lastApprovedOn = data.lastApprovedOn ? data.lastApprovedOn : 0;
						user.lastApprovedPercentage = data.lastApprovedPercentage ? Math.round(data.lastApprovedPercentage) : 0;
						user.company = data.company ? data.company : '';
						user.email = data.email;
						user.password = data.password;
						user.role = data.role;
						user.linkedInProfileId = data.linkedInProfileId;
						user.memberId = data.memberId;
						users.push(user);
					});

					return users;
				}));
	}

	getCADashboard(): Observable<User[]> {
		const url = this.getUrl('/users/documents/ca-dashboard/company/' + localStorage.getItem('USER_COMPANY'));

		return this.http.get(url, this.getOptions())
			.pipe(
				map((querySnapshot: any) => {
					const users: User[] = [];

					querySnapshot.forEach((data) => {
						const user = new User();
						user._id = data._id;
						user.name = data.name;
						user.lastApprovedCount = data.lastApprovedCount;
						user.lastApprovedOn = data.lastApprovedOn;
						user.lastApprovedPercentage = Math.round(data.lastApprovedPercentage);
						user.company = data.company;
						user.email = data.email;
						user.password = data.password;
						user.role = data.role;
						user.linkedInProfileId = data.linkedInProfileId;
						user.connectionsCount = data.connectionsCount;
						user.pxUpdatedOn = data.pxUpdatedOn;
						user.avUpdatedOn = data.avUpdatedOn;
						user.r4rCount = data.r4rCount;
						user.msgUpdateOn = data.msgUpdateOn;
						user.incomingMsgs = data.incomingMsgs;
						user.unReviewedListsNumber = data.unReviewedListsNumber;
						users.push(user);
					});

					return users;
				}));
	}

	updateProfile(user: User): Observable<boolean> {
		const url = this.getUrl('/users/' + user._id);

		return this.http.put(url, {
			name: user.name,
			email: user.email
		}, this.getOptions())
			.pipe(map((res: any) => {
				return true;
			}));
	}

	setLastApproved(user: User,summary:any={}): Observable<boolean> {
		const url = this.getUrl('/users/' + user._id);
		return this.http.put(url, {

			lastApprovedOn: user.lastApprovedOn,
			lastApprovedCount: user.lastApprovedCount,
			lastApprovedPercentage: Math.round(user.lastApprovedPercentage),
		}, this.getOptions())
			.pipe(
				map((res: any) => {
					let userEmail = localStorage.getItem('USER_EMAIL');
					let userName = localStorage.getItem('USER_NAME');
					// send slack message here
					let message = `*${userName} has approved ${user.lastApprovedCount} records (${Math.round(user.lastApprovedPercentage)}%)*`;
					if(user.lastApprovedPercentage<100 && Object.entries(summary).length){
						message = `${message}\n>*Rejected Summary*`;
						let testSummary = ``;
						for(let [key,value] of Object.entries(summary)){
							testSummary = `${testSummary}\n>${key}: ${value}`;
						}
						message+=testSummary
					}
					if (user.note) {
						message = message + `\nNote: ${user.note}`;
					}
					
					this.sendSlackMessage(message);

					// send email to the admin
					let email = {
						subject: `List Approved - ${userEmail}`,
						to: `activity-tracker@rainmakerdots.com`,
						from: `Activity Tracker <noreply@rainmakerdots.com>`,
						html: `<p>Hi there,</p><p>This is to inform you that ${userName} <${userEmail}> has approved ${user.lastApprovedCount} records (${Math.round(user.lastApprovedPercentage)}%).</p>`
					}

					if (user.note) {
						email.html = email.html + `<p><b>Note from user:</b> ${user.note}</p>`;
					}

					const url_admin = this.getUrl('/users/send-email');

					this.http.post(url_admin, { content: email }, this.getOptions()).subscribe((res) => {
						console.log(res);
					}, (err) => {
						console.log(err);
					})

					// send email to user
					let emailToUser = {
						subject: `List Approved`,
						to: `${userEmail}`,
						from: `Activity Tracker <noreply@rainmakerdots.com>`,
						html: `<p>Hi ${userName},</p><p>Thanks for approving your list, we are working on it.</p>`
					};

					const url_user = this.getUrl('/users/send-email-user');

					this.http.post(url_user, { content: emailToUser, userName: userName }, this.getOptions()).subscribe((res) => {
						console.log(res);
					}, (err) => {
						console.log(err);
					});

					return true;
				}));
	}

	sendSlackMessage(message: string) {
		this.slackService.sendSlackMessage({ "text": message, "channel": 'list-approvals', "username": 'Approval Bot' }).subscribe()
	}
	updateMessageData(messageDataArray: any[]): Observable<any> {
		const url = this.getUrl('/messages');

		return this.http.post(url, { messageDataArray: messageDataArray }, this.getOptions())
			.pipe(
				map((res: any) => {
					return res;
				})
			)
	}
	updateCorpEmailData(dataArray: any[]): Observable<any> {
		const url = this.getUrl('/corp-emails/upload');

		return this.http.put(url, { dataArray }, this.getOptions())
			.pipe(
				map((res: any) => {
					return res;
				})
			)
	}
	updateRequestedEmailData(dataArray: any[]): Observable<any> {
		const url = this.getUrl('/corp-emails/upload-requested-email');

		return this.http.put(url, { dataArray }, this.getOptions())
			.pipe(
				map((res: any) => {
					return res;
				})
			)
	}
	updatePXData(pxDataArray: PXData[], _id: string): Observable<string> {
		const url = this.getUrl('/pxdata');

		return this.http.post(url, { pxDataArray: pxDataArray, _id: _id }, this.getOptions())
			.pipe(
				map((res: any) => {
					return res;
				})
			)
	}

	approveAVData(avData: any[], _id: string, actionUID: string, note: string): Observable<boolean> {

		const url = this.getUrl('/avdata/approve/' + _id + '/' + actionUID);

		return this.http.put(url, { avData: avData }, this.getOptions())
			.pipe(
				map((res) => {
					return true;
				})
			);
	}

	saveTempAvdata(selected_ids: string[], all_ids: string[]): Observable<boolean> {
		const url = this.getUrl('/avdata/save-temp-selected');

		return this.http.put(url, { selected_ids: selected_ids, all_ids: all_ids }, this.getOptions())
			.pipe(
				map((res) => {
					return true;
				})
			);
	}

	disapproveAVData(avData: any[], _id: string): Observable<boolean> {
		const url = this.getUrl('/avdata/disapprove/' + _id);

		return this.http.put(url, { avData: avData }, this.getOptions())
			.pipe(
				map((res) => {
					return true;
				})
			);
	}

	updateAVData(pxDataArray: any[], _id: string, listName: string, type: string): Observable<any> {
		const url = this.getUrl('/avdata');

		return this.http.post(url, { pxDataArray: pxDataArray, _id: _id, listName: listName, type: type }, this.getOptions())
			.pipe(
				map((res: any) => {
					return res;
				})
			)
	}

	getUserByEmail(email: string): Observable<User> {
		const url = this.getUrl('/users');

		return this.http.get(url, this.getOptions())
			.pipe(
				map((querySnapshot: any) => {
					const user = new User();

					querySnapshot.forEach((data) => {
						if (data.email.toLowerCase() == email.toLocaleLowerCase()) {
							user._id = data._id;
							user.name = data.name;
							user.company = data.company;
							user.email = data.email;
							user.password = data.password;
							user.role = data.role;
							user.linkedInProfileId = data.linkedInProfileId;
							user.googleDocURL = data.googleDocURL;
							user.isHidden = data.isHidden;
							user.selfserve = data.selfserve;
						}
					});

					return user;
				})
			);
	}

	getUserByKey(_id: string): Observable<User> {
		const url = this.getUrl('/users/' + _id);

		return this.http.get(url, this.getOptions())
			.pipe(
				map((data: any) => {
					const user = new User();
					user._id = _id;
					user.name = data.name;
					user.leadStatusOptions = data.leadStatusOptions||[];
					user.company = data.company;
					user.email = data.email;
					user.password = data.password;
					user.role = data.role;
					user.linkedInProfileId = data.linkedInProfileId ? data.linkedInProfileId : '';
					user.googleDocURL = data.googleDocURL ? data.googleDocURL : '';
					user.isHidden = data.isHidden;
					user.notifications = data.notifications;
					user.isLila = !!data.isLila;
					user.groups = !!data.groups;
					user.isViewedprofilesActive = !!data.isViewedprofilesActive
					return user;
				}));

	}

	editUser(user: User): Observable<User> {
		const url = this.getUrl('/users/' + user._id);

		return this.http.put(url, user, this.getOptions())
			.pipe(
				map((data: any) => {
					const user = new User();
					user._id = data._id;
					user.name = data.name;
					user.lastApprovedCount = data.lastApprovedCount;
					user.lastApprovedOn = data.lastApprovedOn;
					user.lastApprovedPercentage = Math.round(data.lastApprovedPercentage);
					user.company = data.company;
					user.email = data.email;
					user.role = data.role;
					user.linkedInProfileId = data.linkedInProfileId;
					user.myId = data.myId;
					user.memberId = data.memberId;
					user.googleDocURL = data.googleDocURL;
					user.isHidden = data.isHidden;
					user.selfserve = data.selfserve;
					user.isResearcher = data.isResearcher;
					user.assignedResearcher = data.assignedResearcher;
					user.initials = data.initials;

					return user;
				})
			)
	}

	addUser(user: User): Observable<User> {
		const url = this.getUrl('/users');

		return this.http.post(url, user, this.getOptions())
			.pipe(
				map((data: any) => {
					const user = new User();
					user._id = data._id;
					user.name = data.name;
					user.company = data.company;
					user.email = data.email;
					user.password = data.password;
					user.role = data.role;
					user.linkedInProfileId = data.linkedInProfileId;
					user.myId = data.myId;
					user.memberId = data.memberId;
					user.googleDocURL = data.googleDocURL;
					user.isHidden = data.isHidden;
					user.isLila = data.isLila;
					user.groups = data.groups;
					user.isViewedprofilesActive = data.isViewedprofilesActive;
					user.selfserve = data.selfserve; //code here

					return user;
				})
			)
	}

	changeUserLinkedinID(pre_lid: string, linkedInProfileId: string): Observable<Boolean> {
		const url = this.getUrl('/messages/documents/' + pre_lid);

		return this.http.put(url, { new_lid: linkedInProfileId }, this.getOptions())
			.pipe(
				map((res: any) => {
					return true
				})
			)
	}

	toggleNotifications(_id: string, notifications: boolean): Observable<Boolean> {
		const url = this.getUrl('/users/toggleNotifications/' + _id);

		return this.http.put(url, { notifications: notifications }, this.getOptions())
			.pipe(
				map((res: any) => {
					console.log(res);
					return res.notifications;
				})
			)
	}
	stopMessage(_id: string): Observable<Message_Beta[]> {
		const url = this.getUrl('/pxdata/stop-msg/'+_id);
		return this.http.put(url, {},this.getOptions())
			.pipe(
				map((response: any) => {
					return response
				})
			)
	}
	getThreadMessages(_id: string, memberId: string): Observable<any> {
		return new Observable((observer) => {
			const url = this.getUrl('/messages/thread-documents/' + _id + '/' + memberId);
			this.http.get(url, this.getOptions())
				.subscribe((querySnapshot: any) => {
					observer.next(querySnapshot.data);
				});
		});
	}
	getIncomingMessages(_id: string, memberId: string,skip:number,count:number): Observable<any> {
		return new Observable((observer) => {
			this.shouldUseCachedIncomingMessagesBeta(_id).subscribe((shouldUseCachedMessagesBeta) => {
				if (shouldUseCachedMessagesBeta && this.myCache.get('USER_INCOMING_MESSAGES_BETA')) {
					console.log('Getting user messages_beta from cache...');
					observer.next(JSON.parse(this.myCache.get('USER_INCOMING_MESSAGES_BETA')));
				} else {
					console.log('Getting user messages_bata from database...');
					const url = this.getUrl('/pxdata/documents/incoming-messages/' + _id + '/' + memberId);

					this.http.get(url, this.getOptions())
						.subscribe((querySnapshot: any) => {
							const messages: Message_Beta[] = [];

							querySnapshot.data.forEach((data) => {
								var message = new Message_Beta();
								message.pxDataId = data.pxDataId;
								message.pxDataStatus = data.pxDataStatus||"";
								message.stopmsg = data.stopmsg;
								message.thread = data['thread'];
								message.id = data['_id'];
								message.tstamp = new Date(data['tstamp']);
								message.company = data['company'] ? data['company'] : '';
								message.company_url = data['company_url'] ? data['company_url'] : '';
								message.subRows = data['subRows'] ? data['subRows'] : [];
								message.contact = data['contact'];
								message.contactURL = data['contactURL'];
								message.body = data['body'];
								message.from_avdata = Boolean(data['from_avdata']);
								messages.push(message);
							});

							this.myCache.set("USER_INCOMING_MESSAGES_BETA", JSON.stringify(messages), 86400000);
							//localStorage.setItem('USER_MESSAGES', JSON.stringify(messages));
							localStorage.setItem('USER_INCOMING_MESSAGES_BETA_CACHED_AT', '' + new Date().getTime());

							observer.next(messages);
						});
				}
			});
		});
	}
	getUserBetaMessages(_id: string, memberId: string,skip:number,count:number): Observable<any> {
		return new Observable((observer) => {
			this.shouldUseCachedMessagesBeta(_id).subscribe((shouldUseCachedMessagesBeta) => {
				if (shouldUseCachedMessagesBeta && this.myCache.get('USER_MESSAGES_BETA')) {
					console.log('Getting user messages_beta from cache...');
					observer.next(JSON.parse(this.myCache.get('USER_MESSAGES_BETA')));
				} else {
					console.log('Getting user messages_bata from database...');
					const url = this.getUrl('/messages/documents/beta/' + _id + '/' + memberId+"?skip="+skip+"&count="+count);

					this.http.get(url, this.getOptions())
						.subscribe((querySnapshot: any) => {
							const messages: Message_Beta[] = [];

							querySnapshot.data.forEach((data) => {
								var message = new Message_Beta();
								message.pxDataId = data.pxDataId;
								message.pxDataStatus = data.pxDataStatus||"";
								message.stopmsg = data.stopmsg;
								message.thread = data['thread'];
								message.id = data['_id'];
								message.tstamp = data['tstamp'];
								message.company = data['company'] ? data['company'] : '';
								message.company_url = data['company_url'] ? data['company_url'] : '';
								message.subRows = data['subRows'] ? data['subRows'] : [];
								message.contact = data['contact'];
								message.contactURL = data['contactURL'];
								message.body = data['body'];
								messages.push(message);
							});

							this.myCache.set("USER_MESSAGES_BETA", JSON.stringify(messages), 86400000);
							//localStorage.setItem('USER_MESSAGES', JSON.stringify(messages));
							localStorage.setItem('USER_MESSAGES_BETA_CACHED_AT', '' + new Date().getTime());

							observer.next(messages);
						});
				}
			});
		});
	}
	getUserSentMessages(ids:[]): Observable<any> {
		return new Observable((observer) => {
			const url = this.getUrl('/messages/outgoing-count');

					this.http.put(url, ids, this.getOptions())
						.subscribe((querySnapshot: any) => {
							

							//this.myCache.set("USER_MESSAGES", JSON.stringify(messages), 86400000);
							// localStorage.setItem('USER_MESSAGES', JSON.stringify(messages));
							//localStorage.setItem('USER_MESSAGES_CACHED_AT', '' + new Date().getTime());

							observer.next(querySnapshot);
						});
		});
	}
	// getUserMessages(_id: string): Observable<Message[]> {
	// 	return new Observable((observer) => {
	// 		this.shouldUseCachedMessages(_id).subscribe((shouldUseCachedMessages) => {
	// 			if (shouldUseCachedMessages && this.myCache.get('USER_MESSAGES')) {
	// 				console.log('Getting user messages from cache...');
	// 				observer.next(JSON.parse(this.myCache.get('USER_MESSAGES')));
	// 			} else {
	// 				console.log('Getting user messages from database...');
	// 				const url = this.getUrl('/messages/documents/incoming/' + _id);

	// 				this.http.get(url, this.getOptions())
	// 					.subscribe((querySnapshot: any) => {
	// 						const messages: Message[] = [];

	// 						querySnapshot.forEach((data: any) => {
	// 							var message = new Message();
	// 							message.contact = data['firstName_from'] + " " + data['lastName_from'];
	// 							message.contactURL = `https://www.linkedin.com/in/${data['li_pid_from']}`;
	// 							message.createdAt = data['createdAt'];
	// 							message.thread = data['thread'];
	// 							message.body = data['body'];
	// 							message.tstamp = data['tstamp'];
	// 							if (message.createdAt) {
	// 								message.createdAt1 = this.formatDate(parseFloat(message.createdAt));
	// 							} else {
	// 								message.createdAt1 = '';
	// 							}
	// 							message.av_id = data['av_id'] ? 'YES' : 'NO';
	// 							message.company = data['company'];
	// 							message.company_url = data['company_url'];
	// 							messages.push(message);
	// 						});

	// 						this.myCache.set("USER_MESSAGES", JSON.stringify(messages), 86400000);
	// 						// localStorage.setItem('USER_MESSAGES', JSON.stringify(messages));
	// 						localStorage.setItem('USER_MESSAGES_CACHED_AT', '' + new Date().getTime());

	// 						observer.next(messages);
	// 					});
	// 			}
	// 		});
	// 	});
	// }

	//  Review from Randy - SPEED
	getUserMessages(_id: string): Observable<Message[]> {
		return new Observable((observer) => {
		  this.shouldUseCachedMessages(_id).subscribe((shouldUseCachedMessages) => {
			if (shouldUseCachedMessages && this.myCache.get('USER_MESSAGES')) {
			  console.log('Getting user messages from cache...');
			  observer.next(JSON.parse(this.myCache.get('USER_MESSAGES')));
			} else {
			  console.log('Getting user messages from database...');
			  const url = this.getUrl('/messages/documents/incoming/' + _id);
	  
			  this.http.get(url, this.getOptions()).subscribe((querySnapshot: any) => {
				const messages: Message[] = querySnapshot.map((data: any) => ({
				  contact: `${data['firstName_from']} ${data['lastName_from']}`,
				  contactURL: `https://www.linkedin.com/in/${data['li_pid_from']}`,
				  createdAt: data['createdAt'],
				  thread: data['thread'],
				  body: data['body'],
				  tstamp: data['tstamp'],
				  createdAt1: data['createdAt'] ? this.formatDate(parseFloat(data['createdAt'])) : '',
				  av_id: data['av_id'] ? 'YES' : 'NO',
				  company: data['company'],
				  company_url: data['company_url'],
				}));
	  
				this.myCache.set("USER_MESSAGES", JSON.stringify(messages), 86400000);
				localStorage.setItem('USER_MESSAGES_CACHED_AT', `${new Date().getTime()}`);
				observer.next(messages);
			  });
			}
		  });
		});
	  }

	updatePXDataStatus(_id:string,status:number){
		const url = this.getUrl('/pxdata/update-status/' + _id);
		return this.http.put(url,{status}, this.getOptions())
			.pipe(map((data: any) => {
				console.log(data);
				if (this.myCache.get('USER_INCOMING_MESSAGES_BETA')) {
					console.log('Getting user messages_beta from cache...');
					let messages = JSON.parse(this.myCache.get('USER_INCOMING_MESSAGES_BETA'));
					for(let i in messages){
						if(messages[i].id === _id){
							messages[i].pxDataStatus = status;
							break;
						}
					}
					this.myCache.set("USER_INCOMING_MESSAGES_BETA", JSON.stringify(messages), 86400000);
				}
				return data;
			}));
	}
	getUserPXdata(_id: string): Observable<PXData[]> {
		return new Observable((observer) => {
			this.shouldUseCachedPXData(_id).subscribe((shouldUseCachedPX) => {
				if (shouldUseCachedPX && this.myCache.get('USER_PXDATA')) {
					console.log('Getting user PXData from cache...');
					observer.next(JSON.parse(this.myCache.get('USER_PXDATA')));
				} else {
					console.log('Getting user PXData from database...');
					const url = this.getUrl('/pxdata/documents/' + _id);

					this.http.get(url, this.getOptions())
						.subscribe((querySnapshot: any) => {
							const dataArray: PXData[] = [];

							querySnapshot.forEach((data) => {
								const object = new PXData();
								const year = data.yearsAtCompany && data.yearsAtCompany.split('.').length > 1 ? data.yearsAtCompany.split('.')[0] : '';
								object.id = data._id;
								object.ecEmail = data.ecEmail;
								object.campaignStatus = data.campaign_status;
								object.ecEmailUpdatedOn = data.ecEmailUpdatedOn;
								object.lastViewedOn = data.lastViewedOn;
								object.companyName = data.companyName ? data.companyName : ' ';
								object.firstName = data.firstName;
								object.lastName = data.lastName;
								object.profileURL = data.profileURL;
								object.avatar = data.avatar;
								object.title = data.title;
								object.ckId = data.ckId;
								object.status = data.status||0;
								object.archetype = data.archetype;
								object.discType = data.discType;
								object.degrees = data.degrees;
								object.intensity = data.intensity;
								object.p4d = data.p4d;
								object.p4i = data.p4i;
								object.p4s = data.p4s;
								object.p4c = data.p4c;
								object.qualities = data.qualities;
								object.email = data.ecEmail || data.email;
								object.from_avdata = data.from_avdata;
								object.phone = data.phone;
								object.location = data.location;
								object.yearsAtCompany = data.yearsAtCompany;
								object.since1 = year && year > 0 ? ((new Date()).getFullYear() - year) : 0;
								object.mutualConnections = data.mutualConnections;
								object.connectedOnDate = data.connectedOnDate;
								if (object.connectedOnDate) {
									object.connectedOnDate = this.formatDateWithShortYear(object.connectedOnDate);
								} else {
									object.connectedOnDate = ' ';
								}
								object.companyProfileURL = data.companyProfileURL;
								object.convoUrl = data.lastIncomingMsgThread ? "https://www.linkedin.com/messaging/thread/"+data.lastIncomingMsgThread : '';
								object.lastMessage = data.lastIncomingMsgBody ? data.lastIncomingMsgBody : '';
								object.lastMessageDate = data.lastIncomingMsgDate ? this.formatDateWithShortYear(data.lastIncomingMsgDate) : '';
								if (object.lastMessageDate) {
									object.lastMessageDate1 = this.formatDate(object.lastMessageDate);
								} else {
									object.lastMessageDate1 = '';
								}
								dataArray.push(object);
							});

							this.myCache.set("USER_PXDATA", JSON.stringify(dataArray), 86400000);
							// localStorage.setItem('USER_PXDATA', JSON.stringify(dataArray));
							localStorage.setItem('USER_PXDATA_CACHED_AT', '' + new Date().getTime());

							observer.next(dataArray);
						});
				}
			});
		});
	}
	
	getCorporateData(body: any): Observable<any> {
		const url = this.getUrl('/pxdata/getCorporateData');
		return this.http.post(url,body, this.getOptions());
	}
	getEUDashboard(_id: string, user_member_id): Observable<any> {
		const url = this.getUrl('/pxdata/getEUDashboard/' + _id + '/' + user_member_id);

		return this.http.get(url, this.getOptions())
			.pipe(map((data: any) => {
				return data;
			}));
	}

	getPxdataById(_id: string): Observable<PXData[]> {
		const url = this.getUrl('/pxdata/' + _id);

		return this.http.get(url, this.getOptions())
			.pipe(map((data: any) => {
				return data;
			}));
	}

	getCompanyPXdata(_ids: any[], connectedAfter): Observable<PXData[]> {
		return new Observable((observer) => {
			const allObservables = [];

			for (let i = 0; i < _ids.length; i++) {
				const url = this.getUrl('/pxdata/documents/export/' + _ids[i]);
				const eachObservable = this.http.get(url, this.getOptions());
				allObservables.push(eachObservable);
			}

			forkJoin(allObservables).subscribe((res: any) => {
				console.log('forkjoin result: ', res);
				const dataArray: PXData[] = [];

				for (let k = 0; k < res.length; k++
				) {
					for (let i = 0; i < res[k].length; i++) {
						const data = res[k][i];

						if (connectedAfter !== undefined) {
							if (new Date(data.connectedOn) > new Date(connectedAfter)) {
								const object = new PXData();
								const year = data.yearsAtCompany && data.yearsAtCompany.split('.').length > 1 ? data.yearsAtCompany.split('.')[0] : '';
								object.id = data.Id;
								object.user_id = _ids[i];
								object.companyName = data.companyName ? data.companyName : ' ';
								object.firstName = data.firstName;
								object.lastName = data.lastName;
								object.profileURL = data.profileURL;
								object.title = data.title;
								object.email = data.email;
								object.phone = data.phone;
								object.location = data.location;
								object.yearsAtCompany = data.yearsAtCompany;
								object.since = year && year > 0 ? ((new Date()).getFullYear() - year).toString() : '';
								if (object.since === '0') { object.since = '<1'; }
								object.mutualConnections = data.mutualConnections;
								object.connectedOn = data.connectedOn;
								if (object.connectedOn) {
									object.connectedOn = this.formatDate(object.connectedOn);
								} else {
									object.connectedOn = ' ';
								}
								object.companyProfileURL = data.companyProfileURL;
								dataArray.push(object);
							}
						} else {
							const object = new PXData();
							const year = data.yearsAtCompany && data.yearsAtCompany.split('.').length > 1 ? data.yearsAtCompany.split('.')[0] : '';
							object.id = data.Id;
							object.user_id = _ids[i];
							object.companyName = data.companyName ? data.companyName : ' ';
							object.firstName = data.firstName;
							object.lastName = data.lastName;
							object.profileURL = data.profileURL;
							object.title = data.title;
							object.email = data.email;
							object.phone = data.phone;
							object.location = data.location;
							object.yearsAtCompany = data.yearsAtCompany;
							object.since = year && year > 0 ? ((new Date()).getFullYear() - year).toString() : '';
							if (object.since === '0') { object.since = '<1'; }
							object.mutualConnections = data.mutualConnections;
							object.connectedOn = data.connectedOn;
							if (object.connectedOn) {
								object.connectedOn = this.formatDate(object.connectedOn);
							} else {
								object.connectedOn = ' ';
							}
							object.companyProfileURL = data.companyProfileURL;
							dataArray.push(object);
						}
					}
				};

				observer.next(dataArray);
			});
		});
	}

	getCompanyApprovedContacts(_ids: any[], connectedAfter): Observable<PXData[]> {
		return new Observable((observer) => {
			const allObservables = [];

			for (let i = 0; i < _ids.length; i++) {
				const url = this.getUrl('/avdata/documents/approved/' + _ids[i]);
				const eachObservable = this.http.get(url, this.getOptions());
				allObservables.push(eachObservable);
			}

			forkJoin(allObservables).subscribe((res: any) => {
				console.log('forkjoin result: ', res);
				const dataArray: PXData[] = [];

				for (let k = 0; k < res.length; k++) {
					for (let i = 0; i < res[k].length; i++) {
						const data = res[k][i];

						if (connectedAfter !== undefined) {
							if (new Date(data.connectedOn) > new Date(connectedAfter)) {
								const object = new PXData();
								const year = data.yearsAtCompany && data.yearsAtCompany.split('.').length > 1 ? data.yearsAtCompany.split('.')[0] : '';
								object.id = data._id;
								object.user_id = _ids[i];
								object.companyName = data.companyName ? data.companyName : ' ';
								object.firstName = data.firstName;
								object.lastName = data.lastName;
								object.profileURL = data.profileURL;
								object.title = data.title;
								object.email = data.email;
								object.phone = data.phone;
								object.location = data.location;
								object.yearsAtCompany = data.yearsAtCompany;
								object.since = year && year > 0 ? ((new Date()).getFullYear() - year).toString() : '';
								if (object.since === '0') { object.since = '<1'; }
								object.mutualConnections = data.mutualConnections;
								object.connectedOn = data.connectedOn;
								if (object.connectedOn) {
									object.connectedOn = this.formatDate(object.connectedOn);
								} else {
									object.connectedOn = ' ';
								}
								object.companyProfileURL = data.companyProfileURL;
								//if (allKeys.indexOf(object.user_id) == -1) {
								dataArray.push(object);
								//allKeys.push(object.user_id);
								//}
							}
						} else {
							const object = new PXData();
							const year = data.yearsAtCompany && data.yearsAtCompany.split('.').length > 1 ? data.yearsAtCompany.split('.')[0] : '';
							object.id = data._id;
							object.user_id = _ids[i];
							object.companyName = data.companyName ? data.companyName : ' ';
							object.firstName = data.firstName;
							object.lastName = data.lastName;
							object.profileURL = data.profileURL;
							object.title = data.title;
							object.email = data.email;
							object.phone = data.phone;
							object.location = data.location;
							object.yearsAtCompany = data.yearsAtCompany;
							object.since = year && year > 0 ? ((new Date()).getFullYear() - year).toString() : '';
							if (object.since === '0') { object.since = '<1'; }
							object.mutualConnections = data.mutualConnections;
							object.connectedOn = data.connectedOn;
							if (object.connectedOn) {
								object.connectedOn = this.formatDate(object.connectedOn);
							} else {
								object.connectedOn = ' ';
							}
							object.companyProfileURL = data.companyProfileURL;
							//if (allKeys.indexOf(object.user_id) == -1) {
							dataArray.push(object);
							//allKeys.push(object.user_id);
							//}
						}
					}
				};

				observer.next(dataArray);
			});
		});
	}

	getCompanyEUdata(_id: string, from, to, users, directionI=0, directionS=0): Observable<PXData[]> {
		const url = this.getUrl('/pxdata/documents/export/' + _id + '/' + from + '/' + to + "?directionI=" + directionI + "&directionS=" + directionS);
		let ids = [];

		if (users) {
			for (let i of users) {
				ids.push(i._id);
			}
		}

		return this.http.put(url, { ids: ids }, this.getOptions())
			.pipe(map((querySnapshot: any) => {
				const dataArray: PXData[] = [];
				querySnapshot.forEach((data: any) => {
					const object = new PXData();
					const year = data.yearsAtCompany && data.yearsAtCompany.split('.').length > 1 ? data.yearsAtCompany.split('.')[0] : '';
					object.id = data.Id;
					object.messages = data.messages;
					object.companyName = data.companyName ? data.companyName : ' ';
					object.firstName = data.firstName;
					object.lastName = data.lastName;
					object.profileURL = data.profileURL;
					object.title = data.title;
					object.email = data.email;
					object.phone = data.phone;
					object.location = data.location;
					object.yearsAtCompany = data.yearsAtCompany;
					object.since = year && year > 0 ? ((new Date()).getFullYear() - year).toString() : '';
					if (object.since === '0') { object.since = '<1'; }
					object.mutualConnections = data.mutualConnections;
					object.connectedOn = data.connectedOn;
					if (object.connectedOn) {
						object.connectedOn = this.formatDate(object.connectedOn);
					} else {
						object.connectedOn = ' ';
					}
					object.companyProfileURL = data.companyProfileURL;
					object.user = data.user||"";
					dataArray.push(object);
				});

				return dataArray;
			}));
	}

	getUserAVdata(_id: string, listKey: string): Observable<PXData[]> {
		return new Observable((observer) => {
			this.shouldUseCachedAVData(_id).subscribe((shouldUseCachedAV) => {
				if (listKey ==='all' && shouldUseCachedAV && this.myCache.get('USER_AVDATA')) {
					console.log('Getting user AVData from cache...');
					observer.next(JSON.parse(this.myCache.get('USER_AVDATA')));
				} else {
					// console.log('Getting user AVData from database...');
					const url = this.getUrl('/avdata/documents/review/' + _id + '/' + listKey);

					this.http.get(url, this.getOptions())
						.subscribe((querySnapshot: any) => {
							const dataArray: PXData[] = [];

							querySnapshot.forEach((data: any) => {
								const object = new PXData();
								const year = data.yearsAtCompany ? data.yearsAtCompany.split('.')[0] : 0;
								object.id = data._id;
								object.user_id = data.user_id;
								object.member_id = data.member_id;
								object.companyName = data.companyName;
								object.companyId = data.companyId;
								object.firstName = data.firstName;
								object.lastName = data.lastName;
								object.profileURL = data.profileURL;
								object.title = data.title;
								object.email = data.email;
								object.phone = data.phone;
								object.location = data.location;
								object.industry = data.industry;
								object.approved = data.approved;
								object.yearsAtCompany = data.yearsAtCompany;
								object.since1 = year && year > 0 ? ((new Date()).getFullYear() - year) : 0;
								object.mutualConnections = data.mutualConnections;
								object.connectedOn = data.connectedOn;
								object.companyProfileURL = data.companyProfileURL;
								object.listId = data.listId;
								object.rejectionReason = data.rejectionReason;
								object.tempSelected = data.tempSelected;
								object.organization_location_1 = data.organization_location_1||"";
								object.organization_industry_1 = data.organization_industry_1||"";
								object.organization_headcount_1 = data.organization_headcount_1 || "";
								// Randy added this as it wasn't showing up on review
								object.followers = data.followers || "";
								object.organization_website_1 = data.organization_website_1 || "";
								dataArray.push(object);
							});

							if (listKey === 'all'){
								this.myCache.set("USER_AVDATA", JSON.stringify(dataArray), 86400000);
								// localStorage.setItem('USER_AVDATA', JSON.stringify(dataArray));
								localStorage.setItem('USER_AVDATA_CACHED_AT', '' + new Date().getTime());
							}
							observer.next(dataArray);
						});
				}
			});
		});
	}

	getAVlistPrep(_id: string, type: string): Observable<listsProps> {
		console.log('Getting user AVLists from database...');
		const url = this.getUrl('/avlists/documents/list-prep/' + _id);

		return this.http.get(url, this.getOptions())
			.pipe(map((querySnapshot: any) => {
				const dataArray: AVLists[] = [];
				const users: listUser[] = [];
				const companies: string[] = [];

				// if (type === 'unapproved') {
				// 	querySnapshot = querySnapshot.filter((item) => item.approvedOn === 0);
				// }

				querySnapshot.forEach((data: any) => {
					const object = new AVLists();
					object._id = data._id;
					object.listName = data.listName;
					object.listFor = data.listFor;
					let newUser = new listUser();
					const found = users.some((el: listUser) => el._id === data.listFor);
					if (data.name && !found) {
						newUser._id = data.listFor;
						newUser.name = data.name;
						users.push(newUser);
					}
					object.uploadedCount = data.uploadedCount;
					object.approvedOn = data.approvedOn;
					object.status = data.approvedOn ? 'Y' : 'N';

					object.finalized_on = data.finalized_on;
					object.researcher = data.researcher;
					object.research_finalized_note = data.research_finalized_note;
					object.ext_rec_count = data.ext_rec_count;
					object.company = data.company;
					object.createdAt = data.createdAt;
					if (data.company && !companies.includes(data.company)) {
						companies.push(data.company);
					}
					object.name = data.name;
					object.listPrepComplete = !!data.listPrepComplete;
					object.research_finalized = !!data.research_finalized;
					object.emptyFields = data.emptyFields;
					dataArray.push(object);
				});

				return { avlists: dataArray, companies: companies, users: users };
			}));
	}

	getAVlists(_id: string, type: string): Observable<listsProps> {
		console.log('Getting user AVLists from database...');
		const url = this.getUrl('/avlists/documents/' + _id);

		return this.http.get(url, this.getOptions())
			.pipe(map((querySnapshot: any) => {
				const dataArray: AVLists[] = [];
				const users: listUser[] = [];
				const companies: string[] = [];

				if (type === 'unapproved') {
					querySnapshot = querySnapshot.filter((item) => item.approvedOn === 0);
				}

				querySnapshot.forEach((data: any) => {
					const object = new AVLists();
					object._id = data._id;
					object.listName = data.listName;
					object.listFor = data.listFor;
					let newUser = new listUser();
					const found = users.some((el: listUser) => el._id === data.listFor);
					if (data.name && !found) {
						newUser._id = data.listFor;
						newUser.name = data.name;
						users.push(newUser);
					}
					object.uploadedCount = data.uploadedCount;
					object.uploadedOn = data.uploadedOn;
					object.approvedOn = data.approvedOn;
					object.status = data.approvedOn ? 'Y' : 'N';
					object.approvedBy = data.approvedBy;
					object.approvedCount = data.approvedCount;
					object.connectedCount = data.connectedCount;
					object.notApproved = data.uploadedCount - data.approvedCount;
					object.approvedPercentage = Math.round((data.approvedCount / data.uploadedCount) * 100);
					object.connectedPercentage = data.approvedCount ? Math.round((data.connectedCount / data.approvedCount) * 100):0;
					object.positiveCount = data.positiveCount;
					object.invitedCount = data.invitedCount;
					object.respondedCount = data.respondedCount;
					object.listDescription = data.listDescription;
					object.company = data.company;
					object.createdAt = data.createdAt;
					object.research_finalized = data.research_finalized;
					if (data.company && !companies.includes(data.company)) {
						companies.push(data.company);
					}
					object.name = data.name;
					object.daysSinceUpload = Math.round((new Date().getTime() - new Date(data.uploadedOn).getTime()) / 3600000 / 24);
					object.listPrepComplete = !!data.listPrepComplete;
					dataArray.push(object);
				});

				return { avlists: dataArray, companies: companies, users: users };
			}));
	}

	getAVReviewedlists(_id: string): Observable<listsProps> {
		console.log('Getting user AVLists from database...');
		const url = this.getUrl('/avlists/documents/' + _id);

		return this.http.get(url, this.getOptions())
			.pipe(map((querySnapshot: any) => {
				const dataArray: AVLists[] = [];
				const users: listUser[] = [];
				const companies: string[] = [];
				querySnapshot.forEach((data: any) => {
					if (data.approvedOn > 0) {
						const object = new AVLists();
						object._id = data._id;
						object.listName = data.listName;
						object.listFor = data.listFor;
						let newUser = new listUser();
						const found = users.some((el: listUser) => el._id === data.listFor);
						if (!found) {
							newUser._id = data.listFor;
							newUser.name = data.name;
							users.push(newUser);
						}
						object.uploadedCount = data.uploadedCount;
						object.uploadedOn = data.uploadedOn;
						object.approvedOn = data.approvedOn;
						object.status = data.approvedOn ? 'Y' : 'N';
						object.approvedBy = data.approvedBy;
						object.approvedCount = data.approvedCount;
						object.notApproved = data.uploadedCount - data.approvedCount;
						object.approvedPercentage = Math.round((data.approvedCount / data.uploadedCount) * 100);
						object.connectedCount = data.connectedCount;
						object.positiveCount = data.positiveCount;
						object.invitedCount = data.invitedCount;
						object.respondedCount = data.respondedCount;
						object.company = data.company;
						object.createdAt = data.createdAt;
						if (!companies.includes(data.company)) {
							companies.push(data.company);
						}
						object.name = data.name;
						dataArray.push(object);
					}
				});

				return { avlists: dataArray, companies: companies, users: users };
			}));
	}

	getAVDataByListId(_id: string, user_id: string): Observable<PXData[]> {
		return new Observable((observer) => {
			this.shouldUseCachedListAVData(user_id).subscribe((shouldUseCachedListAV) => {
				if (shouldUseCachedListAV && this.myCache.get('LIST_AVDATA')) {
					console.log('Getting list AVData from cache...');
					observer.next(JSON.parse(this.myCache.get('LIST_AVDATA')));
				} else {
					console.log('Getting list AVData from database...');
					const url = this.getUrl('/avdata/documents/list-contacts/' + _id);
 
					this.http.get(url, this.getOptions())
						.subscribe((querySnapshot: any) => {
							const dataArray: PXData[] = [];
 
							querySnapshot.forEach((data: any) => {
								const object = new PXData();
								const year = data.yearsAtCompany ? data.yearsAtCompany.split('.')[0] : 0;
								object.id = data._id;
								object.user_id = data.user_id;
								object.member_id = data.member_id;
								object.companyName = data.companyName;
								object.firstName = data.firstName;
								object.lastName = data.lastName;
								object.profileURL = data.profileURL;
								object.title = data.title;
								object.email = data.email;
								object.phone = data.phone;
								object.location = data.location;
								object.industry = data.industry;
								object.organization_location_1 = data.organization_location_1;
								object.organization_headcount_1 = data.organization_headcount_1;
								object.organization_industry_1 = data.organization_industry_1;
								object.organization_website_1 = data.organization_website_1;
								object.approved = data.approved;
								object.followers = data.followers;
								object.yearsAtCompany = data.yearsAtCompany;
								object.since1 = year && year > 0 ? ((new Date()).getFullYear() - year) : 0;
								object.mutualConnections = data.mutualConnections;
								object.connectedOn = data.connectedOn;
								object.companyProfileURL = data.companyProfileURL;
								object.listId = data.listId;
								object.rejectionReason = data.rejectionReason;
								dataArray.push(object);
							});

							this.myCache.set("LIST_AVDATA", JSON.stringify(dataArray), 86400000);
							// localStorage.setItem('LIST_AVDATA', JSON.stringify(dataArray));
							localStorage.setItem('LIST_AVDATA_CACHED_AT', '' + new Date().getTime());

							observer.next(dataArray);
						});
				}
			});
		});
	}

	editAVlist(_id: string, body): Observable<boolean> {
		const url = this.getUrl('/avlists/documents/' + _id);

		return this.http.put(url, body, this.getOptions())
			.pipe(map((res: any) => {
				return res;
			}));
	}

	deleteAVlist(_id: string): Observable<boolean> {
		const url = this.getUrl('/avlists/documents/' + _id);

		return this.http.delete(url, this.getOptions())
			.pipe(map((res: any) => {
				return res;
			}));
	}

	getProfileURLs(_id: string): Observable<AVLists[]> {
		const url = this.getUrl('/avlists/documents/profile-urls/' + _id);

		return this.http.get(url, this.getOptions())
			.pipe(map((res: any) => {
				return res;
			}));
	}

	deleteRecord(_id: string): Observable<boolean> {
		const url = this.getUrl('/avdata/' + _id);

		return this.http.delete(url, this.getOptions())
			.pipe(map((res: any) => {
				return res;
			}));
	}

	getUserApprovedAVdata(_id: string): Observable<PXData[]> {
		return new Observable((observer) => {
			this.shouldUseCachedAVDataReviewed(_id).subscribe((shouldUseCachedAVReviewed) => {
				if (shouldUseCachedAVReviewed && this.myCache.get('USER_REVIEWED_AVDATA')) {
					console.log('Getting user AVData Reviewed from cache...');
					observer.next(JSON.parse(this.myCache.get('USER_REVIEWED_AVDATA')));
				} else {
					console.log('Getting user AVData Reviewed from database...');
					const url = this.getUrl('/avdata/documents/approved/' + _id);

					this.http.get(url, this.getOptions())
						.subscribe((querySnapshot: any) => {
							const dataArray: PXData[] = [];

							querySnapshot.forEach((data: any) => {
								const object = new PXData();
								const year = data.yearsAtCompany ? data.yearsAtCompany.split('.')[0] : '0';
								object.id = data._id;
								object.user_id = data.user_id;
								object.member_id = data.member_id;
								object.companyName = data.companyName;
								object.firstName = data.firstName;
								object.lastName = data.lastName;
								object.profileURL = data.profileURL;
								object.title = data.title;
								object.email = data.email;
								object.phone = data.phone;
								object.location = data.location;
								object.industry = data.industry;
								object.approved = data.approved;
								object.approvedOn = data.approvedOn;
								object.yearsAtCompany = data.yearsAtCompany;
								object.since1 = year && year > 0 ? ((new Date()).getFullYear() - year) : 0;
								object.mutualConnections = data.mutualConnections;
								object.connectedOn = data.connectedOn;
								object.companyProfileURL = data.companyProfileURL;
								dataArray.push(object);
							});

							this.myCache.set("USER_REVIEWED_AVDATA", JSON.stringify(dataArray), 86400000);
							// localStorage.setItem('USER_REVIEWED_AVDATA', JSON.stringify(dataArray));
							localStorage.setItem('USER_REVIEWED_AVDATA_CACHED_AT', '' + new Date().getTime());

							observer.next(dataArray);
						});
				}
			});
		});
	}

	formatDate(date): string {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear().toString();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [month, day, year].join('/');
	}
	formatDateWithShortYear(date): string {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear().toString().substr(-2);

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [month, day, year].join('/');
	}

	shouldUseCachedPXData(_id: string): Observable<boolean> {
		return new Observable((observer) => {
			if (!localStorage.getItem('USER_PXDATA_CACHED_AT')) {
				observer.next(false);
			}
			else {
				const url = this.getUrl('/pxdata-stats/' + _id);

				this.http.get(url, this.getOptions()).subscribe((querySnapshot: any) => {
					if (querySnapshot && parseInt(querySnapshot.pxUpdatedOn) < parseInt(localStorage.getItem('USER_PXDATA_CACHED_AT'))) {
						observer.next(true);
					} else {
						observer.next(false);
					}
				});
			}
		});
	}

	shouldUseCachedMessages(_id: string): Observable<boolean> {
		return new Observable((observer) => {
			if (!localStorage.getItem('USER_MESSAGES_CACHED_AT')) {
				observer.next(false);
			}
			else {
				const url = this.getUrl('/pxdata-stats/' + _id);

				this.http.get(url, this.getOptions()).subscribe((querySnapshot: any) => {
					if (querySnapshot && parseInt(querySnapshot.msgUpdateOn) < parseInt(localStorage.getItem('USER_MESSAGES_CACHED_AT'))) {
						observer.next(true);
					} else {
						observer.next(false);
					}
				});
			}
		});
	}

	shouldUseCachedMessagesBeta(_id: string): Observable<boolean> {
		return new Observable((observer) => {
			if (!localStorage.getItem('USER_MESSAGES_BETA_CACHED_AT')) {
				observer.next(false);
			}
			else {
				const url = this.getUrl('/pxdata-stats/' + _id);

				this.http.get(url, this.getOptions()).subscribe((querySnapshot: any) => {
					if (querySnapshot && parseInt(querySnapshot.msgUpdateOn) < parseInt(localStorage.getItem('USER_MESSAGES_BETA_CACHED_AT'))) {
						observer.next(true);
					} else {
						observer.next(false);
					}
				});
			}
		});
	}
	shouldUseCachedIncomingMessagesBeta(_id: string): Observable<boolean> {
		return new Observable((observer) => {
			if (!localStorage.getItem('USER_INCOMING_MESSAGES_BETA_CACHED_AT')) {
				observer.next(false);
			}
			else {
				const url = this.getUrl('/pxdata-stats/' + _id);

				this.http.get(url, this.getOptions()).subscribe((querySnapshot: any) => {
					if (querySnapshot && parseInt(querySnapshot.msgUpdateOn) < parseInt(localStorage.getItem('USER_INCOMING_MESSAGES_BETA_CACHED_AT'))) {
						observer.next(true);
					} else {
						observer.next(false);
					}
				});
			}
		});
	}
	shouldUseCachedAVData(_id: string): Observable<boolean> {
		return new Observable((observer) => {
			if (!localStorage.getItem('USER_AVDATA_CACHED_AT')) {
				observer.next(false);
			}
			else {
				const url = this.getUrl('/pxdata-stats/' + _id);

				this.http.get(url, this.getOptions()).subscribe((querySnapshot: any) => {
					if (querySnapshot && parseInt(querySnapshot.avUpdatedOn) < parseInt(localStorage.getItem('USER_AVDATA_CACHED_AT'))) {
						observer.next(true);
					} else {
						observer.next(false);
					}
				});
			}
		});
	}

	shouldUseCachedListAVData(_id: string): Observable<boolean> {
		return new Observable((observer) => {
			if (!localStorage.getItem('LIST_AVDATA_CACHED_AT')) {
				observer.next(false);
			}
			else {
				const url = this.getUrl('/pxdata-stats/' + _id);

				this.http.get(url, this.getOptions()).subscribe((querySnapshot: any) => {
					if (querySnapshot && parseInt(querySnapshot.avUpdatedOn) < parseInt(localStorage.getItem('LIST_AVDATA_CACHED_AT'))) {
						observer.next(true);
					} else {
						observer.next(false);
					}
				});
			}
		});
	}

	shouldUseCachedAVDataReviewed(_id: string): Observable<boolean> {
		return new Observable((observer) => {
			if (!localStorage.getItem('USER_REVIEWED_AVDATA_CACHED_AT')) {
				observer.next(false);
			}
			else {
				const url = this.getUrl('/pxdata-stats/' + _id);

				this.http.get(url, this.getOptions()).subscribe((querySnapshot: any) => {
					if (querySnapshot && parseInt(querySnapshot.avDataChangedOn) < parseInt(localStorage.getItem('USER_REVIEWED_AVDATA_CACHED_AT'))) {
						observer.next(true);
					} else {
						observer.next(false);
					}
				});
			}
		});
	}

	// secound dashboard
	secoundDashboard(): Observable<any> {
		return new Observable((observer) => {
			const url = this.getUrl('/users');

			this.http.get(url, this.getOptions()).subscribe((querySnapshot: any) => {
				// console.log('query',querySnapshot);
				querySnapshot.forEach((item) => {
					const usercompany = item.val();
					// console.log('usercompany',usercompany);
					let data: Object = {};
					// console.log('key',item.key);
					data['company_name_user'] = (querySnapshot.val().name) ? querySnapshot.val().name : '';
					var date7Days = new Date();
					var date30Days = new Date();
					date7Days.setDate(date7Days.getDate() - 7);
					date30Days.setDate(date30Days.getDate() - 30);
					const url = this.getUrl('/messages/' + item._id);
				});

				observer.next()
			})
		})
	}
	getRejectionReasons(): Observable<any> {
		return new Observable((observer) => {
			const url = this.getUrl('/rejection-reasons');

			this.http.get(url, this.getOptions()).subscribe((querySnapshot: any) => {
				observer.next(querySnapshot)
			})
		})
	}
	updateReason(id: string,rejectionReason:string): Observable<boolean> {
		const url = this.getUrl('/avdata/rejection-reason/'+id);

		return this.http.put(url, {rejectionReason}, this.getOptions())
			.pipe(map((res: any) => {
				return true;
			}));
	}
}