import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var MessageTemplateComponent = /** @class */ (function () {
    function MessageTemplateComponent(fb, sequenceService, router, location) {
        this.fb = fb;
        this.sequenceService = sequenceService;
        this.router = router;
        this.location = location;
        this.index = -1;
        this.isLoading = true;
        this.defaultDelay = 1;
        this.dropdownList = [];
        this.previewMessages = [];
        this.clickByDoneButton = false;
        this.editMode = localStorage.getItem("EDIT_SEQUENCE") ? true : false;
        this.stepTwoForm = this.fb.group({
            messageType: this.fb.control('', Validators.required),
            sequenceId: this.fb.control('', Validators.required),
            message: this.fb.control('', Validators.required),
            draft: this.fb.control(false),
            delay: this.fb.control(1)
        });
    }
    MessageTemplateComponent.prototype.ngOnInit = function () {
        var _this = this;
        var campaignInfo = localStorage.getItem('CAMPAIGN_INFO');
        if (!campaignInfo) {
            this.router.navigate(['campaigns']);
            return;
        }
        this.sequenceService.getMessageTemplates().subscribe(function (messageTemplates) {
            _this.dropdownList = messageTemplates;
        }, function (err) {
            alert('error');
            console.log(err);
        });
        var messageInfo = localStorage.getItem('TEMPLATE_MESSAGE_INFO');
        if (messageInfo) {
            this.stepTwoForm = this.fb.group({
                messageType: this.fb.control(JSON.parse(messageInfo).messageType, Validators.required),
                sequenceId: this.fb.control(JSON.parse(campaignInfo).sequenceId, Validators.required),
                message: this.fb.control(JSON.parse(messageInfo).message, Validators.required),
                draft: this.fb.control(JSON.parse(messageInfo).draft),
                delay: this.fb.control(JSON.parse(messageInfo).delay),
                _id: this.fb.control(JSON.parse(messageInfo)._id)
            });
        }
        else {
            this.stepTwoForm = this.fb.group({
                messageType: this.fb.control('', Validators.required),
                sequenceId: this.fb.control(JSON.parse(campaignInfo).sequenceId, Validators.required),
                message: this.fb.control('', Validators.required),
                draft: this.fb.control(false),
                delay: this.fb.control(1)
            });
        }
        this.sequenceService.getMessages(JSON.parse(campaignInfo).sequenceId).subscribe(function (res) {
            _this.previewMessages = res;
            if (_this.previewMessages.length === 0) {
                _this.defaultDelay = 0;
            }
            _this.isLoading = false;
        }, function (err) {
            alert('error');
            console.log(err);
        });
    };
    MessageTemplateComponent.prototype.stepTwoSubmit = function () {
        var _this = this;
        console.log(this.stepTwoForm);
        if (this.stepTwoForm.valid) {
            this.isLoading = true;
            this.sequenceService.submitSequence(this.stepTwoForm.value).subscribe(function (res) {
                console.log(res);
                if (_this.clickByDoneButton) {
                    _this.router.navigate(['campaigns']);
                }
                else {
                    localStorage.setItem("TEMPLATE_MESSAGE_INFO", JSON.stringify(res));
                    _this.isLoading = false;
                    _this.router.navigate(['message-preview']);
                }
            }, function (err) {
                alert('error');
                console.log(err);
            });
        }
    };
    MessageTemplateComponent.prototype.counter = function (i) {
        return new Array(i);
    };
    MessageTemplateComponent.prototype.changeMessageType = function () {
        var index = -1;
        var val = this.stepTwoForm.value.messageType;
        this.dropdownList.find(function (item, i) {
            if (item.messageType === val) {
                index = i;
                return true;
            }
            return false;
        });
        this.index = index;
        if (index !== -1) {
            this.defaultDelay = this.dropdownList[index].days;
        }
    };
    MessageTemplateComponent.prototype.cancelNewCampaign = function () {
        this.router.navigate(['campaigns']);
    };
    MessageTemplateComponent.prototype.back = function () {
        var campaignInfo = localStorage.getItem('CAMPAIGN_INFO');
        if (!campaignInfo) {
            this.router.navigate(['campaigns']);
        }
        else if (JSON.parse(campaignInfo).campaignType === "Message Only") {
            this.router.navigate(['message-send-who']);
        }
        else if (JSON.parse(campaignInfo).campaignType === "Invite, Connect and Message") {
            this.router.navigate(['connection-request']);
        }
    };
    MessageTemplateComponent.prototype.getCheckboxesValue = function (event) {
        this.stepTwoForm.value.draft = event.checked;
        if (!event.checked) {
            this.stepTwoForm.get('message').setValidators([Validators.required]);
        }
        else {
            this.stepTwoForm.get('message').clearValidators();
        }
        this.stepTwoForm.controls["message"].updateValueAndValidity();
    };
    MessageTemplateComponent.prototype.clickDoneButton = function () {
        this.clickByDoneButton = true;
    };
    return MessageTemplateComponent;
}());
export { MessageTemplateComponent };
