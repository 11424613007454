import { Component, OnInit } from '@angular/core';

import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-company-pxdata',
  templateUrl: './company-pxdata.component.html',
  styleUrls: ['./company-pxdata.component.scss']
})
export class CompanyPxdataComponent implements OnInit {

  select = '';
  users: User[];
  connectedAfter;
  startDate;
  endDate;
  isLoading = true;
  user = new User();
  uniqueData: any[];

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers(): void {
    this.userService.getAllUsers().subscribe(res => {
      this.users = res.users;
      this.uniqueData = Object.values(this.users.reduce((r, o) => (r[o.company.trim()] = o, r), {}));

      this.uniqueData = this.uniqueData.sort((a, b) => {
        if (a.company.toLowerCase() < b.company.toLowerCase()) {
          return -1;
        }
        if (a.company.toLowerCase() > b.company.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      this.isLoading = false;

    }, (err) => {
      alert('error');
      console.log('error to get users: ', err);
    });
  }

  getUserLinkedinProfileID(_id: string): string {
    let linkedinProfileID = '';

    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i]._id === _id) {
        linkedinProfileID = this.users[i].linkedInProfileId;
      }
    }

    return linkedinProfileID;
  }

  download(): void {
    if (!this.select) {
      alert('Please select a company');
      return;
    }

    const selectedusers = [];
    this.isLoading = true;

    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].company === this.select) {
        if (this.users[i]._id) {
          selectedusers.push(this.users[i]._id);
        }
      }
    }

    this.userService.getCompanyPXdata(selectedusers, this.connectedAfter).subscribe((companies) => {
      const con = [];
      let csvContent = '';

      Object.keys(companies).forEach((data) => {
        const val = companies[data];
        con.push(val);
      });

      const title = `Rep LI Profile ID, LI Profile ID, Company Name, First Name, Last Name, Title, Profile Url, Email, Phone, Location, Company Start Date, Connected On, Mutual Connections`;
      csvContent += title + '\r\n';
      const that = this;

      con.forEach(function (rowArray) {
        const row = '"' + that.getUserLinkedinProfileID(rowArray.user_id) + '","' + rowArray.id + '","' + rowArray.companyName + '","' + rowArray.firstName + '","'
          + rowArray.lastName + '","' + rowArray.title + '","'
          + rowArray.profileURL + '","' + rowArray.email + '","'
          + rowArray.phone + '","' + rowArray.location + '","' +
          rowArray.yearsAtCompany + '","' + rowArray.connectedOn
          + '","' + rowArray.mutualConnections + '"';
        csvContent += row + '\r\n';
      });

      csvContent = csvContent.replace(/#/g, '');
      const blob = new Blob([csvContent], { type: "octet/stream" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', this.select + ' - PX Data.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get company px data: ', err);
    });
  }

  exportCorporateEmails(): void {
    console.log(this.startDate, this.endDate);
    if (!this.startDate) {
      alert("Select start date!");
      return;
    }
    if (!this.endDate) this.endDate = "2099-01-01";
    const body = {
      startDate: this.startDate,
      endDate: this.endDate
    };
    this.isLoading = true;
    this.userService.getCorporateData(body).subscribe((response) => {
      this.isLoading = false;
      if (response.code === 200) {
        let csvContent = '';
        const title = `Member ID, Full Name, Company Name, First Name, Last Name, Title, Public Id, Profile URL, Company Id, Organization Domain`;
        csvContent += title + '\r\n';
        response.data.forEach(function (rowArray) {
          const row = '"' + rowArray.member_id + '","'
            + rowArray.fullName + '","'
            + rowArray.companyName + '","'
            + rowArray.firstName + '","'
            + rowArray.lastName + '","'
            + rowArray.title + '","'
            + rowArray.publicId + '","'
            + rowArray.profileURL + '","'
            + rowArray.companyId + '","'
            + rowArray.organization_domain_1.toLowerCase().replace("www.", "") + '"';
          csvContent += row + '\r\n';
        });
        csvContent = csvContent.replace(/#/g, '');
        const blob = new Blob([csvContent], { type: "octet/stream" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', this.select + `corporate-emails-needed-(${new Date().toJSON().split("T")[0]}).csv`);
        document.body.appendChild(link); // Required for FF
        link.click();
        this.isLoading = false;
      }
      console.log(response);
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get corporate emails data: ', err);
    });
  }

  downloadApprovedContacts(): void {
    if (!this.select) {
      alert('Please select a company');
      return;
    }

    const selectedusers = [];
    this.isLoading = true;

    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].company === this.select) {
        if (this.users[i]._id) {
          selectedusers.push(this.users[i]._id);
        }
      }
    }

    this.userService.getCompanyApprovedContacts(selectedusers, this.connectedAfter).subscribe((companies) => {
      const con = [];
      let csvContent = '';

      Object.keys(companies).forEach((data) => {
        const val = companies[data];
        con.push(val);
      });

      const title = `Rep LI Profile ID, LI Profile ID, Company Name, First Name, Last Name, Title, Profile Url, Email, Phone, Location, Company Start Date, Connected On, Mutual Connections`;
      csvContent += title + '\r\n';
      const that = this;

      con.forEach(function (rowArray) {
        const row = '"' + that.getUserLinkedinProfileID(rowArray.user_id) + '","' + rowArray.id + '","' + rowArray.companyName + '","' + rowArray.firstName + '","'
          + rowArray.lastName + '","' + rowArray.title + '","'
          + rowArray.profileURL + '","' + rowArray.email + '","'
          + rowArray.phone + '","' + rowArray.location + '","' +
          rowArray.yearsAtCompany + '","' + rowArray.connectedOn
          + '","' + rowArray.since + '","' + rowArray.mutualConnections + '"';
        csvContent += row + '\r\n';
      });

      csvContent = csvContent.replace(/#/g, '');
      const blob = new Blob([csvContent], { type: "octet/stream" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', this.select + ' - Approved Contacts.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get company approved contacts: ', err);
    });
  }
}