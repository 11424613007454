<div class="loading" *ngIf="isLoading">Loading&#8230;</div>
<ag-grid-angular id="scrolling-table"  
    (paginationChanged)="onPaginationChanged($event)" 
    (gridReady)="onGridReady($event)" 
    style="width: 100%; height: calc(100% - 17px)" 
    [enableColResize]="true" 
    [paginationAutoPageSize]="true"
    [enableSorting]="true" 
    [pagination]="true" class="ag-theme-alpine" 
    [rowData]="rows" 
    [columnDefs]="columnDefs"
    [enableFilter]="true" 
    [overlayNoRowsTemplate]="noDataText"
    (firstDataRendered)="onFirstDataRendered($event)"
    (rowEditingStarted)="onRowEditingStarted($event)"
    (rowEditingStopped)="onRowEditingStopped($event)"
    (cellEditingStarted)="onCellEditingStarted($event)"
    (cellEditingStopped)="onCellEditingStopped($event)"
    
    >
</ag-grid-angular>

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="+1" role="dialog" aria-labelledby="myBasicModalLabel"
    aria-hidden="true" style="z-index: 10000;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" *ngIf="selectedRow">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{selectedRow.firstName}} {{selectedRow.lastName}}</h5>
                <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <img [src]="selectedRow.avatar" style="width: 100%;" />
            </div>
        </div>
    </div>
</div>
<div mdbModal #graphModal="mdbModal" class="modal fade" tabindex="+1" role="dialog" aria-labelledby="myBasicModalLabel"
    aria-hidden="true" style="z-index: 10000;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" *ngIf="graphData?.discType">
            <div class="modal-header" [ngStyle]="{'background-color':modalBorder}">
                <h2 class="modal-title" style="color:white;font-weight: 500;" id="exampleModalLabel">Type
                    {{graphData.discType}}</h2>
                <button type="button" class="close pull-right" aria-label="Close" (click)="graphModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="display: flex;">
                    <div style="width: 45%;">
                        <h3>{{graphData?.archetype}}</h3>
                        <h5 *ngIf="graphData?.qualities?.length" [innerHTML]="tendsTitle"></h5>
                    </div>
                    <div>
                        <div style="display: flex;    align-items: baseline;">
                            <div class="graph-item" style="display: flex;flex-direction: column;">
                                <div [ngStyle]="{height:(graphData.p4d * 3)+'px'}" style="background-color: #db3c48;">
                                </div>
                                <span class="label-item" style="color:#db3c48">D</span>
                                <span class="item-value">{{graphData.p4d}}%</span>
                            </div>
                            <div class="graph-item" style="display: flex;flex-direction: column;">
                                <div [ngStyle]="{height:(graphData.p4i * 3)+'px'}" style="background-color: #f7cf0d">
                                </div>
                                <span class="label-item" style="color:#f7cf0d">I</span>
                                <span class="item-value">{{graphData.p4i}}%</span>
                            </div>
                            <div class="graph-item" style="display: flex;flex-direction: column;">
                                <div [ngStyle]="{height:(graphData.p4s * 3)+'px'}" style="background-color: #11b21b;">
                                </div>
                                <span class="label-item" style="color:#11b21b">S</span>
                                <span class="item-value">{{graphData.p4s}}%</span>
                            </div>
                            <div class="graph-item" style="display: flex;flex-direction: column;">
                                <div [ngStyle]="{height:(graphData.p4c * 3)+'px'}" style="background-color: #2f5fa5;">
                                </div>
                                <span class="label-item" style="color:#2f5fa5">C</span>
                                <span class="item-value">{{graphData.p4c}}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mdbModal #lilaModal="mdbModal" class="modal fade" tabindex="+1" role="dialog" aria-labelledby="myBasicModalLabel"
    aria-hidden="true" style="z-index: 10000;">
    <div class="modal-dialog" role="document" style="max-width:725px">
        <div class="modal-content" *ngIf="personalityModaData?.pxData?.discType">
            <div class="modal-header" [ngStyle]="{'background-color':modalBorder}">
                <h2 class="modal-title" style="color:white;font-weight: 500;" id="exampleModalLabel">
                    {{personalityModaData.pxData.firstName}} {{personalityModaData.pxData.lastName}} - {{personalityModaData.pxData.discType}} ({{personalityModaData.pxData.archetype}})</h2>
                <button type="button" class="close pull-right" aria-label="Close" (click)="lilaModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="">
                    <div style="float: left;width: 45%;margin-right:40px" *ngIf="personalityModaData.contentArr.behaviorArr">
                        <h5 class="p-heading" style="font-weight: 400;color:#62b329">{{personalityModaData.pxData.discType}} types are natural at</h5>
                        <div class="p-item lbg" *ngFor="let item of personalityModaData.contentArr.behaviorArr">
                            {{item.text}}</div>
                        <div>
                            <div class="content-section">
                                <p>{{paragraph}}</p>
                            </div>
                            <div class="crm-paging">
                                <i [ngClass]="[pagingIndex ? '' : 'disabled disable-item']" class="fa fa-angle-left  " (click)="changeParagraph('P')"></i>
                                <i [ngClass]="[pagingIndex < personalityModaData.queryTwoData.length-1 ? '' : 'disabled disable-item']" class="fa fa-angle-right" style="margin-left:35px" (click)="changeParagraph('N')"></i>
                            </div>
                        </div>    
                    </div>
                    <div style="float: left;width: 45%;">
                        <div *ngIf="personalityModaData.contentArr.doArr">
                            <h5 class="p-heading" style="font-weight: 400;color:#62b329">For a {{personalityModaData.pxData.discType}} ({{personalityModaData.pxData.archetype}}), it's best to</h5>
                            <div class="p-item lbg" *ngFor="let item of personalityModaData.contentArr.doArr">
                                {{item.text}}</div>
                        </div>
                        <div *ngIf="personalityModaData.contentArr.dontArr" style="margin-top:20px!important;">
                            <h5 class="p-heading" style="font-weight: 400;color:#db3b48">For a {{personalityModaData.pxData.discType}} ({{personalityModaData.pxData.archetype}}), don't</h5>
                            <div class="p-item lbr" *ngFor="let item of personalityModaData.contentArr.dontArr">
                                {{item.text}}</div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>