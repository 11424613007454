import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as moment from "moment";
import { UserService } from '../../../services/user.service';
import { validateImage } from 'src/app/helpers/common';

@Component({
  selector: 'app-incomingrequests',
  templateUrl: './incomingrequests.component.html',
  styleUrls: ['./incomingrequests.component.scss']
})

export class IncomingConnectionsComponent implements OnInit {


  rows: any = [];
  token: any;
  isLoading = true;
 

  constructor(@Inject(DOCUMENT) document: Document, private userService: UserService, private toastrService: ToastService) {
    
  }

  ngOnInit() {
    
    this.userService.getUserIncomingRequests(localStorage.getItem('USER_MEMBER_ID')).subscribe((connection) => {
      console.log('connections', connection)
      if (connection.data) {
        this.rows = connection.data;
        
        validateImage();
      }
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get user pxdata: ', err);
    });
  }
  doAction(actionTaken,record) { 
    this.userService.actionOnRequest(localStorage.getItem('USER_MEMBER_ID'), { actionTaken, connectionId: record._id }).subscribe((connection) => { 
      console.log(connection);
      if (connection.code == 200) {
        for (let i in this.rows) {
          if (this.rows[i]._id == connection.data._id) {
            this.rows[i] = connection.data;
          }
        }
        this.toastrService.success(connection.message)
      } else {
        this.toastrService.error(connection.message)
      }
    })
  }
  

}