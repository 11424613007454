import { PXData } from '../../../models/PXData';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';

import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { AVListService } from 'src/app/services/avlists.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})

export class ReviewComponent implements OnInit {

  @ViewChild('approveModal', { static: true }) approveModal: ModalDirective;

  rejectionReasonList=[];
  rows = [];
  private gridApi;
  isLoading = true;
  selectedRows = [];
  selectedCount = 0;
  note: string = '';
  allChecked = false;
  private gridColumnApi;
  rowSelection = 'multiple';
  defaultColDef = { resizable: true };
  noDataText = 'Nothing to approve right now';
  showGrid = false;
  columnDefs = [
    {
      field: 'companyName',
      width: 100,
      sortable: true,
      checkboxSelection: true,
      headerName: 'Company Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      comparator: function (valueA, valueB,) {
        if (!valueA) return -1;
        if (!valueB) return 1;
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
        if (valueA == valueB) return 0;
        if (valueA > valueB) return 1;
        return -1;
      },
      cellRenderer: function (params) {
        if (params.data.tempSelected) {
          params.node.setSelected(true);
        }
        return (params.data.companyProfileURL) ? 
        `<a href="${params.data.companyProfileURL}" target="_blank">${params.data.companyName}</a>` 
        : (params.data.companyId) 
            ? `<a href="https://www.linkedin.com/company/${params.data.companyId}/" target="_blank">${params.data.companyName}</a>` 
        : `<a href="https://www.linkedin.com/search/results/companies/?keywords=${params.data.companyName}" target="_blank">${params.data.companyName}</a>`;
      },
    },
    {
      field: 'organization_website_1',
      width: 20,
      // sortable: true,
      headerName: 'Web',
      headerTooltip: 'Company Website',
      // filter: "agTextColumnFilter",

      cellRenderer: function (params) {
        const iconStyle = `
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        `;
        if (params.value) {
          return `<a href="${params.value}" target="_blank" style="color: #007bff; ${iconStyle}">
                    <i class="fas fa-globe"></i>
                  </a>`;
        } else {
          return `<div style="${iconStyle}">
                    <i class="fas fa-globe" style="color: #ccc;"></i>
                  </div>`;
        }
      }
    },
    {
      field: 'fullName',
      width: 70,
      sortable: true,
      headerName: 'Name',
      headerTooltip: 'Full Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      cellRenderer: function (params) {
        const fullName = `${params.data.firstName} ${params.data.lastName}`;
        if (params.data.profileURL) {
          return `<a href="${params.data.profileURL}" target="_blank">${fullName}</a>`;
        } else {
          return fullName;
        }
      },
      comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
        const fullNameA = `${nodeA.data.firstName} ${nodeA.data.lastName}`.toLowerCase();
        const fullNameB = `${nodeB.data.firstName} ${nodeB.data.lastName}`.toLowerCase();
        if (fullNameA === fullNameB) return 0;
        return (fullNameA > fullNameB) ? 1 : -1;
      }
    },
    {
      field: 'title',
      width: 100,
      sortable: true,
      headerName: 'Title',
      headerTooltip: 'Prospect Title',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },


    {
      field: 'location',
      width: 80,
      sortable: true,
      headerName: 'Prospect Location',
      headerTooltip: 'Prospect Location',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
      field: 'followers',
      width: 60,
      maxWidth: 80,
      sortable: true,
      headerTooltip: 'Connections',
      headerName: 'Conn.',
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      valueFormatter: (params) => {
        if (params.value === null || params.value === undefined) return '';
        return Number(params.value).toLocaleString('en-US');
      },
      cellStyle: { 
        textAlign: 'right', 
        paddingRight: '30px'
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        return Number(valueA) - Number(valueB);
      }
    },
    {
      field: 'organization_industry_1',
      width: 60,
      sortable: true,
      headerName: 'Industry',
      headerTooltip: 'Organization Industry',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },

    {
      field: 'organization_location_1',
      width: 100,
      sortable: true,
      headerName: 'Company HQ',
      headerTooltip: 'Organization Headquarters',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },

    {
      field: 'organization_headcount_1',
      width: 70,
      maxWidth: 80,
      sortable: true,
      headerName: 'HC',
      headerTooltip: 'Organization Headcount',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      cellRenderer: function(params) {
        console.log('Headcount value:', params.value, 'Type:', typeof params.value);
        
        if (params.value === null || params.value === undefined || params.value === '' || params.value === 'NaN') {
          return 'N/A';
        } else if (isNaN(Number(params.value))) {
          console.log('Invalid number:', params.value);
          return 'N/A';
        } else {
          const numValue = Number(params.value);
          return numValue.toLocaleString();
        }
      },
      cellStyle: { 
        textAlign: 'right', 
        paddingRight: '20px'  // Add padding to the right
      }
    },

    // {
    //   field: 'organization_headcount_1',
    //   width: 70,
    //   maxWidth: 80,
    //   sortable: true,
    //   headerName: 'HC',
    //   headerTooltip: 'Organization Headcount',
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ['reset', 'apply'],
    //     closeOnApply: true
    //   },
    //   cellRenderer: function(params) {
    //     if (params.value || params.value === 0) {
    //       return Number(params.value).toLocaleString();
    //     } else {
    //       return '';
    //     }
    //   },
    //   cellStyle: { 
    //     textAlign: 'right', 
    //     paddingRight: '20px'  // Add padding to the right
    //   }
    // },
    
    {
      field: 'rejectionReason',
      width: 60,
      sortable: false,
      headerName: 'Rejection Reason',
      cellRenderer: this.createDropdown.bind(this)
    },
 

  ];
  totalListIds = [];
  gridActualData=[];
  totalList:any=[];
  selectedListName="";
  constructor(private route:ActivatedRoute,private avListService: AVListService, private userService: UserService, private toastrService: ToastService, private router: Router) {
  }

  ngOnInit() {
    this.loadRejectionReasons();
    this.loadData();
  }
  updateReason(id,reason){
    this.userService.updateReason(id,reason).subscribe((res)=>{
      let index = this.rows.findIndex(x=>x.id===id);
      if(index>=0)this.rows[index].rejectionReason=reason
    })
  }
  createDropdown(params){
    const selectInp = document.createElement('select');
    selectInp.id = "select-"+params.data.id;
    selectInp.classList.add('custom-select')
    selectInp.classList.add('small-select');
    // console.log(this.selectedRows);
    let options = `<option value=""></option>`;
      for(let reason of this.rejectionReasonList){
        options+=`<option ${params.data.rejectionReason === reason ? 'selected':''} value="${reason}">${reason}</option>`;
      }
      selectInp.innerHTML = options;
      if(this.selectedRows.find(x=>x.id === params.data.id)){
        selectInp.classList.add("disabled")
      }
      selectInp.addEventListener('change', (e:Event) => {
        let target = e.target as HTMLInputElement;
        this.updateReason(params.data.id,target.value);
      });
      return selectInp;
  }
  loadRejectionReasons(){
    this.userService.getRejectionReasons().subscribe((res) => {
      this.rejectionReasonList = res||[];
    })
  }
  getAllList(listIds=[]){
    return new Promise((resolve, reject) => this.avListService.getAll(localStorage.getItem('USER_KEY'), listIds).subscribe(resolve))

  }

  loadData() {
    this.selectedCount = 0;
    this.showGrid = false;
    this.selectedRows = [];
    const listKey = localStorage.getItem('USER_REVIEW_LISTKEY') ? localStorage.getItem('USER_REVIEW_LISTKEY') : 'all';
    this.userService.getUserAVdata(localStorage.getItem('USER_KEY'), listKey).subscribe(async (res) => {
      this.totalListIds = Object.keys(res.reduce((r, { listId }) => (r[listId] = '', r), {}));
      if (this.totalListIds.length === 0 || this.totalListIds.length>1){
        this.router.navigate(['review-list']);
        return;
      }
      this.totalList = await this.getAllList(this.totalListIds);
      this.showGrid = true;
      this.selectedListName = this.totalList[0].listName;
      this.rows = res.map(row => ({
        ...row,
        organization_headcount_1: row.organization_headcount_1 !== null && row.organization_headcount_1 !== undefined && !isNaN(Number(row.organization_headcount_1))
          ? Number(row.organization_headcount_1)
          : null
      })).sort((a, b) => {
        if (a.companyName.toLowerCase() < b.companyName.toLowerCase()) {
          return -1;
        }
        if (a.companyName.toLowerCase() > b.companyName.toLowerCase()) {
          return 1;
        }

        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }

        return 0;
      });
      this.gridActualData = this.rows;
      this.selectedCount = this.rows.filter((row) => row.tempSelected).length;

      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.error('Error getting AV data:', err);
    });
  }
  
  // loadData() {
  //   this.selectedCount = 0;
  //   this.showGrid = false;
  //   this.selectedRows = [];
  //   const listKey = localStorage.getItem('USER_REVIEW_LISTKEY') ? localStorage.getItem('USER_REVIEW_LISTKEY') : 'all';
  //   this.userService.getUserAVdata(localStorage.getItem('USER_KEY'), listKey).subscribe(async (res) => {
  //     this.totalListIds = Object.keys(res.reduce((r, { listId }) => (r[listId] = '', r), {}));
  //     if (this.totalListIds.length === 0 || this.totalListIds.length>1){
  //       this.router.navigate(['review-list']);
  //       return;
  //     }
  //     this.totalList = await this.getAllList(this.totalListIds);
  //     this.showGrid = true;
  //     this.selectedListName = this.totalList[0].listName;
  //     this.rows = res.sort((a, b) => {
  //       if (a.companyName.toLowerCase() < b.companyName.toLowerCase()) {
  //         return -1;
  //       }
  //       if (a.companyName.toLowerCase() > b.companyName.toLowerCase()) {
  //         return 1;
  //       }

  //       if (a.title.toLowerCase() < b.title.toLowerCase()) {
  //         return -1;
  //       }
  //       if (a.title.toLowerCase() > b.title.toLowerCase()) {
  //         return 1;
  //       }

  //       return 0;
  //     });
  //     this.gridActualData = this.rows;
  //     this.selectedCount = this.rows.filter((row) => row.tempSelected).length;

  //     this.isLoading = false;

  //   }, (err) => {
  //     this.isLoading = false;
  //     alert('error');
  //     // console.log('error to get av data: ', err);
  //   });
  // }

  autoSizeAll() {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });

    this.gridColumnApi.autoSizeColumns(allColumnIds);
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged(event: any) {
    if (event.type === "rowSelected"){
      for (let i in this.rows){
        if (this.rows[i].id === event.data.id){
          this.rows[i].tempSelected = event.node.selected;
          break;
        }
      } 
    }
    this.selectedCount = this.rows.filter((row) => row.tempSelected).length;
    //this.selectedCount = event.api.getSelectedNodes().length;
    for(let item of this.selectedRows){
      let selectInp: HTMLInputElement = document.querySelector('#select-'+item.id);
      if(selectInp){
        selectInp.classList.remove("disabled");
        selectInp.value = item.rejectionReason;
      }
    }
    
    document.querySelectorAll('select.disabled').forEach((element:HTMLInputElement) => {
      element.classList.remove("disabled")
    });
    this.selectedRows = [];
    event.api.getSelectedNodes().forEach(element => {
      let selectInp: HTMLInputElement = document.querySelector('#select-'+element.data.id);
      if(selectInp){
        selectInp.classList.add("disabled")
        selectInp.value = null;
      }
      this.selectedRows.push(element.data);
    });
  }

  saveTempAvdata() {
    this.isLoading = true;
    const selected_ids = this.selectedRows.map((item: PXData) => { return item.id });
    const all_ids = this.rows.map((item: PXData) => { return item.id });

    this.userService.saveTempAvdata(selected_ids, all_ids).subscribe((res) => {
      this.isLoading = false;
      const options = { positionClass: 'toast-bottom-right', closeButton: true, timeOut: 20000 };
      this.toastrService.success(`Your selections have been saved. They have NOT been submitted for approval. When you are ready to approve the selections, please click on the green Approve Button.`, '', options);
      for(let id of selected_ids){
        let index = this.rows.findIndex(x=>x.id===id)
        if(index>=0){
          this.rows[index].tempSelected = true;
        }
      }
      
    }, (err) => {
      this.isLoading = false;
      alert('error');
      // console.log('error to save temporary avdata: ', err);
    })
  }

  openApproveModal() {
    this.selectedCount = this.rows.filter((row) => row.tempSelected || this.selectedRows.some(selectedRow => selectedRow.id === row.id)).length;
    this.approveModal.show();
  }

  // openApproveModal() {
  //   this.selectedCount = this.rows.filter((row) => row.tempSelected).length;
  //   this.approveModal.show();
  // }

  approve() {
    this.approveModal.hide();
    const user = new User();
    user.note = this.note ? this.note : '';

    // Update this part to include both selected and previously saved records
    this.rows.forEach((row) => {
      if (row.tempSelected || this.selectedRows.some(selectedRow => selectedRow.id === row.id)) {
        row.checked = true;
      }
    });

    let summary = {}
    for(let row of this.rows){
      if(row.checked) continue;
      if(!row.rejectionReason){
        if(!summary["No input provided"]) summary["No input provided"] = 0;
        summary["No input provided"]++;
      } else {
        if(!summary[row.rejectionReason]) summary[row.rejectionReason] = 0;
        summary[row.rejectionReason]++; 
      }
    }
    delete summary[""]
    delete summary["undefined"]

    // Update the selectedCount to include both selected and previously saved records
    this.selectedCount = this.rows.filter(row => row.checked).length;

    this.userService.approveAVData(this.rows, localStorage.getItem('USER_KEY'), localStorage.getItem('USER_KEY'), this.note).subscribe((res) => {
      user._id = localStorage.getItem('USER_KEY');
      user.lastApprovedCount = this.selectedCount;
      user.lastApprovedOn = new Date().getTime();
      user.lastApprovedPercentage = Math.round((this.selectedCount / this.rows.length) * 100);

      this.userService.setLastApproved(user, summary).subscribe((result) => {
        localStorage.removeItem('USER_AVDATA_CACHED_AT');
        this.router.navigate(['review-list']);
      }, (err) => {
        this.isLoading = false;
        alert('Error setting last approved');
        console.error('Error setting last approved:', err);
      });
    }, (err) => {
      this.isLoading = false;
      alert('Error approving AV data');
      console.error('Error approving AV data:', err);
    });
  }

  // approve() {
  //   // hide approve modal
  //   this.approveModal.hide();
  //   const user = new User();
  //   //this.isLoading = true;
  //   user.note = this.note ? this.note : '';
  //   // console.log(this.rows.length);
  //   this.rows.forEach((row) => {
  //     this.selectedRows.forEach(selectedRow => {
  //       if (row.id === selectedRow.id) {
  //         row.checked = true;
  //       }
  //     });
  //   });
  //   let summary = {}
  //   for(let row of this.rows){
  //     if(row.tempSelected|| row.checked)continue;
  //     if(!row.rejectionReason){
  //       if(!summary["No input provided"])summary["No input provided"] = 0;
  //       summary["No input provided"]++;
  //     }
  //     if(!summary[row.rejectionReason])summary[row.rejectionReason]=0;
  //     summary[row.rejectionReason]++; 
  //   }
  //   delete summary[""]
  //   delete summary["undefined"]
  //   this.userService.approveAVData(this.rows, localStorage.getItem('USER_KEY'), localStorage.getItem('USER_KEY'), this.note).subscribe((res) => {
  //     user._id = localStorage.getItem('USER_KEY');
  //     user.lastApprovedCount = this.selectedCount;
  //     user.lastApprovedOn = new Date().getTime();
  //     user.lastApprovedPercentage = Math.round((this.selectedCount / this.rows.length) * 100);

  //     this.userService.setLastApproved(user,summary).subscribe((result) => {
  //       localStorage.removeItem('USER_AVDATA_CACHED_AT');
  //       this.router.navigate(['review-list']);

  //     }, (err) => {
  //       this.isLoading = false;
  //       alert('error');
  //       // console.log('error to set last approved: ', err);
  //     });

  //   }, (err) => {
  //     this.isLoading = false;
  //     alert('error');
  //     // console.log('error to approve av data: ', err);
  //   });
  // }

}
