import { OnInit } from '@angular/core';
var CampaignsSummaryComponent = /** @class */ (function () {
    function CampaignsSummaryComponent(router, strategyService, sequenceService, _location, campaignService) {
        this.router = router;
        this.strategyService = strategyService;
        this.sequenceService = sequenceService;
        this._location = _location;
        this.campaignService = campaignService;
        this.isLoading = true;
        this.messageToWhoCampaignName = "";
    }
    CampaignsSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.campaignInfo = localStorage.getItem('CAMPAIGN_INFO');
        if (!this.campaignInfo) {
            this.router.navigate(['campaigns']);
            return; // Add return statement
        }
        this.campaignInfo = JSON.parse(this.campaignInfo);
        this.strategyService.getStrategy(this.campaignInfo.strategyId).subscribe(function (res) {
            console.log('existing strategy: ', res);
            _this.strategy = res;
            if (_this.strategy.msgTargetType === 'msgTargetCampaign') {
                _this.campaignService.getCampaign(_this.strategy.msgTargetCampaign).subscribe(function (cam) {
                    _this.messageToWhoCampaignName = cam.campaignName;
                    _this.isLoading = false;
                });
            }
            else {
                _this.isLoading = false;
            }
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user lastest messages: ', err);
            return; // Add return statement to handle error case
        });
        this.sequenceService.getMessages(this.campaignInfo.sequenceId).subscribe(function (res) {
            console.log("previewMessages: ", res);
            _this.previewMessages = res;
        }, function (err) {
            alert('error');
            console.log(err);
        });
    };
    CampaignsSummaryComponent.prototype.backClicked = function () {
        this._location.back();
    };
    return CampaignsSummaryComponent;
}());
export { CampaignsSummaryComponent };
