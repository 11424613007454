import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(authenticationService, router, fb) {
        this.authenticationService = authenticationService;
        this.router = router;
        this.fb = fb;
        this.isLoading = true;
        this.validationForm = fb.group({
            emailFormEx: [null, [Validators.required, Validators.email]],
            passwordFormEx: [null, Validators.required],
        });
    }
    Object.defineProperty(LoginComponent.prototype, "emailFormEx", {
        get: function () {
            return this.validationForm.get('emailFormEx');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "passwordFormEx", {
        get: function () {
            return this.validationForm.get('passwordFormEx');
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authenticationService.sharedLoginStatus.subscribe(function (status) {
            _this.isLoggedIn = status;
            _this.isLoading = status;
        });
        this.authenticationService.check().subscribe(function (user) {
            if (user) {
                if (user.role === '1' || user.role === '2') {
                    _this.router.navigate(['/dashboard']);
                }
                else if (user.role === '3') {
                    _this.router.navigate(['/caDashboard']);
                }
                else {
                    // this.router.navigate(['/euDashboard']);
                    _this.router.navigate(['/messages']);
                }
            }
        });
    };
    LoginComponent.prototype.sendToReset = function () {
        if (!this.email2) {
            alert('Please double check your email address.');
            return;
        }
        else if (!this.validateEmail(this.email2)) {
            alert('Please double check your email address');
            return;
        }
        else {
            this.showModalOnClick.hide();
            this.authenticationService.sendResetPasswordEmail(this.email2).subscribe(function (result) {
                if (result) {
                    alert('A password reset link has been sent to your email address.');
                }
                else {
                    alert('Something went wrong.');
                }
            }, function (err) {
                alert('error');
                console.log('error to reset password: ', err);
            });
        }
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        if (!this.email) {
            alert('Please enter your login email.');
            return;
        }
        else if (!this.validateEmail(this.email)) {
            alert('Please enter valid email');
            return;
        }
        else if (!this.password) {
            alert('Please enter your password.');
            return;
        }
        this.isLoading = true;
        this.authenticationService.login(this.email, this.password).subscribe(function (user) {
            _this.authenticationService.toggleLoginStatus(true);
            _this.isLoggedIn = true;
            localStorage.setItem('USER_KEY', user._id);
            localStorage.setItem('USER_NAME', user.name);
            localStorage.setItem('USER_EMAIL', user.email);
            localStorage.setItem('USER_COMPANY', user.company);
            localStorage.setItem('USER_LI_PID', user.linkedInProfileId);
            localStorage.setItem('USER_MEMBER_ID', user.memberId);
            localStorage.setItem('USER_GOOGLE_DOC_URL', user.googleDocURL);
            if (user.role === '1' || user.role === '2') {
                _this.router.navigate(['/dashboard']);
            }
            else if (user.role === '3') {
                _this.router.navigate(['/caDashboard']);
            }
            else {
                localStorage.setItem('USER_LISTS_KEY', user._id);
                // this.router.navigate(['/euDashboard']);
                _this.router.navigate(['/messages']);
            }
        }, function (err) {
            _this.isLoggedIn = false;
            _this.isLoading = false;
            alert('error');
            console.log('error to login: ', err);
        });
    };
    LoginComponent.prototype.resetPassword = function () {
        this.showModalOnClick.show();
    };
    LoginComponent.prototype.validateEmail = function (email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    LoginComponent.prototype.keyPressed = function (event) {
        if (event.which === 13 || event.keyCode === 13) {
            this.login();
        }
    };
    return LoginComponent;
}());
export { LoginComponent };
