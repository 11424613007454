import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AVLists } from "../models/AVLists";


declare var require: any
const NodeCache = require("node-cache");

@Injectable({
  providedIn: 'root'
})

export class AVListService {
  myCache = new NodeCache();
  constructor(private http: HttpClient) {
  }
  public getBaseUrl(): string {
    return environment.baseUrl;
  }

  protected getUrl(relativeUrl: string) {
    return this.getBaseUrl() + relativeUrl;
  }

  protected getOptions(): any {
    const AuthorizeToken = localStorage.getItem('USER_TOKEN');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + AuthorizeToken
    });

    const options = {
      headers: headers
    };

    return options;
  }

  getAll(user_id,listIds = []): Observable<AVLists[]> {
    const url = this.getUrl('/avlists/get-selected-lists/' + user_id);
    return this.http.put(url, {listIds}, this.getOptions())
      .pipe(map((res: any) => res));
  }
  getUserReviewList(_id: string): Observable<AVLists[]> {
    return new Observable((observer) => {
      const url = this.getUrl('/avlists/get-review-lists/' + _id);

      this.http.get(url, this.getOptions())
        .subscribe((querySnapshot: any) => {
          observer.next(querySnapshot);
        });
    });
  }
}
