<div class="loading" *ngIf="isLoading">Loading&#8230;</div>
<div class="userInfo">
    <div class="flex align-end user-data" *ngFor="let record of rows; let i = index" >
        <div style="margin-left: 20px">
            <a target="_blank" href="https://www.linkedin.com/in/{{record.publicIdentifier}}">
            <img src="{{record.photo}}"/>
            </a>
        </div>
        <div style="width: 80%;margin-left: 20px;margin-right: 20px;">
            <div class="name">{{record.firstName}} {{record.lastName}} lastName invited you to connect on {{record.inviteSentOn | date: 'M/d/yyyy'}}</div>
            <div class="headline"> {{record.occupation}}</div>
        </div>
        <div *ngIf="!record.actionTaken"><button (click)="doAction('ACCEPT',record)" class="btn btn-primary">Accept</button><button (click)="doAction('IGNORE',record)" class="btn btn-default">Reject</button></div>
    </div>
</div>