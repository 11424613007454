import { OnInit } from '@angular/core';
var MessagedataComponent = /** @class */ (function () {
    function MessagedataComponent(userService, papa, HttpClient) {
        this.userService = userService;
        this.papa = papa;
        this.HttpClient = HttpClient;
        this.accept = '*';
        this.isLoading = false;
        this.processedFiles = 0;
        this.files = [];
        this.warningMessages = [];
        this.messageDataArray = [];
        this.hasBaseDropZoneOver = false;
    }
    MessagedataComponent.prototype.cancel = function () {
        this.progress = 0;
        if (this.httpEmitter) {
            console.log('cancelled');
            this.httpEmitter.unsubscribe();
        }
    };
    MessagedataComponent.prototype.uploadFiles = function (files) {
        var _this = this;
        if (files && files.length > 0) {
            this.isLoading = true;
            this.warningMessages = [];
            var _loop_1 = function (i) {
                var reader = new FileReader();
                reader.readAsText(files[i]);
                reader.onload = function (data) {
                    _this.papa.parse(reader.result.toString(), {
                        header: true,
                        complete: function (parsedData) {
                            if (!files[i].name.includes('messages-')) {
                                _this.warningMessages.push({ filename: files[i].name, message: "This file cannot be imported. Please make sure the filename contains messages-." });
                            }
                            if (_this.warningMessages.length === 0) {
                                _this.userService.updateMessageData(parsedData.data).subscribe(function (res) {
                                    if (res.message === 'no found user in messages') {
                                        _this.warningMessages.push({ filename: files[i].name, message: "This file could not be imported since there is not user related to the messages" });
                                    }
                                    _this.processedFiles++;
                                    console.log("processed file #" + _this.processedFiles);
                                    if (_this.processedFiles === files.length) {
                                        _this.isLoading = false;
                                        _this.messageDataArray = null;
                                        _this.files = [];
                                        _this.processedFiles = 0;
                                    }
                                }, function (err) {
                                    _this.isLoading = false;
                                    alert('error');
                                    console.log('error to update messagesdata: ', err);
                                });
                            }
                            else {
                                _this.isLoading = false;
                            }
                        }
                    });
                };
            };
            for (var i = 0; i < files.length; i++) {
                _loop_1(i);
            }
        }
        else {
            alert('Please select data file.');
        }
    };
    MessagedataComponent.prototype.getDate = function () {
        return new Date();
    };
    MessagedataComponent.prototype.ngOnInit = function () {
        this.successMessage = '';
    };
    return MessagedataComponent;
}());
export { MessagedataComponent };
