<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="main" *ngIf="!isLoading">

    <ng-stepper #cdkStepper [linear]="true">

        <!-- <cdk-step [stepControl]="stepOne.stepOneForm" [optional]="false">
            <ng-template cdkStepLabel>
                <div class="step-bullet">1</div>
                <div class="step-title">Strategy Info<span class="required">*</span></div>
            </ng-template>
            <app-step-one #stepOne [strategy]="strategy"></app-step-one>
        </cdk-step> -->

        <cdk-step [stepControl]="stepTwo.stepTwoForm" [optional]="false">
            <ng-template cdkStepLabel>
                <div class="step-bullet">2</div>
                <div class="step-title">Geography</div>
            </ng-template>
            <app-step-two #stepTwo [strategy]="strategy"></app-step-two>
        </cdk-step>

        <cdk-step [stepControl]="stepThree.stepThreeForm" [optional]="false">
            <ng-template cdkStepLabel>
                <div class="step-bullet">3</div>
                <div class="step-title">Company / Industry Information</div>
            </ng-template>
            <app-step-three #stepThree [strategy]="strategy"></app-step-three>
        </cdk-step>

        <cdk-step [stepControl]="stepFour.stepFourForm" [optional]="false">
            <ng-template cdkStepLabel>
                <div class=" step-bullet ">4
                </div>
                <div class="step-title ">Role & Tenure</div>
            </ng-template>
            <app-step-four #stepFour [strategy]="strategy" (setLoading)="setLoading($event)"></app-step-four>
        </cdk-step>

        <!-- <cdk-step [stepControl]="stepFive.stepFiveForm " [optional]="true " [state]="stepFive.state ">
            <ng-template cdkStepLabel>
                <div class="step-bullet ">5</div>
                <div class="step-title ">Messaging</div>
            </ng-template>
            <app-step-five #stepFive [strategy]="strategy "></app-step-five>
        </cdk-step> -->

    </ng-stepper>
</div>