import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { StrategyService } from 'src/app/services/strategy.service';

@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss']
})
export class StepFourComponent implements OnInit {

  @Input() strategy: any;
  @Output() setLoading: EventEmitter<any> = new EventEmitter();

  isLoading = false;
  dropdownList = [];
  stepFourForm: FormGroup;
  clickByDoneButton = false;
  dropdownSettings: IDropdownSettings;
  editMode = localStorage.getItem("EDIT_STRATEGY") ? true : false;

  limitResearchYearsOptions = [
    "Less than 1 year",
    "1-2",
    "3-5",
    "6-10",
    "More than 10"
  ];

  constructor(private fb: FormBuilder, public strategyService: StrategyService, private router: Router) {
    this.stepFourForm = this.fb.group({
      userId: localStorage.getItem('USER_KEY'),
      jobTitles: this.fb.control([], Validators.required),
      jobTitlesMore: this.fb.control(''),
      companyTenureEnable: this.fb.control(false),
      companyTenure: this.fb.control([]),
      positionTenureEnable: this.fb.control(false),
      positionTenure: this.fb.control([])
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'positionCode',
      textField: 'positionName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  ngOnInit(): void {
    this.strategyService.getPositions().subscribe((positions) => {
      this.dropdownList = positions.sort((a, b) => {
        if (a.positionName.toLowerCase() < b.positionName.toLowerCase()) {
          return -1;
        }
        if (a.positionName.toLowerCase() > b.positionName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }, (err) => {
      alert('error');
      console.log(err);
    });

    if (this.strategy) {
      this.stepFourForm = this.fb.group({
        userId: localStorage.getItem('USER_KEY'),
        strategyId: this.fb.control(this.strategy._id, Validators.required),
        jobTitles: this.fb.control(this.strategy.jobTitles, Validators.required),
        jobTitlesMore: this.fb.control(this.strategy.jobTitlesMore),
        companyTenureEnable: this.fb.control(this.strategy.companyTenureEnable),
        companyTenure: this.fb.control(this.strategy.companyTenure),
        positionTenureEnable: this.fb.control(this.strategy.positionTenureEnable),
        positionTenure: this.fb.control(this.strategy.positionTenure)
      });
    }

    this.jobTitlesChange();
  }

  jobTitlesChange(): void {
    if (this.stepFourForm.value.jobTitles.length === 0 && !this.stepFourForm.value.jobTitlesMore) {
      this.stepFourForm.get('jobTitles').setValidators([Validators.required]);
    } else {
      this.stepFourForm.get('jobTitles').clearValidators();
    }
    this.stepFourForm.get('jobTitlesMore').clearValidators();

    this.stepFourForm.controls["jobTitles"].updateValueAndValidity();
    this.stepFourForm.controls["jobTitlesMore"].updateValueAndValidity();
  }

  stepFourSubmit(): void {
    console.log(this.stepFourForm);

    if (this.stepFourForm.valid && (!this.stepFourForm.value.companyTenureEnable || this.stepFourForm.value.companyTenure.length > 0) && (!this.stepFourForm.value.positionTenureEnable || this.stepFourForm.value.positionTenure.length > 0)) {
      this.setLoading.emit(true);
      this.strategyService.submitStrategy(this.stepFourForm.value).subscribe((res) => {
        console.log(res);
        this.setLoading.emit(false);
        if (this.clickByDoneButton) {
          this.router.navigate(['campaigns']);
        } else {
          this.router.navigate(['intro-message-sequence']);
        }
      }, (err) => {
        alert('error');
        console.log(err);
        this.setLoading.emit(false);
      });
    }
  }

  onItemSelect(item: any): void {
    // console.log(item);
  }

  onSelectAll(items: any): void {
    // console.log(items);
  }

  getCompanyCheckboxesValue(event, limit): void {
    if (!this.stepFourForm.value.companyTenure.includes(limit)) {
      this.stepFourForm.value.companyTenure.push(limit);
    } else {
      const index = this.stepFourForm.value.companyTenure.indexOf(limit);
      if (index > -1) {
        this.stepFourForm.value.companyTenure.splice(index, 1);
      }
    }
  }

  getPositionCheckboxesValue(event, limit): void {
    if (!this.stepFourForm.value.positionTenure.includes(limit)) {
      this.stepFourForm.value.positionTenure.push(limit);
    } else {
      const index = this.stepFourForm.value.positionTenure.indexOf(limit);
      if (index > -1) {
        this.stepFourForm.value.positionTenure.splice(index, 1);
      }
    }
  }

  cancelNewCampaign(): void {
    this.router.navigate(['campaigns']);
  }

  clickDoneButton(): void {
    this.clickByDoneButton = true;
  }

}