import { OnInit } from '@angular/core';
import { Client } from '../../../models/client';
var ClientComponent = /** @class */ (function () {
    function ClientComponent(clientService, authenticationService, router, mdbSpinningPreloader) {
        this.clientService = clientService;
        this.authenticationService = authenticationService;
        this.router = router;
        this.mdbSpinningPreloader = mdbSpinningPreloader;
        this.isLoading = true;
        this.client = new Client();
    }
    ClientComponent.prototype.ngOnInit = function () {
        this.mdbSpinningPreloader.start();
        this.getClients();
    };
    ClientComponent.prototype.openToAdd = function () {
        this.client = new Client();
        this.showModalOnClick.show();
    };
    ClientComponent.prototype.openToEdit = function (client) {
        this.client._id = client._id;
        this.client.fname = client.fname;
        this.client.lname = client.lname;
        this.client.email = client.email;
        this.client.phone = client.phone;
        this.client.uname = client.uname;
        this.client.Linkedin = client.Linkedin;
        this.showModalOnClick.show();
    };
    ClientComponent.prototype.reset = function () {
        this.client = new Client();
    };
    ClientComponent.prototype.getClients = function () {
        var _this = this;
        this.clientService.getClients().subscribe(function (clients) {
            _this.isLoading = false;
            _this.clients = clients;
            _this.mdbSpinningPreloader.stop();
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get clients: ', err);
        });
    };
    ClientComponent.prototype.remove = function (client) {
        var _this = this;
        if (confirm('Are you sure you want to delete this client?')) {
            this.clientService.deleteClient(client).subscribe(function (res) { return _this.getClients(); }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to delete client: ', err);
            });
        }
    };
    ClientComponent.prototype.saveClient = function () {
        var _this = this;
        if (!this.client.fname) {
            alert('Please enter client first name');
            return;
        }
        if (!this.client.lname) {
            alert('Please enter client last name');
            return;
        }
        if (!this.client.email) {
            alert('Please enter client email');
            return;
        }
        if (!this.client.phone) {
            alert('Please enter client phone');
            return;
        }
        if (!this.client.uname) {
            alert('Please enter client user name');
            return;
        }
        if (!this.client.Linkedin) {
            alert('Please enter client LinkedIn ID');
            return;
        }
        if (!this.client._id) {
            this.clientService.addClient(this.client).subscribe(function (client) {
                _this.reset();
                _this.getClients();
                _this.showModalOnClick.hide();
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to add client: ', err);
            });
        }
        else {
            this.clientService.editClient(this.client).subscribe(function (client) {
                _this.reset();
                _this.getClients();
                _this.showModalOnClick.hide();
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to edit client: ', err);
            });
        }
    };
    return ClientComponent;
}());
export { ClientComponent };
