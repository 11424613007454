import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { User } from '../../../models/user';
var UserComponent = /** @class */ (function () {
    function UserComponent(userService) {
        this.userService = userService;
        this.userValue = '';
        this.roleValue = '';
        this.statusValue = '';
        this.isLoading = true;
        this.user = new User();
        this.companyValue = '';
        this.modal_type = 'add';
        this.update_password = false;
    }
    UserComponent.prototype.ngOnInit = function () {
        this.getUsers();
    };
    UserComponent.prototype.openToAdd = function () {
        this.user = new User();
        this.modal_type = 'add';
        this.user.role = "";
        this.user.assignedResearcher = "";
        this.showModalOnClick.show();
    };
    UserComponent.prototype.reset = function () {
        this.user = new User();
        localStorage.removeItem('PREV_LI_PID');
        this.getUsers();
    };
    UserComponent.prototype.getUsers = function () {
        var _this = this;
        this.userService.getAllUsers().subscribe(function (res) {
            _this.dropdownUsers = tslib_1.__spread(res.users).sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            _this.users = tslib_1.__spread(res.users).sort(function (a, b) {
                if (a.company.toLowerCase() < b.company.toLowerCase())
                    return -1;
                if (a.company.toLowerCase() > b.company.toLowerCase())
                    return 1;
                if (a.name.toLowerCase() < b.name.toLowerCase())
                    return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase())
                    return 1;
                return 0;
            });
            _this.companies = res.companies.sort(function (a, b) {
                if (a.toLowerCase() < b.toLowerCase())
                    return -1;
                if (a.toLowerCase() > b.toLowerCase())
                    return 1;
                return 0;
            });
            _this.filter();
            _this.assignedResearchers = tslib_1.__spread(_this.users).filter(function (user) { return user.isResearcher === true; }).sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get all users: ', err);
        });
    };
    UserComponent.prototype.filter = function () {
        var _this = this;
        this.filteredUsers = this.users;
        if (this.companyValue) {
            this.filteredUsers = this.filteredUsers.filter(function (item) { return item.company === _this.companyValue; });
        }
        if (this.userValue) {
            this.filteredUsers = this.filteredUsers.filter(function (item) { return item._id === _this.userValue; });
        }
        if (this.roleValue) {
            this.filteredUsers = this.filteredUsers.filter(function (item) { return item.role === _this.roleValue; });
        }
        if (this.statusValue === 'inactive') {
            this.filteredUsers = this.filteredUsers.filter(function (item) { return item.isHidden === true; });
        }
        if (this.statusValue === 'active') {
            this.filteredUsers = this.filteredUsers.filter(function (item) { return item.isHidden === false; });
        }
        return this.filteredUsers;
    };
    UserComponent.prototype.openToEdit = function (user) {
        console.log(user);
        this.modal_type = 'edit';
        this.user._id = user._id;
        this.user.name = user.name;
        this.user.company = user.company;
        this.user.email = user.email;
        this.user.role = user.role;
        this.user.linkedInProfileId = user.linkedInProfileId;
        localStorage.setItem('PREV_LI_PID', user.linkedInProfileId);
        this.user.vm = user.vm || '';
        this.user.myId = user.myId;
        this.user.memberId = user.memberId;
        this.user.isHidden = user.isHidden;
        this.user.isLila = !!user.isLila;
        this.user.groups = !!user.groups;
        this.user.isViewedprofilesActive = !!user.isViewedprofilesActive;
        this.user.selfserve = user.selfserve;
        this.user.notifications = user.notifications;
        this.user.googleDocURL = user.googleDocURL;
        this.user.isResearcher = user.isResearcher;
        this.user.assignedResearcher = user.assignedResearcher || "";
        this.user.initials = user.initials;
        this.user.leadStatusOptions = (user.leadStatusOptions || [0, 1, 2, 3, 4]).join(",");
        this.showModalOnClick.show();
    };
    UserComponent.prototype.clearNewPassword = function () {
        this.user.password = '';
    };
    UserComponent.prototype.saveUser = function () {
        var _this = this;
        //console.log(this.user);return;
        if (!this.user.name) {
            alert('Please enter user name');
            return;
        }
        if (!this.user.company) {
            alert('Please enter user company');
            return;
        }
        if (!this.user.linkedInProfileId) {
            alert('Please enter linkedin profile ID');
            return;
        }
        if (!this.user.myId) {
            alert('Please enter My ID');
            return;
        }
        if (!this.user.memberId) {
            alert('Please enter Member ID');
            return;
        }
        if (!this.user.email) {
            alert('Please enter user email');
            return;
        }
        if (!this.user.password && this.modal_type === 'add') {
            alert('Please enter user password');
            return;
        }
        if (!this.user.role) {
            alert('Please select user role');
            return;
        }
        if (!this.user.password && this.modal_type === 'edit' && this.update_password) {
            alert("Please enter user's new password");
            return;
        }
        if (!this.user.leadStatusOptions) {
            alert('Please select lead code status');
            return;
        }
        if (!this.user._id) {
            this.user.leadStatusOptions = this.user.leadStatusOptions.split(",").map(function (x) { return x.trim(); });
            this.userService.addUser(this.user).subscribe(function (user) {
                _this.reset();
                _this.showModalOnClick.hide();
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to add new user: ', err);
            });
        }
        else {
            this.isLoading = true;
            this.showModalOnClick.hide();
            this.user.leadStatusOptions = this.user.leadStatusOptions.split(",").map(function (x) { return x.trim(); });
            if (localStorage.getItem('PREV_LI_PID') !== this.user.linkedInProfileId) {
                this.userService.changeUserLinkedinID(localStorage.getItem('PREV_LI_PID'), this.user.linkedInProfileId).subscribe(function (s) {
                    _this.userService.editUser(_this.user).subscribe(function (user) {
                        _this.reset();
                        _this.isLoading = false;
                    }, function (err) {
                        _this.isLoading = false;
                        alert('error');
                        console.log('error to edit the user: ', err);
                    });
                }, function (err) {
                    _this.isLoading = false;
                    alert('error');
                    console.log('error to update the user linkedin id: ', err);
                });
            }
            else {
                console.log(this.user);
                this.userService.editUser(this.user).subscribe(function (user) {
                    _this.reset();
                    _this.isLoading = false;
                }, function (err) {
                    _this.isLoading = false;
                    alert('error');
                    console.log('error to edit the user: ', err);
                });
            }
        }
    };
    return UserComponent;
}());
export { UserComponent };
