import * as tslib_1 from "tslib";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { OnInit } from '@angular/core';
import { dateFilterParams } from 'src/app/helpers/dateFilterParams';
import { validateImage } from 'src/app/helpers/common';
var ConnectionsComponent = /** @class */ (function () {
    function ConnectionsComponent(document, userService, personalityDataService) {
        var _this = this;
        this.userService = userService;
        this.personalityDataService = personalityDataService;
        this.rows = [];
        this.leadStatusOptions = [];
        this.isLoading = true;
        this.selectedRow = undefined;
        this.personalityModaData = {};
        this.pagingIndex = 0;
        this.paragraph = "";
        this.modalBorder = "#dee2e6";
        this.noDataText = 'No connections right now';
        this.discTypeLabels = {
            D: { color: "#db3c48" },
            Di: { color: "#e5561f" },
            DI: { color: "#f48119" },
            Id: { color: "#ffb727" },
            I: { color: "#f7cf0d" },
            Is: { color: "#d3e000" },
            IS: { color: "#afd80a" },
            Si: { color: "#88c100" },
            S: { color: "#11b21b" },
            Sc: { color: "#51b48e" },
            SC: { color: "#00b6bc" },
            Cs: { color: "#007fb6" },
            C: { color: "#2f5fa5" },
            Cd: { color: "#6756b2" },
            CD: { color: "#93359b" },
            Dc: { color: "#c62e85" },
        };
        this.tendsTitle = "";
        this.columnDefs = [
            {
                width: 200,
                sortable: true,
                resizable: true,
                field: 'companyName',
                headerName: 'Company Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                    if (!valueA)
                        return -1;
                    if (!valueB)
                        return 1;
                    valueA = valueA.toLowerCase();
                    valueB = valueB.toLowerCase();
                    if (valueA == valueB)
                        return 0;
                    if (valueA > valueB)
                        return 1;
                    return -1;
                },
                cellRenderer: function (params) {
                    if (params.data.companyProfileURL) {
                        return "<a href=\"" + params.data.companyProfileURL + "\" target=\"_blank\">" + params.value + "</a>";
                    }
                    else {
                        return "<div>" + params.value + "</div>";
                    }
                },
            },
            // {
            //   width: 40,
            //   field: 'lastViewedOn',
            //   sortable: true,
            //   resizable: true,
            //   filterParams: {
            //     buttons: ['reset', 'apply'],
            //     closeOnApply: true
            //   },
            //   headerName: 'PV',
            //   headerTooltip:"Days since profile was viewed",
            //   headerClass:"profile-view-data",
            //   cellRenderer: function(params){
            //     if (params.data.lastViewedOn) {
            //       let diff = moment().diff(moment(params.data.lastViewedOn,'x'),'days');
            //       if(diff <= 7){
            //         return `<div class="viewprofileCase1 circle">${diff}</div>`
            //       }else if(diff<=14 && diff>7){
            //         return `<div class="viewprofileCase2 circle" >${diff}</div>`
            //       }else if(diff<=21 && diff>14){
            //         return `<div class="viewprofileCase3 circle">${diff}</div>`
            //       }
            //     }
            //   }
            // },
            {
                width: 100,
                sortable: true,
                resizable: true,
                editable: true,
                field: 'status',
                headerName: 'Lead Status',
                headerTooltip: "Lead Status",
                cellEditor: 'agSelectCellEditor',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                cellEditorParams: this.getLeadOptions.bind(this),
                cellRenderer: this.cellLeadCodeRenderer.bind(this)
            },
            {
                width: 100,
                sortable: true,
                resizable: true,
                field: 'firstName',
                headerName: 'First Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                }
            },
            {
                width: 100,
                sortable: true,
                resizable: true,
                field: 'lastName',
                headerName: 'Last Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                }
            },
            {
                width: 200,
                field: 'title',
                sortable: true,
                resizable: true,
                headerName: 'Title',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                }
            },
            {
                width: 75,
                resizable: true,
                field: 'Avatar',
                headerName: 'Avatar',
                cellRenderer: this.avatarShow.bind(this)
            },
            {
                width: 75,
                headerName: 'LiLa',
                sortable: true,
                resizable: true,
                field: 'discType',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    caseSensitive: true,
                    closeOnApply: true
                },
                onCellClicked: this.showGraph.bind(this),
                //cellRenderer:this.showGraph.bind(this),
                cellRenderer: function (params) {
                    return params.data.discType ? "<div (click)=\"showGraph(params.data)\" class=\"custom-disctype\" style=\"background-color:" + _this.discTypeLabels[params.data.discType].color + "\">" + params.data.discType + "</div>" : "";
                }
            },
            {
                width: 75,
                resizable: true,
                headerName: 'View',
                field: 'profileURL',
                cellRenderer: function (params) {
                    return "<a href=\"" + params.data.profileURL + "\" target=\"_blank\">View</a>";
                }
            },
            // {
            //   width: 75,
            //   sortable: true,
            //   resizable: true,
            //   field: 'since1',
            //   headerName: 'Tenure',
            //   filter: 'agNumberColumnFilter',
            //   filterParams: {
            //     buttons: ['reset', 'apply'],
            //     closeOnApply: true
            //   },
            //   comparator: function (number1, number2) {
            //     if (number1 === null && number2 === null) {
            //       return 0;
            //     }
            //     if (number1 === null) {
            //       return -1;
            //     }
            //     if (number2 === null) {
            //       return 1;
            //     }
            //     return number1 - number2;
            //   },
            //   cellRenderer: function (params) {
            //     if (!params.data.yearsAtCompany) {
            //       return ``
            //     }
            //     return `<span>${params.data.since1}</span>`;
            //   }
            // },
            {
                width: 200,
                field: 'email',
                sortable: true,
                resizable: true,
                headerName: 'Email',
                cellRenderer: function (params) {
                    if (params.value)
                        return "<a href=\"mailto:" + params.value + "\">" + params.value + "<a>";
                    return '';
                },
                filter: "agTextColumnFilter", filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                }
            },
            {
                width: 125,
                field: 'phone',
                sortable: true,
                resizable: true,
                headerName: 'Phone',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                }
            },
            {
                width: 150,
                sortable: true,
                resizable: true,
                field: 'location',
                headerName: 'Location',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                }
            },
            // {
            //   width: 150,
            //   sortable: true,
            //   resizable: true,
            //   field: 'campaignStatus',
            //   headerName: ' Campaign Status',
            //   filter: "agTextColumnFilter",
            //   filterParams: {
            //     buttons: ['reset', 'apply'],
            //     closeOnApply: true
            //   },
            //   cellRenderer: function (params) {
            //     if (!params.value) return ``;
            //     if (params.value==1) return `<i class="fa fa-solid fa-person-running"></i>`;
            //     if (params.value==5) return `<i class="fa fa-circle-check"></i>`;
            //     return '';
            //   },
            // },
            // {
            //   width: 150,
            //   sortable: true,
            //   resizable: true,
            //   field: 'mutualConnections',
            //   filter: "agTextColumnFilter",
            //   headerName: 'Mutual Connections',
            //   filterParams: {
            //     buttons: ['reset', 'apply'],
            //     closeOnApply: true
            //   }
            // },
            {
                width: 150,
                sort: "desc",
                sortable: true,
                resizable: true,
                field: 'connectedOnDate',
                headerName: 'Connected on',
                headerTooltip: 'Connected On Date',
                filter: "agDateColumnFilter",
                filterParams: dateFilterParams,
                comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                    if (!valueA)
                        return -1;
                    if (!valueB)
                        return 1;
                    if (new Date(valueA) > new Date(valueB))
                        return 1;
                    if (new Date(valueA) < new Date(valueB))
                        return -1;
                    else
                        return 0;
                },
            },
            {
                width: 150,
                sortable: true,
                resizable: true,
                field: 'lastMessageDate1',
                headerName: 'Last Msg Date',
                headerTooltip: 'Date of Last Incoming Message',
                filter: "agDateColumnFilter",
                filterParams: dateFilterParams,
                comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                    if (!valueA)
                        return -1;
                    if (!valueB)
                        return 1;
                    if (new Date(valueA) > new Date(valueB))
                        return 1;
                    if (new Date(valueA) < new Date(valueB))
                        return -1;
                    else
                        return 0;
                },
                cellRenderer: function (params) {
                    return "" + params.data.lastMessageDate;
                }
            },
            {
                width: 150,
                sortable: true,
                resizable: true,
                field: 'lastMessage',
                filter: "agTextColumnFilter",
                headerName: 'Msg',
                headerTooltip: 'Last Incoming Message',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                width: 75,
                resizable: true,
                field: 'convoUrl',
                headerName: 'View',
                headerTooltip: 'View Full Conversation',
                cellRenderer: function (params) {
                    if (params.value)
                        return "<a style=\"color:black;text-decoration:none;\" target=\"_blank\" href=\"" + params.value + "\"><i class=\"fa fa-comments-o\"></i></a>";
                    return '';
                }
            },
            {
                width: 75,
                field: 'id',
                resizable: true,
                headerName: 'PDF',
                cellRenderer: this.pdfIconRender.bind(this)
            }
        ];
        this.isDataLoading = false;
        this.topPosition = 0;
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }
    ConnectionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.getUserByKey(localStorage.getItem('USER_KEY')).subscribe(function (user) {
            if (user.leadStatusOptions.length == 0) {
                _this.leadStatusOptions = ["", 0, 1, 2, 3, 4];
            }
            else
                _this.leadStatusOptions = [""].concat(user.leadStatusOptions);
            if (!user.isLila) {
                _this.columnDefs = _this.columnDefs.filter(function (x) { return x.headerName !== "LiLa"; });
            }
        });
        ;
        this.userService.getUserPXdata(localStorage.getItem('USER_KEY')).subscribe(function (connections) {
            _this.rows = connections.map(function (con) { return (tslib_1.__assign({}, con, { status: con.status != 0 ? (_this.leadStatusOptions[con.status] || con.status) : '' })); });
            console.log(connections);
            if (_this.rows.length) {
                var parent_1 = document.querySelector('.ag-paging-panel.ag-unselectable');
                var span = parent_1.querySelector("span#download-btn");
                if (span == null) {
                    span = document.createElement("SPAN");
                    span.innerHTML = "<i style=\"cursor:pointer\" id=\"download-btn\" class=\"fas fa-download export-icon\" *ngIf=\"rows.length\" (click)=\"exportData()\"></i>";
                    span.addEventListener('click', function (e) {
                        _this.exportData(); //your typescript function
                    });
                    parent_1.appendChild(span);
                }
            }
            validateImage();
            _this.isLoading = false;
            document.querySelector('#scrolling-table .ag-body-viewport').addEventListener('scroll', function (e) {
                var div = document.querySelector('#scrolling-table .ag-body-viewport');
                console.log(div.scrollHeight, div.scrollTop);
                if ((div.scrollHeight - div.scrollTop) < 1000) {
                    _this.topPosition = div.scrollTop;
                    _this.loadMoreData();
                }
            }, false);
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user pxdata: ', err);
        });
    };
    ConnectionsComponent.prototype.loadMoreData = function () {
        var _this = this;
        console.log(this.gridApi);
        return;
        if (this.isDataLoading)
            return;
        this.isDataLoading = true;
        this.userService.getUserPXdata(localStorage.getItem('USER_KEY')).subscribe(function (connections) {
            //let newRows = connections.map(con=>({...con,status: con.status!=0 ? (this.leadStatusOptions[con.status]||con.status):''}))
            //this.gridApi.updateRowData({add: newRows[0]});
            _this.rows = _this.rows.concat(connections.map(function (con) { return (tslib_1.__assign({}, con, { status: con.status != 0 ? (_this.leadStatusOptions[con.status] || con.status) : '' })); }));
            setTimeout(function () {
                var div = document.querySelector('#scrolling-table .ag-body-viewport');
                div.scrollTop = _this.topPosition;
                _this.isDataLoading = false;
            }, 100);
        });
    };
    ConnectionsComponent.prototype.onPaginationChanged = function ($evt) { validateImage(); };
    ConnectionsComponent.prototype.onRowEditingStarted = function (event) {
        console.log('never called - not doing row editing');
    };
    ConnectionsComponent.prototype.onRowEditingStopped = function (event) {
        console.log('never called - not doing row editing');
    };
    ConnectionsComponent.prototype.onCellEditingStarted = function (event) {
        console.log('cellEditingStarted', event);
        console.log(this.leadStatusOptions);
    };
    ConnectionsComponent.prototype.onCellEditingStopped = function (event) {
        console.log('onCellEditingStopped', event);
        var newValue = event.newValue;
        var newVal = this.leadStatusOptions.findIndex(function (x) { return x == newValue; });
        if (newVal == 0)
            event.data.status = '';
        this.userService.updatePXDataStatus(event.data.id, newVal != -1 ? newVal : event.newValue).subscribe(function (response) {
            console.log(response);
        });
    };
    ConnectionsComponent.prototype.cellEditorSelector = function (params) {
        return {
            component: 'agSelectCellEditor',
            params: {
                values: ["", 0, 1, 2, 3, 4],
            },
            popup: true,
        };
    };
    ConnectionsComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    };
    ConnectionsComponent.prototype.exportData = function () {
        this.gridApi.exportDataAsCsv({
            processCellCallback: function (params) {
                var colDef = params.column.getColDef();
                if (colDef.valueFormatter) {
                    var valueFormatterParams = tslib_1.__assign({}, params, { data: params.node.data, node: params.node, colDef: params.column.getColDef() });
                    return colDef.valueFormatter(valueFormatterParams);
                }
                return params.value;
            },
        });
    };
    ConnectionsComponent.prototype.onFirstDataRendered = function (params) {
        var yourFilterComponent = params.api.getFilterInstance('companyName');
        yourFilterComponent.setModel({
            type: 'contains',
            filter: localStorage.getItem('ORGANIZATION_1') ? localStorage.getItem('ORGANIZATION_1') : ''
        });
        params.api.onFilterChanged();
        localStorage.removeItem('ORGANIZATION_1');
    };
    ConnectionsComponent.prototype.chunks = function (array, size) {
        if (size === void 0) { size = 4; }
        var results = [];
        while (array.length) {
            results.push(array.splice(0, size));
        }
        return results;
    };
    ;
    ConnectionsComponent.prototype.groupBy = function (list, keyGetter) {
        console.log('list', list);
        var map = new Map();
        list.forEach(function (item) {
            var key = keyGetter(item);
            var collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            }
            else {
                collection.push(item);
            }
        });
        return map;
    };
    ConnectionsComponent.prototype.showGraph = function (params) {
        var _this = this;
        //this.showGraphBackup(params);return;
        var discType = params.data.discType;
        this.personalityDataService.getQueryOne(discType).subscribe(function (res) {
            console.log(res);
            if (res.code == 200) {
                _this.personalityModaData.pxData = params.data;
                var _a = tslib_1.__read([res.data.filter(function (x) { return x.tag1 == 'do'; }).reverse(), res.data.filter(function (x) { return x.tag1 == 'dont'; }).reverse(), res.data.filter(function (x) { return x.tag1 == 'behavior'; }).reverse(), res.data.filter(function (x) { return x.tag1 == 'overview'; }).reverse()], 4), _b = _a[0], doArr = _b === void 0 ? [] : _b, _c = _a[1], dontArr = _c === void 0 ? [] : _c, _d = _a[2], behaviorArr = _d === void 0 ? [] : _d, _e = _a[3], overviewArr = _e === void 0 ? [] : _e;
                _this.personalityModaData.contentArr = {
                    doArr: doArr, dontArr: dontArr, behaviorArr: behaviorArr, overviewArr: overviewArr
                };
                _this.personalityModaData.p4d = params.data.p4d || 0;
                _this.personalityModaData.p4i = params.data.p4i || 0;
                _this.personalityModaData.p4s = params.data.p4s || 0;
                _this.personalityModaData.p4c = params.data.p4c || 0;
                var _f = _this.personalityModaData, _g = _f.p4d, d = _g === void 0 ? 0 : _g, _h = _f.p4i, i = _h === void 0 ? 0 : _h, _j = _f.p4s, s = _j === void 0 ? 0 : _j, _k = _f.p4c, c = _k === void 0 ? 0 : _k;
                var max = [{ v: d, k: 'D' }, { v: i, k: 'I' }, { v: s, k: "S" }, { v: c, k: "C" }].sort(function (a, b) { return b.v - a.v; })[0];
                _this.modalBorder = _this.discTypeLabels[max.k].color;
                _this.tendsTitle = _this.personalityModaData.firstName + " tends to be ";
                _this.personalityModaData.queryTwoData = overviewArr;
                _this.pagingIndex = 0;
                _this.paragraph = _this.renderParagraph((_this.personalityModaData.queryTwoData[_this.pagingIndex] || "").text);
                console.log(_this.personalityModaData);
            }
        });
        this.showLilaModalOnClick.show();
    };
    ConnectionsComponent.prototype.renderParagraph = function (para) {
        if (para === void 0) { para = ""; }
        console.log(para);
        return para.replace(/{firstName}/g, this.personalityModaData.pxData.firstName);
    };
    ConnectionsComponent.prototype.changeParagraph = function (direction) {
        console.log(direction);
        if (direction == "N") {
            ++this.pagingIndex;
            if (this.pagingIndex == this.personalityModaData.queryTwoData.length)
                this.pagingIndex = this.personalityModaData.queryTwoData.length - 1;
        }
        else {
            --this.pagingIndex;
            if (this.pagingIndex == -1)
                this.pagingIndex = 0;
        }
        this.paragraph = this.renderParagraph((this.personalityModaData.queryTwoData[this.pagingIndex] || "").text);
    };
    ConnectionsComponent.prototype.showGraphBackup = function (params) {
        this.graphData = params.data;
        this.graphData.p4d = this.graphData.p4d || 0;
        this.graphData.p4i = this.graphData.p4i || 0;
        this.graphData.p4s = this.graphData.p4s || 0;
        this.graphData.p4c = this.graphData.p4c || 0;
        var _a = this.graphData, _b = _a.p4d, d = _b === void 0 ? 0 : _b, _c = _a.p4i, i = _c === void 0 ? 0 : _c, _d = _a.p4s, s = _d === void 0 ? 0 : _d, _e = _a.p4c, c = _e === void 0 ? 0 : _e;
        var max = [{ v: d, k: 'D' }, { v: i, k: 'I' }, { v: s, k: "S" }, { v: c, k: "C" }].sort(function (a, b) { return b.v - a.v; })[0];
        this.modalBorder = this.discTypeLabels[max.k].color;
        this.graphData.qualities = this.graphData.qualities || [];
        this.tendsTitle = this.graphData.firstName + " tends to be " + this.graphData.qualities.slice(0, this.graphData.qualities.length - 1).join(", ") + " and " + this.graphData.qualities.slice(this.graphData.qualities.length - 1)[0];
        this.showGraphModalOnClick.show();
    };
    ConnectionsComponent.prototype.getLeadOptions = function () {
        return { values: this.leadStatusOptions || [0, 1, 2, 3, 4] };
    };
    ConnectionsComponent.prototype.cellLeadCodeRenderer = function (params) {
        return (this.leadStatusOptions || [""])[params.data.status] || params.data.status;
    };
    ConnectionsComponent.prototype.avatarShow = function (params) {
        var _this = this;
        var eDiv = document.createElement('div');
        if (!params.data.avatar) {
            eDiv.innerHTML = '';
        }
        else {
            eDiv.innerHTML =
                "<a href=\"" + params.data.profileURL + "\" target=\"_blank\"><img src=\"" + params.data.avatar + "\" class=\"avatar-img liImg\"/></a>";
        }
        eDiv.addEventListener('click', function () {
            _this.selectedRow = params.data;
            //this.showModalOnClick.show();
        });
        return eDiv;
    };
    ConnectionsComponent.prototype.pdfIconRender = function (params) {
        var _this = this;
        var eDiv = document.createElement('span');
        eDiv.style.cursor = 'pointer';
        eDiv.innerHTML = "<i class=\"fa fa-file-pdf\" style=\"color: red\"></i>";
        eDiv.addEventListener('click', function () {
            _this.getPxdata(params.data.id);
        });
        return eDiv;
    };
    ConnectionsComponent.prototype.getPxdata = function (id) {
        var _this = this;
        this.userService.getPxdataById(id).subscribe(function (res) {
            _this.exportAsPDF(res);
        });
    };
    ConnectionsComponent.prototype.exportAsPDF = function (user) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var documentDefinition;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDocumentDefinition(user)];
                    case 1:
                        documentDefinition = _a.sent();
                        pdfMake.createPdf(documentDefinition).download(user.fullName + '.pdf');
                        return [2 /*return*/];
                }
            });
        });
    };
    ConnectionsComponent.prototype.getDocumentDefinition = function (user) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        sessionStorage.setItem('resume', JSON.stringify(user));
                        _a = {};
                        _b = [{
                                bold: true,
                                fontSize: 20,
                                alignment: 'center',
                                text: user.fullName,
                                margin: [0, 0, 0, 20]
                            },
                            {
                                text: user.headline
                            },
                            {
                                text: user.location,
                                color: 'grey'
                            }];
                        _c = {};
                        _d = [[
                                {
                                    text: 'Contact',
                                    style: 'header'
                                },
                                {
                                    text: 'Email : ' + user.email,
                                },
                                {
                                    color: 'blue',
                                    link: user.profileURL,
                                    text: 'Linkedin: ' + user.profileURL,
                                }
                            ]];
                        return [4 /*yield*/, this.getProfilePicObject(user)];
                    case 1: return [2 /*return*/, (_a.content = _b.concat([
                            (_c.columns = _d.concat([
                                [
                                    _e.sent()
                                ]
                            ]),
                                _c),
                            {
                                style: 'header',
                                text: 'Experience',
                            },
                            this.getExperienceObject(user),
                            {
                                style: 'header',
                                text: 'Education',
                            },
                            this.getEducationObject(user),
                            {
                                text: 'Skills',
                                style: 'header'
                            },
                            {
                                columns: [
                                    {
                                        ul: tslib_1.__spread(user.skills.filter(function (value, index) { return index % 3 === 0; }))
                                    },
                                    {
                                        ul: tslib_1.__spread(user.skills.filter(function (value, index) { return index % 3 === 1; }))
                                    },
                                    {
                                        ul: tslib_1.__spread(user.skills.filter(function (value, index) { return index % 3 === 2; }))
                                    }
                                ]
                            }
                        ]),
                            _a.info = {
                                subject: 'PROFILE',
                                title: user.fullName,
                                author: user.fullName,
                                keywords: 'PROFILE, ONLINE PROFILE',
                            },
                            _a.styles = {
                                header: {
                                    bold: true,
                                    fontSize: 18,
                                    margin: [0, 20, 0, 10],
                                    decoration: 'underline'
                                },
                                name: {
                                    bold: true,
                                    fontSize: 16,
                                },
                                organization: {
                                    bold: true,
                                    fontSize: 14,
                                    italics: true
                                },
                                tableHeader: {
                                    bold: true,
                                }
                            },
                            _a)];
                }
            });
        });
    };
    ConnectionsComponent.prototype.getProfilePicObject = function (user) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var base64;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!user.avatar) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getBase64ImageFromURL(user.avatar)];
                    case 1:
                        base64 = _a.sent();
                        if (base64) {
                            return [2 /*return*/, {
                                    image: base64,
                                    width: 75,
                                    alignment: 'right'
                                }];
                        }
                        return [2 /*return*/, null];
                    case 2: return [2 /*return*/, null];
                }
            });
        });
    };
    ConnectionsComponent.prototype.getBase64ImageFromURL = function (url) {
        return new Promise(function (resolve, reject) {
            var img = new Image();
            img.setAttribute("crossOrigin", "anonymous");
            img.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
                resolve(dataURL);
            };
            img.onerror = function (error) {
                // reject(error);
                resolve(null);
            };
            img.src = url;
        });
    };
    ConnectionsComponent.prototype.getExperienceObject = function (user) {
        var exs = [];
        if (user.organization_2) {
            exs.push([
                {
                    columns: [
                        [
                            {
                                text: user.organization_2,
                                style: 'organization'
                            },
                            {
                                text: user.organization_title_2,
                            },
                            {
                                text: user.organization_location_2, color: 'grey'
                            },
                            {
                                text: user.organization_description_2
                            }
                        ],
                        {
                            text: user.organization_start_2 + ' - Present',
                            alignment: 'right'
                        }
                    ]
                }
            ]);
        }
        if (user.organization_1) {
            exs.push([
                {
                    columns: [
                        [
                            {
                                text: user.organization_1,
                                style: 'organization'
                            },
                            {
                                text: user.organization_title_1,
                            },
                            {
                                text: user.organization_location_1, color: 'grey'
                            },
                            {
                                text: user.organization_description_1
                            }
                        ],
                        {
                            text: user.organization_start_1 + ' - Present',
                            alignment: 'right'
                        }
                    ]
                }
            ]);
        }
        if (user.organization_3) {
            exs.push([
                {
                    columns: [
                        [
                            {
                                text: user.organization_3,
                                style: 'organization'
                            },
                            {
                                text: user.organization_title_3,
                            },
                            {
                                text: user.organization_location_3, color: 'grey'
                            },
                            {
                                text: user.organization_description_3
                            }
                        ],
                        {
                            text: user.organization_start_3 + ' - Present',
                            alignment: 'right'
                        }
                    ]
                }
            ]);
        }
        if (exs.length > 0) {
            return {
                table: {
                    widths: ['*'],
                    body: tslib_1.__spread(exs)
                }
            };
        }
        return null;
    };
    ConnectionsComponent.prototype.getEducationObject = function (user) {
        if (user.education_1) {
            return {
                table: {
                    widths: ['*', '*', '*'],
                    body: [
                        [
                            {
                                text: 'College',
                                style: 'tableHeader'
                            },
                            {
                                text: 'Degree',
                                style: 'tableHeader'
                            },
                            {
                                text: 'Passing Year',
                                style: 'tableHeader'
                            }
                        ],
                        [user.education_1, user.education_degree_1, user.education_start_1 + ("" + (user.education_end_1 ? ' - ' : '')) + user.education_end_1,],
                    ]
                }
            };
        }
        return null;
    };
    return ConnectionsComponent;
}());
export { ConnectionsComponent };
