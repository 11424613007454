<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="container campaigns" *ngIf="!isLoading">
    <h3>Strategy</h3>
    <span>
        How would you like RMD to research prospects?
    </span>

    <form [formGroup]="strategyForm" (ngSubmit)="strategyFormSubmit()" autocomplete="off" novalidate #formRef="ngForm">

        <div class="input">
            <div class="custom-control custom-radio" *ngFor="let option of research_options; let i=index">
                <input class="custom-control-input" type="radio" id="research-{{option.id}}" formControlName="research" [value]="option.id" mdbInput (change)="checkValidation()" />
                <label class="custom-control-label" for="research-{{option.id}}">{{option.value}}</label>
            </div>
            <span class="error" *ngIf="strategyForm.controls['research'].hasError('required') && formRef.submitted">
                Please select from one of the options above.
            </span>
        </div>

        <div class="input" *ngIf="strategyForm.value.research === 'savedSearch'">
            <label for="savedSearchUrl">What is the name or URL of your saved search?</label>
            <input type="text" id="savedSearchUrl" formControlName="savedSearchUrl" class="form-control form-control-lg" />
            <span class="error" *ngIf="strategyForm.controls['savedSearchUrl'].hasError('required') && formRef.submitted">
                Please enter the name or URL of your saved Search.
            </span>
        </div>

        <div class="buttons">
            <button type="submit" *ngIf="editMode" class="previous" (click)="clickDoneButton()">Done</button>
            <button type="button" *ngIf="!editMode" class="previous" (click)="cancelNewCampaign()">Cancel</button>
            <button type="button" class="previous" (click)="gotoCreateNewCampaign()">Previous</button>
            <button type="submit">Next</button>
        </div>
    </form>
</div>