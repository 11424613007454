import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, ViewChild, OnInit } from '@angular/core';


import { User } from '../../../models/user';
import { AVLists } from 'src/app/models/AVLists';
// import { getUserRole } from 'src/app/helpers/getUserRole';

import { UserService } from '../../../services/user.service';
// import { SlackService } from 'src/app/services/slack.services';

@Component({
  selector: 'app-list',
  templateUrl: './list-prep.component.html',
  styleUrls: ['./list-prep.component.scss']
})
export class ListPrepComponent implements OnInit {

  @ViewChild('editModal', { static: true }) editModal: ModalDirective;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @ViewChild('profileModal', { static: true }) profileModal: ModalDirective;

  editId = '';
  deleteId = '';
  listName = '';
  users: User[] = [];
  userValue = '';
  dateValue = '60';
  showValue = '';
  isLoading = true;
  companyValue = '';
  isFinalized=false;
  stats = {
    totalCount: 0,
    totalApproved: 0,
    totalNotApproved: 0,
    percentageApproved: 0,
    totalInvited: 0,
    totalConnected: 0,
    totalResponded: 0,
    totalPositive: 0,
    totalConnectedPercentage: 0
  };
  sortOrder = 'desc';
  sortBy = 'finalized_on';
  sortByPrimary: string = 'finalized_on'; // Example default value
  sortOrderPrimary: string = 'desc';
  avlists: AVLists[] = [];
  companies: string[] = [];
  listDescription = '';
  listPrepComplete: boolean = false;
  profileURLs: string[] = [];
  selectedList: AVLists;
  filteredAvlists: AVLists[] = [];


  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {

    this.loadData();
  }

  loadData() {
    this.isLoading = true;

    // this.userService.getCAUsers().subscribe((res) => {
    //   this.users = res.sort((a, b) => a.name.localeCompare(b.name));
    // }, (err) => {
    //   alert('error');
    //   console.log('error to get ca users: ', err);
    // });

    const listFor = localStorage.getItem('USER_KEY');
    this.userService.getAVlistPrep(listFor, 'all').subscribe((res: any) => {
      this.avlists = res.avlists;
      this.companies = res.companies.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

      this.users = res.users.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      this.initializeFilters();
      this.filter();
      this.isLoading = false;
      // this.updateStats(this.avlists);
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get dashboard: ', err);
    });
  }

  initializeFilters() {
    this.showValue = localStorage.getItem('LIST_PREP_SHOW_FILTER') || '';
    this.companyValue = localStorage.getItem('LISTS_COMPANY_FILTER') || '';
    this.userValue = localStorage.getItem('LISTS_USER_FILTER') || '';
  }



  resetFilter() {
    this.showValue = '';
    this.companyValue = '';
    this.userValue = '';


    localStorage.removeItem('LIST_PREP_SHOW_FILTER');
    localStorage.removeItem('LISTS_COMPANY_FILTER');
    localStorage.removeItem('LISTS_USER_FILTER');
    // localStorage.removeItem('LISTS_DATE_RANGE_FILTER');
    localStorage.removeItem('USER_PXDATA_CACHED_AT');
    localStorage.removeItem('USER_PXDATA');
    localStorage.removeItem('USER_LISTS_KEY');

    this.loadData();
  }

  filter(isDaysTrigger = false) {

  
    this.filteredAvlists = this.avlists;
  
    if (this.showValue) {
      localStorage.setItem('LIST_PREP_SHOW_FILTER', this.showValue);
      if (this.showValue === 'in_progress') {
        this.filteredAvlists = this.filteredAvlists.filter(item => !item.research_finalized);
      } else if (this.showValue === 'research_finalized') {
        this.filteredAvlists = this.filteredAvlists.filter(item => item.research_finalized && !item.listPrepComplete);
      }
    } else {
      localStorage.removeItem('LIST_PREP_SHOW_FILTER');
      this.filteredAvlists = this.avlists.filter(item => !item.listPrepComplete);
    }
  
    if (this.companyValue) {
      localStorage.setItem('LISTS_COMPANY_FILTER', this.companyValue);
      this.filteredAvlists = this.filteredAvlists.filter(item => item.company === this.companyValue);
    } else {
      localStorage.removeItem('LISTS_COMPANY_FILTER');
    }
  
    if (this.userValue) {
      localStorage.setItem('LISTS_USER_FILTER', this.userValue);
      this.filteredAvlists = this.filteredAvlists.filter(item => item.listFor === this.userValue);
    } else {
      localStorage.removeItem('LISTS_USER_FILTER');
    }
  

  
    if (this.dateValue) {
      this.filteredAvlists = this.filteredAvlists.filter(item => (Math.abs(new Date().getTime() - new Date(item.createdAt).getTime()) / 3600000) < parseInt(this.dateValue) * 24);
    }
// Custom sort by `finalized_on` field
this.filteredAvlists.sort(this.customSort.bind(this));


return this.filteredAvlists;

  }
  
  customSort(a: AVLists, b: AVLists): number {
    const aFinalized = a.finalized_on ? new Date(a.finalized_on).getTime() : 0;
    const bFinalized = b.finalized_on ? new Date(b.finalized_on).getTime() : 0;
  
    if (aFinalized === 0 && bFinalized === 0) {
      return 0; // Both have no finalized_on
    }
  
    if (aFinalized === 0) {
      return this.sortOrderPrimary === 'asc' ? -1 : 1; // Handle empty dates
    }
  
    if (bFinalized === 0) {
      return this.sortOrderPrimary === 'asc' ? 1 : -1; // Handle empty dates
    }
  
    return this.sortOrderPrimary === 'asc' ? aFinalized - bFinalized : bFinalized - aFinalized; // Ascending or descending
  }

  onSort(event: any) {
    if (event.sortBy === 'finalized_on') {
      // Toggle sort order
      this.sortOrderPrimary = this.sortOrderPrimary === 'asc' ? 'desc' : 'asc';
      this.filteredAvlists.sort(this.customSort);
    } else {
      // Reset sort order for finalized_on
      this.sortOrderPrimary = 'desc';
  
      // Apply default sorting logic for other columns
      this.filteredAvlists.sort((a, b) => {
        const valueA = a[event.sortBy];
        const valueB = b[event.sortBy];
        return (valueA < valueB ? -1 : 1) * (event.sortOrder === 'asc' ? 1 : -1);
      });
    }
  }
  
  updateListPrep(avlist, isFinalized = false) {
    this.editId = avlist._id;
    this.isFinalized = isFinalized;
    this.listName = avlist.listName;
    this.listDescription = avlist.listDescription;
    this.listPrepComplete = !avlist.listPrepComplete;
    console.log(avlist);
    this.editList();
  }

  openEditModal(avlist) {
    this.editId = avlist._id;
    this.listName = avlist.listName;
    this.listDescription = avlist.listDescription;
    this.listPrepComplete = avlist.listPrepComplete;
    this.editModal.show();
  }

  editList() {
    const data: any = { listName: this.listName, listDescription: this.listDescription };
    if (this.isFinalized) {
      data.research_finalized = true;
    } else {
      data.approvedOn = 0;
      data.listPrepComplete = this.listPrepComplete;
    }
    this.isFinalized = false;
    this.userService.editAVlist(this.editId, data).subscribe((res: any) => {
      if (res) {
        this.ngOnInit();
      }
      this.isLoading = false;
      this.editModal.hide();
    }, (err) => {
      this.isLoading = false;
      this.editModal.hide();
      alert('error');
      console.log('error to edit list: ', err);
    });
  }

  openDeleteModal(deleteId) {
    this.deleteId = deleteId;
    this.deleteModal.show();
  }

  deleteList() {
    this.userService.deleteAVlist(this.deleteId).subscribe((res: any) => {
      if (res) {
        this.ngOnInit();
      }
      this.isLoading = false;
      this.deleteModal.hide();
    }, (err) => {
      this.isLoading = false;
      this.deleteModal.hide();
      alert('error');
      console.log('error to delete list: ', err);
    });
  }

  
  openProfileModal(avlist) {
    this.isLoading = true;
    this.selectedList = avlist;

    this.userService.getProfileURLs(avlist._id).subscribe((res: any) => {
      if (res) {
        this.profileURLs = res.map(value => value.profileURL);
        this.profileModal.show();
      }
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get profile URLs: ', err);
    });
  }

  listContacts(list, type) {
    localStorage.removeItem('LIST_CONTACTS_VIEW_TYPE');

    if (list._id != localStorage.getItem('LIST_CONTACTS_KEY')) {
      localStorage.removeItem('LIST_AVDATA_CACHED_AT');
    }

    switch (type) {
      case 'all':
        localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', '');
        break;
      case 'approved':
        localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', 'Y');
        break;
      case 'not-approved':
        localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', 'N');
        break;
    }

    localStorage.setItem('LIST_CONTACTS_KEY', list._id);
    localStorage.setItem('LIST_CONTACTS_NAME', list.name);
    localStorage.setItem('LIST_CONTACTS_COMPANY', list.company);
    localStorage.setItem('LIST_CONTACTS_LISTNAME', list.listName);

    //this.router.navigate(['/listContacts']);
    this.router.navigate(['/list-prep-prospects']);
  }

  listReviewbylist(list) {
    if (list._id != localStorage.getItem('USER_REVIEW_LISTKEY')) {
      localStorage.removeItem('USER_AVDATA_CACHED_AT');
    }

    localStorage.setItem('USER_REVIEW_KEY', list.listFor);
    localStorage.setItem('USER_REVIEW_LISTKEY', list._id);
    localStorage.setItem('USER_REVIEW_NAME', list.name);

    // if (this.isEU) {
    //   return this.router.navigate(['/review']);
    // }

    this.router.navigate(['/userReview']);
  }

  exportList(list) {
    localStorage.removeItem('LIST_AVDATA_CACHED_AT');

    this.userService.getAVDataByListId(list._id, localStorage.getItem('USER_KEY')).subscribe((res) => {
      const con = [];
      let csvContent = 'data:text/csv;charset=utf-8,';
      Object.keys(res).forEach((data) => {
        const val = res[data];
        con.push(val);
      });

      const title = `Status, Rejection Reason, Company Name, First Name, Last Name, Title, Profile Url, Email, Phone, Location, Company Start Date, Tenure, Connected On, Industry, Mutual Connections`;
      csvContent += title + '\r\n';

      con.forEach(function (rowArray) {
        const row = `"${rowArray.approved}","${rowArray.rejectionReason || 'N/A'}","${rowArray.companyName}","${rowArray.firstName}","${rowArray.lastName}","${rowArray.title}","${rowArray.profileURL}","${rowArray.email}","${rowArray.phone}","${rowArray.location}","${rowArray.yearsAtCompany}","${rowArray.since1}","${rowArray.connectedOn}","${rowArray.industry}","${rowArray.mutualConnections}"`;
        csvContent += row + '\r\n';
      });

      csvContent = csvContent.replace(/#/g, '');
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);

      const d = new Date();
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      const _d = `${mo}-${da}-${ye}`;

      link.setAttribute('download', `${list.company} - ${list.name} (${list.listName}) - Exported on ${_d}.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
      this.isLoading = false;
    }, (err) => {
      alert('error');
      console.log('error to get user av data: ', err);
    });
  }

  isTwoDayGapCheck(date: number): boolean {
    if (date === 0) {
      return false;
    }

    const diff = Math.abs(new Date().getTime() - new Date(date).getTime()) / 3600000;
    return diff > 48;
  }
}

