import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, ViewChild, OnInit } from '@angular/core';

import { User } from 'src/app/models/user';
import { AVLists } from 'src/app/models/AVLists';
import { getUserRole } from 'src/app/helpers/getUserRole';

import { UserService } from '../../../services/user.service';
import { SlackService } from 'src/app/services/slack.services';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss']
})
export class ListsComponent implements OnInit {

  @ViewChild('editModal', { static: true }) editModal: ModalDirective;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;
  @ViewChild('profileModal', { static: true }) profileModal: ModalDirective;

  editId = '';
  deleteId = '';
  listName = '';
  users: User[];
  userValue = '';
  dateValue = '60';
  showValue = '';
  isLoading = true;
  companyValue = '';
  stats = {
    totalCount: 0,
    totalApproved: 0,
    totalNotApproved: 0,
    percentageApproved: 0,
    totalInvited: 0,
    totalConnected:0,
    totalResponded:0,
    totalPositive: 0,
    totalConnectedPercentage:0

  };
  isFinalized = false;
  dataPayload:any = {};
  sortOrder = 'desc';
  avlists: AVLists[];
  companies: string[];
  listDescription = '';
  listPrepComplete:boolean=false;
  profileURLs: string[];
  selectedList: AVLists;
  sortBy = 'uploadedOn';
  filteredAvlists: AVLists[];
  excludemostrecentlists = false;
  isEU = getUserRole() === '4' ? true : false;
  isCA = getUserRole() === '3' ? true : false;
  isRSA = getUserRole() === '1' ? true : false;

  constructor(private userService: UserService, private router: Router, private slackService: SlackService) { }

  ngOnInit() {
    this.dateValue = localStorage.getItem('LISTS_DATE_RANGE_FILTER') ? localStorage.getItem('LISTS_DATE_RANGE_FILTER') : '60'
    localStorage.setItem('LISTS_DATE_RANGE_FILTER', this.dateValue);
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    if (this.isCA) {
      this.userService.getCAUsers().subscribe((res) => {
        this.users = res.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

      }, (err) => {
        alert('error');
        console.log('error to get ca users: ', err);
      });
    }

    const listFor = localStorage.getItem('USER_LISTS_KEY') ? localStorage.getItem('USER_LISTS_KEY') : 'all';

    this.userService.getAVlists(listFor + `${this.isRSA && listFor === 'all' ? '?recent_days='+this.dateValue :''}`, 'all').subscribe((res: any) => {
      this.avlists = res.avlists;

      this.companies = res.companies.sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        }
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      if (this.isRSA) {
        this.users = res.users.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }

          return 0;
        });
      }

      if (this.isCA) {
        this.avlists = this.avlists.filter((item: AVLists) => item.company === localStorage.getItem('USER_COMPANY'));
      }
      if(!this.isRSA){
        this.avlists = this.avlists.filter((item: AVLists) => item.listPrepComplete);
      }
      this.showValue = localStorage.getItem('LISTS_SHOW_FILTER') ? localStorage.getItem('LISTS_SHOW_FILTER') : '';
      this.companyValue = localStorage.getItem('LISTS_COMPANY_FILTER') ? localStorage.getItem('LISTS_COMPANY_FILTER') : '';
      this.userValue = localStorage.getItem('LISTS_USER_FILTER') ? localStorage.getItem('LISTS_USER_FILTER') : '';
      this.dateValue = localStorage.getItem('LISTS_DATE_RANGE_FILTER') ? localStorage.getItem('LISTS_DATE_RANGE_FILTER') : '';
      this.excludemostrecentlists = localStorage.getItem('LISTS_EXCLUDE_RECENT_FILTER') && localStorage.getItem('LISTS_EXCLUDE_RECENT_FILTER') === '1' ? true : false;
      this.filter();
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get dashboard: ', err)
    });
  }

  toggleExcludemostrecentlists() {
    this.excludemostrecentlists = !this.excludemostrecentlists;
    this.filter();
  }
  updateStats(lists) {
    this.stats = {
      totalCount: 0,
      totalApproved: 0,
      totalNotApproved: 0,
      percentageApproved: 0,
      totalInvited: 0,
      totalConnected: 0,
      totalResponded: 0,
      totalPositive: 0,
      totalConnectedPercentage: 0

    };
    for (let row of lists) {
      this.stats.totalCount += +row.uploadedCount;
      if (row.approvedOn>0){
        this.stats.totalApproved += +row.approvedCount;
        this.stats.totalNotApproved += +row.notApproved;
      }
      this.stats.totalInvited += +row.invitedCount;
      this.stats.totalConnected += +row.connectedCount;
      if(row.respondedCount !=undefined)
      this.stats.totalResponded += +row.respondedCount;
      this.stats.totalPositive += +row.positiveCount;
    }
    this.stats.percentageApproved = (this.stats.totalApproved / this.stats.totalCount) * 100;
    this.stats.totalConnectedPercentage = this.stats.totalApproved ? (this.stats.totalConnected / this.stats.totalApproved) * 100 :0;
    console.log(this.stats)
  }
  resetFilter() {
    this.showValue = '';
    this.companyValue = '';
    this.userValue = '';
    this.dateValue = '';
    this.excludemostrecentlists = false;

    localStorage.removeItem('LISTS_SHOW_FILTER');
    localStorage.removeItem('LISTS_COMPANY_FILTER');
    localStorage.removeItem('LISTS_USER_FILTER');
    localStorage.removeItem('LISTS_DATE_RANGE_FILTER');

    localStorage.removeItem('USER_PXDATA_CACHED_AT');
    localStorage.removeItem('USER_PXDATA');
    if (this.isRSA) {
      localStorage.removeItem('USER_LISTS_KEY');
    }
    this.loadData();
  }

  filter(isDaysTrigger = false): AVLists[] {
    if (this.showValue || this.companyValue || this.userValue || this.dateValue) {
      localStorage.setItem('CA_CONNECTIONS_KEY', '');
      localStorage.setItem('CA_CONNECTIONS_NAME', '');
      localStorage.setItem('CA_CONNECTIONS_LINKEDINID', '');
      localStorage.removeItem('USER_PXDATA_CACHED_AT');
      localStorage.removeItem('USER_PXDATA');
    }
  
    this.filteredAvlists = this.avlists;
    if (!this.showValue) {
      localStorage.removeItem('LISTS_SHOW_FILTER');
      this.filteredAvlists = this.avlists.filter((item: AVLists) => item.listPrepComplete || item.research_finalized);
    }
  
    if (this.showValue === 'reviewed') {
      localStorage.setItem('LISTS_SHOW_FILTER', this.showValue);
      this.filteredAvlists = this.avlists.filter((item: AVLists) => item.approvedOn > 0);
    }
  
    if (this.showValue === 'r4r') {
      localStorage.setItem('LISTS_SHOW_FILTER', this.showValue);
      if (this.isRSA)
        this.filteredAvlists = this.avlists.filter((item: AVLists) => item.approvedOn === 0 && item.listPrepComplete);
      else this.filteredAvlists = this.avlists.filter((item: AVLists) => item.approvedOn === 0);
    }
    if (this.showValue === 'research_finalized') {
      localStorage.setItem('LISTS_SHOW_FILTER', this.showValue);
      this.filteredAvlists = this.avlists.filter((item: AVLists) => !item.listPrepComplete && item.research_finalized);
    }
    if (this.companyValue) {
      localStorage.setItem('LISTS_COMPANY_FILTER', this.companyValue);
      this.filteredAvlists = this.filteredAvlists.filter((item: AVLists) => item.company === this.companyValue);
    } else {
      localStorage.removeItem('LISTS_COMPANY_FILTER');
    }
  
    if (this.userValue) {
      localStorage.setItem('LISTS_USER_FILTER', this.userValue);
      this.filteredAvlists = this.filteredAvlists.filter((item: AVLists) => item.listFor === this.userValue);
    } else {
      localStorage.removeItem('LISTS_USER_FILTER');
    }
    if (isDaysTrigger && this.isRSA) {
      localStorage.setItem('LISTS_DATE_RANGE_FILTER', this.dateValue);
      this.loadData();
      return this.filteredAvlists; // Return the current filtered list
    }
    
    if (this.dateValue !== '') {
      this.filteredAvlists = this.filteredAvlists.filter((item: AVLists) => (Math.abs(new Date().getTime() - new Date(item.createdAt).getTime()) / 3600000) < parseInt(this.dateValue) * 24);
    }
  
    if (this.excludemostrecentlists) {
      this.filteredAvlists = this.filteredAvlists.filter((item: AVLists) => (Math.abs(new Date().getTime() - new Date(item.createdAt).getTime()) / 3600000) > 7 * 24);
    }
    this.updateStats(this.filteredAvlists);
    console.log(this.filteredAvlists);
    return this.filteredAvlists;
  }

  updateListPrep(avlist, isFinalized = false) {
    this.editId = avlist._id;
    this.isFinalized = isFinalized;
    this.listName = avlist.listName;
    this.listDescription = avlist.listDescription;
    this.listPrepComplete = !avlist.listPrepComplete;
    this.editList();
  }
  openEditModal(avlist) {
    this.editId = avlist._id;
    this.listName = avlist.listName;
    this.listDescription = avlist.listDescription;
    this.listPrepComplete = avlist.listPrepComplete;
    this.editModal.show();
  }

  editList(): void {
   const data:any = { listName: this.listName, listDescription: this.listDescription };
    if (this.isFinalized) {
      data.research_finalized = true;
    } else {
      data.approvedOn = 0;
      data.listPrepComplete = this.listPrepComplete;
    }
    this.isFinalized = false;
    this.userService.editAVlist(this.editId,data).subscribe((res: any) => {
      if (res) {
        this.ngOnInit();
      }

      this.isLoading = false;
      this.editModal.hide();

    }, (err) => {
      this.isLoading = false;
      this.editModal.hide();
      alert('error');
      console.log('error to delete list: ', err);
    });
  }

  openDeleteModal(deleteId) {
    this.deleteId = deleteId;
    this.deleteModal.show();
  }

  deleteList() {
    this.userService.deleteAVlist(this.deleteId).subscribe((res: any) => {
      if (res) {
        this.ngOnInit();
      };

      this.isLoading = false;
      this.deleteModal.hide();

    }, (err) => {
      this.isLoading = false;
      this.deleteModal.hide();
      alert('error');
      console.log('error to delete list: ', err)
    });
  }

  openProfileModal(avlist) {
    if (this.isRSA) {
      this.isLoading = true;
      this.selectedList = avlist;

      this.userService.getProfileURLs(avlist._id).subscribe((res: any) => {
        if (res) {
          this.profileURLs = res.map(value => value.profileURL);
          this.profileModal.show();
        };
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to get profile URLS: ', err)
      });
    }
  }

  listContacts(list, type) {
    localStorage.removeItem('LIST_CONTACTS_VIEW_TYPE');

    if (list._id != localStorage.getItem('LIST_CONTACTS_KEY')) {
      localStorage.removeItem('LIST_AVDATA_CACHED_AT');
    }

    switch (type) {
      case 'all':
        localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', '');
        break;
      case 'approved':
        localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', 'Y');
        break;
      case 'not-approved':
        localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', 'N');
        break;
    }

    localStorage.setItem('LIST_CONTACTS_KEY', list._id);
    localStorage.setItem('LIST_CONTACTS_NAME', list.name);
    localStorage.setItem('LIST_CONTACTS_COMPANY', list.company);
    localStorage.setItem('LIST_CONTACTS_LISTNAME', list.listName);

    this.router.navigate(['/listContacts']);
  }

  listReviewbylist(list: AVLists): void {
    if (list._id != localStorage.getItem('USER_REVIEW_LISTKEY')) {
      localStorage.removeItem('USER_AVDATA_CACHED_AT');
    }

    localStorage.setItem('USER_REVIEW_KEY', list.listFor);
    localStorage.setItem('USER_REVIEW_LISTKEY', list._id);
    localStorage.setItem('USER_REVIEW_NAME', list.name);

    if (this.isEU) {
      this.router.navigate(['/review']);
      return;
    }

    this.router.navigate(['/userReview']);
  }

  exportList(list) {
    localStorage.removeItem('LIST_AVDATA_CACHED_AT');

    this.userService.getAVDataByListId(list._id, localStorage.getItem('USER_KEY')).subscribe((res) => {
      const con = [];
      let csvContent = 'data:text/csv;charset=utf-8,';
      Object.keys(res).forEach((data) => {
        const val = res[data];
        con.push(val);
      });

      const title = `Status, Rejection Reason, Company Name, First Name, Last Name, Title, Profile Url, Email, Phone, Location, Company Start Date, Tenure, Connected On, Industry, Mutual Connections`;
      csvContent += title + '\r\n';

      con.forEach(function (rowArray) {
        const row = '"' + rowArray.approved + '","' + (rowArray.rejectionReason||"N/A") + '","' + rowArray.companyName + '","' + rowArray.firstName + '","'
          + rowArray.lastName + '","' + rowArray.title + '","'
          + rowArray.profileURL + '","' + rowArray.email + '","'
          + rowArray.phone + '","' + rowArray.location + '","'
          + rowArray.yearsAtCompany + '","' + rowArray.since1 + '","' + rowArray.connectedOn
          + '","' + rowArray.industry + '","' + rowArray.mutualConnections + '"';
        csvContent += row + '\r\n';
      });

      csvContent = csvContent.replace(/#/g, '');
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);

      const d = new Date();
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      const _d = `${mo}-${da}-${ye}`;

      link.setAttribute('download', `${list.company} - ${list.name} (${list.listName}) - Exported on ${_d}.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
      this.isLoading = false;

    }, (err) => {
      alert('error');
      console.log('error to get user av data: ', err);
    });
  }

  isTwoDayGapCheck(date: number): boolean {
    if (date === 0) {
      return false;
    }

    let diff = Math.abs(new Date().getTime() - new Date(date).getTime()) / 3600000;
    if (diff > 48) { return true; }

    return false;
  }

}
