<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="container campaigns" *ngIf="!isLoading">
    <h3 class="text-danger">Campaign Review</h3>

    <div class="input">
        <label>Name</label>: <span>{{campaignInfo.campaignName}}</span><br>
        <label>Type</label>: <span>{{campaignInfo.campaignType}}</span><br>
        <label>Description</label>: <span>{{campaignInfo.campaignDescription}}</span><br>
    </div>

    <h3 class="text-danger">Strategy</h3>

    <div *ngIf="campaignInfo.campaignType === 'Message Only'">
        <div class="input">
            <label>Who would you like RMD to message?</label><br>
            <span *ngIf="strategy.msgTargetType === 'msgTargetCampaign'">Connections from a campaign</span><br>
            <span *ngIf="strategy.msgTargetType === 'msgTargetCampaign'">{{messageToWhoCampaignName}}</span>
            <span *ngIf="strategy.msgTargetType === 'msgTargetList'">Connections from a list I created</span><br>
            <span *ngIf="strategy.msgTargetType === 'msgTargetList'">{{strategy.msgTargetList}}</span>
            <span *ngIf="strategy.msgTargetType === 'msgTargetNote'">Other</span><br>
            <span *ngIf="strategy.msgTargetType === 'msgTargetNote'">{{strategy.msgTargetNote}}</span>
        </div>
    </div>

    <div *ngIf="campaignInfo.campaignType === 'Invite, Connect and Message'">

        <div class="input">
            <label>How would you like RMD to research prospects?</label><br>
            <span *ngIf="strategy.research === 'wizard'">Use RMD's wizard</span>
            <span *ngIf="strategy.research === 'savedSearch'">Use a saved search in Sales Navigator</span><br>
            <span *ngIf="strategy.research === 'savedSearch'">{{strategy.savedSearchUrl}}</span>
        </div>

        <div *ngIf="strategy.research === 'wizard'">

            <div class="input">
                <label>Countries/Regions</label><br>
                <span *ngIf="strategy.geoCountry">{{strategy.geoCountry}}</span><br>
                <span *ngFor="let geoLocation of strategy.geoLocations; let i = index">
                    {{geoLocation.geoName}}<span *ngIf="i != strategy.geoLocations.length">,</span>
                </span><br *ngIf="strategy.geoLocations.length > 0">
                <span *ngIf="strategy.geoLocationsMore">{{strategy.geoLocationsMore}}</span><br>
            </div>

            <div class="input">
                <label>Industries</label><br>
                <span *ngFor="let industry of strategy.industries; let i = index">
                    {{industry.industryName}}<span *ngIf="i != strategy.industries.length - 1">,</span>
                </span>
            </div>

            <div class="input">
                <label>Company Size</label><br>
                <span *ngFor="let companySize of strategy.companySize; let i = index">
                    {{companySize}}<span *ngIf="i != strategy.companySize.length - 1">,</span>
                </span>
                <span *ngIf="!strategy.companySizeEnable">No limit specified</span>
            </div>

            <div class="input">
                <label>Job Titles</label><br>
                <span *ngFor="let jobTitle of strategy.jobTitles; let i = index">
                    {{jobTitle.positionName}}<span *ngIf="i != strategy.jobTitles.length - 1">,</span>
                </span>
            </div>

            <div class="input">
                <label>Limit research based on the number of years of current company?</label><br>
                <span *ngFor="let companyTenure of strategy.companyTenure; let i = index">
                    {{companyTenure}}<span *ngIf="i != strategy.companyTenure.length - 1">,</span>
                </span>
                <span *ngIf="!strategy.companyTenureEnable">No limit specified</span>
            </div>

            <div class="input">
                <label>Limit research based on the number of years in current position?</label><br>
                <span *ngFor="let positionTenure of strategy.positionTenure; let i = index">
                    {{positionTenure}}<span *ngIf="i != strategy.positionTenure.length - 1">,</span>
                </span>
                <span *ngIf="!strategy.positionTenureEnable">No limit specified</span>
            </div>

        </div>

    </div>

    <h3 class="text-danger">Message Sequence</h3>

    <div class="input message" *ngFor="let message of previewMessages; let i=index;">
        <span *ngIf="message.delay">
            <i class="far fa-clock"></i>
            <span class="ml-2"
                *ngIf="i>0 && previewMessages[i-1].messageType === 'Connection Request'; else templateName">
                Wait {{message.delay}} days after the connection is accepted
            </span>
        <ng-template #templateName>
            <span class="ml-2">Wait {{message.delay}} days after the previous message is sent</span>
        </ng-template>
        </span>

        <br *ngIf="message.delay" />

        <label>
            <i class="far fa-comment mr-1"></i>
            {{message.messageType}} Message
        </label>

        <br>

        <span class="message">
            {{message.message}}
        </span>
    </div>

    <div class="buttons">
        <button type="submit" (click)="backClicked()">Done</button>
    </div>

</div>