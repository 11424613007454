import { Router } from '@angular/router';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { Message } from '../../../models/message';
import { UserService } from '../../../services/user.service';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { SlackService } from 'src/app/services/slack.services';

@Component({
  selector: 'app-messages-beta',
  templateUrl: './ca-messages-new.component.html',
  styleUrls: ['./ca-messages-new.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class CaMessagesNewComponent implements OnInit {
  @ViewChild('confirmModal', { static: true }) showConfirmModalOnClick: ModalDirective;
  @ViewChild('informModal', { static: true }) showInformModalOnClick: ModalDirective;
  gridApi;
  rows = [];
  select = '';
  totalRecords=0;
  skip=0;count=25;
  gridColumnApi;
  cars: any = [];
  isLoading = true;
  rowGroupMetadata: any;
  leadStatusOptions=[];
  userLatestMessage: Message[] = [];
  noDataText = 'No messages right now';
  rowData: any;
  users=[];
  cols = [
    { field: 'prospect', header: 'Prospect' },
    { field: 'last_incoming_message', header: 'Last Incoming Message' },
    { field: 'createdAt', header: 'Date/Time' },
  ];

  constructor(private slackService: SlackService, @Inject(DOCUMENT) document: Document, private userService: UserService, private router: Router) { }

  ngOnInit() {
    //this.fetchConversations()
    this.userService.getUserByKey(localStorage.getItem('USER_KEY')).subscribe(user => {
      if(user.leadStatusOptions.length==0){
        this.leadStatusOptions = ["",0,1,2,3,4];
      }
      else this.leadStatusOptions = [""].concat(user.leadStatusOptions);
    });;
    this.userService.getCAUsers().subscribe((res) => {
      this.users = res.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      if (localStorage.getItem('CA_MESSAGE_KEY')) {
        this.select = localStorage.getItem('CA_MESSAGE_KEY');// + ',' + localStorage.getItem('CA_MESSAGE_LINKEDINID');
        this.selectUser();
      } else {
        this.isLoading = false;
      }

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get ca users: ', err);
    });  
  }
  selectUser() {
    if (this.select) {
      this.isLoading = true;
      this.fetchConversations();
      return;
      this.userService.getUserMessages(this.select).subscribe((messages) => {
        this.cars = messages;
        localStorage.setItem('CA_MESSAGE_KEY', '');
        localStorage.setItem('CA_MESSAGE_NAME', '');
        localStorage.setItem('CA_MESSAGE_LINKEDINID', '');
        localStorage.removeItem('USER_MESSAGES_CACHED_AT');
        localStorage.removeItem('USER_MESSAGES');

        this.isLoading = false;

      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to get user latest messages: ', err);
      });
    }
  }
  fetchConversations(){
    this.isLoading = true;
    let d = this.select.split("-");
    console.log(this.select)
    this.userService.getUserBetaMessages(d[0], d[1],this.skip,this.count).subscribe((data:any) => {
      this.isLoading = false;
      this.cars = data;
      //this.totalRecords = parseInt(data.totalRecords);
      
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get user lastest messages: ', err);
    });
  }
  sendStopMessageSlack(message: string) {
    this.slackService.stopSlackMessage({ "text": message, "channel": 'messaging-alerts', "username": 'Messaging Bot' }).subscribe()
  }
  onPage(evt){
    console.log(evt)
    this.skip = parseInt(evt.first);
    this.count = parseInt(evt.rows);
    this.fetchConversations();
  }
  actionOnStopMessage(isYes) {
    if (isYes) {
      let elem = document.getElementById(this.rowData.id);
      let data: any = {};
      data.clientCompany = localStorage.getItem('USER_COMPANY');
      data.clientName = localStorage.getItem('USER_NAME');
      data.prospectName = this.rowData.contact;
      data.prospectLinkedinUrl = this.rowData.contactURL;
      elem.classList.add('msg-red');
      let msg = `*** Stop Messaging Sequence ***
      ${data.clientCompany} - ${data.clientName}
      ${data.prospectName} - ${data.prospectLinkedinUrl}
      Requested at: ${moment().format('MM/DD/YYYY hh:mm:ss A')}`;
      this.sendStopMessageSlack(msg);
      this.rowData.stopmsg = true;
      this.userService.stopMessage(this.rowData.pxDataId).subscribe(res => {
        console.log(res)
      })
    }
    this.showConfirmModalOnClick.hide();
  }
  stopMessaging(rowData) {
    this.rowData = rowData;
    console.log(rowData)
    if (!this.rowData.stopmsg)
      this.showConfirmModalOnClick.show();
    else {
      this.showInformModalOnClick.show();
    }
  }
}
