import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var StepThreeComponent = /** @class */ (function () {
    function StepThreeComponent(fb, strategyService, router) {
        this.fb = fb;
        this.strategyService = strategyService;
        this.router = router;
        this.dropdownList = [];
        this.clickByDoneButton = false;
        this.editMode = localStorage.getItem("EDIT_STRATEGY") ? true : false;
        this.companySizeOptions = [
            "Self-employed",
            "1-10 employees",
            "11-50 employees",
            "51-200 employees",
            "201-500 employees",
            "501-1000 employees",
            "1001-5000 employees",
            "5001-10,000 employees",
            "10,001+ employees",
        ];
        this.stepThreeForm = this.fb.group({
            userId: localStorage.getItem('USER_KEY'),
            industries: this.fb.control([], Validators.required),
            companySizeEnable: this.fb.control(false),
            companySize: this.fb.control([])
        });
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'industryCode',
            textField: 'industryName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }
    StepThreeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.strategyService.getIndustries().subscribe(function (industries) {
            _this.dropdownList = industries;
        }, function (err) {
            alert('error');
            console.log(err);
        });
        if (this.strategy) {
            this.stepThreeForm = this.fb.group({
                userId: localStorage.getItem('USER_KEY'),
                strategyId: this.fb.control(this.strategy._id, Validators.required),
                industries: this.fb.control(this.strategy.industries, Validators.required),
                companySizeEnable: this.fb.control(this.strategy.companySizeEnable),
                companySize: this.fb.control(this.strategy.companySize)
            });
        }
    };
    StepThreeComponent.prototype.stepThreeSubmit = function () {
        var _this = this;
        console.log(this.stepThreeForm);
        if (this.stepThreeForm.valid) {
            this.strategyService.submitStrategy(this.stepThreeForm.value).subscribe(function (res) {
                console.log(res);
                if (_this.clickByDoneButton) {
                    _this.router.navigate(['campaigns']);
                }
            }, function (err) {
                alert('error');
                console.log(err);
            });
        }
    };
    StepThreeComponent.prototype.onItemSelect = function (item) {
        // console.log(item);
    };
    StepThreeComponent.prototype.onSelectAll = function (items) {
        // console.log(items);
    };
    StepThreeComponent.prototype.getCheckboxesValue = function (event, company) {
        if (!this.stepThreeForm.value.companySize.includes(company)) {
            this.stepThreeForm.value.companySize.push(company);
        }
        else {
            var index = this.stepThreeForm.value.companySize.indexOf(company);
            if (index > -1) {
                this.stepThreeForm.value.companySize.splice(index, 1);
            }
        }
    };
    StepThreeComponent.prototype.cancelNewCampaign = function () {
        this.router.navigate(['campaigns']);
    };
    StepThreeComponent.prototype.clickDoneButton = function () {
        this.clickByDoneButton = true;
    };
    return StepThreeComponent;
}());
export { StepThreeComponent };
