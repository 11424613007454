<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="actions">
    <span class="mr-3">Status</span>
    <select class="browser-default custom-select" style="width: 200px;" [(ngModel)]="statusValue" (change)="filter()">
        <option value=""> All</option>
        <option value="MEMBER">Member</option>
        <option value="REQUEST_PENDING">Request Pending</option>
    </select>

    <fa name="fas fa-download" class="ml-3 download-icon" (click)="exportData()" title="Dropdown List"></fa>
</div>

<div class="table-responsive">
    <table class="table table-striped" [mfData]="filteredGroups" #mf="mfDataTable" [(mfSortBy)]="sortBy"
        [(mfSortOrder)]="sortOrder" [mfRowsOnPage]="pageSize">
        <thead>
            <tr>
                <th>
                    <mfDefaultSorter by="groupLogo"></mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="groupName">Group Name</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="groupDescription">Description </mfDefaultSorter>
                </th>
                <th class="text-center">
                    <mfDefaultSorter by="memberCount">Members </mfDefaultSorter>
                </th>
                <th class="text-center">
                    <mfDefaultSorter by="groupCreatedOn">Age</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="status">Status</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="joinedOn">Joined</mfDefaultSorter>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let group of mf.data; let i = index">
                <td>
                    <div *ngIf="group.groupLogo"><img src="{{group.groupLogo}}"></div>
                </td>
                <td>
                    <a href="https://www.linkedin.com/groups/{{group.groupId}}" target="_blank">{{ group.groupName}}</a>
                </td>
                <td title="{{group.groupDescription}}">{{ (group.groupDescription.length>200)? (group.groupDescription |
                    slice:0:200)+'..':(group.groupDescription) }} </td>
                <td class="text-center"> {{group.memberCount | number:'1.0':'en-US'}} </td>
                <td class="text-center">{{getAgeOfGroup(group.groupCreatedOn)}}</td>
                <td>
                    <span *ngIf="group.status === 'MEMBER'">member</span>
                    <span *ngIf="group.status === 'REQUEST_PENDING'">pending</span>
                </td>
                <td>{{group.joinedAt | date : 'MM/dd/yy'}} </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="4">
                    <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                </td>
            </tr>
        </tfoot>
    </table>
</div>