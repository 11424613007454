export class PXData {
  id: string;
  messages: any;
  title: string;
  since: string;
  email: string;
  phone: string;
  avatar: string;
  listId: string;
  since1: number;
  user_id: string;
  convoUrl: string;
  fullName: string;
  approved: string;
  lastName: string;
  location: string;
  checked: boolean;
  industry: string;
  member_id: string;
  lastViewedOn: number;
  firstName: string;
  profileURL: string;
  approvedOn: number;
  companyName: string;
  connectedOn: string;
  lastMessage: string;
  tempSelected: boolean;
  yearsAtCompany: string;
  connectedOnDate: string;
  lastMessageDate: string;
  lastMessageDate1: string;
  companyProfileURL: string;
  mutualConnections: string;
  ecEmail: string;
  ecEmailUpdatedOn: string;
  status: number;
  campaignStatus: number;
  user: "";
  ckId: string;
  archetype: string;
  discType: string;
  degrees: number;
  intensity: number;
  p4d: number;
  p4i: number;
  p4s: number;
  p4c: number;
  qualities: [];
  rejectionReason: string;
  organization_location_1: string;
  organization_industry_1: string;
  organization_website_1: string;
  organization_headcount_1: number;
  companyId: string;
  followers: string;
  avdata_checked: boolean;
  from_avdata: boolean;
}
