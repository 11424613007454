<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="row" style="margin-top:10px;margin-left:0px;">
    <input type="text" (keypress)="keyPressed($event)" style="width:300px;margin-top:10px;" class="form-control" id="search" name="search" [(ngModel)]="searchByCompany" placeholder="Filter by company name">
    <button type="button" (click)="search()" class="btn btn-primary"><i class="fa fa-search"></i> Filter</button>
    <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="openToAdd()" mdbWavesEffect><i
            class="fa fa-plus"></i> Add New Company</button>
</div>

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">Company Information</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-sm-12">
                        <label for="companyName">Company name</label>
                        <input type="text" class="form-control" name="companyName" id="companyName" [(ngModel)]="company.companyName" placeholder="Enter company name">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect (click)="saveCompany()">Save changes</button>
            </div>
        </div>
    </div>
</div>

<div class="table-responsive">
    <table class="table">
        <thead id="tablebackground">
            <tr>
                <th>Company Name</th>
                <th>Action</th>
            </tr>
        </thead>
        <tr *ngFor="let company of companies">
            <td>{{company.companyName}}</td>
            <td> <button type="button" class="btn btn-warning " (click)="openToEdit(company)" style="margin-right: 5px;">Edit</button>
                <button type="button" class="btn btn-danger" (click)="remove(company)">Delete</button>
            </td>
        </tr>
    </table>
</div>