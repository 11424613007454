import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SequenceService } from 'src/app/services/sequence.service';

@Component({
  selector: 'app-message-template',
  templateUrl: './message-template.component.html',
  styleUrls: ['./message-template.component.scss']
})
export class MessageTemplateComponent implements OnInit {

  index = -1;
  isLoading = true;
  defaultDelay = 1;
  dropdownList = [];
  previewMessages = [];
  stepTwoForm: FormGroup;
  clickByDoneButton = false;
  editMode = localStorage.getItem("EDIT_SEQUENCE") ? true : false;

  constructor(private fb: FormBuilder, public sequenceService: SequenceService, public router: Router, private location: Location) {
    this.stepTwoForm = this.fb.group({
      messageType: this.fb.control('', Validators.required),
      sequenceId: this.fb.control('', Validators.required),
      message: this.fb.control('', Validators.required),
      draft: this.fb.control(false),
      delay: this.fb.control(1)
    });
  }

  ngOnInit(): void {
    const campaignInfo = localStorage.getItem('CAMPAIGN_INFO');

    if (!campaignInfo) {
      this.router.navigate(['campaigns']);
      return;
    }

    this.sequenceService.getMessageTemplates().subscribe((messageTemplates) => {
      this.dropdownList = messageTemplates;
    }, (err) => {
      alert('error');
      console.log(err);
    });

    const messageInfo = localStorage.getItem('TEMPLATE_MESSAGE_INFO');

    if (messageInfo) {
      this.stepTwoForm = this.fb.group({
        messageType: this.fb.control(JSON.parse(messageInfo).messageType, Validators.required),
        sequenceId: this.fb.control(JSON.parse(campaignInfo).sequenceId, Validators.required),
        message: this.fb.control(JSON.parse(messageInfo).message, Validators.required),
        draft: this.fb.control(JSON.parse(messageInfo).draft),
        delay: this.fb.control(JSON.parse(messageInfo).delay),
        _id: this.fb.control(JSON.parse(messageInfo)._id)
      });
    } else {
      this.stepTwoForm = this.fb.group({
        messageType: this.fb.control('', Validators.required),
        sequenceId: this.fb.control(JSON.parse(campaignInfo).sequenceId, Validators.required),
        message: this.fb.control('', Validators.required),
        draft: this.fb.control(false),
        delay: this.fb.control(1)
      });
    }

    this.sequenceService.getMessages(JSON.parse(campaignInfo).sequenceId).subscribe((res) => {
      this.previewMessages = res;
      if (this.previewMessages.length === 0) {
        this.defaultDelay = 0;
      }
      this.isLoading = false;
    }, (err) => {
      alert('error');
      console.log(err);
    });
  }

  stepTwoSubmit(): void {
    console.log(this.stepTwoForm);
    if (this.stepTwoForm.valid) {
      this.isLoading = true;
      this.sequenceService.submitSequence(this.stepTwoForm.value).subscribe((res) => {
        console.log(res);
        if (this.clickByDoneButton) {
          this.router.navigate(['campaigns']);
        } else {
          localStorage.setItem("TEMPLATE_MESSAGE_INFO", JSON.stringify(res));
          this.isLoading = false;
          this.router.navigate(['message-preview']);
        }
      }, (err) => {
        alert('error');
        console.log(err);
      });
    }
  }

  counter(i: number): any[] {
    return new Array(i);
  }

  changeMessageType(): void {
    let index = -1;
    const val = this.stepTwoForm.value.messageType;

    this.dropdownList.find((item, i) => {
      if (item.messageType === val) {
        index = i;
        return true;
      }
      return false;
    });

    this.index = index;

    if (index !== -1) {
      this.defaultDelay = this.dropdownList[index].days;
    }
  }

  cancelNewCampaign(): void {
    this.router.navigate(['campaigns']);
  }

  back(): void {
    const campaignInfo = localStorage.getItem('CAMPAIGN_INFO');

    if (!campaignInfo) {
      this.router.navigate(['campaigns']);
    } else if (JSON.parse(campaignInfo).campaignType === "Message Only") {
      this.router.navigate(['message-send-who']);
    } else if (JSON.parse(campaignInfo).campaignType === "Invite, Connect and Message") {
      this.router.navigate(['connection-request']);
    }
  }

  getCheckboxesValue(event): void {
    this.stepTwoForm.value.draft = event.checked;

    if (!event.checked) {
      this.stepTwoForm.get('message').setValidators([Validators.required]);
    } else {
      this.stepTwoForm.get('message').clearValidators();
    }

    this.stepTwoForm.controls["message"].updateValueAndValidity();
  }

  clickDoneButton(): void {
    this.clickByDoneButton = true;
  }

}