export class User {
  logs: any;
  _id: string;
  name: string;
  role: string;
  myId: string;
  note: string;
  email: string;
  role_title: any;
  industry: any[];
  company: string;
  geography: any[];
  password: string;
  memberId: string;
  r4rCount: number;
  companysize: any;
  initials: string;
  reseacher: string;
  look_audince: any;
  isHidden: boolean;
  isLila: boolean;
  groups: boolean;
  leadStatusOptions:any;
  isViewedprofilesActive:boolean=false;
  additionalTag: any;
  role_senority: any;
  lastSentMsg: number;
  role_functions: any;
  pxUpdatedOn: number;
  avUpdatedOn: number;
  msgUpdateOn: number;
  googleDocURL: string;
  selfserve: boolean;
  lastMsgCheck: string;
  incomingMsgs: number;
  isResearcher: boolean;
  lastApprovedOn: number;
  notifications: boolean;
  lastIncomingMsg: number;
  additional_keyword: any;
  role_year_experince: any;
  connectionsCount: number;
  linkedInProfileId: string;
  lastApprovedCount: number;
  role_current_company: any;
  role_current_position: any;
  assignedResearcher: string;
  strategy_action_massage: any;
  unReviewedListsNumber: number;
  strategy_nurture_massage: any;
  strategy_invation_massage: any;
  lastApprovedPercentage: number;
  lastDisapprovedProfileUrls: string[];
}
