<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<h4>
    <span *ngIf="!isEU">
        <fa name="user"></fa> {{listContactsName}} -
    </span> {{listContactsListName}}
    <fa name="fas fa-download" class="ml-2 download-icon" (click)="exportData()" title="Dropdown List"></fa>
</h4>

<ag-grid-angular #agGrid (gridReady)="onGridReady($event)"  style="width: 100%; height: calc(100% - 52px)" [defaultColDef]="defaultColDef" [enableColResize]="true" class="ag-theme-alpine" [rowData]="rows" [columnDefs]="columnDefs"
    [enableSorting]="true" [enableFilter]="true" [overlayLoadingTemplate]="loadingTemplate" [overlayNoRowsTemplate]="noRowsTemplate">
</ag-grid-angular>

<div mdbModal style="overflow-y: auto" #deleteModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Delete Record </p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body edit-list" *ngIf="deleteId">
                Are you sure you want to delete this record?
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="deleteRecord()">Yes</a>
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="deleteModal.hide()">No</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>