<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="d-flex align-items-center">
    <span class="mr-3">Company</span>
    <select class="browser-default custom-select" style="width: 250px;" [(ngModel)]="companyValue" (change)="filter()">
        <option selected value=""> All</option>
        <option *ngFor="let company of companies" value="{{company}}">{{company}}</option>
    </select>
    <span class="ml-5 mr-3">User</span>
    <select class="browser-default custom-select" style="width: 250px;" [(ngModel)]="userValue" (change)="filter()">
        <option selected value=""> All</option>
        <option *ngFor="let user of dropdownUsers" value="{{user._id}}">{{user.name}}</option>
    </select>
</div>

<div class="table-responsive" *ngIf="filteredUsers">
    <table class="table table-striped" [mfData]="filteredUsers" #mf="mfDataTable">
        <thead>
            <tr>
                <th>
                    <mfDefaultSorter by="company">Company Name</mfDefaultSorter>
                </th>
                <th>
                    <mfDefaultSorter by="name">User Name</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="lastApprovedOn">Approved</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="lastApprovedPercentage">Approved</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="avUpdatedOn">Last Up</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="r4rCount">R4R</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="connectionsCount">Conn.</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="pxUpdatedOn">Updated On</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="incomingMsgs">Msgs</mfDefaultSorter>
                </th>
                <th class="text-center custom-margin-right">
                    <mfDefaultSorter by="lastMsgCheck">Last Msg Check</mfDefaultSorter>
                </th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of mf.data">
                <td>{{user.company}}</td>
                <td>{{user.name}}</td>
                <td class="text-center" [ngClass]="{'highlighted':isTwoDayGapWithR4RCheck(user.lastApprovedOn, user.r4rCount)}" [title]="user.lastApprovedOn | date : 'MM/dd/yy hh:mm aaa'">
                    <span *ngIf="user.lastApprovedOn > 0">{{user.lastApprovedOn | date : 'MM/dd/yy'}}</span>
                </td>
                <td class="text-center">
                    <span data-toggle="modal" data-target="#basicExample" mdbWavesEffect *ngIf="user.lastApprovedCount">
                        {{user.lastApprovedCount}} ({{user.lastApprovedPercentage }}%)
                    </span>
                </td>
                <td class="text-center" [ngClass]="{'highlighted':isEightDayGapCheck(user.avUpdatedOn)}" [title]="user.avUpdatedOn | date : 'MM/dd/yy hh:mm aaa'">
                    <span *ngIf="user.avUpdatedOn > 0">{{user.avUpdatedOn | date : 'MM/dd/yy'}}</span>
                </td>
                <td class="text-center">
                    <a *ngIf="user.r4rCount && user.r4rCount > 0" class="mr0" href="javascript:void(0)" (click)="userReview(user)" title="Ready for Review">
                        {{user.r4rCount}}
                    </a>
                    <span *ngIf="user.unReviewedListsNumber > 1">(</span>
                    <a *ngIf="user.r4rCount && user.r4rCount && user.unReviewedListsNumber > 1 " class="mr0" href="javascript:void(0)" (click)="userLists(user)" title="Lists">
                        {{user.unReviewedListsNumber}}
                    </a>
                    <span *ngIf="user.unReviewedListsNumber > 1">)</span>
                </td>
                <td class="text-center">{{user.connectionsCount}}</td>
                <td class="text-center" [ngClass]="{'highlighted':isTwoDayGapCheck(user.pxUpdatedOn)}" [title]="user.pxUpdatedOn | date : 'MM/dd/yy hh:mm aaa'">
                    <span *ngIf="user.pxUpdatedOn > 0">{{user.pxUpdatedOn | date : 'MM/dd/yy'}}</span>
                </td>
                <td class="text-center">{{user.incomingMsgs}}</td>
                <td class="text-center" [ngClass]="{'highlighted':isTwoDayGapCheck(user.lastMsgCheck)}">
                    <span *ngIf="user.lastMsgCheck">{{user.lastMsgCheck | date : 'MM/dd/yy hh:mm aaa'}}</span>
                </td>
                <td>
                    <a href="javascript:void(0)" title="Connections">
                        <fa name="link" (click)="userConnections(user)"></fa>
                    </a>
                    <a href="javascript:void(0)" title="Groups">
                        <i class="fas fa-people-arrows" (click)="userGroups(user)"></i>
                    </a>
                    <a href="javascript:void(0)" title="Messages" (click)="userMessage(user)">
                        <fa name="comments"></fa>
                    </a>
                    <a href="javascript:void(0)" title="Lists" (click)="userLists(user)">
                        <fa name="far fa-list-alt"></fa>
                    </a>
                    <a href="javascript:void(0)" (click)="userReviewed(user)" title="Reviewed">
                        <fa name="check"></fa>
                    </a>
                    <a *ngIf="user.r4rCount && user.r4rCount > 0" href="javascript:void(0)" (click)="userReview(user)" title="Ready for Review">
                        <fa name="user-o"></fa>
                    </a>
                    <a *ngIf="user.selfserve" href="javascript:void(0)" (click)="userCampaigns(user)" title="Client Campaigns">
                        <fa name="fas fa-portrait"></fa>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div mdbModal style="overflow-y: auto" #modal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-fluid modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Last Approved Profile Urls </p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body" *ngIf="selectedUser">
                <ul class="list-group">
                    <!-- <li class="list-group-item" *ngFor="let url of selectedUser.lastApprovedProfileUrls">{{url}}</li> -->
                </ul>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="modal.hide()">Close</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>