import { Component, OnInit } from '@angular/core';

import { dateFilterParams } from 'src/app/helpers/dateFilterParams';

import { Message } from '../../../models/message';
import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.scss']
})
export class UserMessageComponent implements OnInit {
  gridApi;
  rows = [];
  gridColumnApi;
  isLoading = true;
  userLatestMessage: Message[] = [];
  noDataText = 'No messages right now';
  userMessageName = localStorage.getItem('USER_MESSAGE_NAME');

  columnDefs = [
    {
      width: 150,
      sort: 'desc',
      maxWidth: 150,
      minWidth: 150,
      sortable: true,
      field: 'createdAt',
      headerName: 'Date/Time',
      // filter: "agDateColumnFilter",
      // filterParams: dateFilterParams,
      cellRenderer: function (params) {
        return `${params.data.tstamp}`;
      }
    },
    {
      width: 75,
      maxWidth: 75,
      minWidth: 75,
      field: 'av_id',
      sortable: true,
      headerName: 'RMD',
      filter: "agTextColumnFilter",
      cellStyle: { 'text-align': 'center' },
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      cellRenderer: function (params) {
        if (params.data.av_id === 'NO') {
          return `<i class="fas fa-tint-slash" style="color: #c1e2f3"></i>`;
        }
        return '<i class="fas fa-tint" style="color: #1eca3c"></i>';
      }
    },
    {
      width: 200,
      maxWidth: 200,
      minWidth: 200,
      sortable: true,
      field: 'contact',
      headerName: 'Contact',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      cellRenderer: function (params) {
        return `<a href="${params.data.contactURL}" target="_blank">${params.data.contact}</a>`;
      }
    },
    {
      width: 200,
      maxWidth: 200,
      minWidth: 200,
      sortable: true,
      field: 'company',
      headerName: 'Company',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      cellRenderer: function (params) {
        if (params.data.company_url) {
          return `<a href="${params.data.company_url}" target="_blank">${params.data.company}</a>`;
        }
        return '';
      }
    },
    {
      width: 75,
      maxWidth: 75,
      minWidth: 75,
      headerName: '',
      cellStyle: { 'text-align': 'center' },
      cellRenderer: function (params) {
        return `<a style="color:black;text-decoration:none;" href="https://www.linkedin.com/messaging/thread/${params.data.thread}/"
        target="_blank"><i class="fa fa-comments-o"></i></a>`;
      }
    },
    {
      field: 'body',
      minWidth: 200,
      sortable: true,
      headerName: 'Message',
      cellClass: "cell-wrap-text",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    }
  ];

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.userService.getUserMessages(localStorage.getItem('USER_MESSAGE_KEY')).subscribe((result) => {
      this.rows = result;
      this.isLoading = false;

    }, (err) => {
      alert('error');
      this.isLoading = false;
      console.log('error to get user messages: ', err);
    });
  }

  onColumnResized(event) {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();

    setTimeout(function () {
      params.api.resetRowHeights();
    }, 500);
  }

}
