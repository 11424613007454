export const editorConfig = {
    toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        { name: 'links', groups: ['links'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
        { name: 'forms', groups: ['forms'] },
        '/',
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'insert', groups: ['insert'] },
        '/',
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] }
    ],
    removeButtons: 'About,Maximize,Source,Save,Templates,Find,SelectAll,Scayt,Form,Checkbox,Radio,TextField,HiddenField,ImageButton,Button,Select,Textarea,Superscript,Subscript,Strike,Underline,TextColor,Styles,Image,Flash,Table,Anchor,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,ShowBlocks,Format,Font,FontSize,JustifyBlock,JustifyRight,JustifyCenter,JustifyLeft,BidiRtl,Language,BidiLtr,CreateDiv,Indent,Outdent,Blockquote,NumberedList,Italic,Bold,CopyFormatting,RemoveFormat,NewPage,Preview,Print,Replace'
};