import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ClientService = /** @class */ (function () {
    function ClientService(http) {
        this.http = http;
    }
    ClientService.prototype.getBaseUrl = function () {
        return environment.baseUrl;
    };
    ClientService.prototype.getUrl = function (relativeUrl) {
        return this.getBaseUrl() + relativeUrl;
    };
    ClientService.prototype.getOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    ClientService.prototype.getClients = function () {
        var url = this.getUrl('/clients');
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var clients = [];
            querySnapshot.forEach(function (data) {
                clients.push({
                    _id: data._id,
                    fname: data.fname,
                    lname: data.lname,
                    email: data.email,
                    phone: data.phone,
                    uname: data.uname,
                    Linkedin: data.Linkedin
                });
            });
            return clients;
        }));
    };
    ClientService.prototype.deleteClient = function (client) {
        var url = this.getUrl('/clients/' + client._id);
        return this.http.delete(url, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    ClientService.prototype.editClient = function (client) {
        var url = this.getUrl('/clients/' + client._id);
        return this.http.put(url, {
            fname: client.fname,
            lname: client.lname,
            email: client.email,
            phone: client.phone,
            uname: client.uname,
            Linkedin: client.Linkedin
        }, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    ClientService.prototype.addClient = function (client) {
        var url = this.getUrl('/clients');
        return this.http.post(url, client, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    ClientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientService_Factory() { return new ClientService(i0.ɵɵinject(i1.HttpClient)); }, token: ClientService, providedIn: "root" });
    return ClientService;
}());
export { ClientService };
