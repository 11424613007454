import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavbarComponent as navmdb } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { getUserRole } from 'src/app/helpers/getUserRole';

import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild('basicModal', { static: true }) showModalOnClick: ModalDirective;
  @ViewChild('navbarid', { static: true })

  private navbaridRef: navmdb;

  to: any;
  from: any;
  directionS=true;
  directionI=false;
  userName;
  isLoading;
  havedocurl;
  googleDocURL;
  users: User[];
  isAdmin = false;
  groups = false;
  isViewedprofilesActive=false;
  userValue = 'all';
  isCollapsed = true;
  isLoggedIn: boolean;
  isSuperAdmin = false;
  isCompanyAdmin = false;
  userselfserve = false;
  isCompanyEndUser = false;
  dataValue = 'connections';

  constructor(private authenticationService: AuthenticationService, private router: Router, private userService: UserService) { }

  ngOnInit() {
    //temp
    localStorage.removeItem('USER_ROLE');
    if (getUserRole() === '4') {
      localStorage.setItem("USER_LISTS_KEY", localStorage.getItem("USER_KEY"));
    }

    this.authenticationService.sharedLoginStatus.subscribe((status) => {
      this.isLoggedIn = status;

      this.authenticationService.check().subscribe((result) => {
        if (result === null) {
          this.isLoggedIn = false;
          if (location.pathname.includes('/reset-password/')) {
          } else {
            this.router.navigate(['/login']);
          }
        } else {
          this.isLoggedIn = true;
          this.userName = '';
          this.isSuperAdmin = false;
          this.isAdmin = false;
          this.isCompanyAdmin = false;
          this.isCompanyEndUser = false;
          this.googleDocURL = localStorage.getItem('USER_GOOGLE_DOC_URL');
          this.setUserData(result);
        }
      });
    });

  }

  setUserData(user: User): void {
    this.userName = user.name;
    this.groups = user.groups;
    this.isViewedprofilesActive = user.isViewedprofilesActive;
    console.log(user)
    if (user.role === '1') {
      this.isSuperAdmin = true;
    } else if (user.role === '2') {
      this.isAdmin = true;
    } else if (user.role === '3') {
      this.isCompanyAdmin = true;
    } else if (user.role === '4') {
      this.isCompanyEndUser = true;
      this.userValue = localStorage.getItem('USER_KEY');
    }
    if (user.googleDocURL != '') {
      this.havedocurl = true;
    } else {
      this.havedocurl = false;
    }
    // target
    if (user.selfserve == true) {
      this.userselfserve = true;
    }
  }

  logout() {
    this.authenticationService.logout().subscribe((result) => {
      if (result) {
        this.authenticationService.toggleLoginStatus(false)
        this.isCollapsed = !this.isCollapsed;
        this.isLoggedIn = false;
        this.router.navigate(['/login']);
      }

    }, (err) => {
      alert('error');
      console.log('error to logout: ', err);
    });
  }

  toggleNavbar(): void {
    if (this.navbaridRef.shown) {
      this.navbaridRef.toggle();
    }
  }

  clearListKey(): void {
    localStorage.removeItem('USER_LISTS_KEY');
  }

  clearReviewListKey(): void {
    localStorage.removeItem('USER_REVIEW_LISTKEY');
    localStorage.removeItem('USER_AVDATA_CACHED_AT');
  }

  openToAdd() {
    this.dataValue = 'connections';
    this.from = new Date(Date.now() - 12096e5).toJSON().slice(0, 10);
    this.to = new Date().toJSON().slice(0, 10);
    console.log('this.isCompanyAdmin',this.isCompanyAdmin)
    if (this.isCompanyAdmin) {
      this.userValue = 'all';
      this.userService.getCAUsers().subscribe((res) => {
        this.users = res.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        this.showModalOnClick.show();

      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to get ca users: ', err);
      });
    } else {
      this.userValue = localStorage.getItem('USER_KEY');
      this.showModalOnClick.show();
    }
  }

  async export(): Promise<boolean> {
    if (!this.dataValue) {
        alert('Please select data');
        return false;
    }
    if (!this.from) {
        alert('You have to choose from date');
        return false;
    }
    if (!this.to) {
        alert('You have to enter to date');
        return false;
    }

    this.showModalOnClick.hide();
    this.isLoading = true;

    try {
        const connections = await this.userService.getCompanyEUdata(this.userValue, this.from, this.to, this.users, this.directionI ? 1 : 0, 0).toPromise();
        const con: any[] = [];
        let csvContent = 'data:text/csv;charset=utf-8,';
        console.log(connections);

        Object.keys(connections).forEach((data) => {
            const val = connections[data];
            con.push(val);
        });

        let title = `Company Name, First Name, Last Name, Title, Profile Url, Email, Phone, Location, Company Start Date, Connected On, Mutual Connections`;
        if (this.userValue === 'all') {
            title = `User, ${title}`;
        }
        if (this.directionI) {
            title = `${title}, Last Msg Date, Last Msg`;
        }
        csvContent += title + '\r\n';

        con.forEach((rowArray) => {
            const row = (this.userValue == 'all' ? '"' + rowArray.user + '",' : ``) + '"' + rowArray.companyName + '","' + rowArray.firstName + '","'
                + rowArray.lastName + '","' + rowArray.title + '","'
                + rowArray.profileURL + '","' + rowArray.email + '","'
                + rowArray.phone + '","' + rowArray.location + '","' +
                rowArray.yearsAtCompany + '","' + rowArray.connectedOn
                + '","' + rowArray.mutualConnections
                + (this.directionI && rowArray.messages && Object.keys(rowArray.messages).length ? ('","' + rowArray.messages.tstamp + '","' + rowArray.messages.body) : ``)
                + '"';
            csvContent += row + '\r\n';
        });

        csvContent = csvContent.replace(/#/g, '');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'Exported Data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.isLoading = false;
        return true;
    } catch (err) {
        alert('error');
        console.log('error to get company eu data: ', err);
        this.isLoading = false;
        return false;
    }
}

  gotoGoogleDocURL() {
    this.userService.getUserByEmail(localStorage.getItem('USER_EMAIL')).subscribe((user) => {
      console.log(user);
      window.open(user.googleDocURL, '_blank');

    }, (err) => {
      alert('error');
      console.log('error to get user by email: ', err);
    });

  }
}
