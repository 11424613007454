import { OnInit } from '@angular/core';
var MessagesComponent = /** @class */ (function () {
    function MessagesComponent(userService) {
        this.userService = userService;
        this.rows = [];
        this.isLoading = true;
        this.userLatestMessage = [];
        this.noDataText = 'No messages right now';
        this.columnDefs = [
            {
                width: 150,
                sort: "desc",
                maxWidth: 150,
                minWidth: 150,
                field: 'createdAt',
                headerName: 'Date/Time',
                cellRenderer: function (params) {
                    return "" + params.data.tstamp;
                }
            },
            {
                width: 200,
                maxWidth: 200,
                minWidth: 200,
                field: 'contact',
                headerName: 'Contact',
                cellRenderer: function (params) {
                    return "<a href=\"" + params.data.contactURL + "\" target=\"_blank\">" + params.data.contact + "</a>";
                }
            },
            {
                width: 200,
                maxWidth: 200,
                minWidth: 200,
                field: 'company',
                headerName: 'Company',
                comparator: function (valueA, valueB) {
                    if (!valueA)
                        return -1;
                    if (!valueB)
                        return 1;
                    valueA = valueA.toLowerCase();
                    valueB = valueB.toLowerCase();
                    if (valueA == valueB)
                        return 0;
                    if (valueA > valueB)
                        return 1;
                    return -1;
                },
                cellRenderer: function (params) {
                    if (params.data.company_url) {
                        return "<a href=\"" + params.data.company_url + "\" target=\"_blank\">" + params.data.company + "</a>";
                    }
                    else {
                        return "<span>" + params.data.company + "</span>";
                    }
                }
            },
            {
                width: 75,
                maxWidth: 75,
                minWidth: 75,
                headerName: '',
                field: 'convoUrl',
                cellRenderer: function (params) {
                    return "<a style=\"color:black;text-decoration:none;\" href=\"https://www.linkedin.com/messaging/thread/" + params.data.thread + "/\"\n        target=\"_blank\"><i class=\"fa fa-comments-o\"></i></a>";
                }
            },
            {
                field: 'body',
                minWidth: 200,
                autoHeight: true,
                headerName: 'Message',
                cellClass: "cell-wrap-text",
            }
        ];
    }
    MessagesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.getUserMessages(localStorage.getItem('USER_KEY')).subscribe(function (messages) {
            _this.rows = messages;
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user lastest messages: ', err);
        });
    };
    MessagesComponent.prototype.onColumnResized = function (event) {
        if (event.finished) {
            this.gridApi.resetRowHeights();
        }
    };
    MessagesComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
        setTimeout(function () {
            params.api.resetRowHeights();
        }, 500);
    };
    return MessagesComponent;
}());
export { MessagesComponent };
