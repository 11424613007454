import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';

import { CampaignService } from 'src/app/services/campaign.service';
import { SequenceService } from 'src/app/services/sequence.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {

  @ViewChild('stopCampaignModal', { static: true }) stopCampaignModal: ModalDirective;
  @ViewChild('startCampaignModal', { static: true }) startCampaignModal: ModalDirective;
  @ViewChild('deleteCampaignModal', { static: true }) deleteCampaignModal: ModalDirective;

  campaigns = [];
  isLoading = true;
  sortOrder = 'asc';
  campaignId = "";
  sortBy = 'campaignName';

  constructor(private router: Router, public campaignService: CampaignService, public sequenceService: SequenceService) { }

  ngOnInit() {
    this.loadCampaign();
  }

  loadCampaign() {
    localStorage.removeItem('CAMPAIGN_INFO');
    localStorage.removeItem('CONNECTION_MESSAGE_INFO');
    localStorage.removeItem('TEMPLATE_MESSAGE_INFO');
    localStorage.removeItem('EDIT_CAMPAIGN');
    localStorage.removeItem('EDIT_STRATEGY');
    localStorage.removeItem('EDIT_SEQUENCE');

    this.campaignService.getCampaigns(localStorage.getItem('USER_KEY')).subscribe((res) => {
      this.campaigns = res;
      console.log(res)
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log(err);
    });
  }

  gotoCreateNewCampaign() {
    this.router.navigate(["create-new-campaign"]);
  }

  doAction(actionValue, campaign) {
    this.campaignId = campaign._id;

    if (actionValue === "Start Campaign") {
      this.openStartCampaignModal();
    }

    if (actionValue === "Stop Campaign") {
      this.openStopCampaignModal();
    }

    if (actionValue === "Delete Campaign") {
      this.openDeleteCampaignModal();
    }

    if (actionValue === "Edit Campaign") {
      localStorage.setItem('EDIT_CAMPAIGN', "true");
      localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(campaign));
      this.router.navigate(["create-new-campaign"]);
    }

    if (actionValue === "Edit Strategy") {
      localStorage.setItem('EDIT_STRATEGY', "true");
      localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(campaign));

      if (campaign.campaignType === 'Invite, Connect and Message') {
        this.router.navigate(["strategy-type"]);
      } else if (campaign.campaignType === "Message Only") {
        this.router.navigate(["message-send-who"]);
      }
    }

    if (actionValue === "Edit Sequence") {
      localStorage.setItem('EDIT_SEQUENCE', "true");
      localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(campaign));
      if (campaign.campaignType === "Invite, Connect and Message") {
        this.sequenceService.getMessages(campaign.sequenceId).subscribe((res) => {
          if (res.length === 0) {
            this.router.navigate(["connection-request"]);
          } else {
            this.router.navigate(["message-preview"]);
          }
        }, (err) => {
          alert('error');
          console.log(err);
        })
      } else {
        this.router.navigate(["message-preview"]);
      }
    }
  }

  openStartCampaignModal() {
    this.startCampaignModal.show();
  }

  startCampaign() {
    this.campaignService.startStopCampaign(this.campaignId, { status: true }).subscribe((res) => {
      console.log(res);
      this.startCampaignModal.hide();
      this.loadCampaign();
    }, (err) => {
      alert('error');
      console.log(err);
    });
  }

  openStopCampaignModal() {
    this.stopCampaignModal.show();
  }

  stopCampaign() {
    this.campaignService.startStopCampaign(this.campaignId, { status: false }).subscribe((res) => {
      console.log(res);
      this.stopCampaignModal.hide();
      this.loadCampaign();
    }, (err) => {
      alert('error');
      console.log(err);
    });
  }

  openDeleteCampaignModal() {
    this.deleteCampaignModal.show();
  }

  deleteCampaign() {
    this.campaignService.updateCampaign(this.campaignId, { deleted: true }).subscribe((res) => {
      console.log(res);
      this.deleteCampaignModal.hide();
      this.loadCampaign();
    }, (err) => {
      alert('error');
      console.log(err);
    });
  }

  viewCampaignsSummary(campaign) {
    localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(campaign));
    this.router.navigate(["campaigns-summary"]);
  }

}
