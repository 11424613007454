import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { PXData } from '../models/PXData';
import { AVLists } from '../models/AVLists';
import { Message_Beta } from '../models/message_beta';
import { listUser } from '../models/listsProps';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./slack.services";
var NodeCache = require("node-cache");
var UserService = /** @class */ (function () {
    function UserService(http, slackService) {
        this.http = http;
        this.slackService = slackService;
        this.myCache = new NodeCache();
    }
    UserService.prototype.getBaseUrl = function () {
        return environment.baseUrl;
    };
    UserService.prototype.getUrl = function (relativeUrl) {
        return this.getBaseUrl() + relativeUrl;
    };
    UserService.prototype.getOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    UserService.prototype.getUsers = function () {
        var url = this.getUrl('/users/documents/not-hidden');
        return this.http.get(url, this.getOptions())
            .pipe(map(function (response) {
            var users = [];
            response.forEach(function (data) {
                var user = new User();
                user._id = data._id;
                user.name = data.name;
                user.lastApprovedCount = data.lastApprovedCount;
                user.lastApprovedOn = data.lastApprovedOn;
                user.lastApprovedPercentage = Math.round(data.lastApprovedPercentage);
                user.company = data.company;
                user.email = data.email;
                user.password = data.password;
                user.role = data.role;
                user.linkedInProfileId = data.linkedInProfileId;
                user.memberId = data.memberId;
                user.googleDocURL = data.googleDocURL;
                user.isHidden = data.isHidden;
                user.selfserve = data.selfserve; //code here
                users.push(user);
            });
            return users;
        }));
    };
    UserService.prototype.searchAVLists = function (_id, searchText) {
        console.log('Searching user AVLists from database...');
        var url = this.getUrl('/avlists/documents/' + _id);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var dataArray = [];
            querySnapshot.forEach(function (data) {
                if (data.listName && data.listName.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
                    var object = new AVLists();
                    object._id = data._id;
                    object.listName = data.listName;
                    object.listFor = data.listFor;
                    object.uploadedCount = data.uploadedCount;
                    object.uploadedOn = data.uploadedOn;
                    object.approvedOn = data.approvedOn;
                    object.approvedBy = data.approvedBy;
                    object.approvedCount = data.approvedCount;
                    object.notApproved = data.uploadedCount - data.approvedCount;
                    object.approvedPercentage = Math.round((data.approvedCount / data.uploadedCount) * 100);
                    object.company = data.company;
                    object.name = data.name;
                    dataArray.push(object);
                }
            });
            return dataArray;
        }));
    };
    UserService.prototype.getAllUsers = function () {
        var _this = this;
        var url = this.getUrl('/users');
        return new Observable(function (observer) {
            _this.http.get(url, _this.getOptions())
                .subscribe(function (response) {
                var users = [];
                var companies = [];
                response.forEach(function (data) {
                    var user = new User();
                    user._id = data._id;
                    user.name = data.name;
                    user.lastApprovedCount = data.lastApprovedCount;
                    user.lastApprovedOn = data.lastApprovedOn;
                    user.lastApprovedPercentage = Math.round(data.lastApprovedPercentage);
                    user.company = data.company;
                    if (!companies.includes(data.company)) {
                        companies.push(data.company);
                    }
                    user.email = data.email;
                    user.password = data.password;
                    user.role = data.role;
                    user.linkedInProfileId = data.linkedInProfileId;
                    user.myId = data.myId;
                    user.memberId = data.memberId;
                    user.googleDocURL = data.googleDocURL;
                    user.isHidden = data.isHidden;
                    user.isLila = data.isLila;
                    user.isViewedprofilesActive = data.isViewedprofilesActive;
                    user.groups = data.groups;
                    user.selfserve = data.selfserve;
                    user.notifications = data.notifications;
                    user.isResearcher = data.isResearcher;
                    var researcher = response.filter(function (user) { return user._id === data.assignedResearcher; });
                    if (researcher.length > 0) {
                        user.reseacher = researcher[0].initials;
                    }
                    user.assignedResearcher = data.assignedResearcher;
                    user.initials = data.initials;
                    user.leadStatusOptions = data.leadStatusOptions || [];
                    users.push(user);
                });
                observer.next({ users: users, companies: companies });
            });
        });
    };
    UserService.prototype.actionOnRequest = function (USER_MEMBER_ID, data) {
        var url = this.getUrl('/connection-requests/action-on-request/' + USER_MEMBER_ID);
        return this.http.post(url, data, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    UserService.prototype.getUserIncomingRequests = function (USER_MEMBER_ID) {
        var url = this.getUrl('/connection-requests/get-incoming-requests/' + USER_MEMBER_ID);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            return querySnapshot;
        }));
    };
    UserService.prototype.getUsersDashboard = function () {
        var url = this.getUrl('/users/documents/get-dashboard');
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var users = [];
            var companies = [];
            querySnapshot.forEach(function (data) {
                var user = new User();
                user._id = data._id;
                user.name = data.name;
                user.memberId = data.memberId;
                user.lastApprovedCount = data.lastApprovedCount;
                user.lastApprovedOn = data.lastApprovedOn;
                user.lastApprovedPercentage = Math.round(data.lastApprovedPercentage);
                user.company = data.company;
                if (!companies.includes(data.company)) {
                    companies.push(data.company);
                }
                user.email = data.email;
                user.password = data.password;
                user.role = data.role;
                user.linkedInProfileId = data.linkedInProfileId;
                user.connectionsCount = data.connectionsCount;
                user.pxUpdatedOn = data.pxUpdatedOn;
                user.avUpdatedOn = data.avUpdatedOn;
                user.r4rCount = data.r4rCount;
                user.msgUpdateOn = data.msgUpdateOn;
                user.lastMsgCheck = data.lastMsgCheck;
                user.incomingMsgs = data.incomingMsgs;
                user.selfserve = data.selfserve;
                user.unReviewedListsNumber = data.unReviewedListsNumber;
                users.push(user);
            });
            return { users: users, companies: companies };
        }));
    };
    UserService.prototype.getUsersSimpleDashboard = function () {
        var url = this.getUrl('/users/documents/get-simple-dashboard');
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var users = [];
            var companies = [];
            querySnapshot.forEach(function (data) {
                var user = new User();
                user._id = data._id;
                user.name = data.name;
                user.lastApprovedCount = data.lastApprovedCount;
                user.lastApprovedOn = data.lastApprovedOn;
                user.lastApprovedPercentage = Math.round(data.lastApprovedPercentage);
                user.company = data.company;
                if (!companies.includes(data.company)) {
                    companies.push(data.company);
                }
                user.email = data.email;
                user.password = data.password;
                user.role = data.role;
                user.linkedInProfileId = data.linkedInProfileId;
                user.connectionsCount = data.connectionsCount;
                user.avUpdatedOn = data.avUpdatedOn;
                user.r4rCount = data.r4rCount;
                user.unReviewedListsNumber = data.unReviewedListsNumber;
                user.lastIncomingMsg = data.lastIncomingMsg;
                user.lastSentMsg = data.lastSentMsg;
                user.selfserve = data.selfserve;
                users.push(user);
            });
            return { users: users, companies: companies };
        }));
    };
    UserService.prototype.getCAUsers = function () {
        var url = this.getUrl('/users/documents/company/' + localStorage.getItem('USER_COMPANY'));
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var users = [];
            querySnapshot.forEach(function (data) {
                var user = new User();
                user._id = data._id;
                user.name = data.name;
                user.lastApprovedCount = data.lastApprovedCount;
                user.lastApprovedOn = data.lastApprovedOn ? data.lastApprovedOn : 0;
                user.lastApprovedPercentage = data.lastApprovedPercentage ? Math.round(data.lastApprovedPercentage) : 0;
                user.company = data.company ? data.company : '';
                user.email = data.email;
                user.password = data.password;
                user.role = data.role;
                user.linkedInProfileId = data.linkedInProfileId;
                user.memberId = data.memberId;
                users.push(user);
            });
            return users;
        }));
    };
    UserService.prototype.getCADashboard = function () {
        var url = this.getUrl('/users/documents/ca-dashboard/company/' + localStorage.getItem('USER_COMPANY'));
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var users = [];
            querySnapshot.forEach(function (data) {
                var user = new User();
                user._id = data._id;
                user.name = data.name;
                user.lastApprovedCount = data.lastApprovedCount;
                user.lastApprovedOn = data.lastApprovedOn;
                user.lastApprovedPercentage = Math.round(data.lastApprovedPercentage);
                user.company = data.company;
                user.email = data.email;
                user.password = data.password;
                user.role = data.role;
                user.linkedInProfileId = data.linkedInProfileId;
                user.connectionsCount = data.connectionsCount;
                user.pxUpdatedOn = data.pxUpdatedOn;
                user.avUpdatedOn = data.avUpdatedOn;
                user.r4rCount = data.r4rCount;
                user.msgUpdateOn = data.msgUpdateOn;
                user.incomingMsgs = data.incomingMsgs;
                user.unReviewedListsNumber = data.unReviewedListsNumber;
                users.push(user);
            });
            return users;
        }));
    };
    UserService.prototype.updateProfile = function (user) {
        var url = this.getUrl('/users/' + user._id);
        return this.http.put(url, {
            name: user.name,
            email: user.email
        }, this.getOptions())
            .pipe(map(function (res) {
            return true;
        }));
    };
    UserService.prototype.setLastApproved = function (user, summary) {
        var _this = this;
        if (summary === void 0) { summary = {}; }
        var url = this.getUrl('/users/' + user._id);
        return this.http.put(url, {
            lastApprovedOn: user.lastApprovedOn,
            lastApprovedCount: user.lastApprovedCount,
            lastApprovedPercentage: Math.round(user.lastApprovedPercentage),
        }, this.getOptions())
            .pipe(map(function (res) {
            var e_1, _a;
            var userEmail = localStorage.getItem('USER_EMAIL');
            var userName = localStorage.getItem('USER_NAME');
            // send slack message here
            var message = "*" + userName + " has approved " + user.lastApprovedCount + " records (" + Math.round(user.lastApprovedPercentage) + "%)*";
            if (user.lastApprovedPercentage < 100 && Object.entries(summary).length) {
                message = message + "\n>*Rejected Summary*";
                var testSummary = "";
                try {
                    for (var _b = tslib_1.__values(Object.entries(summary)), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var _d = tslib_1.__read(_c.value, 2), key = _d[0], value = _d[1];
                        testSummary = testSummary + "\n>" + key + ": " + value;
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                message += testSummary;
            }
            if (user.note) {
                message = message + ("\nNote: " + user.note);
            }
            _this.sendSlackMessage(message);
            // send email to the admin
            var email = {
                subject: "List Approved - " + userEmail,
                to: "activity-tracker@rainmakerdots.com",
                from: "Activity Tracker <noreply@rainmakerdots.com>",
                html: "<p>Hi there,</p><p>This is to inform you that " + userName + " <" + userEmail + "> has approved " + user.lastApprovedCount + " records (" + Math.round(user.lastApprovedPercentage) + "%).</p>"
            };
            if (user.note) {
                email.html = email.html + ("<p><b>Note from user:</b> " + user.note + "</p>");
            }
            var url_admin = _this.getUrl('/users/send-email');
            _this.http.post(url_admin, { content: email }, _this.getOptions()).subscribe(function (res) {
                console.log(res);
            }, function (err) {
                console.log(err);
            });
            // send email to user
            var emailToUser = {
                subject: "List Approved",
                to: "" + userEmail,
                from: "Activity Tracker <noreply@rainmakerdots.com>",
                html: "<p>Hi " + userName + ",</p><p>Thanks for approving your list, we are working on it.</p>"
            };
            var url_user = _this.getUrl('/users/send-email-user');
            _this.http.post(url_user, { content: emailToUser, userName: userName }, _this.getOptions()).subscribe(function (res) {
                console.log(res);
            }, function (err) {
                console.log(err);
            });
            return true;
        }));
    };
    UserService.prototype.sendSlackMessage = function (message) {
        this.slackService.sendSlackMessage({ "text": message, "channel": 'list-approvals', "username": 'Approval Bot' }).subscribe();
    };
    UserService.prototype.updateMessageData = function (messageDataArray) {
        var url = this.getUrl('/messages');
        return this.http.post(url, { messageDataArray: messageDataArray }, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    UserService.prototype.updateCorpEmailData = function (dataArray) {
        var url = this.getUrl('/corp-emails/upload');
        return this.http.put(url, { dataArray: dataArray }, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    UserService.prototype.updateRequestedEmailData = function (dataArray) {
        var url = this.getUrl('/corp-emails/upload-requested-email');
        return this.http.put(url, { dataArray: dataArray }, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    UserService.prototype.updatePXData = function (pxDataArray, _id) {
        var url = this.getUrl('/pxdata');
        return this.http.post(url, { pxDataArray: pxDataArray, _id: _id }, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    UserService.prototype.approveAVData = function (avData, _id, actionUID, note) {
        var url = this.getUrl('/avdata/approve/' + _id + '/' + actionUID);
        return this.http.put(url, { avData: avData }, this.getOptions())
            .pipe(map(function (res) {
            return true;
        }));
    };
    UserService.prototype.saveTempAvdata = function (selected_ids, all_ids) {
        var url = this.getUrl('/avdata/save-temp-selected');
        return this.http.put(url, { selected_ids: selected_ids, all_ids: all_ids }, this.getOptions())
            .pipe(map(function (res) {
            return true;
        }));
    };
    UserService.prototype.disapproveAVData = function (avData, _id) {
        var url = this.getUrl('/avdata/disapprove/' + _id);
        return this.http.put(url, { avData: avData }, this.getOptions())
            .pipe(map(function (res) {
            return true;
        }));
    };
    UserService.prototype.updateAVData = function (pxDataArray, _id, listName, type) {
        var url = this.getUrl('/avdata');
        return this.http.post(url, { pxDataArray: pxDataArray, _id: _id, listName: listName, type: type }, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    UserService.prototype.getUserByEmail = function (email) {
        var url = this.getUrl('/users');
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var user = new User();
            querySnapshot.forEach(function (data) {
                if (data.email.toLowerCase() == email.toLocaleLowerCase()) {
                    user._id = data._id;
                    user.name = data.name;
                    user.company = data.company;
                    user.email = data.email;
                    user.password = data.password;
                    user.role = data.role;
                    user.linkedInProfileId = data.linkedInProfileId;
                    user.googleDocURL = data.googleDocURL;
                    user.isHidden = data.isHidden;
                    user.selfserve = data.selfserve;
                }
            });
            return user;
        }));
    };
    UserService.prototype.getUserByKey = function (_id) {
        var url = this.getUrl('/users/' + _id);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (data) {
            var user = new User();
            user._id = _id;
            user.name = data.name;
            user.leadStatusOptions = data.leadStatusOptions || [];
            user.company = data.company;
            user.email = data.email;
            user.password = data.password;
            user.role = data.role;
            user.linkedInProfileId = data.linkedInProfileId ? data.linkedInProfileId : '';
            user.googleDocURL = data.googleDocURL ? data.googleDocURL : '';
            user.isHidden = data.isHidden;
            user.notifications = data.notifications;
            user.isLila = !!data.isLila;
            user.groups = !!data.groups;
            user.isViewedprofilesActive = !!data.isViewedprofilesActive;
            return user;
        }));
    };
    UserService.prototype.editUser = function (user) {
        var url = this.getUrl('/users/' + user._id);
        return this.http.put(url, user, this.getOptions())
            .pipe(map(function (data) {
            var user = new User();
            user._id = data._id;
            user.name = data.name;
            user.lastApprovedCount = data.lastApprovedCount;
            user.lastApprovedOn = data.lastApprovedOn;
            user.lastApprovedPercentage = Math.round(data.lastApprovedPercentage);
            user.company = data.company;
            user.email = data.email;
            user.role = data.role;
            user.linkedInProfileId = data.linkedInProfileId;
            user.myId = data.myId;
            user.memberId = data.memberId;
            user.googleDocURL = data.googleDocURL;
            user.isHidden = data.isHidden;
            user.selfserve = data.selfserve;
            user.isResearcher = data.isResearcher;
            user.assignedResearcher = data.assignedResearcher;
            user.initials = data.initials;
            return user;
        }));
    };
    UserService.prototype.addUser = function (user) {
        var url = this.getUrl('/users');
        return this.http.post(url, user, this.getOptions())
            .pipe(map(function (data) {
            var user = new User();
            user._id = data._id;
            user.name = data.name;
            user.company = data.company;
            user.email = data.email;
            user.password = data.password;
            user.role = data.role;
            user.linkedInProfileId = data.linkedInProfileId;
            user.myId = data.myId;
            user.memberId = data.memberId;
            user.googleDocURL = data.googleDocURL;
            user.isHidden = data.isHidden;
            user.isLila = data.isLila;
            user.groups = data.groups;
            user.isViewedprofilesActive = data.isViewedprofilesActive;
            user.selfserve = data.selfserve; //code here
            return user;
        }));
    };
    UserService.prototype.changeUserLinkedinID = function (pre_lid, linkedInProfileId) {
        var url = this.getUrl('/messages/documents/' + pre_lid);
        return this.http.put(url, { new_lid: linkedInProfileId }, this.getOptions())
            .pipe(map(function (res) {
            return true;
        }));
    };
    UserService.prototype.toggleNotifications = function (_id, notifications) {
        var url = this.getUrl('/users/toggleNotifications/' + _id);
        return this.http.put(url, { notifications: notifications }, this.getOptions())
            .pipe(map(function (res) {
            console.log(res);
            return res.notifications;
        }));
    };
    UserService.prototype.stopMessage = function (_id) {
        var url = this.getUrl('/pxdata/stop-msg/' + _id);
        return this.http.put(url, {}, this.getOptions())
            .pipe(map(function (response) {
            return response;
        }));
    };
    UserService.prototype.getThreadMessages = function (_id, memberId) {
        var _this = this;
        return new Observable(function (observer) {
            var url = _this.getUrl('/messages/thread-documents/' + _id + '/' + memberId);
            _this.http.get(url, _this.getOptions())
                .subscribe(function (querySnapshot) {
                observer.next(querySnapshot.data);
            });
        });
    };
    UserService.prototype.getIncomingMessages = function (_id, memberId, skip, count) {
        var _this = this;
        return new Observable(function (observer) {
            _this.shouldUseCachedIncomingMessagesBeta(_id).subscribe(function (shouldUseCachedMessagesBeta) {
                if (shouldUseCachedMessagesBeta && _this.myCache.get('USER_INCOMING_MESSAGES_BETA')) {
                    console.log('Getting user messages_beta from cache...');
                    observer.next(JSON.parse(_this.myCache.get('USER_INCOMING_MESSAGES_BETA')));
                }
                else {
                    console.log('Getting user messages_bata from database...');
                    var url = _this.getUrl('/pxdata/documents/incoming-messages/' + _id + '/' + memberId);
                    _this.http.get(url, _this.getOptions())
                        .subscribe(function (querySnapshot) {
                        var messages = [];
                        querySnapshot.data.forEach(function (data) {
                            var message = new Message_Beta();
                            message.pxDataId = data.pxDataId;
                            message.pxDataStatus = data.pxDataStatus || "";
                            message.stopmsg = data.stopmsg;
                            message.thread = data['thread'];
                            message.id = data['_id'];
                            message.tstamp = new Date(data['tstamp']);
                            message.company = data['company'] ? data['company'] : '';
                            message.company_url = data['company_url'] ? data['company_url'] : '';
                            message.subRows = data['subRows'] ? data['subRows'] : [];
                            message.contact = data['contact'];
                            message.contactURL = data['contactURL'];
                            message.body = data['body'];
                            messages.push(message);
                        });
                        _this.myCache.set("USER_INCOMING_MESSAGES_BETA", JSON.stringify(messages), 86400000);
                        //localStorage.setItem('USER_MESSAGES', JSON.stringify(messages));
                        localStorage.setItem('USER_INCOMING_MESSAGES_BETA_CACHED_AT', '' + new Date().getTime());
                        observer.next(messages);
                    });
                }
            });
        });
    };
    UserService.prototype.getUserBetaMessages = function (_id, memberId, skip, count) {
        var _this = this;
        return new Observable(function (observer) {
            _this.shouldUseCachedMessagesBeta(_id).subscribe(function (shouldUseCachedMessagesBeta) {
                if (shouldUseCachedMessagesBeta && _this.myCache.get('USER_MESSAGES_BETA')) {
                    console.log('Getting user messages_beta from cache...');
                    observer.next(JSON.parse(_this.myCache.get('USER_MESSAGES_BETA')));
                }
                else {
                    console.log('Getting user messages_bata from database...');
                    var url = _this.getUrl('/messages/documents/beta/' + _id + '/' + memberId + "?skip=" + skip + "&count=" + count);
                    _this.http.get(url, _this.getOptions())
                        .subscribe(function (querySnapshot) {
                        var messages = [];
                        querySnapshot.data.forEach(function (data) {
                            var message = new Message_Beta();
                            message.pxDataId = data.pxDataId;
                            message.pxDataStatus = data.pxDataStatus || "";
                            message.stopmsg = data.stopmsg;
                            message.thread = data['thread'];
                            message.id = data['_id'];
                            message.tstamp = data['tstamp'];
                            message.company = data['company'] ? data['company'] : '';
                            message.company_url = data['company_url'] ? data['company_url'] : '';
                            message.subRows = data['subRows'] ? data['subRows'] : [];
                            message.contact = data['contact'];
                            message.contactURL = data['contactURL'];
                            message.body = data['body'];
                            messages.push(message);
                        });
                        _this.myCache.set("USER_MESSAGES_BETA", JSON.stringify(messages), 86400000);
                        //localStorage.setItem('USER_MESSAGES', JSON.stringify(messages));
                        localStorage.setItem('USER_MESSAGES_BETA_CACHED_AT', '' + new Date().getTime());
                        observer.next(messages);
                    });
                }
            });
        });
    };
    UserService.prototype.getUserSentMessages = function (ids) {
        var _this = this;
        return new Observable(function (observer) {
            var url = _this.getUrl('/messages/outgoing-count');
            _this.http.put(url, ids, _this.getOptions())
                .subscribe(function (querySnapshot) {
                //this.myCache.set("USER_MESSAGES", JSON.stringify(messages), 86400000);
                // localStorage.setItem('USER_MESSAGES', JSON.stringify(messages));
                //localStorage.setItem('USER_MESSAGES_CACHED_AT', '' + new Date().getTime());
                observer.next(querySnapshot);
            });
        });
    };
    // getUserMessages(_id: string): Observable<Message[]> {
    // 	return new Observable((observer) => {
    // 		this.shouldUseCachedMessages(_id).subscribe((shouldUseCachedMessages) => {
    // 			if (shouldUseCachedMessages && this.myCache.get('USER_MESSAGES')) {
    // 				console.log('Getting user messages from cache...');
    // 				observer.next(JSON.parse(this.myCache.get('USER_MESSAGES')));
    // 			} else {
    // 				console.log('Getting user messages from database...');
    // 				const url = this.getUrl('/messages/documents/incoming/' + _id);
    // 				this.http.get(url, this.getOptions())
    // 					.subscribe((querySnapshot: any) => {
    // 						const messages: Message[] = [];
    // 						querySnapshot.forEach((data: any) => {
    // 							var message = new Message();
    // 							message.contact = data['firstName_from'] + " " + data['lastName_from'];
    // 							message.contactURL = `https://www.linkedin.com/in/${data['li_pid_from']}`;
    // 							message.createdAt = data['createdAt'];
    // 							message.thread = data['thread'];
    // 							message.body = data['body'];
    // 							message.tstamp = data['tstamp'];
    // 							if (message.createdAt) {
    // 								message.createdAt1 = this.formatDate(parseFloat(message.createdAt));
    // 							} else {
    // 								message.createdAt1 = '';
    // 							}
    // 							message.av_id = data['av_id'] ? 'YES' : 'NO';
    // 							message.company = data['company'];
    // 							message.company_url = data['company_url'];
    // 							messages.push(message);
    // 						});
    // 						this.myCache.set("USER_MESSAGES", JSON.stringify(messages), 86400000);
    // 						// localStorage.setItem('USER_MESSAGES', JSON.stringify(messages));
    // 						localStorage.setItem('USER_MESSAGES_CACHED_AT', '' + new Date().getTime());
    // 						observer.next(messages);
    // 					});
    // 			}
    // 		});
    // 	});
    // }
    //  Review from Randy - SPEED
    UserService.prototype.getUserMessages = function (_id) {
        var _this = this;
        return new Observable(function (observer) {
            _this.shouldUseCachedMessages(_id).subscribe(function (shouldUseCachedMessages) {
                if (shouldUseCachedMessages && _this.myCache.get('USER_MESSAGES')) {
                    console.log('Getting user messages from cache...');
                    observer.next(JSON.parse(_this.myCache.get('USER_MESSAGES')));
                }
                else {
                    console.log('Getting user messages from database...');
                    var url = _this.getUrl('/messages/documents/incoming/' + _id);
                    _this.http.get(url, _this.getOptions()).subscribe(function (querySnapshot) {
                        var messages = querySnapshot.map(function (data) { return ({
                            contact: data['firstName_from'] + " " + data['lastName_from'],
                            contactURL: "https://www.linkedin.com/in/" + data['li_pid_from'],
                            createdAt: data['createdAt'],
                            thread: data['thread'],
                            body: data['body'],
                            tstamp: data['tstamp'],
                            createdAt1: data['createdAt'] ? _this.formatDate(parseFloat(data['createdAt'])) : '',
                            av_id: data['av_id'] ? 'YES' : 'NO',
                            company: data['company'],
                            company_url: data['company_url'],
                        }); });
                        _this.myCache.set("USER_MESSAGES", JSON.stringify(messages), 86400000);
                        localStorage.setItem('USER_MESSAGES_CACHED_AT', "" + new Date().getTime());
                        observer.next(messages);
                    });
                }
            });
        });
    };
    UserService.prototype.updatePXDataStatus = function (_id, status) {
        var _this = this;
        var url = this.getUrl('/pxdata/update-status/' + _id);
        return this.http.put(url, { status: status }, this.getOptions())
            .pipe(map(function (data) {
            console.log(data);
            if (_this.myCache.get('USER_INCOMING_MESSAGES_BETA')) {
                console.log('Getting user messages_beta from cache...');
                var messages = JSON.parse(_this.myCache.get('USER_INCOMING_MESSAGES_BETA'));
                for (var i in messages) {
                    if (messages[i].id === _id) {
                        messages[i].pxDataStatus = status;
                        break;
                    }
                }
                _this.myCache.set("USER_INCOMING_MESSAGES_BETA", JSON.stringify(messages), 86400000);
            }
            return data;
        }));
    };
    UserService.prototype.getUserPXdata = function (_id) {
        var _this = this;
        return new Observable(function (observer) {
            _this.shouldUseCachedPXData(_id).subscribe(function (shouldUseCachedPX) {
                if (shouldUseCachedPX && _this.myCache.get('USER_PXDATA')) {
                    console.log('Getting user PXData from cache...');
                    observer.next(JSON.parse(_this.myCache.get('USER_PXDATA')));
                }
                else {
                    console.log('Getting user PXData from database...');
                    var url = _this.getUrl('/pxdata/documents/' + _id);
                    _this.http.get(url, _this.getOptions())
                        .subscribe(function (querySnapshot) {
                        var dataArray = [];
                        querySnapshot.forEach(function (data) {
                            var object = new PXData();
                            var year = data.yearsAtCompany && data.yearsAtCompany.split('.').length > 1 ? data.yearsAtCompany.split('.')[0] : '';
                            object.id = data._id;
                            object.ecEmail = data.ecEmail;
                            object.campaignStatus = data.campaign_status;
                            object.ecEmailUpdatedOn = data.ecEmailUpdatedOn;
                            object.lastViewedOn = data.lastViewedOn;
                            object.companyName = data.companyName ? data.companyName : ' ';
                            object.firstName = data.firstName;
                            object.lastName = data.lastName;
                            object.profileURL = data.profileURL;
                            object.avatar = data.avatar;
                            object.title = data.title;
                            object.ckId = data.ckId;
                            object.status = data.status || 0;
                            object.archetype = data.archetype;
                            object.discType = data.discType;
                            object.degrees = data.degrees;
                            object.intensity = data.intensity;
                            object.p4d = data.p4d;
                            object.p4i = data.p4i;
                            object.p4s = data.p4s;
                            object.p4c = data.p4c;
                            object.qualities = data.qualities;
                            object.email = data.ecEmail || data.email;
                            object.phone = data.phone;
                            object.location = data.location;
                            object.yearsAtCompany = data.yearsAtCompany;
                            object.since1 = year && year > 0 ? ((new Date()).getFullYear() - year) : 0;
                            object.mutualConnections = data.mutualConnections;
                            object.connectedOnDate = data.connectedOnDate;
                            if (object.connectedOnDate) {
                                object.connectedOnDate = _this.formatDateWithShortYear(object.connectedOnDate);
                            }
                            else {
                                object.connectedOnDate = ' ';
                            }
                            object.companyProfileURL = data.companyProfileURL;
                            object.convoUrl = data.lastIncomingMsgThread ? "https://www.linkedin.com/messaging/thread/" + data.lastIncomingMsgThread : '';
                            object.lastMessage = data.lastIncomingMsgBody ? data.lastIncomingMsgBody : '';
                            object.lastMessageDate = data.lastIncomingMsgDate ? _this.formatDateWithShortYear(data.lastIncomingMsgDate) : '';
                            if (object.lastMessageDate) {
                                object.lastMessageDate1 = _this.formatDate(object.lastMessageDate);
                            }
                            else {
                                object.lastMessageDate1 = '';
                            }
                            dataArray.push(object);
                        });
                        _this.myCache.set("USER_PXDATA", JSON.stringify(dataArray), 86400000);
                        // localStorage.setItem('USER_PXDATA', JSON.stringify(dataArray));
                        localStorage.setItem('USER_PXDATA_CACHED_AT', '' + new Date().getTime());
                        observer.next(dataArray);
                    });
                }
            });
        });
    };
    UserService.prototype.getCorporateData = function (body) {
        var url = this.getUrl('/pxdata/getCorporateData');
        return this.http.post(url, body, this.getOptions());
    };
    UserService.prototype.getEUDashboard = function (_id, user_member_id) {
        var url = this.getUrl('/pxdata/getEUDashboard/' + _id + '/' + user_member_id);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (data) {
            return data;
        }));
    };
    UserService.prototype.getPxdataById = function (_id) {
        var url = this.getUrl('/pxdata/' + _id);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (data) {
            return data;
        }));
    };
    UserService.prototype.getCompanyPXdata = function (_ids, connectedAfter) {
        var _this = this;
        return new Observable(function (observer) {
            var allObservables = [];
            for (var i = 0; i < _ids.length; i++) {
                var url = _this.getUrl('/pxdata/documents/export/' + _ids[i]);
                var eachObservable = _this.http.get(url, _this.getOptions());
                allObservables.push(eachObservable);
            }
            forkJoin(allObservables).subscribe(function (res) {
                console.log('forkjoin result: ', res);
                var dataArray = [];
                for (var k = 0; k < res.length; k++) {
                    for (var i = 0; i < res[k].length; i++) {
                        var data = res[k][i];
                        if (connectedAfter !== undefined) {
                            if (new Date(data.connectedOn) > new Date(connectedAfter)) {
                                var object = new PXData();
                                var year = data.yearsAtCompany && data.yearsAtCompany.split('.').length > 1 ? data.yearsAtCompany.split('.')[0] : '';
                                object.id = data.Id;
                                object.user_id = _ids[i];
                                object.companyName = data.companyName ? data.companyName : ' ';
                                object.firstName = data.firstName;
                                object.lastName = data.lastName;
                                object.profileURL = data.profileURL;
                                object.title = data.title;
                                object.email = data.email;
                                object.phone = data.phone;
                                object.location = data.location;
                                object.yearsAtCompany = data.yearsAtCompany;
                                object.since = year && year > 0 ? ((new Date()).getFullYear() - year).toString() : '';
                                if (object.since === '0') {
                                    object.since = '<1';
                                }
                                object.mutualConnections = data.mutualConnections;
                                object.connectedOn = data.connectedOn;
                                if (object.connectedOn) {
                                    object.connectedOn = _this.formatDate(object.connectedOn);
                                }
                                else {
                                    object.connectedOn = ' ';
                                }
                                object.companyProfileURL = data.companyProfileURL;
                                dataArray.push(object);
                            }
                        }
                        else {
                            var object = new PXData();
                            var year = data.yearsAtCompany && data.yearsAtCompany.split('.').length > 1 ? data.yearsAtCompany.split('.')[0] : '';
                            object.id = data.Id;
                            object.user_id = _ids[i];
                            object.companyName = data.companyName ? data.companyName : ' ';
                            object.firstName = data.firstName;
                            object.lastName = data.lastName;
                            object.profileURL = data.profileURL;
                            object.title = data.title;
                            object.email = data.email;
                            object.phone = data.phone;
                            object.location = data.location;
                            object.yearsAtCompany = data.yearsAtCompany;
                            object.since = year && year > 0 ? ((new Date()).getFullYear() - year).toString() : '';
                            if (object.since === '0') {
                                object.since = '<1';
                            }
                            object.mutualConnections = data.mutualConnections;
                            object.connectedOn = data.connectedOn;
                            if (object.connectedOn) {
                                object.connectedOn = _this.formatDate(object.connectedOn);
                            }
                            else {
                                object.connectedOn = ' ';
                            }
                            object.companyProfileURL = data.companyProfileURL;
                            dataArray.push(object);
                        }
                    }
                }
                ;
                observer.next(dataArray);
            });
        });
    };
    UserService.prototype.getCompanyApprovedContacts = function (_ids, connectedAfter) {
        var _this = this;
        return new Observable(function (observer) {
            var allObservables = [];
            for (var i = 0; i < _ids.length; i++) {
                var url = _this.getUrl('/avdata/documents/approved/' + _ids[i]);
                var eachObservable = _this.http.get(url, _this.getOptions());
                allObservables.push(eachObservable);
            }
            forkJoin(allObservables).subscribe(function (res) {
                console.log('forkjoin result: ', res);
                var dataArray = [];
                for (var k = 0; k < res.length; k++) {
                    for (var i = 0; i < res[k].length; i++) {
                        var data = res[k][i];
                        if (connectedAfter !== undefined) {
                            if (new Date(data.connectedOn) > new Date(connectedAfter)) {
                                var object = new PXData();
                                var year = data.yearsAtCompany && data.yearsAtCompany.split('.').length > 1 ? data.yearsAtCompany.split('.')[0] : '';
                                object.id = data._id;
                                object.user_id = _ids[i];
                                object.companyName = data.companyName ? data.companyName : ' ';
                                object.firstName = data.firstName;
                                object.lastName = data.lastName;
                                object.profileURL = data.profileURL;
                                object.title = data.title;
                                object.email = data.email;
                                object.phone = data.phone;
                                object.location = data.location;
                                object.yearsAtCompany = data.yearsAtCompany;
                                object.since = year && year > 0 ? ((new Date()).getFullYear() - year).toString() : '';
                                if (object.since === '0') {
                                    object.since = '<1';
                                }
                                object.mutualConnections = data.mutualConnections;
                                object.connectedOn = data.connectedOn;
                                if (object.connectedOn) {
                                    object.connectedOn = _this.formatDate(object.connectedOn);
                                }
                                else {
                                    object.connectedOn = ' ';
                                }
                                object.companyProfileURL = data.companyProfileURL;
                                //if (allKeys.indexOf(object.user_id) == -1) {
                                dataArray.push(object);
                                //allKeys.push(object.user_id);
                                //}
                            }
                        }
                        else {
                            var object = new PXData();
                            var year = data.yearsAtCompany && data.yearsAtCompany.split('.').length > 1 ? data.yearsAtCompany.split('.')[0] : '';
                            object.id = data._id;
                            object.user_id = _ids[i];
                            object.companyName = data.companyName ? data.companyName : ' ';
                            object.firstName = data.firstName;
                            object.lastName = data.lastName;
                            object.profileURL = data.profileURL;
                            object.title = data.title;
                            object.email = data.email;
                            object.phone = data.phone;
                            object.location = data.location;
                            object.yearsAtCompany = data.yearsAtCompany;
                            object.since = year && year > 0 ? ((new Date()).getFullYear() - year).toString() : '';
                            if (object.since === '0') {
                                object.since = '<1';
                            }
                            object.mutualConnections = data.mutualConnections;
                            object.connectedOn = data.connectedOn;
                            if (object.connectedOn) {
                                object.connectedOn = _this.formatDate(object.connectedOn);
                            }
                            else {
                                object.connectedOn = ' ';
                            }
                            object.companyProfileURL = data.companyProfileURL;
                            //if (allKeys.indexOf(object.user_id) == -1) {
                            dataArray.push(object);
                            //allKeys.push(object.user_id);
                            //}
                        }
                    }
                }
                ;
                observer.next(dataArray);
            });
        });
    };
    UserService.prototype.getCompanyEUdata = function (_id, from, to, users, directionI, directionS) {
        var e_2, _a;
        var _this = this;
        if (directionI === void 0) { directionI = 0; }
        if (directionS === void 0) { directionS = 0; }
        var url = this.getUrl('/pxdata/documents/export/' + _id + '/' + from + '/' + to + "?directionI=" + directionI + "&directionS=" + directionS);
        var ids = [];
        if (users) {
            try {
                for (var users_1 = tslib_1.__values(users), users_1_1 = users_1.next(); !users_1_1.done; users_1_1 = users_1.next()) {
                    var i = users_1_1.value;
                    ids.push(i._id);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (users_1_1 && !users_1_1.done && (_a = users_1.return)) _a.call(users_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        return this.http.put(url, { ids: ids }, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var dataArray = [];
            querySnapshot.forEach(function (data) {
                var object = new PXData();
                var year = data.yearsAtCompany && data.yearsAtCompany.split('.').length > 1 ? data.yearsAtCompany.split('.')[0] : '';
                object.id = data.Id;
                object.messages = data.messages;
                object.companyName = data.companyName ? data.companyName : ' ';
                object.firstName = data.firstName;
                object.lastName = data.lastName;
                object.profileURL = data.profileURL;
                object.title = data.title;
                object.email = data.email;
                object.phone = data.phone;
                object.location = data.location;
                object.yearsAtCompany = data.yearsAtCompany;
                object.since = year && year > 0 ? ((new Date()).getFullYear() - year).toString() : '';
                if (object.since === '0') {
                    object.since = '<1';
                }
                object.mutualConnections = data.mutualConnections;
                object.connectedOn = data.connectedOn;
                if (object.connectedOn) {
                    object.connectedOn = _this.formatDate(object.connectedOn);
                }
                else {
                    object.connectedOn = ' ';
                }
                object.companyProfileURL = data.companyProfileURL;
                object.user = data.user || "";
                dataArray.push(object);
            });
            return dataArray;
        }));
    };
    UserService.prototype.getUserAVdata = function (_id, listKey) {
        var _this = this;
        return new Observable(function (observer) {
            _this.shouldUseCachedAVData(_id).subscribe(function (shouldUseCachedAV) {
                if (listKey === 'all' && shouldUseCachedAV && _this.myCache.get('USER_AVDATA')) {
                    console.log('Getting user AVData from cache...');
                    observer.next(JSON.parse(_this.myCache.get('USER_AVDATA')));
                }
                else {
                    // console.log('Getting user AVData from database...');
                    var url = _this.getUrl('/avdata/documents/review/' + _id + '/' + listKey);
                    _this.http.get(url, _this.getOptions())
                        .subscribe(function (querySnapshot) {
                        var dataArray = [];
                        querySnapshot.forEach(function (data) {
                            var object = new PXData();
                            var year = data.yearsAtCompany ? data.yearsAtCompany.split('.')[0] : 0;
                            object.id = data._id;
                            object.user_id = data.user_id;
                            object.member_id = data.member_id;
                            object.companyName = data.companyName;
                            object.companyId = data.companyId;
                            object.firstName = data.firstName;
                            object.lastName = data.lastName;
                            object.profileURL = data.profileURL;
                            object.title = data.title;
                            object.email = data.email;
                            object.phone = data.phone;
                            object.location = data.location;
                            object.industry = data.industry;
                            object.approved = data.approved;
                            object.yearsAtCompany = data.yearsAtCompany;
                            object.since1 = year && year > 0 ? ((new Date()).getFullYear() - year) : 0;
                            object.mutualConnections = data.mutualConnections;
                            object.connectedOn = data.connectedOn;
                            object.companyProfileURL = data.companyProfileURL;
                            object.listId = data.listId;
                            object.rejectionReason = data.rejectionReason;
                            object.tempSelected = data.tempSelected;
                            object.organization_location_1 = data.organization_location_1 || "";
                            object.organization_industry_1 = data.organization_industry_1 || "";
                            object.organization_headcount_1 = data.organization_headcount_1 || "";
                            // Randy added this as it wasn't showing up on review
                            object.followers = data.followers || "";
                            object.organization_website_1 = data.organization_website_1 || "";
                            dataArray.push(object);
                        });
                        if (listKey === 'all') {
                            _this.myCache.set("USER_AVDATA", JSON.stringify(dataArray), 86400000);
                            // localStorage.setItem('USER_AVDATA', JSON.stringify(dataArray));
                            localStorage.setItem('USER_AVDATA_CACHED_AT', '' + new Date().getTime());
                        }
                        observer.next(dataArray);
                    });
                }
            });
        });
    };
    UserService.prototype.getAVlistPrep = function (_id, type) {
        console.log('Getting user AVLists from database...');
        var url = this.getUrl('/avlists/documents/list-prep/' + _id);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var dataArray = [];
            var users = [];
            var companies = [];
            // if (type === 'unapproved') {
            // 	querySnapshot = querySnapshot.filter((item) => item.approvedOn === 0);
            // }
            querySnapshot.forEach(function (data) {
                var object = new AVLists();
                object._id = data._id;
                object.listName = data.listName;
                object.listFor = data.listFor;
                var newUser = new listUser();
                var found = users.some(function (el) { return el._id === data.listFor; });
                if (data.name && !found) {
                    newUser._id = data.listFor;
                    newUser.name = data.name;
                    users.push(newUser);
                }
                object.uploadedCount = data.uploadedCount;
                object.approvedOn = data.approvedOn;
                object.status = data.approvedOn ? 'Y' : 'N';
                object.finalized_on = data.finalized_on;
                object.researcher = data.researcher;
                object.research_finalized_note = data.research_finalized_note;
                object.ext_rec_count = data.ext_rec_count;
                object.company = data.company;
                object.createdAt = data.createdAt;
                if (data.company && !companies.includes(data.company)) {
                    companies.push(data.company);
                }
                object.name = data.name;
                object.listPrepComplete = !!data.listPrepComplete;
                object.research_finalized = !!data.research_finalized;
                object.emptyFields = data.emptyFields;
                dataArray.push(object);
            });
            return { avlists: dataArray, companies: companies, users: users };
        }));
    };
    UserService.prototype.getAVlists = function (_id, type) {
        console.log('Getting user AVLists from database...');
        var url = this.getUrl('/avlists/documents/' + _id);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var dataArray = [];
            var users = [];
            var companies = [];
            if (type === 'unapproved') {
                querySnapshot = querySnapshot.filter(function (item) { return item.approvedOn === 0; });
            }
            querySnapshot.forEach(function (data) {
                var object = new AVLists();
                object._id = data._id;
                object.listName = data.listName;
                object.listFor = data.listFor;
                var newUser = new listUser();
                var found = users.some(function (el) { return el._id === data.listFor; });
                if (data.name && !found) {
                    newUser._id = data.listFor;
                    newUser.name = data.name;
                    users.push(newUser);
                }
                object.uploadedCount = data.uploadedCount;
                object.uploadedOn = data.uploadedOn;
                object.approvedOn = data.approvedOn;
                object.status = data.approvedOn ? 'Y' : 'N';
                object.approvedBy = data.approvedBy;
                object.approvedCount = data.approvedCount;
                object.connectedCount = data.connectedCount;
                object.notApproved = data.uploadedCount - data.approvedCount;
                object.approvedPercentage = Math.round((data.approvedCount / data.uploadedCount) * 100);
                object.connectedPercentage = data.approvedCount ? Math.round((data.connectedCount / data.approvedCount) * 100) : 0;
                object.positiveCount = data.positiveCount;
                object.invitedCount = data.invitedCount;
                object.respondedCount = data.respondedCount;
                object.listDescription = data.listDescription;
                object.company = data.company;
                object.createdAt = data.createdAt;
                object.research_finalized = data.research_finalized;
                if (data.company && !companies.includes(data.company)) {
                    companies.push(data.company);
                }
                object.name = data.name;
                object.daysSinceUpload = Math.round((new Date().getTime() - new Date(data.uploadedOn).getTime()) / 3600000 / 24);
                object.listPrepComplete = !!data.listPrepComplete;
                dataArray.push(object);
            });
            return { avlists: dataArray, companies: companies, users: users };
        }));
    };
    UserService.prototype.getAVReviewedlists = function (_id) {
        console.log('Getting user AVLists from database...');
        var url = this.getUrl('/avlists/documents/' + _id);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var dataArray = [];
            var users = [];
            var companies = [];
            querySnapshot.forEach(function (data) {
                if (data.approvedOn > 0) {
                    var object = new AVLists();
                    object._id = data._id;
                    object.listName = data.listName;
                    object.listFor = data.listFor;
                    var newUser = new listUser();
                    var found = users.some(function (el) { return el._id === data.listFor; });
                    if (!found) {
                        newUser._id = data.listFor;
                        newUser.name = data.name;
                        users.push(newUser);
                    }
                    object.uploadedCount = data.uploadedCount;
                    object.uploadedOn = data.uploadedOn;
                    object.approvedOn = data.approvedOn;
                    object.status = data.approvedOn ? 'Y' : 'N';
                    object.approvedBy = data.approvedBy;
                    object.approvedCount = data.approvedCount;
                    object.notApproved = data.uploadedCount - data.approvedCount;
                    object.approvedPercentage = Math.round((data.approvedCount / data.uploadedCount) * 100);
                    object.connectedCount = data.connectedCount;
                    object.positiveCount = data.positiveCount;
                    object.invitedCount = data.invitedCount;
                    object.respondedCount = data.respondedCount;
                    object.company = data.company;
                    object.createdAt = data.createdAt;
                    if (!companies.includes(data.company)) {
                        companies.push(data.company);
                    }
                    object.name = data.name;
                    dataArray.push(object);
                }
            });
            return { avlists: dataArray, companies: companies, users: users };
        }));
    };
    UserService.prototype.getAVDataByListId = function (_id, user_id) {
        var _this = this;
        return new Observable(function (observer) {
            _this.shouldUseCachedListAVData(user_id).subscribe(function (shouldUseCachedListAV) {
                if (shouldUseCachedListAV && _this.myCache.get('LIST_AVDATA')) {
                    console.log('Getting list AVData from cache...');
                    observer.next(JSON.parse(_this.myCache.get('LIST_AVDATA')));
                }
                else {
                    console.log('Getting list AVData from database...');
                    var url = _this.getUrl('/avdata/documents/list-contacts/' + _id);
                    _this.http.get(url, _this.getOptions())
                        .subscribe(function (querySnapshot) {
                        var dataArray = [];
                        querySnapshot.forEach(function (data) {
                            var object = new PXData();
                            var year = data.yearsAtCompany ? data.yearsAtCompany.split('.')[0] : 0;
                            object.id = data._id;
                            object.user_id = data.user_id;
                            object.member_id = data.member_id;
                            object.companyName = data.companyName;
                            object.firstName = data.firstName;
                            object.lastName = data.lastName;
                            object.profileURL = data.profileURL;
                            object.title = data.title;
                            object.email = data.email;
                            object.phone = data.phone;
                            object.location = data.location;
                            object.industry = data.industry;
                            object.organization_location_1 = data.organization_location_1;
                            object.organization_headcount_1 = data.organization_headcount_1;
                            object.organization_industry_1 = data.organization_industry_1;
                            object.organization_website_1 = data.organization_website_1;
                            object.approved = data.approved;
                            object.followers = data.followers;
                            object.yearsAtCompany = data.yearsAtCompany;
                            object.since1 = year && year > 0 ? ((new Date()).getFullYear() - year) : 0;
                            object.mutualConnections = data.mutualConnections;
                            object.connectedOn = data.connectedOn;
                            object.companyProfileURL = data.companyProfileURL;
                            object.listId = data.listId;
                            object.rejectionReason = data.rejectionReason;
                            dataArray.push(object);
                        });
                        _this.myCache.set("LIST_AVDATA", JSON.stringify(dataArray), 86400000);
                        // localStorage.setItem('LIST_AVDATA', JSON.stringify(dataArray));
                        localStorage.setItem('LIST_AVDATA_CACHED_AT', '' + new Date().getTime());
                        observer.next(dataArray);
                    });
                }
            });
        });
    };
    UserService.prototype.editAVlist = function (_id, body) {
        var url = this.getUrl('/avlists/documents/' + _id);
        return this.http.put(url, body, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    UserService.prototype.deleteAVlist = function (_id) {
        var url = this.getUrl('/avlists/documents/' + _id);
        return this.http.delete(url, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    UserService.prototype.getProfileURLs = function (_id) {
        var url = this.getUrl('/avlists/documents/profile-urls/' + _id);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    UserService.prototype.deleteRecord = function (_id) {
        var url = this.getUrl('/avdata/' + _id);
        return this.http.delete(url, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    UserService.prototype.getUserApprovedAVdata = function (_id) {
        var _this = this;
        return new Observable(function (observer) {
            _this.shouldUseCachedAVDataReviewed(_id).subscribe(function (shouldUseCachedAVReviewed) {
                if (shouldUseCachedAVReviewed && _this.myCache.get('USER_REVIEWED_AVDATA')) {
                    console.log('Getting user AVData Reviewed from cache...');
                    observer.next(JSON.parse(_this.myCache.get('USER_REVIEWED_AVDATA')));
                }
                else {
                    console.log('Getting user AVData Reviewed from database...');
                    var url = _this.getUrl('/avdata/documents/approved/' + _id);
                    _this.http.get(url, _this.getOptions())
                        .subscribe(function (querySnapshot) {
                        var dataArray = [];
                        querySnapshot.forEach(function (data) {
                            var object = new PXData();
                            var year = data.yearsAtCompany ? data.yearsAtCompany.split('.')[0] : '0';
                            object.id = data._id;
                            object.user_id = data.user_id;
                            object.member_id = data.member_id;
                            object.companyName = data.companyName;
                            object.firstName = data.firstName;
                            object.lastName = data.lastName;
                            object.profileURL = data.profileURL;
                            object.title = data.title;
                            object.email = data.email;
                            object.phone = data.phone;
                            object.location = data.location;
                            object.industry = data.industry;
                            object.approved = data.approved;
                            object.approvedOn = data.approvedOn;
                            object.yearsAtCompany = data.yearsAtCompany;
                            object.since1 = year && year > 0 ? ((new Date()).getFullYear() - year) : 0;
                            object.mutualConnections = data.mutualConnections;
                            object.connectedOn = data.connectedOn;
                            object.companyProfileURL = data.companyProfileURL;
                            dataArray.push(object);
                        });
                        _this.myCache.set("USER_REVIEWED_AVDATA", JSON.stringify(dataArray), 86400000);
                        // localStorage.setItem('USER_REVIEWED_AVDATA', JSON.stringify(dataArray));
                        localStorage.setItem('USER_REVIEWED_AVDATA_CACHED_AT', '' + new Date().getTime());
                        observer.next(dataArray);
                    });
                }
            });
        });
    };
    UserService.prototype.formatDate = function (date) {
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear().toString();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [month, day, year].join('/');
    };
    UserService.prototype.formatDateWithShortYear = function (date) {
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear().toString().substr(-2);
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [month, day, year].join('/');
    };
    UserService.prototype.shouldUseCachedPXData = function (_id) {
        var _this = this;
        return new Observable(function (observer) {
            if (!localStorage.getItem('USER_PXDATA_CACHED_AT')) {
                observer.next(false);
            }
            else {
                var url = _this.getUrl('/pxdata-stats/' + _id);
                _this.http.get(url, _this.getOptions()).subscribe(function (querySnapshot) {
                    if (querySnapshot && parseInt(querySnapshot.pxUpdatedOn) < parseInt(localStorage.getItem('USER_PXDATA_CACHED_AT'))) {
                        observer.next(true);
                    }
                    else {
                        observer.next(false);
                    }
                });
            }
        });
    };
    UserService.prototype.shouldUseCachedMessages = function (_id) {
        var _this = this;
        return new Observable(function (observer) {
            if (!localStorage.getItem('USER_MESSAGES_CACHED_AT')) {
                observer.next(false);
            }
            else {
                var url = _this.getUrl('/pxdata-stats/' + _id);
                _this.http.get(url, _this.getOptions()).subscribe(function (querySnapshot) {
                    if (querySnapshot && parseInt(querySnapshot.msgUpdateOn) < parseInt(localStorage.getItem('USER_MESSAGES_CACHED_AT'))) {
                        observer.next(true);
                    }
                    else {
                        observer.next(false);
                    }
                });
            }
        });
    };
    UserService.prototype.shouldUseCachedMessagesBeta = function (_id) {
        var _this = this;
        return new Observable(function (observer) {
            if (!localStorage.getItem('USER_MESSAGES_BETA_CACHED_AT')) {
                observer.next(false);
            }
            else {
                var url = _this.getUrl('/pxdata-stats/' + _id);
                _this.http.get(url, _this.getOptions()).subscribe(function (querySnapshot) {
                    if (querySnapshot && parseInt(querySnapshot.msgUpdateOn) < parseInt(localStorage.getItem('USER_MESSAGES_BETA_CACHED_AT'))) {
                        observer.next(true);
                    }
                    else {
                        observer.next(false);
                    }
                });
            }
        });
    };
    UserService.prototype.shouldUseCachedIncomingMessagesBeta = function (_id) {
        var _this = this;
        return new Observable(function (observer) {
            if (!localStorage.getItem('USER_INCOMING_MESSAGES_BETA_CACHED_AT')) {
                observer.next(false);
            }
            else {
                var url = _this.getUrl('/pxdata-stats/' + _id);
                _this.http.get(url, _this.getOptions()).subscribe(function (querySnapshot) {
                    if (querySnapshot && parseInt(querySnapshot.msgUpdateOn) < parseInt(localStorage.getItem('USER_INCOMING_MESSAGES_BETA_CACHED_AT'))) {
                        observer.next(true);
                    }
                    else {
                        observer.next(false);
                    }
                });
            }
        });
    };
    UserService.prototype.shouldUseCachedAVData = function (_id) {
        var _this = this;
        return new Observable(function (observer) {
            if (!localStorage.getItem('USER_AVDATA_CACHED_AT')) {
                observer.next(false);
            }
            else {
                var url = _this.getUrl('/pxdata-stats/' + _id);
                _this.http.get(url, _this.getOptions()).subscribe(function (querySnapshot) {
                    if (querySnapshot && parseInt(querySnapshot.avUpdatedOn) < parseInt(localStorage.getItem('USER_AVDATA_CACHED_AT'))) {
                        observer.next(true);
                    }
                    else {
                        observer.next(false);
                    }
                });
            }
        });
    };
    UserService.prototype.shouldUseCachedListAVData = function (_id) {
        var _this = this;
        return new Observable(function (observer) {
            if (!localStorage.getItem('LIST_AVDATA_CACHED_AT')) {
                observer.next(false);
            }
            else {
                var url = _this.getUrl('/pxdata-stats/' + _id);
                _this.http.get(url, _this.getOptions()).subscribe(function (querySnapshot) {
                    if (querySnapshot && parseInt(querySnapshot.avUpdatedOn) < parseInt(localStorage.getItem('LIST_AVDATA_CACHED_AT'))) {
                        observer.next(true);
                    }
                    else {
                        observer.next(false);
                    }
                });
            }
        });
    };
    UserService.prototype.shouldUseCachedAVDataReviewed = function (_id) {
        var _this = this;
        return new Observable(function (observer) {
            if (!localStorage.getItem('USER_REVIEWED_AVDATA_CACHED_AT')) {
                observer.next(false);
            }
            else {
                var url = _this.getUrl('/pxdata-stats/' + _id);
                _this.http.get(url, _this.getOptions()).subscribe(function (querySnapshot) {
                    if (querySnapshot && parseInt(querySnapshot.avDataChangedOn) < parseInt(localStorage.getItem('USER_REVIEWED_AVDATA_CACHED_AT'))) {
                        observer.next(true);
                    }
                    else {
                        observer.next(false);
                    }
                });
            }
        });
    };
    // secound dashboard
    UserService.prototype.secoundDashboard = function () {
        var _this = this;
        return new Observable(function (observer) {
            var url = _this.getUrl('/users');
            _this.http.get(url, _this.getOptions()).subscribe(function (querySnapshot) {
                // console.log('query',querySnapshot);
                querySnapshot.forEach(function (item) {
                    var usercompany = item.val();
                    // console.log('usercompany',usercompany);
                    var data = {};
                    // console.log('key',item.key);
                    data['company_name_user'] = (querySnapshot.val().name) ? querySnapshot.val().name : '';
                    var date7Days = new Date();
                    var date30Days = new Date();
                    date7Days.setDate(date7Days.getDate() - 7);
                    date30Days.setDate(date30Days.getDate() - 30);
                    var url = _this.getUrl('/messages/' + item._id);
                });
                observer.next();
            });
        });
    };
    UserService.prototype.getRejectionReasons = function () {
        var _this = this;
        return new Observable(function (observer) {
            var url = _this.getUrl('/rejection-reasons');
            _this.http.get(url, _this.getOptions()).subscribe(function (querySnapshot) {
                observer.next(querySnapshot);
            });
        });
    };
    UserService.prototype.updateReason = function (id, rejectionReason) {
        var url = this.getUrl('/avdata/rejection-reason/' + id);
        return this.http.put(url, { rejectionReason: rejectionReason }, this.getOptions())
            .pipe(map(function (res) {
            return true;
        }));
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SlackService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
