<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="container campaigns" *ngIf="!isLoading">
    <h3>Strategy</h3>
    <span>
        Who would you like RMD to message?
    </span>

    <form [formGroup]="strategyForm" (ngSubmit)="strategyFormSubmit()" autocomplete="off" novalidate #formRef="ngForm">

        <div class="input">
            <div class="custom-control custom-radio">
                <input class="custom-control-input" type="radio" id="msgTargetType-msgTargetCampaign" (change)="handleChange()" formControlName="msgTargetType" value="msgTargetCampaign" mdbInput />
                <label class="custom-control-label" for="msgTargetType-msgTargetCampaign">
                    Connections from a campaign
                </label>
            </div>
        </div>

        <div class="input" *ngIf="strategyForm.value.msgTargetType === 'msgTargetCampaign'">
            <select class="browser-default custom-select custom-select-lg" formControlName="msgTargetCampaign">
                <option value="" selected></option>
                <option *ngFor="let campaign of campaigns" value="{{campaign._id}}">{{campaign.campaignName}}</option>
            </select>
            <span class="error" *ngIf="strategyForm.controls['msgTargetCampaign'].hasError('required') && formRef.submitted">
                Please select a list from the dropdown.
            </span>
        </div>

        <div class="input">
            <div class="custom-control custom-radio">
                <input class="custom-control-input" type="radio" id="msgTargetType-msgTargetList" (change)="handleChange()" formControlName="msgTargetType" value="msgTargetList" mdbInput />
                <label class="custom-control-label" for="msgTargetType-msgTargetList">
                    Connections from a list I created
                </label>
            </div>
        </div>

        <div class="input" *ngIf="strategyForm.value.msgTargetType === 'msgTargetList'">
            <input type="text" id="msgTargetList" formControlName="msgTargetList" placeholder="please enter a link to your list here" class="form-control form-control-lg" />
            <span class="error" *ngIf="strategyForm.controls['msgTargetList'].hasError('required') && formRef.submitted">
                Please provide a link to your list.
            </span>
        </div>

        <div class="input">
            <div class="custom-control custom-radio">
                <input class="custom-control-input" type="radio" id="msgTargetType-msgTargetNote" (change)="handleChange()" formControlName="msgTargetType" value="msgTargetNote" mdbInput />
                <label class="custom-control-label" for="msgTargetType-msgTargetNote">
                    Other
                </label>
            </div>
        </div>

        <div class="input" *ngIf="strategyForm.value.msgTargetType === 'msgTargetNote'">
            <textarea class="form-control" formControlName="msgTargetNote" rows="7" placeholder="please leave us a note here"></textarea>
            <span class="error" *ngIf="strategyForm.controls['msgTargetNote'].hasError('required') && formRef.submitted">
                Please provide a description of your list.
            </span>
        </div>

        <div class="buttons">
            <button type="submit" *ngIf="editMode" class="previous" (click)="clickDoneButton()">Done</button>
            <button type="button" *ngIf="!editMode" class="previous" (click)="cancelNewCampaign()">Cancel</button>
            <button type="button" class="previous" (click)="gotoCreateNewCampaign()">Previous</button>
            <button type="submit">Next</button>
        </div>
    </form>
</div>