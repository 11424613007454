/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-connections.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/angular-font-awesome/dist/angular-font-awesome.ngfactory";
import * as i4 from "angular-font-awesome";
import * as i5 from "../../../../../node_modules/ag-grid-angular/ag-grid-angular.ngfactory";
import * as i6 from "ag-grid-angular";
import * as i7 from "../../../../../node_modules/ng-uikit-pro-standard/ng-uikit-pro-standard.ngfactory";
import * as i8 from "ng-uikit-pro-standard";
import * as i9 from "./user-connections.component";
import * as i10 from "../../../services/user.service";
var styles_UserConnectionsComponent = [i0.styles];
var RenderType_UserConnectionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserConnectionsComponent, data: {} });
export { RenderType_UserConnectionsComponent as RenderType_UserConnectionsComponent };
function View_UserConnectionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading\u2026"]))], null, null); }
function View_UserConnectionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h5", [["class", "modal-title"], ["id", "exampleModalLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 14).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["style", "width: 100%;"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedRow.firstName; var currVal_1 = _co.selectedRow.lastName; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.selectedRow.avatar; _ck(_v, 8, 0, currVal_2); }); }
export function View_UserConnectionsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { showModalOnClick: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserConnectionsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "fa", [["name", "user"]], null, null, null, i3.View_AngularFontAwesomeComponent_0, i3.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(5, 114688, null, 0, i4.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null), (_l()(), i1.ɵted(6, null, [" ", " - Connections\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["id", "gridContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-alpine"], ["style", "width: 100%; height: calc(100% - 17px)"]], [[8, "enableColResize", 0], [8, "enableSorting", 0], [8, "enableFilter", 0]], [[null, "paginationChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("paginationChanged" === en)) {
        var pd_0 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_AgGridAngular_0, i5.RenderType_AgGridAngular)), i1.ɵprd(512, null, i6.AngularFrameworkOverrides, i6.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i6.AngularFrameworkComponentWrapper, i6.AngularFrameworkComponentWrapper, []), i1.ɵdid(11, 4898816, null, 1, i6.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i6.AngularFrameworkOverrides, i6.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"], overlayNoRowsTemplate: [2, "overlayNoRowsTemplate"], pagination: [3, "pagination"], paginationAutoPageSize: [4, "paginationAutoPageSize"] }, { paginationChanged: "paginationChanged" }), i1.ɵqud(603979776, 2, { columns: 1 }), (_l()(), i1.ɵeld(13, 16777216, null, null, 4, "div", [["aria-hidden", "true"], ["aria-labelledby", "myBasicModal1Label"], ["class", "modal fade"], ["mdbModal", ""], ["role", "dialog"], ["style", "z-index: 10000;"], ["tabindex", "+1"]], null, [[null, "keydown"], [null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.esc" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14).onEsc() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_ModalDirective_0, i7.RenderType_ModalDirective)), i1.ɵdid(14, 4898816, [[1, 4], ["basicModal", 4]], 0, i8.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i8.ɵdj], null, null), (_l()(), i1.ɵeld(15, 0, null, 0, 2, "div", [["class", "modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserConnectionsComponent_2)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = "user"; _ck(_v, 5, 0, currVal_1); var currVal_6 = _co.rows; var currVal_7 = _co.columnDefs; var currVal_8 = _co.noDataText; var currVal_9 = true; var currVal_10 = true; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.selectedRow; _ck(_v, 17, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.userconnectionName; _ck(_v, 6, 0, currVal_2); var currVal_3 = true; var currVal_4 = true; var currVal_5 = true; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_UserConnectionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-connections", [], null, null, null, View_UserConnectionsComponent_0, RenderType_UserConnectionsComponent)), i1.ɵdid(1, 114688, null, 0, i9.UserConnectionsComponent, [i10.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserConnectionsComponentNgFactory = i1.ɵccf("app-user-connections", i9.UserConnectionsComponent, View_UserConnectionsComponent_Host_0, {}, {}, []);
export { UserConnectionsComponentNgFactory as UserConnectionsComponentNgFactory };
