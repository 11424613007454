import { Validators } from '@angular/forms';
import { OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { editorConfig } from 'src/app/helpers/ckeditor';
var SettingComponent = /** @class */ (function () {
    function SettingComponent(formBuilder, adminsetting) {
        this.formBuilder = formBuilder;
        this.adminsetting = adminsetting;
        this.isLoading = false;
        this.Editor = ClassicEditor;
        this.editorConfig = editorConfig;
        this.admin_setting = this.formBuilder.group({
            approved_7day: ['', [Validators.required]],
            approved_30day: ['', [Validators.required]],
            message_7day: ['', [Validators.required]],
            message_30day: ['', [Validators.required]],
            connection_7day: ['', [Validators.required]],
            connection_30day: ['', [Validators.required]]
        });
    }
    SettingComponent.prototype.ngOnInit = function () {
        this.getSettingValue();
    };
    // save setting
    SettingComponent.prototype.onSubmit = function () {
        this.adminsetting.updateSetting(this.admin_setting.value).subscribe(function (result) {
            if (result == true) {
                alert('Setting change successfully');
            }
            else {
                alert('Some Thing want worng');
            }
        }, function (err) {
            alert('error');
            console.log('error to update setting: ', err);
        });
    };
    // get setting value
    SettingComponent.prototype.getSettingValue = function () {
        var _this = this;
        this.isLoading = true;
        this.adminsetting.getSettingValue().subscribe(function (result) {
            if (result != false) {
                _this.isLoading = false;
                _this.admin_setting.get('approved_7day').setValue(result.approved_7day);
                _this.admin_setting.get('approved_30day').setValue(result.approved_30day);
                _this.admin_setting.get('message_7day').setValue(result.message_7day);
                _this.admin_setting.get('message_30day').setValue(result.message_30day);
                _this.admin_setting.get('connection_7day').setValue(result.connection_7day);
                _this.admin_setting.get('connection_30day').setValue(result.connection_30day);
                _this.message = result.message;
            }
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get setting value: ', err);
        });
    };
    // save message
    SettingComponent.prototype.saveMessage = function () {
        this.adminsetting.updateMessage(this.message).subscribe(function (result) {
            if (result == true) {
                alert('Message change successfully');
            }
            else {
                alert('Some Thing want worng');
            }
        }, function (err) {
            alert('error');
            console.log('error to update setting: ', err);
        });
    };
    return SettingComponent;
}());
export { SettingComponent };
