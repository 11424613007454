import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, ViewChild, OnInit } from '@angular/core';

import { User } from 'src/app/models/user';
import { AVLists } from 'src/app/models/AVLists';
import { getUserRole } from 'src/app/helpers/getUserRole';

import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-unapproved-lists',
  templateUrl: './unapproved-lists.component.html',
  styleUrls: ['./unapproved-lists.component.scss']
})
export class UnapprovedListsComponent implements OnInit {

  @ViewChild('editModal', { static: true }) editModal: ModalDirective;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;

  editId = '';
  users: User[];
  listName = '';
  deleteId = '';
  userValue = '';
  isLoading = true;
  companyValue = '';
  sortOrder = 'desc';
  avlists: AVLists[];
  companies: string[];
  listDescription = '';
  sortBy = 'uploadedOn';
  filteredAvlists: AVLists[];
  isEU = getUserRole() === '4' ? true : false;
  isCA = getUserRole() === '3' ? true : false;
  isRSA = getUserRole() === '1' ? true : false;
  listPrepComplete: boolean = false;
  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {
    if (this.isCA) {
      this.userService.getCAUsers().subscribe((res) => {
        this.users = res.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

      }, (err) => {
        alert('error');
        console.log('error to get ca users: ', err);
      });
    }

    const listFor = localStorage.getItem('USER_LISTS_KEY') ? localStorage.getItem('USER_LISTS_KEY') : 'all';

    this.userService.getAVlists(listFor, 'unapproved').subscribe((res: any) => {
      this.avlists = res.avlists;

      this.companies = res.companies.sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        }
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      if (this.isRSA) {
        this.users = res.users.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      }

      if (this.isCA) {
        this.avlists = this.avlists.filter((item: AVLists) => item.company === localStorage.getItem('USER_COMPANY'));
      }

      this.companyValue = localStorage.getItem('LISTS_COMPANY_FILTER') ? localStorage.getItem('LISTS_COMPANY_FILTER') : '';
      this.userValue = localStorage.getItem('LISTS_USER_FILTER') ? localStorage.getItem('LISTS_USER_FILTER') : '';

      this.filter();
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get dashboard: ', err);
    });
  }

  filter(): AVLists[] {
    if (this.companyValue || this.userValue) {
      localStorage.setItem('CA_CONNECTIONS_KEY', '');
      localStorage.setItem('CA_CONNECTIONS_NAME', '');
      localStorage.setItem('CA_CONNECTIONS_LINKEDINID', '');
      localStorage.removeItem('USER_PXDATA_CACHED_AT');
      localStorage.removeItem('USER_PXDATA');
    }

    this.filteredAvlists = this.avlists;

    if (this.companyValue) {
      localStorage.setItem('LISTS_COMPANY_FILTER', this.companyValue);
      this.filteredAvlists = this.filteredAvlists.filter((item: AVLists) => item.company === this.companyValue);
    } else {
      localStorage.removeItem('LISTS_COMPANY_FILTER');
    }

    if (this.userValue) {
      localStorage.setItem('LISTS_USER_FILTER', this.userValue);
      this.filteredAvlists = this.filteredAvlists.filter((item: AVLists) => item.listFor === this.userValue);
    } else {
      localStorage.removeItem('LISTS_USER_FILTER');
    }

    return this.filteredAvlists;
  }

  openEditModal(avlist: AVLists): void {
    this.editId = avlist._id;
    this.listName = avlist.listName;
    this.listDescription = avlist.listDescription;
    this.listPrepComplete = avlist.listPrepComplete;
    this.editModal.show();
  }

  editList(): void {
    let data = { listName: this.listName, listDescription: this.listDescription, listPrepComplete: this.listPrepComplete }
    this.userService.editAVlist(this.editId, data).subscribe((res: any) => {
      if (res) {
        this.ngOnInit();
      }
      this.isLoading = false;
      this.editModal.hide();
    }, (err) => {
      this.isLoading = false;
      this.editModal.hide();
      alert('error');
      console.log('error to edit list: ', err);
    });
  }

  openDeleteModal(deleteId: string): void {
    this.deleteId = deleteId;
    this.deleteModal.show();
  }

  deleteList(): void {
    this.userService.deleteAVlist(this.deleteId).subscribe((res: any) => {
      if (res) {
        this.ngOnInit();
      }

      this.isLoading = false;
      this.deleteModal.hide();

    }, (err) => {
      this.isLoading = false;
      this.deleteModal.hide();
      alert('error');
      console.log('error to delete list: ', err);
    });
  }

  listContacts(list: AVLists, type: string): void {
    localStorage.removeItem('LIST_CONTACTS_VIEW_TYPE');

    if (list._id !== localStorage.getItem('LIST_CONTACTS_KEY')) {
      localStorage.removeItem('LIST_AVDATA_CACHED_AT');
    }

    switch (type) {
      case 'all':
        localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', '');
        break;
    }

    localStorage.setItem('LIST_CONTACTS_KEY', list._id);
    localStorage.setItem('LIST_CONTACTS_NAME', list.name);
    localStorage.setItem('LIST_CONTACTS_COMPANY', list.company);
    localStorage.setItem('LIST_CONTACTS_LISTNAME', list.listName);

    this.router.navigate(['/listContacts']);
  }

  listReviewbylist(list: AVLists): void {
    if (list._id !== localStorage.getItem('USER_REVIEW_LISTKEY')) {
      localStorage.removeItem('USER_AVDATA_CACHED_AT');
    }

    localStorage.setItem('USER_REVIEW_KEY', list.listFor);
    localStorage.setItem('USER_REVIEW_LISTKEY', list._id);
    localStorage.setItem('USER_REVIEW_NAME', list.name);

    if (this.isEU) {
      this.router.navigate(['/review']);
    } else {
      this.router.navigate(['/userReview']);
    }
  }

  isTwoDayGapCheck(date: number): boolean {
    if (date === 0) {
      return false;
    }

    let diff = Math.abs(new Date().getTime() - new Date(date).getTime()) / 3600000;

    return diff > 48;
  }

}