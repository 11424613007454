import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { User } from 'src/app/models/user';
import { AVLists } from 'src/app/models/AVLists';
import { getUserRole } from 'src/app/helpers/getUserRole';

import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-lists',
  templateUrl: './lists-reports.component.html',
  styleUrls: ['./lists-reports.component.scss']
})
export class ListsReportsComponent implements OnInit {

  users: User[];
  userValue = '';
  dateValue = '';
  isLoading = true;
  companyValue = '';
  sortOrder = 'desc';
  avlists: AVLists[];
  companies: string[];
  sortBy = 'uploadedOn';
  filteredAvlists: AVLists[];
  excludemostrecentlists = false;
  isEU = getUserRole() === '4' ? true : false;
  isCA = getUserRole() === '3' ? true : false;
  isRSA = getUserRole() === '1' ? true : false;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    if (this.isCA) {
      this.userService.getCAUsers().subscribe((res) => {
        this.users = res.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      }, (err) => {
        alert('error');
        console.log('error to get ca users: ', err);
      });
    }

    const listFor = localStorage.getItem('USER_LISTS_KEY') ? localStorage.getItem('USER_LISTS_KEY') : 'all';

    this.userService.getAVReviewedlists(listFor).subscribe((res: any) => {
      this.avlists = res.avlists;

      this.companies = res.companies.sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) {
          return -1;
        }
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      if (this.isRSA) {
        this.users = res.users.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      }

      if (this.isCA) {
        this.avlists = this.avlists.filter((item: AVLists) => item.company === localStorage.getItem('USER_COMPANY'));
      }

      this.filter();
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get dashboard: ', err)
    });
  }

  toggleExcludemostrecentlists() {
    this.excludemostrecentlists = !this.excludemostrecentlists;
    this.filter();
  }

  resetFilter() {
    this.userValue = '';
    this.dateValue = '';
    this.companyValue = '';
    this.excludemostrecentlists = false;
    localStorage.removeItem('USER_LISTS_KEY');
    localStorage.removeItem('USER_PXDATA_CACHED_AT');
    localStorage.removeItem('USER_PXDATA');
    this.loadData();
  }

  filter() {
    if (this.companyValue || this.userValue || this.dateValue) {
      localStorage.setItem('CA_CONNECTIONS_KEY', '');
      localStorage.setItem('CA_CONNECTIONS_NAME', '');
      localStorage.setItem('CA_CONNECTIONS_LINKEDINID', '');
      localStorage.removeItem('USER_PXDATA_CACHED_AT');
      localStorage.removeItem('USER_PXDATA');
    }

    this.filteredAvlists = this.avlists;

    if (this.companyValue) {
      this.filteredAvlists = this.filteredAvlists.filter((item: AVLists) => item.company === this.companyValue);
    }

    if (this.userValue) {
      this.filteredAvlists = this.filteredAvlists.filter((item: AVLists) => item.listFor === this.userValue);
    }

    if (this.dateValue !== '') {
      this.filteredAvlists = this.filteredAvlists.filter((item: AVLists) => (Math.abs(new Date().getTime() - new Date(item.createdAt).getTime()) / 3600000) < parseInt(this.dateValue) * 24);
    }

    if (this.excludemostrecentlists) {
      this.filteredAvlists = this.filteredAvlists.filter((item: AVLists) => (Math.abs(new Date().getTime() - new Date(item.createdAt).getTime()) / 3600000) > 7 * 24);
    }

    const arrById = this.filteredAvlists.reduce((newArr, item) => {
      // Group initialization
      if (!newArr[item.listFor]) {
        newArr[item.listFor] = [];
      }

      // Grouping
      newArr[item.listFor].push(item);

      return newArr;
    }, {});


    let finalArr = [];

    for (const [key, valueArr] of Object.entries(arrById)) {

      let temp = {};

      temp['listFor'] = key;
      temp['company'] = valueArr[0].company;
      temp['name'] = valueArr[0].name;
      temp['listCount'] = (<any>valueArr).length;
      temp['uploadedCount'] = 0;
      temp['approvedCount'] = 0;
      temp['notApproved'] = 0;
      temp['approvedPercentage'] = 0;
      temp['invitedCount'] = 0;
      temp['connectedCount'] = 0;
      temp['respondedCount'] = 0;
      temp['positiveCount'] = 0;

      for (let i = 0; i < (<any>valueArr).length; i++) {
        temp['uploadedCount'] += valueArr[i].uploadedCount;
        temp['approvedCount'] += valueArr[i].approvedCount;
        temp['notApproved'] += valueArr[i].notApproved;
        temp['approvedPercentage'] += valueArr[i].approvedPercentage;
        temp['invitedCount'] += valueArr[i].invitedCount;
        temp['connectedCount'] += valueArr[i].connectedCount;
        temp['respondedCount'] += valueArr[i].respondedCount;
        temp['positiveCount'] += valueArr[i].positiveCount;
      }

      if (isNaN(temp['invitedCount'])) {
        temp['invitedCount'] = 0;
      }
      if (isNaN(temp['connectedCount'])) {
        temp['connectedCount'] = 0;
      }
      if (isNaN(temp['respondedCount'])) {
        temp['respondedCount'] = 0;
      }
      if (isNaN(temp['positiveCount'])) {
        temp['positiveCount'] = 0;
      }

      temp['approvedPercentage'] = Math.round(temp['approvedPercentage'] / (<any>valueArr).length);
      temp['unApprovedPercentage'] = 100 - temp['approvedPercentage'];
      temp['invitedPercentage'] = Math.round(temp['invitedCount'] / temp['approvedCount'] * 100);
      temp['connectedPercentage'] = Math.round(temp['connectedCount'] / temp['invitedCount'] * 100);
      temp['respondedPercentage'] = Math.round(temp['respondedCount'] / temp['connectedCount'] * 100);
      temp['positivePercentage'] = Math.round(temp['positiveCount'] / temp['respondedCount'] * 100);

      if (temp['invitedPercentage'] === Infinity || isNaN(temp['invitedPercentage'])) {
        temp['invitedPercentage'] = 0;
      }
      if (temp['connectedPercentage'] === Infinity || isNaN(temp['connectedPercentage'])) {
        temp['connectedPercentage'] = 0;
      }
      if (temp['respondedPercentage'] === Infinity || isNaN(temp['respondedPercentage'])) {
        temp['respondedPercentage'] = 0;
      }
      if (temp['positivePercentage'] === Infinity || isNaN(temp['positivePercentage'])) {
        temp['positivePercentage'] = 0;
      }

      finalArr.push(temp);
    }

    return this.filteredAvlists = finalArr;
  }

  gotoListsWithCount(avlist: AVLists) {
    localStorage.setItem('LISTS_SHOW_FILTER', 'reviewed');
    localStorage.setItem('LISTS_COMPANY_FILTER', avlist.company);
    localStorage.setItem('LISTS_USER_FILTER', avlist.listFor);
    localStorage.setItem('LISTS_DATE_RANGE_FILTER', this.dateValue);
    localStorage.setItem('LISTS_EXCLUDE_RECENT_FILTER', this.excludemostrecentlists ? '1' : '0');

    this.router.navigate(['/lists']);
  }

}
