import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var StrategyTypeComponent = /** @class */ (function () {
    function StrategyTypeComponent(fb, strategyService, router) {
        this.fb = fb;
        this.strategyService = strategyService;
        this.router = router;
        this.isLoading = true;
        this.clickByDoneButton = false;
        this.editMode = localStorage.getItem("EDIT_STRATEGY") ? true : false;
        this.research_options = [
            {
                id: "wizard",
                value: "Use RMD's wizard. (Most people select this)"
            },
            {
                id: "savedSearch",
                value: "Use a saved search in Sales Navigator."
            },
        ];
        this.strategyForm = this.fb.group({
            userId: localStorage.getItem('USER_KEY'),
            research: this.fb.control('wizard'),
            savedSearchUrl: this.fb.control(''),
        });
    }
    StrategyTypeComponent.prototype.ngOnInit = function () {
        var _this = this;
        var campaignInfo = localStorage.getItem('CAMPAIGN_INFO');
        if (!campaignInfo) {
            this.router.navigate(['campaigns']);
            return;
        }
        this.strategyService.getStrategy(JSON.parse(campaignInfo).strategyId).subscribe(function (res) {
            console.log('existing strategy: ', res);
            if (res) {
                _this.strategyForm = _this.fb.group({
                    strategyId: res._id,
                    userId: localStorage.getItem('USER_KEY'),
                    research: _this.fb.control(res.research, Validators.required),
                    savedSearchUrl: _this.fb.control(res.savedSearchUrl),
                });
            }
            _this.checkValidation();
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user latest messages: ', err);
        });
    };
    StrategyTypeComponent.prototype.strategyFormSubmit = function () {
        var _this = this;
        if (this.strategyForm.valid) {
            this.strategyService.submitStrategy(this.strategyForm.value).subscribe(function (res) {
                console.log(res);
                if (_this.clickByDoneButton) {
                    _this.router.navigate(['campaigns']);
                    return;
                }
                if (res.research === 'wizard') {
                    _this.router.navigate(['new-strategy']);
                }
                else if (res.research === 'savedSearch') {
                    _this.router.navigate(['intro-message-sequence']);
                }
            }, function (err) {
                alert('error');
                console.log(err);
            });
        }
    };
    StrategyTypeComponent.prototype.checkValidation = function () {
        this.strategyForm.value.research;
        if (this.strategyForm.value.research === "savedSearch") {
            this.strategyForm.get('savedSearchUrl').setValidators([Validators.required]);
        }
        else {
            this.strategyForm.get('savedSearchUrl').clearValidators();
        }
        this.strategyForm.controls["savedSearchUrl"].updateValueAndValidity();
    };
    StrategyTypeComponent.prototype.cancelNewCampaign = function () {
        this.router.navigate(['campaigns']);
    };
    StrategyTypeComponent.prototype.gotoCreateNewCampaign = function () {
        this.router.navigate(['create-new-campaign']);
    };
    StrategyTypeComponent.prototype.clickDoneButton = function () {
        this.clickByDoneButton = true;
    };
    return StrategyTypeComponent;
}());
export { StrategyTypeComponent };
