import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CampaignService } from 'src/app/services/campaign.service';

@Component({
  selector: 'app-create-new-campaign',
  templateUrl: './create-new-campaign.component.html',
  styleUrls: ['./create-new-campaign.component.scss']
})
export class CreateNewCampaignComponent implements OnInit {

  isLoading = true;
  campaignForm: FormGroup;
  clickByDoneButton = false;
  editMode = localStorage.getItem("EDIT_CAMPAIGN") ? true : false;

  constructor(private fb: FormBuilder, public campaignService: CampaignService, private router: Router) {
    this.campaignForm = this.fb.group({
      userId: localStorage.getItem('USER_KEY'),
      campaignName: this.fb.control('', Validators.required),
      campaignType: this.fb.control('', Validators.required),
      campaignDescription: this.fb.control(''),
    });
  }

  ngOnInit(): void {
    const campaignInfo = localStorage.getItem('CAMPAIGN_INFO');

    if (!campaignInfo) {
      this.isLoading = false;
      return;
    }

    this.campaignService.getCampaign(JSON.parse(campaignInfo)._id).subscribe((res) => {
      console.log('existing campaign: ', res);
      if (res) {
        this.campaignForm = this.fb.group({
          campaignId: res._id,
          userId: localStorage.getItem('USER_KEY'),
          campaignName: this.fb.control(res.campaignName, Validators.required),
          campaignType: this.fb.control(res.campaignType, Validators.required),
          campaignDescription: this.fb.control(res.campaignDescription),
        });
      }

      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get user lastest messages: ', err);
    })
  }

  campaignFormSubmit() {
    if (this.campaignForm.valid) {
      this.isLoading = true;
      this.campaignService.submitCampaign(this.campaignForm.value).subscribe((res) => {
        localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(res));
        if (this.clickByDoneButton) {
          this.router.navigate(['campaigns']);
          return; // Add return statement
        }
        if (res.campaignType === 'Message Only') {
          this.router.navigate(['message-send-who']);
        } else {
          this.router.navigate(['strategy-type']);
        }
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log(err);
        return; // Add return statement to handle error case
      });
    }
  }
  cancelNewCampaign() {
    this.router.navigate(['campaigns']);
  }

  clickDoneButton() {
    this.clickByDoneButton = true;
  }

}
