import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';

import { UserService } from '../../../services/user.service';
import { AVListService } from 'src/app/services/avlists.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.scss']
})

export class ReviewListComponent implements OnInit {

  @ViewChild('approveModal', { static: true }) approveModal: ModalDirective;
  gridApi:any
  isLoading = true;
  note: string = '';
  allChecked = false;
  private gridColumnApi;
  rowSelection = 'multiple';
  defaultColDef = { resizable: true };
  noDataText = 'Nothing to approve right now';
  showGrid=false;
  totalList:any =  [];
  totalListIds = [];
  gridActualData=[];
  selectedListName="";
  selectedListId="";
  constructor(private avListService: AVListService, private userService: UserService, private toastrService: ToastService, private router: Router) {
    
  }
  ngOnInit() {
    this.loadData();
  }
  
  getAllList(listIds=[]){
    return new Promise((resolve, reject) => this.avListService.getAll(localStorage.getItem('USER_KEY'), listIds).subscribe(resolve))
  }
  listChanged(list){
    localStorage.setItem('USER_REVIEW_LISTKEY',list._id)
    this.router.navigate(['review']);
  }
  loadData() {
    this.showGrid = false;
    this.avListService.getUserReviewList(localStorage.getItem('USER_KEY')).subscribe(async (res) => {
      this.totalList = res;
      this.isLoading = false;
      if (this.totalList.length === 1) {
        localStorage.setItem('USER_REVIEW_LISTKEY', this.totalList[0]._id)
        this.router.navigate(['review']);
        return;
      }
     
      

    }, (err) => {
      this.isLoading = false;
      alert('error');
      // console.log('error to get av data: ', err);
    });
  }

  autoSizeAll() {
    var allColumnIds = [];

    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });

    this.gridColumnApi.autoSizeColumns(allColumnIds);
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridColumnApi = params.columnApi;
  }
}
