import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CampaignService = /** @class */ (function () {
    function CampaignService(http) {
        this.http = http;
    }
    CampaignService.prototype.getBaseUrl = function () {
        return environment.baseUrl;
    };
    CampaignService.prototype.getUrl = function (relativeUrl) {
        return this.getBaseUrl() + relativeUrl;
    };
    CampaignService.prototype.getOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    CampaignService.prototype.getCampaigns = function (userId) {
        var url = this.getUrl('/z-campaigns/campaigns/' + userId);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    CampaignService.prototype.getCampaign = function (campaignId) {
        var url = this.getUrl('/z-campaigns/' + campaignId);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    CampaignService.prototype.updateCampaign = function (campaignId, data) {
        var url = this.getUrl('/z-campaigns/' + campaignId);
        return this.http.put(url, data, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    CampaignService.prototype.startStopCampaign = function (campaignId, data) {
        var url = this.getUrl('/z-campaigns/start-stop-campaign/' + campaignId);
        return this.http.put(url, data, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    CampaignService.prototype.submitCampaign = function (formValue) {
        var url = this.getUrl('/z-campaigns');
        return this.http.post(url, formValue, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    CampaignService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CampaignService_Factory() { return new CampaignService(i0.ɵɵinject(i1.HttpClient)); }, token: CampaignService, providedIn: "root" });
    return CampaignService;
}());
export { CampaignService };
