import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { getUserRole } from 'src/app/helpers/getUserRole';
var ListsComponent = /** @class */ (function () {
    function ListsComponent(userService, router, slackService) {
        this.userService = userService;
        this.router = router;
        this.slackService = slackService;
        this.editId = '';
        this.deleteId = '';
        this.listName = '';
        this.userValue = '';
        this.dateValue = '60';
        this.showValue = '';
        this.isLoading = true;
        this.companyValue = '';
        this.stats = {
            totalCount: 0,
            totalApproved: 0,
            totalNotApproved: 0,
            percentageApproved: 0,
            totalInvited: 0,
            totalConnected: 0,
            totalResponded: 0,
            totalPositive: 0,
            totalConnectedPercentage: 0
        };
        this.isFinalized = false;
        this.dataPayload = {};
        this.sortOrder = 'desc';
        this.listDescription = '';
        this.listPrepComplete = false;
        this.sortBy = 'uploadedOn';
        this.excludemostrecentlists = false;
        this.isEU = getUserRole() === '4' ? true : false;
        this.isCA = getUserRole() === '3' ? true : false;
        this.isRSA = getUserRole() === '1' ? true : false;
    }
    ListsComponent.prototype.ngOnInit = function () {
        this.dateValue = localStorage.getItem('LISTS_DATE_RANGE_FILTER') ? localStorage.getItem('LISTS_DATE_RANGE_FILTER') : '60';
        localStorage.setItem('LISTS_DATE_RANGE_FILTER', this.dateValue);
        this.loadData();
    };
    ListsComponent.prototype.loadData = function () {
        var _this = this;
        this.isLoading = true;
        if (this.isCA) {
            this.userService.getCAUsers().subscribe(function (res) {
                _this.users = res.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
            }, function (err) {
                alert('error');
                console.log('error to get ca users: ', err);
            });
        }
        var listFor = localStorage.getItem('USER_LISTS_KEY') ? localStorage.getItem('USER_LISTS_KEY') : 'all';
        this.userService.getAVlists(listFor + ("" + (this.isRSA && listFor === 'all' ? '?recent_days=' + this.dateValue : '')), 'all').subscribe(function (res) {
            _this.avlists = res.avlists;
            _this.companies = res.companies.sort(function (a, b) {
                if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            if (_this.isRSA) {
                _this.users = res.users.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (_this.isCA) {
                _this.avlists = _this.avlists.filter(function (item) { return item.company === localStorage.getItem('USER_COMPANY'); });
            }
            if (!_this.isRSA) {
                _this.avlists = _this.avlists.filter(function (item) { return item.listPrepComplete; });
            }
            _this.showValue = localStorage.getItem('LISTS_SHOW_FILTER') ? localStorage.getItem('LISTS_SHOW_FILTER') : '';
            _this.companyValue = localStorage.getItem('LISTS_COMPANY_FILTER') ? localStorage.getItem('LISTS_COMPANY_FILTER') : '';
            _this.userValue = localStorage.getItem('LISTS_USER_FILTER') ? localStorage.getItem('LISTS_USER_FILTER') : '';
            _this.dateValue = localStorage.getItem('LISTS_DATE_RANGE_FILTER') ? localStorage.getItem('LISTS_DATE_RANGE_FILTER') : '';
            _this.excludemostrecentlists = localStorage.getItem('LISTS_EXCLUDE_RECENT_FILTER') && localStorage.getItem('LISTS_EXCLUDE_RECENT_FILTER') === '1' ? true : false;
            _this.filter();
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get dashboard: ', err);
        });
    };
    ListsComponent.prototype.toggleExcludemostrecentlists = function () {
        this.excludemostrecentlists = !this.excludemostrecentlists;
        this.filter();
    };
    ListsComponent.prototype.updateStats = function (lists) {
        var e_1, _a;
        this.stats = {
            totalCount: 0,
            totalApproved: 0,
            totalNotApproved: 0,
            percentageApproved: 0,
            totalInvited: 0,
            totalConnected: 0,
            totalResponded: 0,
            totalPositive: 0,
            totalConnectedPercentage: 0
        };
        try {
            for (var lists_1 = tslib_1.__values(lists), lists_1_1 = lists_1.next(); !lists_1_1.done; lists_1_1 = lists_1.next()) {
                var row = lists_1_1.value;
                this.stats.totalCount += +row.uploadedCount;
                if (row.approvedOn > 0) {
                    this.stats.totalApproved += +row.approvedCount;
                    this.stats.totalNotApproved += +row.notApproved;
                }
                this.stats.totalInvited += +row.invitedCount;
                this.stats.totalConnected += +row.connectedCount;
                if (row.respondedCount != undefined)
                    this.stats.totalResponded += +row.respondedCount;
                this.stats.totalPositive += +row.positiveCount;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (lists_1_1 && !lists_1_1.done && (_a = lists_1.return)) _a.call(lists_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.stats.percentageApproved = (this.stats.totalApproved / this.stats.totalCount) * 100;
        this.stats.totalConnectedPercentage = this.stats.totalApproved ? (this.stats.totalConnected / this.stats.totalApproved) * 100 : 0;
        console.log(this.stats);
    };
    ListsComponent.prototype.resetFilter = function () {
        this.showValue = '';
        this.companyValue = '';
        this.userValue = '';
        this.dateValue = '';
        this.excludemostrecentlists = false;
        localStorage.removeItem('LISTS_SHOW_FILTER');
        localStorage.removeItem('LISTS_COMPANY_FILTER');
        localStorage.removeItem('LISTS_USER_FILTER');
        localStorage.removeItem('LISTS_DATE_RANGE_FILTER');
        localStorage.removeItem('USER_PXDATA_CACHED_AT');
        localStorage.removeItem('USER_PXDATA');
        if (this.isRSA) {
            localStorage.removeItem('USER_LISTS_KEY');
        }
        this.loadData();
    };
    ListsComponent.prototype.filter = function (isDaysTrigger) {
        var _this = this;
        if (isDaysTrigger === void 0) { isDaysTrigger = false; }
        if (this.showValue || this.companyValue || this.userValue || this.dateValue) {
            localStorage.setItem('CA_CONNECTIONS_KEY', '');
            localStorage.setItem('CA_CONNECTIONS_NAME', '');
            localStorage.setItem('CA_CONNECTIONS_LINKEDINID', '');
            localStorage.removeItem('USER_PXDATA_CACHED_AT');
            localStorage.removeItem('USER_PXDATA');
        }
        this.filteredAvlists = this.avlists;
        if (!this.showValue) {
            localStorage.removeItem('LISTS_SHOW_FILTER');
            this.filteredAvlists = this.avlists.filter(function (item) { return item.listPrepComplete || item.research_finalized; });
        }
        if (this.showValue === 'reviewed') {
            localStorage.setItem('LISTS_SHOW_FILTER', this.showValue);
            this.filteredAvlists = this.avlists.filter(function (item) { return item.approvedOn > 0; });
        }
        if (this.showValue === 'r4r') {
            localStorage.setItem('LISTS_SHOW_FILTER', this.showValue);
            if (this.isRSA)
                this.filteredAvlists = this.avlists.filter(function (item) { return item.approvedOn === 0 && item.listPrepComplete; });
            else
                this.filteredAvlists = this.avlists.filter(function (item) { return item.approvedOn === 0; });
        }
        if (this.showValue === 'research_finalized') {
            localStorage.setItem('LISTS_SHOW_FILTER', this.showValue);
            this.filteredAvlists = this.avlists.filter(function (item) { return !item.listPrepComplete && item.research_finalized; });
        }
        if (this.companyValue) {
            localStorage.setItem('LISTS_COMPANY_FILTER', this.companyValue);
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return item.company === _this.companyValue; });
        }
        else {
            localStorage.removeItem('LISTS_COMPANY_FILTER');
        }
        if (this.userValue) {
            localStorage.setItem('LISTS_USER_FILTER', this.userValue);
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return item.listFor === _this.userValue; });
        }
        else {
            localStorage.removeItem('LISTS_USER_FILTER');
        }
        if (isDaysTrigger && this.isRSA) {
            localStorage.setItem('LISTS_DATE_RANGE_FILTER', this.dateValue);
            this.loadData();
            return this.filteredAvlists; // Return the current filtered list
        }
        if (this.dateValue !== '') {
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return (Math.abs(new Date().getTime() - new Date(item.createdAt).getTime()) / 3600000) < parseInt(_this.dateValue) * 24; });
        }
        if (this.excludemostrecentlists) {
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return (Math.abs(new Date().getTime() - new Date(item.createdAt).getTime()) / 3600000) > 7 * 24; });
        }
        this.updateStats(this.filteredAvlists);
        console.log(this.filteredAvlists);
        return this.filteredAvlists;
    };
    ListsComponent.prototype.updateListPrep = function (avlist, isFinalized) {
        if (isFinalized === void 0) { isFinalized = false; }
        this.editId = avlist._id;
        this.isFinalized = isFinalized;
        this.listName = avlist.listName;
        this.listDescription = avlist.listDescription;
        this.listPrepComplete = !avlist.listPrepComplete;
        this.editList();
    };
    ListsComponent.prototype.openEditModal = function (avlist) {
        this.editId = avlist._id;
        this.listName = avlist.listName;
        this.listDescription = avlist.listDescription;
        this.listPrepComplete = avlist.listPrepComplete;
        this.editModal.show();
    };
    ListsComponent.prototype.editList = function () {
        var _this = this;
        var data = { listName: this.listName, listDescription: this.listDescription };
        if (this.isFinalized) {
            data.research_finalized = true;
        }
        else {
            data.approvedOn = 0;
            data.listPrepComplete = this.listPrepComplete;
        }
        this.isFinalized = false;
        this.userService.editAVlist(this.editId, data).subscribe(function (res) {
            if (res) {
                _this.ngOnInit();
            }
            _this.isLoading = false;
            _this.editModal.hide();
        }, function (err) {
            _this.isLoading = false;
            _this.editModal.hide();
            alert('error');
            console.log('error to delete list: ', err);
        });
    };
    ListsComponent.prototype.openDeleteModal = function (deleteId) {
        this.deleteId = deleteId;
        this.deleteModal.show();
    };
    ListsComponent.prototype.deleteList = function () {
        var _this = this;
        this.userService.deleteAVlist(this.deleteId).subscribe(function (res) {
            if (res) {
                _this.ngOnInit();
            }
            ;
            _this.isLoading = false;
            _this.deleteModal.hide();
        }, function (err) {
            _this.isLoading = false;
            _this.deleteModal.hide();
            alert('error');
            console.log('error to delete list: ', err);
        });
    };
    ListsComponent.prototype.openProfileModal = function (avlist) {
        var _this = this;
        if (this.isRSA) {
            this.isLoading = true;
            this.selectedList = avlist;
            this.userService.getProfileURLs(avlist._id).subscribe(function (res) {
                if (res) {
                    _this.profileURLs = res.map(function (value) { return value.profileURL; });
                    _this.profileModal.show();
                }
                ;
                _this.isLoading = false;
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to get profile URLS: ', err);
            });
        }
    };
    ListsComponent.prototype.listContacts = function (list, type) {
        localStorage.removeItem('LIST_CONTACTS_VIEW_TYPE');
        if (list._id != localStorage.getItem('LIST_CONTACTS_KEY')) {
            localStorage.removeItem('LIST_AVDATA_CACHED_AT');
        }
        switch (type) {
            case 'all':
                localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', '');
                break;
            case 'approved':
                localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', 'Y');
                break;
            case 'not-approved':
                localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', 'N');
                break;
        }
        localStorage.setItem('LIST_CONTACTS_KEY', list._id);
        localStorage.setItem('LIST_CONTACTS_NAME', list.name);
        localStorage.setItem('LIST_CONTACTS_COMPANY', list.company);
        localStorage.setItem('LIST_CONTACTS_LISTNAME', list.listName);
        this.router.navigate(['/listContacts']);
    };
    ListsComponent.prototype.listReviewbylist = function (list) {
        if (list._id != localStorage.getItem('USER_REVIEW_LISTKEY')) {
            localStorage.removeItem('USER_AVDATA_CACHED_AT');
        }
        localStorage.setItem('USER_REVIEW_KEY', list.listFor);
        localStorage.setItem('USER_REVIEW_LISTKEY', list._id);
        localStorage.setItem('USER_REVIEW_NAME', list.name);
        if (this.isEU) {
            this.router.navigate(['/review']);
            return;
        }
        this.router.navigate(['/userReview']);
    };
    ListsComponent.prototype.exportList = function (list) {
        var _this = this;
        localStorage.removeItem('LIST_AVDATA_CACHED_AT');
        this.userService.getAVDataByListId(list._id, localStorage.getItem('USER_KEY')).subscribe(function (res) {
            var con = [];
            var csvContent = 'data:text/csv;charset=utf-8,';
            Object.keys(res).forEach(function (data) {
                var val = res[data];
                con.push(val);
            });
            var title = "Status, Rejection Reason, Company Name, First Name, Last Name, Title, Profile Url, Email, Phone, Location, Company Start Date, Tenure, Connected On, Industry, Mutual Connections";
            csvContent += title + '\r\n';
            con.forEach(function (rowArray) {
                var row = '"' + rowArray.approved + '","' + (rowArray.rejectionReason || "N/A") + '","' + rowArray.companyName + '","' + rowArray.firstName + '","'
                    + rowArray.lastName + '","' + rowArray.title + '","'
                    + rowArray.profileURL + '","' + rowArray.email + '","'
                    + rowArray.phone + '","' + rowArray.location + '","'
                    + rowArray.yearsAtCompany + '","' + rowArray.since1 + '","' + rowArray.connectedOn
                    + '","' + rowArray.industry + '","' + rowArray.mutualConnections + '"';
                csvContent += row + '\r\n';
            });
            csvContent = csvContent.replace(/#/g, '');
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            var d = new Date();
            var ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            var mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            var da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            var _d = mo + "-" + da + "-" + ye;
            link.setAttribute('download', list.company + " - " + list.name + " (" + list.listName + ") - Exported on " + _d + ".csv");
            document.body.appendChild(link); // Required for FF
            link.click();
            _this.isLoading = false;
        }, function (err) {
            alert('error');
            console.log('error to get user av data: ', err);
        });
    };
    ListsComponent.prototype.isTwoDayGapCheck = function (date) {
        if (date === 0) {
            return false;
        }
        var diff = Math.abs(new Date().getTime() - new Date(date).getTime()) / 3600000;
        if (diff > 48) {
            return true;
        }
        return false;
    };
    return ListsComponent;
}());
export { ListsComponent };
