import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SequenceService } from 'src/app/services/sequence.service';

@Component({
  selector: 'app-connection-request',
  templateUrl: './connection-request.component.html',
  styleUrls: ['./connection-request.component.scss']
})
export class ConnectionRequestComponent implements OnInit {

  isLoading = true;
  dropdownList = [];
  stepTwoForm: FormGroup;
  clickByDoneButton = false;
  editMode = localStorage.getItem("EDIT_SEQUENCE") ? true : false;

  constructor(private fb: FormBuilder, public sequenceService: SequenceService, private router: Router) {
    this.stepTwoForm = this.fb.group({
      messageType: this.fb.control('Connection Request'),
      sequenceId: this.fb.control('', Validators.required),
      message: this.fb.control('', Validators.required),
      draft: this.fb.control(false)
    });
  }

  ngOnInit(): void {
    const campaignInfo = localStorage.getItem('CAMPAIGN_INFO');

    if (!campaignInfo) {
      this.router.navigate(['campaigns']);
      return;
    }

    const messageInfo = localStorage.getItem('CONNECTION_MESSAGE_INFO');

    let message = "";

    if (messageInfo) {
      message = JSON.parse(messageInfo).message;
    }

    this.stepTwoForm = this.fb.group({
      messageType: this.fb.control('Connection Request'),
      sequenceId: this.fb.control(JSON.parse(campaignInfo).sequenceId),
      message: this.fb.control(message, Validators.required),
      draft: this.fb.control(false)
    });

    this.sequenceService.getMessageTemplates().subscribe((messageTemplates) => {
      this.isLoading = false;
      this.dropdownList = messageTemplates;
    }, (err) => {
      alert('error');
      console.log(err);
    });
  }

  stepTwoSubmit(): void {
    console.log(this.stepTwoForm);
    if (this.stepTwoForm.valid) {
      this.isLoading = true;
      this.sequenceService.submitSequence(this.stepTwoForm.value).subscribe((res) => {
        console.log(res);
        if (this.clickByDoneButton) {
          this.router.navigate(['campaigns']);
        } else {
          localStorage.setItem("CONNECTION_MESSAGE_INFO", JSON.stringify(res));
          this.isLoading = false;
          this.router.navigate(['message-template']);
        }
      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log(err);
      });
    }
  }

  cancelNewCampaign(): void {
    this.router.navigate(['campaigns']);
  }

  gotoIntroMessage(): void {
    this.router.navigate(['intro-message-sequence']);
  }

  getCheckboxesValue(event): void {
    this.stepTwoForm.value.draft = event.checked;

    if (!event.checked) {
      this.stepTwoForm.get('message').setValidators([Validators.required]);
    } else {
      this.stepTwoForm.get('message').clearValidators();
    }

    this.stepTwoForm.controls["message"].updateValueAndValidity();
  }

  clickDoneButton(): void {
    this.clickByDoneButton = true;
  }
}