import { OnInit } from '@angular/core';
import { company } from '../../../models/company';
var CompaniesComponent = /** @class */ (function () {
    function CompaniesComponent(companiesService, router) {
        this.companiesService = companiesService;
        this.router = router;
        this.isLoading = true;
        this.searchByCompany = '';
        this.company = new company();
        this.companies = [];
    }
    CompaniesComponent.prototype.ngOnInit = function () {
        this.isLoading = true;
        this.getCompanies();
    };
    CompaniesComponent.prototype.openToAdd = function () {
        this.company = new company();
        this.showModalOnClick.show();
    };
    CompaniesComponent.prototype.reset = function () {
        this.company = new company();
    };
    CompaniesComponent.prototype.openToEdit = function (company) {
        this.company._id = company._id;
        this.company.companyName = company.companyName;
        this.showModalOnClick.show();
    };
    CompaniesComponent.prototype.getCompanies = function () {
        var _this = this;
        this.companiesService.getCompanies().subscribe(function (result) {
            _this.companies = result;
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get companies: ', err);
        });
    };
    CompaniesComponent.prototype.remove = function (company) {
        var _this = this;
        if (confirm('Are you sure you want to delete this company?')) {
            this.companiesService.deleteCompany(company).subscribe(function () { return _this.getCompanies(); }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to delete company: ', err);
            });
            this.isLoading = true;
            this.getCompanies();
            this.isLoading = false;
        }
    };
    CompaniesComponent.prototype.search = function () {
        var _this = this;
        this.isLoading = true;
        this.companiesService.searchCompany(this.searchByCompany).subscribe(function (result) {
            _this.companies = result;
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to search company: ', err);
        });
    };
    CompaniesComponent.prototype.saveCompany = function () {
        var _this = this;
        if (!this.company.companyName) {
            alert('Please enter company name');
            return;
        }
        if (!this.company._id) {
            this.companiesService.addCompany(this.company).subscribe(function (company) {
                _this.reset();
                _this.getCompanies();
                _this.showModalOnClick.hide();
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to add company: ', err);
            });
        }
        else {
            this.companiesService.editCompany(this.company).subscribe(function (company) {
                _this.reset();
                _this.isLoading = true;
                _this.getCompanies();
                _this.isLoading = false;
                _this.showModalOnClick.hide();
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to edit company: ', err);
            });
        }
    };
    CompaniesComponent.prototype.keyPressed = function (event) {
        if (event.which === 13 || event.keyCode === 13) {
            this.search();
        }
    };
    return CompaniesComponent;
}());
export { CompaniesComponent };
