/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/ng-uikit-pro-standard/ng-uikit-pro-standard.ngfactory";
import * as i4 from "ng-uikit-pro-standard";
import * as i5 from "./message-preview.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../../services/sequence.service";
var styles_MessagePreviewComponent = [i0.styles];
var RenderType_MessagePreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessagePreviewComponent, data: {} });
export { RenderType_MessagePreviewComponent as RenderType_MessagePreviewComponent };
function View_MessagePreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading\u2026"]))], null, null); }
function View_MessagePreviewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "ml-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Wait ", " days after the connection is accepted "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.delay; _ck(_v, 1, 0, currVal_0); }); }
function View_MessagePreviewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "ml-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Wait ", " days after the previous message is sent"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.delay; _ck(_v, 1, 0, currVal_0); }); }
function View_MessagePreviewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "far fa-clock"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagePreviewComponent_5)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["templateName", 2]], null, 0, null, View_MessagePreviewComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.parent.context.index > 0) && (_co.previewMessages[(_v.parent.context.index - 1)].messageType === "Connection Request")); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_MessagePreviewComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
function View_MessagePreviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagePreviewComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagePreviewComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "far fa-comment mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " Message "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editMessage(_v.context.$implicit._id, _v.context.$implicit.messageType) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["edit"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addAnotherMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["add"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDeleteMessageModal(_v.context.$implicit._id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["delete"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.delay; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.delay; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.messageType; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.message; _ck(_v, 10, 0, currVal_3); }); }
function View_MessagePreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container campaigns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Messaging Sequence"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your sequence currently looks like this: "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagePreviewComponent_3)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addAnotherMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add an additional message"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoCampaigns() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Done"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previewMessages; _ck(_v, 6, 0, currVal_0); }, null); }
function View_MessagePreviewComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Are you sure you want to delete this message? "]))], null, null); }
export function View_MessagePreviewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { deleteModal: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagePreviewComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagePreviewComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 20, "div", [["aria-hidden", "true"], ["aria-labelledby", "myModalLabel"], ["class", "modal fade top"], ["id", "frameModalTop"], ["mdbModal", ""], ["role", "dialog"], ["style", "overflow-y: auto"], ["tabindex", "-1"]], null, [[null, "keydown"], [null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.esc" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).onEsc() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_ModalDirective_0, i3.RenderType_ModalDirective)), i1.ɵdid(6, 4898816, [[1, 4], ["deleteModal", 4]], 0, i4.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i4.ɵdj], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 18, "div", [["class", "modal-dialog modal-notify modal-info"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 17, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [["class", "heading lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete Message "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["aria-hidden", "true"], ["class", "white-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagePreviewComponent_8)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 8, "div", [["class", "modal-footer justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "a", [["class", "waves-effect"], ["color", "primary"], ["mdbBtn", ""], ["mdbWavesEffect", ""], ["outline", "true"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).click($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.deleteMessage() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MdbBtnDirective_0, i3.RenderType_MdbBtnDirective)), i1.ɵdid(19, 638976, null, 0, i4.MdbBtnDirective, [i1.ElementRef, i1.Renderer2], { color: [0, "color"], outline: [1, "outline"] }, null), i1.ɵdid(20, 16384, null, 0, i4.WavesDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Yes"])), (_l()(), i1.ɵeld(22, 0, null, null, 3, "a", [["class", "waves-effect"], ["color", "primary"], ["mdbBtn", ""], ["mdbWavesEffect", ""], ["outline", "true"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).click($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).hide() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MdbBtnDirective_0, i3.RenderType_MdbBtnDirective)), i1.ɵdid(23, 638976, null, 0, i4.MdbBtnDirective, [i1.ElementRef, i1.Renderer2], { color: [0, "color"], outline: [1, "outline"] }, null), i1.ɵdid(24, 16384, null, 0, i4.WavesDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, 0, ["No"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.deleteId; _ck(_v, 16, 0, currVal_2); var currVal_3 = "primary"; var currVal_4 = "true"; _ck(_v, 19, 0, currVal_3, currVal_4); var currVal_5 = "primary"; var currVal_6 = "true"; _ck(_v, 23, 0, currVal_5, currVal_6); }, null); }
export function View_MessagePreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-preview", [], null, null, null, View_MessagePreviewComponent_0, RenderType_MessagePreviewComponent)), i1.ɵdid(1, 114688, null, 0, i5.MessagePreviewComponent, [i6.Router, i7.SequenceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessagePreviewComponentNgFactory = i1.ɵccf("app-message-preview", i5.MessagePreviewComponent, View_MessagePreviewComponent_Host_0, {}, {}, []);
export { MessagePreviewComponentNgFactory as MessagePreviewComponentNgFactory };
