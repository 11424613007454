import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { dateFilterParams } from 'src/app/helpers/dateFilterParams';
import * as moment from "moment";
import { UserService } from '../../../services/user.service';
import { PersonalityDataService } from '../../../services/personalitydata.service';
import { validateImage } from 'src/app/helpers/common';
import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  ICellEditorParams,
  RowEditingStartedEvent,
  RowEditingStoppedEvent,
} from 'ag-grid-community';
@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss']
})

export class ConnectionsComponent implements OnInit {
  @ViewChild('basicModal', { static: true }) showModalOnClick: ModalDirective;
  @ViewChild('graphModal', { static: true }) showGraphModalOnClick: ModalDirective;
  @ViewChild('lilaModal', { static: true }) showLilaModalOnClick: ModalDirective;
  gridApi: any;
  rows = [];
  leadStatusOptions=[];
  isLoading = true;
  selectedRow = undefined;
  graphData: any;
  personalityModaData: any = {};
  pagingIndex = 0;
  paragraph = "";
  modalBorder = "#dee2e6";
  noDataText = 'No connections right now';
  discTypeLabels = {
    D: { color: "#db3c48" },
    Di: { color: "#e5561f" },
    DI: { color: "#f48119" },
    Id: { color: "#ffb727" },
    I: { color: "#f7cf0d" },
    Is: { color: "#d3e000" },
    IS: { color: "#afd80a" },
    Si: { color: "#88c100" },
    S: { color: "#11b21b" },
    Sc: { color: "#51b48e" },
    SC: { color: "#00b6bc" },
    Cs: { color: "#007fb6" },
    C: { color: "#2f5fa5" },
    Cd: { color: "#6756b2" },
    CD: { color: "#93359b" },
    Dc: { color: "#c62e85" },
  };
  tendsTitle = "";
  columnDefs = [
    {
      width: 200,
      sortable: true,
      resizable: true,
      field: 'companyName',
      headerName: 'Company Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        if (!valueA) return -1;
        if (!valueB) return 1;
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
        if (valueA == valueB) return 0;
        if (valueA > valueB) return 1;
        return -1;
      },
      cellRenderer: function (params) {
        if (params.data.companyProfileURL) {
          return `<a href="${params.data.companyProfileURL}" target="_blank">${params.value}</a>`;
        } else {
          return `<div>${params.value}</div>`
        }
      },
    },
    // {
    //   width: 40,
    //   field: 'lastViewedOn',
    //   sortable: true,
    //   resizable: true,
    //   filterParams: {
    //     buttons: ['reset', 'apply'],
    //     closeOnApply: true
    //   },
    //   headerName: 'PV',
    //   headerTooltip:"Days since profile was viewed",
    //   headerClass:"profile-view-data",
    //   cellRenderer: function(params){
        
    //     if (params.data.lastViewedOn) {
    //       let diff = moment().diff(moment(params.data.lastViewedOn,'x'),'days');
          
    //       if(diff <= 7){
    //         return `<div class="viewprofileCase1 circle">${diff}</div>`
    //       }else if(diff<=14 && diff>7){
    //         return `<div class="viewprofileCase2 circle" >${diff}</div>`
    //       }else if(diff<=21 && diff>14){
    //         return `<div class="viewprofileCase3 circle">${diff}</div>`
    //       }
          
    //     }
    //   }
    // },
    {
      width: 100,
      sortable: true,
      resizable: true,
      editable: true,
      field: 'status',
      headerName: 'Lead Status',
      headerTooltip: "Lead Status",
      cellEditor: 'agSelectCellEditor',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      cellEditorParams: this.getLeadOptions.bind(this),
      cellRenderer: this.cellLeadCodeRenderer.bind(this)
    },
    {
      width: 100,
      sortable: true,
      resizable: true,
      field: 'firstName',
      headerName: 'First Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      }
    },
    {
      width: 100,
      sortable: true,
      resizable: true,
      field: 'lastName',
      headerName: 'Last Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      }
    },
    {
      width: 200,
      field: 'title',
      sortable: true,
      resizable: true,
      headerName: 'Title',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      }
    },
    {
      width: 75,
      resizable: true,
      field: 'Avatar',
      headerName: 'Avatar',
      cellRenderer: this.avatarShow.bind(this)
    },
    {
      width: 75,
      headerName: 'LiLa',
      sortable: true,
      resizable: true,
      field: 'discType',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        caseSensitive: true,
        closeOnApply: true
      },
      onCellClicked: this.showGraph.bind(this),
      //cellRenderer:this.showGraph.bind(this),
      cellRenderer: (params) => {

        return params.data.discType ? `<div (click)="showGraph(params.data)" class="custom-disctype" style="background-color:${this.discTypeLabels[params.data.discType].color}">${params.data.discType}</div>` : ""
      }

    },
    {
      width: 75,
      resizable: true,
      headerName: 'View',
      field: 'profileURL',
      cellRenderer: function (params) {
        return `<a href="${params.data.profileURL}" target="_blank">View</a>`;
      }
    },
    // {
    //   width: 75,
    //   sortable: true,
    //   resizable: true,
    //   field: 'since1',
    //   headerName: 'Tenure',
    //   filter: 'agNumberColumnFilter',
    //   filterParams: {
    //     buttons: ['reset', 'apply'],
    //     closeOnApply: true
    //   },
    //   comparator: function (number1, number2) {
    //     if (number1 === null && number2 === null) {
    //       return 0;
    //     }
    //     if (number1 === null) {
    //       return -1;
    //     }
    //     if (number2 === null) {
    //       return 1;
    //     }
    //     return number1 - number2;
    //   },
    //   cellRenderer: function (params) {
    //     if (!params.data.yearsAtCompany) {
    //       return ``
    //     }
    //     return `<span>${params.data.since1}</span>`;
    //   }
    // },
    {
      width: 200,
      field: 'email',
      sortable: true,
      resizable: true,
      headerName: 'Email',
      cellRenderer: function (params) {
        if (params.value) return `<a href="mailto:${params.value}">${params.value}<a>`;
        return '';
      },
      filter: "agTextColumnFilter", filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      }
    },
    {
      width: 125,
      field: 'phone',
      sortable: true,
      resizable: true,
      headerName: 'Phone',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      }
    },
    {
      width: 150,
      sortable: true,
      resizable: true,
      field: 'location',
      headerName: 'Location',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      }
    },
    // {
    //   width: 150,
    //   sortable: true,
    //   resizable: true,
    //   field: 'campaignStatus',
    //   headerName: ' Campaign Status',
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ['reset', 'apply'],
    //     closeOnApply: true
    //   },
    //   cellRenderer: function (params) {
    //     if (!params.value) return ``;
    //     if (params.value==1) return `<i class="fa fa-solid fa-person-running"></i>`;
    //     if (params.value==5) return `<i class="fa fa-circle-check"></i>`;
    //     return '';
    //   },
    // },
    // {
    //   width: 150,
    //   sortable: true,
    //   resizable: true,
    //   field: 'mutualConnections',
    //   filter: "agTextColumnFilter",
    //   headerName: 'Mutual Connections',
    //   filterParams: {
    //     buttons: ['reset', 'apply'],
    //     closeOnApply: true
    //   }
    // },
    {
      width: 150,
      sort: "desc",
      sortable: true,
      resizable: true,
      field: 'connectedOnDate',
      headerName: 'Connected on',
      headerTooltip: 'Connected On Date',
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        if (!valueA) return -1;
        if (!valueB) return 1;
        if (new Date(valueA) > new Date(valueB)) return 1;
        if (new Date(valueA) < new Date(valueB)) return -1;
        else return 0;
      },
    },
    {
      width: 150,
      sortable: true,
      resizable: true,
      field: 'lastMessageDate1',
      headerName: 'Last Msg Date',
      headerTooltip: 'Date of Last Incoming Message',
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
        if (!valueA) return -1;
        if (!valueB) return 1;
        if (new Date(valueA) > new Date(valueB)) return 1;
        if (new Date(valueA) < new Date(valueB)) return -1;
        else return 0;
      },
      cellRenderer: function (params) {
        return `${params.data.lastMessageDate}`;
      }
    },
    
    {
      width: 150,
      sortable: true,
      resizable: true,
      field: 'lastMessage',
      filter: "agTextColumnFilter",
      headerName: 'Msg',
      headerTooltip:'Last Incoming Message',
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
      width: 75,
      resizable: true,
      field: 'convoUrl',
      headerName: 'View',
      headerTooltip:'View Full Conversation',
      cellRenderer: function (params) {
        if(params.value) return `<a style="color:black;text-decoration:none;" target="_blank" href="${params.value}"><i class="fa fa-comments-o"></i></a>`;
        return '';
      }
    },
    {
      width: 75,
      field: 'id',
      resizable: true,
      headerName: 'PDF',
      cellRenderer: this.pdfIconRender.bind(this)
    }
  ];
  isDataLoading = false;
  topPosition=0;
  constructor(@Inject(DOCUMENT) document: Document, private userService: UserService, private personalityDataService: PersonalityDataService) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  ngOnInit() {
    this.userService.getUserByKey(localStorage.getItem('USER_KEY')).subscribe(user => {
      if(user.leadStatusOptions.length==0){
        this.leadStatusOptions = ["",0,1,2,3,4];
      }
      else this.leadStatusOptions = [""].concat(user.leadStatusOptions);
      if (!user.isLila) {
        this.columnDefs = this.columnDefs.filter(x => x.headerName !== "LiLa")
      }
    });;
    this.userService.getUserPXdata(localStorage.getItem('USER_KEY')).subscribe((connections) => {
      this.rows = connections.map(con=>({...con,status: con.status!=0 ? (this.leadStatusOptions[con.status]||con.status):''}));
      console.log(connections)
      if (this.rows.length) {
        let parent = document.querySelector('.ag-paging-panel.ag-unselectable');
        let span = parent.querySelector("span#download-btn");
        if (span == null) {
          span = document.createElement("SPAN");
          span.innerHTML = `<i style="cursor:pointer" id="download-btn" class="fas fa-download export-icon" *ngIf="rows.length" (click)="exportData()"></i>`
          span.addEventListener('click', (e) => {
            this.exportData();//your typescript function
          });
          parent.appendChild(span);
        }
      }
      validateImage();
      this.isLoading = false;
      document.querySelector('#scrolling-table .ag-body-viewport').addEventListener('scroll',(e)=>{
        let div = document.querySelector('#scrolling-table .ag-body-viewport');
        console.log(div.scrollHeight, div.scrollTop)
        if((div.scrollHeight- div.scrollTop) < 1000 ){
          this.topPosition = div.scrollTop;
          this.loadMoreData();
        }
      },false)
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get user pxdata: ', err);
    });
  }
  loadMoreData(){
    console.log(this.gridApi);
    return;
    if(this.isDataLoading)return;
    this.isDataLoading = true;
    this.userService.getUserPXdata(localStorage.getItem('USER_KEY')).subscribe((connections) => {
      //let newRows = connections.map(con=>({...con,status: con.status!=0 ? (this.leadStatusOptions[con.status]||con.status):''}))
      //this.gridApi.updateRowData({add: newRows[0]});
      this.rows = this.rows.concat(connections.map(con=>({...con,status: con.status!=0 ? (this.leadStatusOptions[con.status]||con.status):''})))
      setTimeout(()=>{
        let div = document.querySelector('#scrolling-table .ag-body-viewport');
        div.scrollTop = this.topPosition;
        this.isDataLoading = false;
      },100)
      
    })
  }
  onPaginationChanged($evt) {validateImage()}
  onRowEditingStarted(event: RowEditingStartedEvent) {
    console.log('never called - not doing row editing');
  }

  onRowEditingStopped(event: RowEditingStoppedEvent) {
    console.log('never called - not doing row editing');
  }

  onCellEditingStarted(event: CellEditingStartedEvent) {
    console.log('cellEditingStarted',event);
    console.log(this.leadStatusOptions)
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    console.log('onCellEditingStopped',event);
    let {newValue} = event; 
    let newVal = this.leadStatusOptions.findIndex(x=>x==newValue);
    if(newVal==0)event.data.status = '';
    this.userService.updatePXDataStatus(event.data.id,newVal!=-1?newVal:event.newValue).subscribe((response)=>{
      console.log(response)
    })
  }
  
 cellEditorSelector(params: ICellEditorParams) {
  
    return {
      component: 'agSelectCellEditor',
      params: {
        values: ["",0,1,2,3,4],
      },
      popup: true,
    };
}
  onGridReady(params) {
    this.gridApi = params.api;

    this.gridApi.sizeColumnsToFit();

  }
  exportData() {
    this.gridApi!.exportDataAsCsv({
      processCellCallback: (params) => {
        const colDef = params.column.getColDef()
        if (colDef.valueFormatter) {
          const valueFormatterParams = {
            ...params,
            data: params.node.data,
            node: params.node!,
            colDef: params.column.getColDef()
          };
          return colDef.valueFormatter(valueFormatterParams);
        }
        return params.value;
      },
    })
  }
  onFirstDataRendered(params) {
    const yourFilterComponent = params.api.getFilterInstance('companyName');

    yourFilterComponent.setModel({
      type: 'contains',
      filter: localStorage.getItem('ORGANIZATION_1') ? localStorage.getItem('ORGANIZATION_1') : ''
    });

    params.api.onFilterChanged();
    localStorage.removeItem('ORGANIZATION_1');
  }
  chunks(array, size = 4) {
    var results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }
    return results;
  };
  groupBy(list, keyGetter) {
    console.log('list', list)
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  showGraph(params) {
    //this.showGraphBackup(params);return;
    let { discType } = params.data;
    this.personalityDataService.getQueryOne(discType).subscribe((res: any) => {
      console.log(res);
      if (res.code == 200) {
        this.personalityModaData.pxData = params.data;
        let [doArr = [], dontArr = [], behaviorArr = [], overviewArr = []] = [res.data.filter(x => x.tag1 == 'do').reverse(), res.data.filter(x => x.tag1 == 'dont').reverse(), res.data.filter(x => x.tag1 == 'behavior').reverse(), res.data.filter(x => x.tag1 == 'overview').reverse()]
        this.personalityModaData.contentArr = {
          doArr, dontArr, behaviorArr, overviewArr
        }
        this.personalityModaData.p4d = params.data.p4d || 0;
        this.personalityModaData.p4i = params.data.p4i || 0;
        this.personalityModaData.p4s = params.data.p4s || 0;
        this.personalityModaData.p4c = params.data.p4c || 0;
        let {
          p4d: d = 0, p4i: i = 0, p4s: s = 0, p4c: c = 0
        } = this.personalityModaData;
        let max = [{ v: d, k: 'D' }, { v: i, k: 'I' }, { v: s, k: "S" }, { v: c, k: "C" }].sort((a, b) => b.v - a.v)[0]
        this.modalBorder = this.discTypeLabels[max.k].color;
        this.tendsTitle = this.personalityModaData.firstName + " tends to be ";
        this.personalityModaData.queryTwoData = overviewArr;
        this.pagingIndex = 0;
        this.paragraph = this.renderParagraph((this.personalityModaData.queryTwoData[this.pagingIndex] || "").text);
        console.log(this.personalityModaData)
      }
    });

    this.showLilaModalOnClick.show();
  }
  renderParagraph(para = "") {
    console.log(para)
    return para.replace(/{firstName}/g, this.personalityModaData.pxData.firstName);
  }
  changeParagraph(direction) {
    console.log(direction)
    if (direction == "N") {
      ++this.pagingIndex;
      if (this.pagingIndex == this.personalityModaData.queryTwoData.length) this.pagingIndex = this.personalityModaData.queryTwoData.length - 1;
    } else {
      --this.pagingIndex;
      if (this.pagingIndex == -1) this.pagingIndex = 0;
    }
    this.paragraph = this.renderParagraph((this.personalityModaData.queryTwoData[this.pagingIndex] || "").text);
  }
  showGraphBackup(params) {
    this.graphData = params.data;
    this.graphData.p4d = this.graphData.p4d || 0;
    this.graphData.p4i = this.graphData.p4i || 0;
    this.graphData.p4s = this.graphData.p4s || 0;
    this.graphData.p4c = this.graphData.p4c || 0;
    let {
      p4d: d = 0, p4i: i = 0, p4s: s = 0, p4c: c = 0
    } = this.graphData;
    let max = [{ v: d, k: 'D' }, { v: i, k: 'I' }, { v: s, k: "S" }, { v: c, k: "C" }].sort((a, b) => b.v - a.v)[0]
    this.modalBorder = this.discTypeLabels[max.k].color;
    this.graphData.qualities = this.graphData.qualities || [];
    this.tendsTitle = this.graphData.firstName + " tends to be " + this.graphData.qualities.slice(0, this.graphData.qualities.length - 1).join(", ") + " and " + this.graphData.qualities.slice(this.graphData.qualities.length - 1)[0];
    this.showGraphModalOnClick.show();

  }
  getLeadOptions(){
    return {values: this.leadStatusOptions||[0,1,2,3,4]}
  }
  cellLeadCodeRenderer(params){
    return (this.leadStatusOptions||[""])[params.data.status]||params.data.status
  }
  avatarShow(params) {
    const eDiv = document.createElement('div');

    if (!params.data.avatar) {
      eDiv.innerHTML = '';
    } else {
      eDiv.innerHTML =
        `<a href="${params.data.profileURL}" target="_blank"><img src="${params.data.avatar}" class="avatar-img liImg"/></a>`
        
    }

    eDiv.addEventListener('click', () => {
      this.selectedRow = params.data;
      //this.showModalOnClick.show();
    });

    return eDiv;
  }

  pdfIconRender(params) {
    const eDiv = document.createElement('span');
    eDiv.style.cursor = 'pointer';
    eDiv.innerHTML = `<i class="fa fa-file-pdf" style="color: red"></i>`;

    eDiv.addEventListener('click', () => {
      this.getPxdata(params.data.id)
    });

    return eDiv;
  }

  getPxdata(id) {
    this.userService.getPxdataById(id).subscribe((res) => {
      this.exportAsPDF(res);
    })
  }

  async exportAsPDF(user) {
    const documentDefinition = await this.getDocumentDefinition(user);
    pdfMake.createPdf(documentDefinition).download(user.fullName + '.pdf');
  }

  async getDocumentDefinition(user) {
    sessionStorage.setItem('resume', JSON.stringify(user));

    return {
      content: [
        {
          bold: true,
          fontSize: 20,
          alignment: 'center',
          text: user.fullName,
          margin: [0, 0, 0, 20]
        },
        {
          text: user.headline
        },
        {
          text: user.location,
          color: 'grey'
        },
        {
          columns: [
            [
              {
                text: 'Contact',
                style: 'header'
              },
              {
                text: 'Email : ' + user.email,
              },
              {
                color: 'blue',
                link: user.profileURL,
                text: 'Linkedin: ' + user.profileURL,
              }
            ],
            [
              await this.getProfilePicObject(user)
            ]
          ]
        },
        {
          style: 'header',
          text: 'Experience',
        },
        this.getExperienceObject(user),
        {
          style: 'header',
          text: 'Education',
        },
        this.getEducationObject(user),
        {
          text: 'Skills',
          style: 'header'
        },
        {
          columns: [
            {
              ul: [
                ...user.skills.filter((value, index) => index % 3 === 0)
              ]
            },
            {
              ul: [
                ...user.skills.filter((value, index) => index % 3 === 1)
              ]
            },
            {
              ul: [
                ...user.skills.filter((value, index) => index % 3 === 2)
              ]
            }
          ]
        },
      ],
      info: {
        subject: 'PROFILE',
        title: user.fullName,
        author: user.fullName,
        keywords: 'PROFILE, ONLINE PROFILE',
      },
      styles: {
        header: {
          bold: true,
          fontSize: 18,
          margin: [0, 20, 0, 10],
          decoration: 'underline'
        },
        name: {
          bold: true,
          fontSize: 16,
        },
        organization: {
          bold: true,
          fontSize: 14,
          italics: true
        },
        tableHeader: {
          bold: true,
        }
      }
    };
  }

  async getProfilePicObject(user) {
    if (user.avatar) {
      const base64 = await this.getBase64ImageFromURL(user.avatar);

      if (base64) {
        return {
          image: base64,
          width: 75,
          alignment: 'right'
        };
      }

      return null;
    }

    return null;
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };

      img.onerror = error => {
        // reject(error);
        resolve(null)
      };

      img.src = url;
    });
  }

  getExperienceObject(user) {
    const exs = [];

    if (user.organization_2) {
      exs.push(
        [
          {
            columns: [
              [
                {
                  text: user.organization_2,
                  style: 'organization'
                },
                {
                  text: user.organization_title_2,
                },
                {
                  text: user.organization_location_2, color: 'grey'
                },
                {
                  text: user.organization_description_2
                }
              ],
              {
                text: user.organization_start_2 + ' - Present',
                alignment: 'right'
              }
            ]
          }
        ]
      );
    }

    if (user.organization_1) {
      exs.push(
        [
          {
            columns: [
              [
                {
                  text: user.organization_1,
                  style: 'organization'
                },
                {
                  text: user.organization_title_1,
                },
                {
                  text: user.organization_location_1, color: 'grey'
                },
                {
                  text: user.organization_description_1
                }
              ],
              {
                text: user.organization_start_1 + ' - Present',
                alignment: 'right'
              }
            ]
          }
        ],
      );
    }

    if (user.organization_3) {
      exs.push(
        [
          {
            columns: [
              [
                {
                  text: user.organization_3,
                  style: 'organization'
                },
                {
                  text: user.organization_title_3,
                },
                {
                  text: user.organization_location_3, color: 'grey'
                },
                {
                  text: user.organization_description_3
                }
              ],
              {
                text: user.organization_start_3 + ' - Present',
                alignment: 'right'
              }
            ]
          }
        ]
      );
    }

    if (exs.length > 0) {
      return {
        table: {
          widths: ['*'],
          body: [
            ...exs
          ]
        }
      };
    }

    return null;
  }

  getEducationObject(user) {
    if (user.education_1) {
      return {
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'College',
                style: 'tableHeader'
              },
              {
                text: 'Degree',
                style: 'tableHeader'
              },
              {
                text: 'Passing Year',
                style: 'tableHeader'
              }
            ],
            [user.education_1, user.education_degree_1, user.education_start_1 + `${user.education_end_1 ? ' - ' : ''}` + user.education_end_1,],
          ]
        }
      };
    }

    return null;
  }

}