import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CompaniesService = /** @class */ (function () {
    function CompaniesService(http) {
        this.http = http;
    }
    CompaniesService.prototype.getBaseUrl = function () {
        return environment.baseUrl;
    };
    CompaniesService.prototype.getUrl = function (relativeUrl) {
        return this.getBaseUrl() + relativeUrl;
    };
    CompaniesService.prototype.getOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    CompaniesService.prototype.addCompany = function (company) {
        var url = this.getUrl('/companies');
        return this.http.post(url, company, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    CompaniesService.prototype.getCompanies = function () {
        var url = this.getUrl('/companies');
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var companies = [];
            querySnapshot.forEach(function (data) {
                companies.push({
                    _id: data._id,
                    companyName: data.companyName
                });
            });
            return companies;
        }));
    };
    CompaniesService.prototype.deleteCompany = function (company) {
        var url = this.getUrl('/companies/' + company._id);
        return this.http.delete(url, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    CompaniesService.prototype.editCompany = function (company) {
        var url = this.getUrl('/companies/' + company._id);
        return this.http.put(url, company, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    CompaniesService.prototype.searchCompany = function (searchByCompany) {
        var url = this.getUrl('/companies');
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var companies = [];
            querySnapshot.forEach(function (data) {
                if (data.companyName && data.companyName.toLowerCase().indexOf(searchByCompany.toLowerCase()) > -1) {
                    companies.push({
                        _id: data._id,
                        companyName: data.companyName
                    });
                }
            });
            return companies;
        }));
    };
    CompaniesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompaniesService_Factory() { return new CompaniesService(i0.ɵɵinject(i1.HttpClient)); }, token: CompaniesService, providedIn: "root" });
    return CompaniesService;
}());
export { CompaniesService };
