import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild } from '@angular/core';

import { SequenceService } from 'src/app/services/sequence.service';


@Component({
  selector: 'app-message-preview',
  templateUrl: './message-preview.component.html',
  styleUrls: ['./message-preview.component.scss']
})
export class MessagePreviewComponent implements OnInit {

  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;

  deleteId = "";
  isLoading = true;
  previewMessages = [];

  constructor(public router: Router, public sequenceService: SequenceService) {
  }

  ngOnInit(): void {
    this.loadMessages();
  }

  loadMessages() {
    const campaignInfo = localStorage.getItem('CAMPAIGN_INFO');

    this.sequenceService.getMessages(JSON.parse(campaignInfo).sequenceId).subscribe((res) => {
      console.log(res);
      this.previewMessages = res;
      this.isLoading = false;
    }, (err) => {
      alert('error');
      console.log(err);
    })
  }

  openDeleteMessageModal(_id) {
    this.deleteId = _id;
    this.deleteModal.show();
  }

  deleteMessage() {
    this.sequenceService.deleteMessage(this.deleteId).subscribe((res) => {
      console.log(res);
      this.deleteModal.hide();
      this.loadMessages();
    }, (err) => {
      alert('error');
      console.log(err);
    })
  }

  gotoCampaigns() {
    this.router.navigate(['campaigns']);
  }

  addAnotherMessage() {
    localStorage.removeItem("TEMPLATE_MESSAGE_INFO");
    this.router.navigate(['message-template']);
  }

  back() {
    this.router.navigate(['message-template']);
  }

  editMessage(_id, messageType) {
    if (messageType === "Connection Request") {
      this.sequenceService.getMessage(_id).subscribe((res) => {
        console.log(res);
        localStorage.setItem("CONNECTION_MESSAGE_INFO", JSON.stringify(res));
        this.router.navigate(['connection-request']);
      }, (err) => {
        alert('error');
        console.log(err);
      })
    } else {
      this.sequenceService.getMessage(_id).subscribe((res) => {
        console.log(res);
        localStorage.setItem("TEMPLATE_MESSAGE_INFO", JSON.stringify(res));
        this.router.navigate(['message-template']);
      }, (err) => {
        alert('error');
        console.log(err);
      })
    }
  }

}
