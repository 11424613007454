<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="container campaigns" *ngIf="!isLoading">
    <h3>Messaging Sequence</h3>
    <span>Your sequence currently looks like this: </span>

    <div class="input" *ngFor="let message of previewMessages; let i=index;">
        <span *ngIf="message.delay">
            <i class="far fa-clock"></i>
            <span class="ml-2"
                *ngIf="i>0 && previewMessages[i-1].messageType === 'Connection Request'; else templateName">
                Wait {{message.delay}} days after the connection is accepted
            </span>
        <ng-template #templateName>
            <span class="ml-2">Wait {{message.delay}} days after the previous message is sent</span>
        </ng-template>
        </span>

        <br *ngIf="message.delay" />

        <label>
            <i class="far fa-comment mr-1"></i>
            {{message.messageType}} Message
        </label>

        <br>

        <span class="message">
            {{message.message}}
        </span>

        <div class="actions">
            <span (click)="editMessage(message._id, message.messageType)">edit</span>
            <span (click)="addAnotherMessage()">add</span>
            <span (click)="openDeleteMessageModal(message._id)">delete</span>
        </div>
    </div>

    <div>
        <button type="button" (click)="addAnotherMessage()" class="btn btn-success">Add an additional message</button>
    </div>

    <div class="buttons">
        <!-- <button type="button" class="previous" (click)="addAnotherMessage()">
            Add another Message
        </button>
        <button type="button" class="previous" (click)="back()">Previous</button> -->
        <button type="submit" (click)="gotoCampaigns()">Done</button>
    </div>

</div>

<div mdbModal style="overflow-y: auto" #deleteModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Delete Message </p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body" *ngIf="deleteId">
                Are you sure you want to delete this message?
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="deleteMessage()">Yes</a>
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="deleteModal.hide()">No</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>