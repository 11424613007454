var ɵ0 = function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null)
        return -1;
    var dateParts = dateAsString.split('/');
    var cellDate = new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1]));
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
        return -1;
    }
    return 1;
};
export var dateFilterParams = {
    comparator: ɵ0,
    browserDatePicker: true,
    minValidYear: 2000,
    buttons: ['reset', 'apply'],
    closeOnApply: true
};
export { ɵ0 };
