<!-- Material form register -->
<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div id="mainDiv" class="form-row" *ngIf="!isLoggedIn">
    <div class="col-sm-6 offset-sm-3">
        <mdb-card>
            <mdb-card-header class="primary-color white-text text-center py-6">
                <h5>
                    <strong>Reset password</strong>
                </h5>
            </mdb-card-header>

            <!--Card content-->
            <mdb-card-body class="px-lg-5 pt-0">
                <div class="reset-email">{{email}}</div>
                <!-- Form -->
                <form class="text-center" style="color: #757575;">

                    <!-- Password -->
                    <div class="md-form">
                        <input type="password" id="password" [mdbValidate]="false" class="form-control" (keypress)="keyPressed($event)" [(ngModel)]="password" name="password" aria-describedby="password" mdbInputDirective>
                        <label for="password">New Password</label>
                    </div>

                    <!-- Sign up button -->
                    <button mdbBtn color="info" outline="true" rounded="true" (click)="save()" block="true" class="my-4 waves-effect z-depth-0" mdbWavesEffect type="button">Save</button>
                    <hr>
                </form>
                <!-- Form -->
            </mdb-card-body>
        </mdb-card>
    </div>
</div>

<!-- Material form register -->