import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { dateFilterParams } from '../../../helpers/dateFilterParams';
import { validateImage } from '../../../helpers/common';
var CaConnectionsComponent = /** @class */ (function () {
    function CaConnectionsComponent(userService) {
        this.userService = userService;
        this.rows = [];
        this.select = '';
        this.isLoading = true;
        this.selectedRow = undefined;
        this.noDataText = 'No connections right now';
        this.leadStatusOptions = [];
        this.columnDefs = [
            {
                width: 200,
                sortable: true,
                field: 'companyName',
                headerName: 'Company Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                    if (!valueA)
                        return -1;
                    if (!valueB)
                        return 1;
                    valueA = valueA.toLowerCase();
                    valueB = valueB.toLowerCase();
                    if (valueA == valueB)
                        return 0;
                    if (valueA > valueB)
                        return 1;
                    return -1;
                },
                cellRenderer: function (params) {
                    return "<a href=\"" + params.data.companyProfileURL + "\" target=\"_blank\">" + params.value + "</a>";
                }
            },
            {
                width: 100,
                sortable: true,
                resizable: true,
                editable: false,
                field: 'status',
                headerName: 'Lead Status',
                headerTooltip: "Lead Status",
                cellEditor: 'agSelectCellEditor',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                cellEditorParams: this.getLeadOptions.bind(this),
                cellRenderer: this.cellLeadCodeRenderer.bind(this)
            },
            {
                width: 100,
                sortable: true,
                field: 'firstName',
                headerName: 'First Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                width: 100,
                sortable: true,
                field: 'lastName',
                headerName: 'Last Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                width: 200,
                sortable: true,
                field: 'title',
                headerName: 'Title',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                width: 75,
                field: 'Avatar',
                headerName: 'Avatar',
                cellRenderer: this.avatarShow.bind(this),
            },
            {
                width: 75,
                headerName: 'View',
                field: 'profileURL',
                cellRenderer: function (params) {
                    return "<a href=\"" + params.data.profileURL + "\" target=\"_blank\">View</a>";
                }
            },
            // {
            //   width: 75,
            //   sortable: true,
            //   field: 'since1',
            //   headerName: 'Tenure',
            //   filter: 'agNumberColumnFilter',
            //   filterParams: {
            //     buttons: ['reset', 'apply'],
            //     closeOnApply: true
            //   },
            //   comparator: function (number1, number2) {
            //     if (number1 === null && number2 === null) {
            //       return 0;
            //     }
            //     if (number1 === null) {
            //       return -1;
            //     }
            //     if (number2 === null) {
            //       return 1;
            //     }
            //     return number1 - number2;
            //   },
            //   cellRenderer: function (params) {
            //     if (params.data.since1 === 0) {
            //       return `<span><1</span>`
            //     }
            //     return `<span>${params.data.since1}</span>`;
            //   }
            // },
            {
                width: 200,
                field: 'email',
                sortable: true,
                headerName: 'Email',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                cellRenderer: function (params) {
                    if (params.value)
                        return "<a href=\"mailto:" + params.value + "\">" + params.value + "<a>";
                    return '';
                }
            },
            {
                width: 125,
                field: 'phone',
                sortable: true,
                headerName: 'Phone',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                width: 150,
                sortable: true,
                field: 'location',
                headerName: 'Location',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            // {
            //   width: 150,
            //   sortable: true,
            //   field: 'mutualConnections',
            //   headerName: 'Mutual Connections',
            //   filter: "agTextColumnFilter",
            //   filterParams: {
            //     buttons: ['reset', 'apply'],
            //     closeOnApply: true
            //   },
            // },
            {
                width: 150,
                sort: "desc",
                sortable: true,
                field: 'connectedOnDate',
                headerName: 'Connected on',
                filter: "agDateColumnFilter",
                filterParams: dateFilterParams,
                comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                    if (!valueA)
                        return -1;
                    if (!valueB)
                        return 1;
                    if (new Date(valueA) > new Date(valueB))
                        return 1;
                    if (new Date(valueA) < new Date(valueB))
                        return -1;
                    else
                        return 0;
                },
            },
        ];
    }
    CaConnectionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.getUserByKey(localStorage.getItem('USER_KEY')).subscribe(function (user) {
            if (user.leadStatusOptions.length == 0) {
                _this.leadStatusOptions = ["", 0, 1, 2, 3, 4];
            }
            else
                _this.leadStatusOptions = [""].concat(user.leadStatusOptions);
        });
        this.userService.getCAUsers().subscribe(function (res) {
            _this.users = res.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            if (localStorage.getItem('CA_CONNECTIONS_KEY')) {
                _this.select = localStorage.getItem('CA_CONNECTIONS_KEY') + ',' + localStorage.getItem('CA_CONNECTIONS_LINKEDINID');
                _this.selectUser();
            }
            else {
                _this.isLoading = false;
            }
            validateImage();
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get ca users: ', err);
        });
    };
    CaConnectionsComponent.prototype.cellLeadCodeRenderer = function (params) {
        return (this.leadStatusOptions || [""])[params.data.status] || params.data.status;
    };
    CaConnectionsComponent.prototype.getLeadOptions = function () {
        return { values: this.leadStatusOptions || [0, 1, 2, 3, 4] };
    };
    CaConnectionsComponent.prototype.onPaginationChanged = function ($evt) { validateImage(); };
    CaConnectionsComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    };
    CaConnectionsComponent.prototype.exportData = function () {
        this.gridApi.exportDataAsCsv({
            processCellCallback: function (params) {
                var colDef = params.column.getColDef();
                if (colDef.valueFormatter) {
                    var valueFormatterParams = tslib_1.__assign({}, params, { data: params.node.data, node: params.node, colDef: params.column.getColDef() });
                    return colDef.valueFormatter(valueFormatterParams);
                }
                return params.value;
            },
        });
    };
    CaConnectionsComponent.prototype.selectUser = function () {
        var _this = this;
        if (this.select) {
            this.isLoading = true;
            var split = this.select.split(',');
            var v1 = split[0];
            var v2 = split[1];
            this.userService.getUserPXdata(v1).subscribe(function (connections) {
                _this.rows = connections.map(function (con) { return (tslib_1.__assign({}, con, { status: con.status != 0 ? (_this.leadStatusOptions[con.status] || con.status) : '' })); });
                console.log(connections);
                localStorage.setItem('CA_CONNECTIONS_KEY', '');
                localStorage.setItem('CA_CONNECTIONS_NAME', '');
                localStorage.setItem('CA_CONNECTIONS_LINKEDINID', '');
                localStorage.removeItem('USER_PXDATA_CACHED_AT');
                localStorage.removeItem('USER_PXDATA');
                _this.isLoading = false;
                validateImage();
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to get user px data: ', err);
            });
        }
    };
    CaConnectionsComponent.prototype.avatarShow = function (params) {
        var _this = this;
        var eDiv = document.createElement('div');
        if (!params.data.avatar) {
            eDiv.innerHTML = '';
        }
        else {
            eDiv.innerHTML =
                '<img src="' + params.data.avatar + '" class="avatar-img liImg"/>';
        }
        eDiv.addEventListener('click', function () {
            _this.selectedRow = params.data;
            _this.showModalOnClick.show();
        });
        return eDiv;
    };
    return CaConnectionsComponent;
}());
export { CaConnectionsComponent };
