import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var ReviewListComponent = /** @class */ (function () {
    function ReviewListComponent(avListService, userService, toastrService, router) {
        this.avListService = avListService;
        this.userService = userService;
        this.toastrService = toastrService;
        this.router = router;
        this.isLoading = true;
        this.note = '';
        this.allChecked = false;
        this.rowSelection = 'multiple';
        this.defaultColDef = { resizable: true };
        this.noDataText = 'Nothing to approve right now';
        this.showGrid = false;
        this.totalList = [];
        this.totalListIds = [];
        this.gridActualData = [];
        this.selectedListName = "";
        this.selectedListId = "";
    }
    ReviewListComponent.prototype.ngOnInit = function () {
        this.loadData();
    };
    ReviewListComponent.prototype.getAllList = function (listIds) {
        var _this = this;
        if (listIds === void 0) { listIds = []; }
        return new Promise(function (resolve, reject) { return _this.avListService.getAll(localStorage.getItem('USER_KEY'), listIds).subscribe(resolve); });
    };
    ReviewListComponent.prototype.listChanged = function (list) {
        localStorage.setItem('USER_REVIEW_LISTKEY', list._id);
        this.router.navigate(['review']);
    };
    ReviewListComponent.prototype.loadData = function () {
        var _this = this;
        this.showGrid = false;
        this.avListService.getUserReviewList(localStorage.getItem('USER_KEY')).subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.totalList = res;
                this.isLoading = false;
                if (this.totalList.length === 1) {
                    localStorage.setItem('USER_REVIEW_LISTKEY', this.totalList[0]._id);
                    this.router.navigate(['review']);
                    return [2 /*return*/];
                }
                return [2 /*return*/];
            });
        }); }, function (err) {
            _this.isLoading = false;
            alert('error');
            // console.log('error to get av data: ', err);
        });
    };
    ReviewListComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
        this.gridApi.sizeColumnsToFit();
    };
    ReviewListComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.gridColumnApi = params.columnApi;
    };
    return ReviewListComponent;
}());
export { ReviewListComponent };
