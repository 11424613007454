<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<!--Navbar-->
<mdb-navbar #navbarid SideClass="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">

    <!-- Navbar brand -->
    <mdb-navbar-brand>
        <a class="navbar-brand" routerLink="dashboard" href="dashboard" *ngIf="isLoggedIn && (isSuperAdmin || isAdmin)">Activity
            Tracker</a>
        <a class="navbar-brand" routerLink="caDashboard" href="caDashboard" *ngIf="isLoggedIn && isCompanyAdmin">Activity
            Tracker</a>
        <!-- <a class="navbar-brand" routerLink="euDashboard" href="euDashboard"
            *ngIf="isLoggedIn && !isSuperAdmin && !isAdmin && !isCompanyAdmin">Activity
            Tracker</a> -->
        <a class="navbar-brand" routerLink="messages" href="messages" *ngIf="isLoggedIn && !isSuperAdmin && !isAdmin && !isCompanyAdmin">Activity
            Tracker</a>
        <a class="navbar-brand" routerLink="login" href="login" *ngIf="!isLoggedIn">Activity Tracker</a>
    </mdb-navbar-brand>

    <!-- Collapsible content -->
    <links>

        <!-- Links -->
        <ul class="navbar-nav mr-auto">
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && (isSuperAdmin || isAdmin)">
                <a class="nav-link waves-light" (click)="toggleNavbar(); clearReviewListKey()" mdbWavesEffect href="dashboard" routerLink="dashboard">Dashboard</a>
            </li>
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && (isSuperAdmin || isAdmin)">
                <a class="nav-link waves-light" (click)="toggleNavbar(); clearReviewListKey()" mdbWavesEffect href="clientOverview" routerLink="clientOverview">Client Overview</a>
            </li>
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && isCompanyAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar(); clearReviewListKey()" mdbWavesEffect href="caDashboard" routerLink="caDashboard">Dashboard</a>
            </li>
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && isCompanyAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar(); clearListKey()" mdbWavesEffect href="lists" routerLink="lists" routerLinkActive="active">Lists</a>
            </li>
            <!-- <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && !isCompanyAdmin && !isSuperAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar()" mdbWavesEffect href="messages-old" routerLink="messages-old">Messages</a>
            </li> -->
            <!-- <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && !isCompanyAdmin && !isSuperAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar()" mdbWavesEffect href="euDashboard"
                    routerLink="euDashboard">Dashboard</a>
            </li> -->


            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && !isCompanyAdmin && !isSuperAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar()" mdbWavesEffect href="messages" routerLink="messages">Messages</a>
            </li>
            <!-- <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && !isCompanyAdmin && !isSuperAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar()" mdbWavesEffect href="incoming-messages" routerLink="incoming-messages">Incoming Messages (beta)</a>
            </li> -->
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && isCompanyAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar()" mdbWavesEffect href="caMessagesNew" routerLink="caMessagesNew">Messages</a>
            </li>
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && !isCompanyAdmin && !isSuperAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar(); clearReviewListKey()" mdbWavesEffect href="review-list" routerLink="review-list" routerLinkActive="active">Ready for Review</a>
            </li>
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && !isCompanyAdmin && !isSuperAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar(); clearReviewListKey()" mdbWavesEffect href="lists" routerLink="lists">Lists</a>
            </li>
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && !isCompanyAdmin && !isSuperAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar()" mdbWavesEffect href="connections" routerLink="connections" routerLinkActive="active" *ngIf="isLoggedIn">Connections</a>
            </li>
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && isCompanyAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar()" mdbWavesEffect href="caConnections" routerLink="caConnections" routerLinkActive="active" *ngIf="isLoggedIn">Connections</a>
            </li>
            <!-- <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && isCompanyAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar()" mdbWavesEffect href="caReports" routerLink="caReports" routerLinkActive="active" *ngIf="isLoggedIn">Reports</a>
            </li> -->
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && isCompanyEndUser && isViewedprofilesActive">
                <a class="nav-link waves-light" (click)="toggleNavbar(); clearReviewListKey()" mdbWavesEffect href="viewedprofiles" routerLink="viewedprofiles">Viewed Profiles</a>
            </li>
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && !isCompanyAdmin && !isSuperAdmin && groups">
                <a class="nav-link waves-light" (click)="toggleNavbar(); clearReviewListKey()" mdbWavesEffect href="groups" routerLink="groups">Groups</a>
            </li>
            
            <li class="nav-item" *ngIf="isLoggedIn && (!isSuperAdmin && !isAdmin && !isCompanyAdmin)  && (havedocurl == true)">
                <a class="nav-link waves-light" (click)="gotoGoogleDocURL()" target="_blank" mdbWavesEffect>Strategy
                    Guide</a>
            </li>
            <!-- <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && userselfserve && !isCompanyAdmin && !isSuperAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar()" mdbWavesEffect href="campaigns" routerLink="campaigns">Campaigns</a>
            </li> -->
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && isSuperAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar()" mdbWavesEffect href="users" routerLink="users">Users</a>
            </li>
            <li class="nav-item dropdown" dropdown *ngIf="isLoggedIn && isSuperAdmin">
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                    Lists<span class="caret"></span>
                </a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" *ngIf="isSuperAdmin" (click)="toggleNavbar();" href="list-prep"
                        routerLink="list-prep">List Prep</a>
                        <a class="dropdown-item waves-light" (click)="toggleNavbar(); clearListKey(); clearReviewListKey()" href="lists" routerLink="lists">All Lists</a>
                    
                    <a class="dropdown-item waves-light" (click)="toggleNavbar(); clearListKey(); clearReviewListKey()" href="unapprovedLists" routerLink="unapprovedLists">Unapproved Lists</a>
                </div>
            </li>
            <li class="nav-item dropdown" dropdown *ngIf="isLoggedIn && (isSuperAdmin || isAdmin)">
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                    Reports<span class="caret"></span>
                </a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" (click)="toggleNavbar(); clearReviewListKey()" href="listsReports" routerLink="listsReports">Reviewed Lists Report</a>
                </div>
            </li>
            <li class="nav-item dropdown" dropdown *ngIf="isLoggedIn && (isSuperAdmin || isAdmin)">
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                    Import<span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" (click)="toggleNavbar()" href="pxdata" routerLink="pxdata">Import PX Data Files</a>
                    <a class="dropdown-item waves-light" (click)="toggleNavbar()" href="avdata" routerLink="avdata">Upload Prospect List</a>
                    <a class="dropdown-item waves-light" (click)="toggleNavbar()" href="specialAvdata" routerLink="specialAvdata">Upload Special Prospect List</a>
                    <a class="dropdown-item waves-light" (click)="toggleNavbar()" href="messagedata" routerLink="messagedata">Import Messages Data Files</a>
                    <a class="dropdown-item waves-light" (click)="toggleNavbar()" href="ecemaildata" routerLink="ecemaildata">Import Corporate Email Addresses</a>
                    <a class="dropdown-item waves-light" (click)="toggleNavbar()" href="import-requested-emails" routerLink="import-requested-emails">Import Requested Emails</a>
                </div>
            </li>
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && isSuperAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar()" mdbWavesEffect href="exportdata" routerLink="exportdata" *ngIf="isLoggedIn">Export Data</a>
            </li>
            <li class="nav-item" routerLinkActive="active" *ngIf="isLoggedIn && isSuperAdmin">
                <a class="nav-link waves-light" (click)="toggleNavbar()" mdbWavesEffect href="setting" routerLink="settings" *ngIf="isLoggedIn">Settings</a>
            </li>
        </ul>
        <form class="form-inline waves-light" mdbWavesEffect *ngIf="isLoggedIn">
            <div class="md-form mt-0">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item" *ngIf="isSuperAdmin"><a class="nav-link waves-effect waves-light" (click)="toggleNavbar()" href="profile" routerLink="profile">{{userName}}</a></li>
                    <li class="nav-item" *ngIf="isSuperAdmin">
                        <a class="nav-link waves-effect waves-light" href="javascript:;;" (click)="logout()">
                            <i class="fa fa-lock"></i> Logout</a>
                    </li>
                </ul>
            </div>
        </form>
        <ul class="navbar-nav ml-auto" *ngIf="!isSuperAdmin && isLoggedIn">
            <li class="nav-item dropdown" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>{{userName}}<span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" (click)="toggleNavbar()" href="profile" routerLink="profile"><i
                            class="fa fa-user"></i> Edit Profile</a>
                    <a class="dropdown-item waves-light" (click)="openToAdd()"><i class="fa fa-download"></i>
                        Export Data</a>
                    <a class="dropdown-item waves-light" href="javascript:void(0)" (click)="logout()">
                        <i class="fa fa-lock"></i> Logout</a>
                </div>
            </li>
        </ul>
    </links>
    <!-- Collapsible content -->

</mdb-navbar>
<!--/.Navbar-->

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">Export Data</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div *ngIf="isCompanyAdmin" class="form-group col-sm-12">
                        <select class="browser-default custom-select" id="role" [(ngModel)]="userValue">
                            <option value="all">All</option>
                            <option *ngFor="let user of users" value="{{user._id}}">{{user.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-sm-12">
                        <select class="browser-default custom-select" id="role" [(ngModel)]="dataValue">
                            <option value="">Select Data</option>
                            <option value="connections">Connections</option>
                        </select>
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="from">From</label>
                        <input type="date" [(ngModel)]="from" name="from" class="form-control" id="from" placeholder="from">
                    </div>
                    <div class="form-group col-sm-6">
                        <label for="to">To</label>
                        <input type="date" [(ngModel)]="to" name="to" class="form-control" id="to" placeholder="to">
                    </div>
                    <div class="form-group col-sm-12">
                        <p>Include Additional Fields</p>
                        <div style="display:none">
                            <label>
                                <input type="checkbox" [(ngModel)]="directionS"/> 
                                Last Outgoing Message Details
                            </label>
                        </div>
                        <div>
                            <label>
                                <input type="checkbox" [(ngModel)]="directionI"/> 
                                Last Incoming Message Details
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="basicModal.hide()" mdbWavesEffect>Close</button>
                <button type="button" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect (click)="export()">Export</button>
            </div>
        </div>
    </div>
</div>