import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { User } from '../../../models/user';
var ReviewComponent = /** @class */ (function () {
    function ReviewComponent(route, avListService, userService, toastrService, router) {
        this.route = route;
        this.avListService = avListService;
        this.userService = userService;
        this.toastrService = toastrService;
        this.router = router;
        this.rejectionReasonList = [];
        this.rows = [];
        this.isLoading = true;
        this.selectedRows = [];
        this.selectedCount = 0;
        this.note = '';
        this.allChecked = false;
        this.rowSelection = 'multiple';
        this.defaultColDef = { resizable: true };
        this.noDataText = 'Nothing to approve right now';
        this.showGrid = false;
        this.columnDefs = [
            {
                field: 'companyName',
                width: 100,
                sortable: true,
                checkboxSelection: true,
                headerName: 'Company Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                comparator: function (valueA, valueB) {
                    if (!valueA)
                        return -1;
                    if (!valueB)
                        return 1;
                    valueA = valueA.toLowerCase();
                    valueB = valueB.toLowerCase();
                    if (valueA == valueB)
                        return 0;
                    if (valueA > valueB)
                        return 1;
                    return -1;
                },
                cellRenderer: function (params) {
                    if (params.data.tempSelected) {
                        params.node.setSelected(true);
                    }
                    return (params.data.companyProfileURL) ?
                        "<a href=\"" + params.data.companyProfileURL + "\" target=\"_blank\">" + params.data.companyName + "</a>"
                        : (params.data.companyId)
                            ? "<a href=\"https://www.linkedin.com/company/" + params.data.companyId + "/\" target=\"_blank\">" + params.data.companyName + "</a>"
                            : "<a href=\"https://www.linkedin.com/search/results/companies/?keywords=" + params.data.companyName + "\" target=\"_blank\">" + params.data.companyName + "</a>";
                },
            },
            {
                field: 'organization_website_1',
                width: 20,
                // sortable: true,
                headerName: 'Web',
                headerTooltip: 'Company Website',
                // filter: "agTextColumnFilter",
                cellRenderer: function (params) {
                    var iconStyle = "\n          display: flex;\n          justify-content: center;\n          align-items: center;\n          height: 100%;\n        ";
                    if (params.value) {
                        return "<a href=\"" + params.value + "\" target=\"_blank\" style=\"color: #007bff; " + iconStyle + "\">\n                    <i class=\"fas fa-globe\"></i>\n                  </a>";
                    }
                    else {
                        return "<div style=\"" + iconStyle + "\">\n                    <i class=\"fas fa-globe\" style=\"color: #ccc;\"></i>\n                  </div>";
                    }
                }
            },
            {
                field: 'fullName',
                width: 70,
                sortable: true,
                headerName: 'Name',
                headerTooltip: 'Full Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                cellRenderer: function (params) {
                    var fullName = params.data.firstName + " " + params.data.lastName;
                    if (params.data.profileURL) {
                        return "<a href=\"" + params.data.profileURL + "\" target=\"_blank\">" + fullName + "</a>";
                    }
                    else {
                        return fullName;
                    }
                },
                comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                    var fullNameA = (nodeA.data.firstName + " " + nodeA.data.lastName).toLowerCase();
                    var fullNameB = (nodeB.data.firstName + " " + nodeB.data.lastName).toLowerCase();
                    if (fullNameA === fullNameB)
                        return 0;
                    return (fullNameA > fullNameB) ? 1 : -1;
                }
            },
            {
                field: 'title',
                width: 100,
                sortable: true,
                headerName: 'Title',
                headerTooltip: 'Prospect Title',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                field: 'location',
                width: 80,
                sortable: true,
                headerName: 'Prospect Location',
                headerTooltip: 'Prospect Location',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                field: 'followers',
                width: 60,
                maxWidth: 80,
                sortable: true,
                headerTooltip: 'Connections',
                headerName: 'Conn.',
                filter: "agNumberColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                valueFormatter: function (params) {
                    if (params.value === null || params.value === undefined)
                        return '';
                    return Number(params.value).toLocaleString('en-US');
                },
                cellStyle: {
                    textAlign: 'right',
                    paddingRight: '30px'
                },
                comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                    return Number(valueA) - Number(valueB);
                }
            },
            {
                field: 'organization_industry_1',
                width: 60,
                sortable: true,
                headerName: 'Industry',
                headerTooltip: 'Organization Industry',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                field: 'organization_location_1',
                width: 100,
                sortable: true,
                headerName: 'Company HQ',
                headerTooltip: 'Organization Headquarters',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                field: 'organization_headcount_1',
                width: 70,
                maxWidth: 80,
                sortable: true,
                headerName: 'HC',
                headerTooltip: 'Organization Headcount',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                cellRenderer: function (params) {
                    console.log('Headcount value:', params.value, 'Type:', typeof params.value);
                    if (params.value === null || params.value === undefined || params.value === '' || params.value === 'NaN') {
                        return 'N/A';
                    }
                    else if (isNaN(Number(params.value))) {
                        console.log('Invalid number:', params.value);
                        return 'N/A';
                    }
                    else {
                        var numValue = Number(params.value);
                        return numValue.toLocaleString();
                    }
                },
                cellStyle: {
                    textAlign: 'right',
                    paddingRight: '20px' // Add padding to the right
                }
            },
            // {
            //   field: 'organization_headcount_1',
            //   width: 70,
            //   maxWidth: 80,
            //   sortable: true,
            //   headerName: 'HC',
            //   headerTooltip: 'Organization Headcount',
            //   filter: "agTextColumnFilter",
            //   filterParams: {
            //     buttons: ['reset', 'apply'],
            //     closeOnApply: true
            //   },
            //   cellRenderer: function(params) {
            //     if (params.value || params.value === 0) {
            //       return Number(params.value).toLocaleString();
            //     } else {
            //       return '';
            //     }
            //   },
            //   cellStyle: { 
            //     textAlign: 'right', 
            //     paddingRight: '20px'  // Add padding to the right
            //   }
            // },
            {
                field: 'rejectionReason',
                width: 60,
                sortable: false,
                headerName: 'Rejection Reason',
                cellRenderer: this.createDropdown.bind(this)
            },
        ];
        this.totalListIds = [];
        this.gridActualData = [];
        this.totalList = [];
        this.selectedListName = "";
    }
    ReviewComponent.prototype.ngOnInit = function () {
        this.loadRejectionReasons();
        this.loadData();
    };
    ReviewComponent.prototype.updateReason = function (id, reason) {
        var _this = this;
        this.userService.updateReason(id, reason).subscribe(function (res) {
            var index = _this.rows.findIndex(function (x) { return x.id === id; });
            if (index >= 0)
                _this.rows[index].rejectionReason = reason;
        });
    };
    ReviewComponent.prototype.createDropdown = function (params) {
        var e_1, _a;
        var _this = this;
        var selectInp = document.createElement('select');
        selectInp.id = "select-" + params.data.id;
        selectInp.classList.add('custom-select');
        selectInp.classList.add('small-select');
        // console.log(this.selectedRows);
        var options = "<option value=\"\"></option>";
        try {
            for (var _b = tslib_1.__values(this.rejectionReasonList), _c = _b.next(); !_c.done; _c = _b.next()) {
                var reason = _c.value;
                options += "<option " + (params.data.rejectionReason === reason ? 'selected' : '') + " value=\"" + reason + "\">" + reason + "</option>";
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        selectInp.innerHTML = options;
        if (this.selectedRows.find(function (x) { return x.id === params.data.id; })) {
            selectInp.classList.add("disabled");
        }
        selectInp.addEventListener('change', function (e) {
            var target = e.target;
            _this.updateReason(params.data.id, target.value);
        });
        return selectInp;
    };
    ReviewComponent.prototype.loadRejectionReasons = function () {
        var _this = this;
        this.userService.getRejectionReasons().subscribe(function (res) {
            _this.rejectionReasonList = res || [];
        });
    };
    ReviewComponent.prototype.getAllList = function (listIds) {
        var _this = this;
        if (listIds === void 0) { listIds = []; }
        return new Promise(function (resolve, reject) { return _this.avListService.getAll(localStorage.getItem('USER_KEY'), listIds).subscribe(resolve); });
    };
    ReviewComponent.prototype.loadData = function () {
        var _this = this;
        this.selectedCount = 0;
        this.showGrid = false;
        this.selectedRows = [];
        var listKey = localStorage.getItem('USER_REVIEW_LISTKEY') ? localStorage.getItem('USER_REVIEW_LISTKEY') : 'all';
        this.userService.getUserAVdata(localStorage.getItem('USER_KEY'), listKey).subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.totalListIds = Object.keys(res.reduce(function (r, _a) {
                            var listId = _a.listId;
                            return (r[listId] = '', r);
                        }, {}));
                        if (this.totalListIds.length === 0 || this.totalListIds.length > 1) {
                            this.router.navigate(['review-list']);
                            return [2 /*return*/];
                        }
                        _a = this;
                        return [4 /*yield*/, this.getAllList(this.totalListIds)];
                    case 1:
                        _a.totalList = _b.sent();
                        this.showGrid = true;
                        this.selectedListName = this.totalList[0].listName;
                        this.rows = res.map(function (row) { return (tslib_1.__assign({}, row, { organization_headcount_1: row.organization_headcount_1 !== null && row.organization_headcount_1 !== undefined && !isNaN(Number(row.organization_headcount_1))
                                ? Number(row.organization_headcount_1)
                                : null })); }).sort(function (a, b) {
                            if (a.companyName.toLowerCase() < b.companyName.toLowerCase()) {
                                return -1;
                            }
                            if (a.companyName.toLowerCase() > b.companyName.toLowerCase()) {
                                return 1;
                            }
                            if (a.title.toLowerCase() < b.title.toLowerCase()) {
                                return -1;
                            }
                            if (a.title.toLowerCase() > b.title.toLowerCase()) {
                                return 1;
                            }
                            return 0;
                        });
                        this.gridActualData = this.rows;
                        this.selectedCount = this.rows.filter(function (row) { return row.tempSelected; }).length;
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        }); }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.error('Error getting AV data:', err);
        });
    };
    // loadData() {
    //   this.selectedCount = 0;
    //   this.showGrid = false;
    //   this.selectedRows = [];
    //   const listKey = localStorage.getItem('USER_REVIEW_LISTKEY') ? localStorage.getItem('USER_REVIEW_LISTKEY') : 'all';
    //   this.userService.getUserAVdata(localStorage.getItem('USER_KEY'), listKey).subscribe(async (res) => {
    //     this.totalListIds = Object.keys(res.reduce((r, { listId }) => (r[listId] = '', r), {}));
    //     if (this.totalListIds.length === 0 || this.totalListIds.length>1){
    //       this.router.navigate(['review-list']);
    //       return;
    //     }
    //     this.totalList = await this.getAllList(this.totalListIds);
    //     this.showGrid = true;
    //     this.selectedListName = this.totalList[0].listName;
    //     this.rows = res.sort((a, b) => {
    //       if (a.companyName.toLowerCase() < b.companyName.toLowerCase()) {
    //         return -1;
    //       }
    //       if (a.companyName.toLowerCase() > b.companyName.toLowerCase()) {
    //         return 1;
    //       }
    //       if (a.title.toLowerCase() < b.title.toLowerCase()) {
    //         return -1;
    //       }
    //       if (a.title.toLowerCase() > b.title.toLowerCase()) {
    //         return 1;
    //       }
    //       return 0;
    //     });
    //     this.gridActualData = this.rows;
    //     this.selectedCount = this.rows.filter((row) => row.tempSelected).length;
    //     this.isLoading = false;
    //   }, (err) => {
    //     this.isLoading = false;
    //     alert('error');
    //     // console.log('error to get av data: ', err);
    //   });
    // }
    ReviewComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
        this.gridApi.sizeColumnsToFit();
    };
    ReviewComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.gridColumnApi = params.columnApi;
    };
    ReviewComponent.prototype.onSelectionChanged = function (event) {
        var e_2, _a;
        var _this = this;
        if (event.type === "rowSelected") {
            for (var i in this.rows) {
                if (this.rows[i].id === event.data.id) {
                    this.rows[i].tempSelected = event.node.selected;
                    break;
                }
            }
        }
        this.selectedCount = this.rows.filter(function (row) { return row.tempSelected; }).length;
        try {
            //this.selectedCount = event.api.getSelectedNodes().length;
            for (var _b = tslib_1.__values(this.selectedRows), _c = _b.next(); !_c.done; _c = _b.next()) {
                var item = _c.value;
                var selectInp = document.querySelector('#select-' + item.id);
                if (selectInp) {
                    selectInp.classList.remove("disabled");
                    selectInp.value = item.rejectionReason;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        document.querySelectorAll('select.disabled').forEach(function (element) {
            element.classList.remove("disabled");
        });
        this.selectedRows = [];
        event.api.getSelectedNodes().forEach(function (element) {
            var selectInp = document.querySelector('#select-' + element.data.id);
            if (selectInp) {
                selectInp.classList.add("disabled");
                selectInp.value = null;
            }
            _this.selectedRows.push(element.data);
        });
    };
    ReviewComponent.prototype.saveTempAvdata = function () {
        var _this = this;
        this.isLoading = true;
        var selected_ids = this.selectedRows.map(function (item) { return item.id; });
        var all_ids = this.rows.map(function (item) { return item.id; });
        this.userService.saveTempAvdata(selected_ids, all_ids).subscribe(function (res) {
            var e_3, _a;
            _this.isLoading = false;
            var options = { positionClass: 'toast-bottom-right', closeButton: true, timeOut: 20000 };
            _this.toastrService.success("Your selections have been saved. They have NOT been submitted for approval. When you are ready to approve the selections, please click on the green Approve Button.", '', options);
            var _loop_1 = function (id) {
                var index = _this.rows.findIndex(function (x) { return x.id === id; });
                if (index >= 0) {
                    _this.rows[index].tempSelected = true;
                }
            };
            try {
                for (var selected_ids_1 = tslib_1.__values(selected_ids), selected_ids_1_1 = selected_ids_1.next(); !selected_ids_1_1.done; selected_ids_1_1 = selected_ids_1.next()) {
                    var id = selected_ids_1_1.value;
                    _loop_1(id);
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (selected_ids_1_1 && !selected_ids_1_1.done && (_a = selected_ids_1.return)) _a.call(selected_ids_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            // console.log('error to save temporary avdata: ', err);
        });
    };
    ReviewComponent.prototype.openApproveModal = function () {
        var _this = this;
        this.selectedCount = this.rows.filter(function (row) { return row.tempSelected || _this.selectedRows.some(function (selectedRow) { return selectedRow.id === row.id; }); }).length;
        this.approveModal.show();
    };
    // openApproveModal() {
    //   this.selectedCount = this.rows.filter((row) => row.tempSelected).length;
    //   this.approveModal.show();
    // }
    ReviewComponent.prototype.approve = function () {
        var e_4, _a;
        var _this = this;
        this.approveModal.hide();
        var user = new User();
        user.note = this.note ? this.note : '';
        // Update this part to include both selected and previously saved records
        this.rows.forEach(function (row) {
            if (row.tempSelected || _this.selectedRows.some(function (selectedRow) { return selectedRow.id === row.id; })) {
                row.checked = true;
            }
        });
        var summary = {};
        try {
            for (var _b = tslib_1.__values(this.rows), _c = _b.next(); !_c.done; _c = _b.next()) {
                var row = _c.value;
                if (row.checked)
                    continue;
                if (!row.rejectionReason) {
                    if (!summary["No input provided"])
                        summary["No input provided"] = 0;
                    summary["No input provided"]++;
                }
                else {
                    if (!summary[row.rejectionReason])
                        summary[row.rejectionReason] = 0;
                    summary[row.rejectionReason]++;
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
        delete summary[""];
        delete summary["undefined"];
        // Update the selectedCount to include both selected and previously saved records
        this.selectedCount = this.rows.filter(function (row) { return row.checked; }).length;
        this.userService.approveAVData(this.rows, localStorage.getItem('USER_KEY'), localStorage.getItem('USER_KEY'), this.note).subscribe(function (res) {
            user._id = localStorage.getItem('USER_KEY');
            user.lastApprovedCount = _this.selectedCount;
            user.lastApprovedOn = new Date().getTime();
            user.lastApprovedPercentage = Math.round((_this.selectedCount / _this.rows.length) * 100);
            _this.userService.setLastApproved(user, summary).subscribe(function (result) {
                localStorage.removeItem('USER_AVDATA_CACHED_AT');
                _this.router.navigate(['review-list']);
            }, function (err) {
                _this.isLoading = false;
                alert('Error setting last approved');
                console.error('Error setting last approved:', err);
            });
        }, function (err) {
            _this.isLoading = false;
            alert('Error approving AV data');
            console.error('Error approving AV data:', err);
        });
    };
    return ReviewComponent;
}());
export { ReviewComponent };
