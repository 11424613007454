import { OnInit } from '@angular/core';
import { getUserRole } from 'src/app/helpers/getUserRole';
var GroupsComponent = /** @class */ (function () {
    function GroupsComponent(router, groupsService) {
        this.router = router;
        this.groupsService = groupsService;
        this.groups = [];
        this.filteredGroups = [];
        this.memberId = '';
        this.pageSize = 25;
        this.isLoading = true;
        this.sortBy = 'groupName';
        this.sortOrder = 'asc';
        this.statusValue = 'MEMBER';
    }
    GroupsComponent.prototype.ngOnInit = function () {
        var _this = this;
        localStorage.removeItem('CAMPAIGN_INFO');
        this.memberId = (localStorage.getItem('USER_GROUPS_MEMBERID') && getUserRole() === '1') ? localStorage.getItem('USER_GROUPS_MEMBERID') : localStorage.getItem('USER_MEMBER_ID');
        this.groupsService.getGroups(this.memberId).subscribe(function (res) {
            _this.groups = res;
            _this.filter();
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log(err);
        });
    };
    GroupsComponent.prototype.getAgeOfGroup = function (groupCreatedOn) {
        return new Date().getFullYear() - new Date(groupCreatedOn).getFullYear();
    };
    GroupsComponent.prototype.filter = function () {
        var _this = this;
        this.filteredGroups = this.groups.filter(function (item) { return item.status.includes(_this.statusValue); });
    };
    GroupsComponent.prototype.exportData = function () {
        var _this = this;
        var con = [];
        var csvContent = 'data:text/csv;charset=utf-8,';
        Object.keys(this.filteredGroups).forEach(function (data) {
            var val = _this.groups[data];
            con.push(val);
        });
        var title = "ID, Name, Description, Status, Joined Date, Member Count";
        csvContent += title + '\r\n';
        con.forEach(function (rowArray) {
            var joinedAt = new Date(rowArray.joinedAt);
            var joinedAt_ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(joinedAt);
            var joinedAt_mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(joinedAt);
            var joinedAt_da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(joinedAt);
            var joinedAt_d = joinedAt_mo + "/" + joinedAt_da + "/" + joinedAt_ye;
            var row = '"' + rowArray.groupId + '","' + rowArray.groupName.replace(/"/g, '""') + '","' + rowArray.groupDescription.replace(/"/g, '""') + '","'
                + rowArray.status + '","' + joinedAt_d + '","'
                + rowArray.memberCount + '"';
            csvContent += row + '\r\n';
        });
        csvContent = csvContent.replace(/#/g, '');
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        var d = new Date();
        var ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        var mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        var da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        var _d = mo + "-" + da + "-" + ye;
        link.setAttribute('download', localStorage.getItem("USER_NAME") + " - Group Export - " + _d + ".csv");
        document.body.appendChild(link); // Required for FF
        link.click();
        this.isLoading = false;
    };
    return GroupsComponent;
}());
export { GroupsComponent };
