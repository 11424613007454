import { OnInit } from '@angular/core';
import { getUserRole } from 'src/app/helpers/getUserRole';
var UnapprovedListsComponent = /** @class */ (function () {
    function UnapprovedListsComponent(userService, router) {
        this.userService = userService;
        this.router = router;
        this.editId = '';
        this.listName = '';
        this.deleteId = '';
        this.userValue = '';
        this.isLoading = true;
        this.companyValue = '';
        this.sortOrder = 'desc';
        this.listDescription = '';
        this.sortBy = 'uploadedOn';
        this.isEU = getUserRole() === '4' ? true : false;
        this.isCA = getUserRole() === '3' ? true : false;
        this.isRSA = getUserRole() === '1' ? true : false;
        this.listPrepComplete = false;
    }
    UnapprovedListsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.isCA) {
            this.userService.getCAUsers().subscribe(function (res) {
                _this.users = res.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
            }, function (err) {
                alert('error');
                console.log('error to get ca users: ', err);
            });
        }
        var listFor = localStorage.getItem('USER_LISTS_KEY') ? localStorage.getItem('USER_LISTS_KEY') : 'all';
        this.userService.getAVlists(listFor, 'unapproved').subscribe(function (res) {
            _this.avlists = res.avlists;
            _this.companies = res.companies.sort(function (a, b) {
                if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            if (_this.isRSA) {
                _this.users = res.users.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (_this.isCA) {
                _this.avlists = _this.avlists.filter(function (item) { return item.company === localStorage.getItem('USER_COMPANY'); });
            }
            _this.companyValue = localStorage.getItem('LISTS_COMPANY_FILTER') ? localStorage.getItem('LISTS_COMPANY_FILTER') : '';
            _this.userValue = localStorage.getItem('LISTS_USER_FILTER') ? localStorage.getItem('LISTS_USER_FILTER') : '';
            _this.filter();
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get dashboard: ', err);
        });
    };
    UnapprovedListsComponent.prototype.filter = function () {
        var _this = this;
        if (this.companyValue || this.userValue) {
            localStorage.setItem('CA_CONNECTIONS_KEY', '');
            localStorage.setItem('CA_CONNECTIONS_NAME', '');
            localStorage.setItem('CA_CONNECTIONS_LINKEDINID', '');
            localStorage.removeItem('USER_PXDATA_CACHED_AT');
            localStorage.removeItem('USER_PXDATA');
        }
        this.filteredAvlists = this.avlists;
        if (this.companyValue) {
            localStorage.setItem('LISTS_COMPANY_FILTER', this.companyValue);
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return item.company === _this.companyValue; });
        }
        else {
            localStorage.removeItem('LISTS_COMPANY_FILTER');
        }
        if (this.userValue) {
            localStorage.setItem('LISTS_USER_FILTER', this.userValue);
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return item.listFor === _this.userValue; });
        }
        else {
            localStorage.removeItem('LISTS_USER_FILTER');
        }
        return this.filteredAvlists;
    };
    UnapprovedListsComponent.prototype.openEditModal = function (avlist) {
        this.editId = avlist._id;
        this.listName = avlist.listName;
        this.listDescription = avlist.listDescription;
        this.listPrepComplete = avlist.listPrepComplete;
        this.editModal.show();
    };
    UnapprovedListsComponent.prototype.editList = function () {
        var _this = this;
        var data = { listName: this.listName, listDescription: this.listDescription, listPrepComplete: this.listPrepComplete };
        this.userService.editAVlist(this.editId, data).subscribe(function (res) {
            if (res) {
                _this.ngOnInit();
            }
            _this.isLoading = false;
            _this.editModal.hide();
        }, function (err) {
            _this.isLoading = false;
            _this.editModal.hide();
            alert('error');
            console.log('error to edit list: ', err);
        });
    };
    UnapprovedListsComponent.prototype.openDeleteModal = function (deleteId) {
        this.deleteId = deleteId;
        this.deleteModal.show();
    };
    UnapprovedListsComponent.prototype.deleteList = function () {
        var _this = this;
        this.userService.deleteAVlist(this.deleteId).subscribe(function (res) {
            if (res) {
                _this.ngOnInit();
            }
            _this.isLoading = false;
            _this.deleteModal.hide();
        }, function (err) {
            _this.isLoading = false;
            _this.deleteModal.hide();
            alert('error');
            console.log('error to delete list: ', err);
        });
    };
    UnapprovedListsComponent.prototype.listContacts = function (list, type) {
        localStorage.removeItem('LIST_CONTACTS_VIEW_TYPE');
        if (list._id !== localStorage.getItem('LIST_CONTACTS_KEY')) {
            localStorage.removeItem('LIST_AVDATA_CACHED_AT');
        }
        switch (type) {
            case 'all':
                localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', '');
                break;
        }
        localStorage.setItem('LIST_CONTACTS_KEY', list._id);
        localStorage.setItem('LIST_CONTACTS_NAME', list.name);
        localStorage.setItem('LIST_CONTACTS_COMPANY', list.company);
        localStorage.setItem('LIST_CONTACTS_LISTNAME', list.listName);
        this.router.navigate(['/listContacts']);
    };
    UnapprovedListsComponent.prototype.listReviewbylist = function (list) {
        if (list._id !== localStorage.getItem('USER_REVIEW_LISTKEY')) {
            localStorage.removeItem('USER_AVDATA_CACHED_AT');
        }
        localStorage.setItem('USER_REVIEW_KEY', list.listFor);
        localStorage.setItem('USER_REVIEW_LISTKEY', list._id);
        localStorage.setItem('USER_REVIEW_NAME', list.name);
        if (this.isEU) {
            this.router.navigate(['/review']);
        }
        else {
            this.router.navigate(['/userReview']);
        }
    };
    UnapprovedListsComponent.prototype.isTwoDayGapCheck = function (date) {
        if (date === 0) {
            return false;
        }
        var diff = Math.abs(new Date().getTime() - new Date(date).getTime()) / 3600000;
        return diff > 48;
    };
    return UnapprovedListsComponent;
}());
export { UnapprovedListsComponent };
