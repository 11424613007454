import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import * as moment from 'moment';

import { Message } from '../../../models/message';
import { UserService } from '../../../services/user.service';
import { SlackService } from 'src/app/services/slack.services';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

interface StopMessageResponse {
  success: boolean;
  code?: number;
  message: string;
}

@Component({
  selector: 'app-messages-beta',
  templateUrl: './messages-beta.component.html',
  styleUrls: ['./messages-beta.component.scss']
})
export class MessagesComponentBeta implements OnInit, AfterViewInit {
  @ViewChild('confirmModal', { static: false }) confirmModal: ModalDirective;
  @ViewChild('informModal', { static: false }) informModal: ModalDirective;


  gridApi;
  rows = [];
  totalRecords = 0;
  skip = 0;
  count = 25;
  gridColumnApi;
  conversations: any = [];
  isLoading = true;
  rowGroupMetadata: any;
  userLatestMessage: Message[] = [];
  noDataText = 'No messages right now';
  rowData: any;
  subRows: any;
  leadStatusOptions = [];
  cols = [
    { field: 'prospect', header: 'Prospect' },
    { field: 'last_incoming_message', header: 'Last Incoming Message' },
    { field: 'createdAt', header: 'Date/Time' },
  ];

  constructor(
    private slackService: SlackService,
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.userService.getUserByKey(localStorage.getItem('USER_KEY')).subscribe(user => {
      if (user.leadStatusOptions.length == 0) {
        this.leadStatusOptions = ["", 0, 1, 2, 3, 4];
      } else {
        this.leadStatusOptions = [""].concat(user.leadStatusOptions);
      }
    });
    this.fetchConversations();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();

  }
  updateStatus(newValue, id) {
    let status, newVal = this.leadStatusOptions.findIndex(x => x == newValue);
    if (newVal == 0) status = '';
    this.userService.updatePXDataStatus(id, newVal != -1 ? newVal : newValue).subscribe((response) => {
      console.log(response);
    });
  }

  fetchConversations() {
    this.isLoading = true;
    this.userService.getIncomingMessages(localStorage.getItem('USER_KEY'), localStorage.getItem('USER_MEMBER_ID'), this.skip, this.count).subscribe((data: any) => {
      this.isLoading = false;
      this.conversations = data;
      console.log(this.conversations);
    }, (err) => {
      this.isLoading = false;
      alert('Error fetching conversations');
      console.log('Error getting user latest messages: ', err);
    });
  }

  fetchAllThreads(thread, expanded) {
    if (!expanded) return;
    this.subRows = [];
    this.isLoading = true;
    this.userService.getThreadMessages(localStorage.getItem('USER_KEY'), thread).subscribe((data: any) => {
      this.isLoading = false;
      this.subRows = data;
      for (let i in this.conversations) {
        if (this.conversations[i].thread == data[0].thread) {
          this.conversations[i].subRows = data;
        }
      }
      console.log(data);
    }, (err) => {
      this.isLoading = false;
      alert('Error fetching thread messages');
      console.log('Error getting thread messages: ', err);
    });
  }

  sendStopMessageSlack(message: string) {
    this.slackService.stopSlackMessage({ "text": message, "channel": 'messaging-alerts', "username": 'Messaging Bot' }).subscribe();
  }

  onPage(evt) {
    console.log(evt);
    this.skip = parseInt(evt.first);
    this.count = parseInt(evt.rows);
    this.fetchConversations();
  }

  stopMessaging(rowData) {
    this.rowData = rowData;
    console.log(rowData);
    if (!this.rowData.stopmsg) {
      this.showConfirmDialog();
    } else {
      this.showInformDialog();
    }
  }

  showConfirmDialog() {
    if (this.confirmModal && typeof this.confirmModal.show === 'function') {
      this.confirmModal.show();
    } else {
      console.error('Confirm modal not available');
      // Fallback to browser confirm if modal is not available
      if (confirm('Are you sure you want to stop messaging?')) {
        this.processStopMessaging();
      }
    }
  }

  showInformDialog() {
    if (this.informModal && typeof this.informModal.show === 'function') {
      this.informModal.show();
    } else {
      alert('The message sequence for this person has already been stopped.');
    }
  }

  onConfirmModalAction(isConfirmed: boolean) {
    if (isConfirmed) {
      this.processStopMessaging();
    }
    this.hideConfirmModal();
  }


  processStopMessaging() {
    let elem = this.document.getElementById(this.rowData.id);
    let data: any = {};
    data.clientCompany = localStorage.getItem('USER_COMPANY');
    data.clientName = localStorage.getItem('USER_NAME');
    data.prospectName = this.rowData.contact;
    data.prospectLinkedinUrl = this.rowData.contactURL;
    if (elem) {
      elem.classList.add('msg-red');
    }
    let msg = `*** Stop Messaging Sequence ***
    ${data.clientCompany} - ${data.clientName}
    ${data.prospectName} - ${data.prospectLinkedinUrl}
    Requested at: ${moment().format('MM/DD/YYYY hh:mm:ss A')}`;
    this.sendStopMessageSlack(msg);
    this.rowData.stopmsg = true;
    
    this.userService.stopMessage(this.rowData.pxDataId)
      .pipe(
        tap((response: any) => console.log('Raw API response:', response)),
        map((response: any): StopMessageResponse => {
          if (response && response.success) {
            return {
              success: true,
              message: 'Message sequence stopped successfully'
            };
          } else {
            return {
              success: false,
              message: response.message || 'Failed to stop message sequence'
            };
          }
        })
      )
      .subscribe(
        (res: StopMessageResponse) => {
          console.log('Processed stop message response:', res);
          if (res.success) {
            console.log('Message sequence stopped successfully');
          } else {
            console.warn('Unexpected API response:', res);
          }
        },
        err => {
          console.error('Error stopping message:', err);
        }
      );
  }



  hideConfirmModal() {
    if (this.confirmModal && typeof this.confirmModal.hide === 'function') {
      this.confirmModal.hide();
    }
  }
}