import { getUserRole } from '../helpers/getUserRole';
import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
import * as i2 from "@angular/router";
var CompanyGuard = /** @class */ (function () {
    function CompanyGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    CompanyGuard.prototype.canActivate = function (next, state) {
        if (getUserRole() === '3') {
            return true;
        }
        else {
            return this.router.navigate(['/messages']);
        }
    };
    CompanyGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyGuard_Factory() { return new CompanyGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router)); }, token: CompanyGuard, providedIn: "root" });
    return CompanyGuard;
}());
export { CompanyGuard };
