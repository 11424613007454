import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

import { PXData } from '../../../models/PXData';

@Component({
  selector: 'app-eu-dashboard',
  templateUrl: './eu-dashboard.component.html',
  styleUrls: ['./eu-dashboard.component.scss']
})
export class EuDashboardComponent implements OnInit {

  connectionsData: any;
  connectionsTooltip: any[];
  connectionsOptionsObject: any;

  whoViewedData: any;
  whoViewedTooltip: any[];
  whoViewedOptionsObject: any;

  totalNumViews: Number;
  overallChangePercentage: Number;

  latestConnections: PXData[];
  latestViewedProfileData: any[];
  latestPxdataDataWithCompany: any[];

  userDashboardMessage = '';

  isLoading = true;

  constructor(private userService: UserService, private router: Router) {
    this.connectionsOptionsObject = {
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            callback: function (value, index, values) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          }
        }]
      }
    }

    this.whoViewedOptionsObject = {
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            const percentage = data.datasets[1].data[tooltipItem.index];
            const operator = percentage > 0 ? '+' : '-';
            const label = data.datasets[0].data[tooltipItem.index] + " views, " + operator + percentage + "% from last week"
            return label;
          }
        }
      }
    }
  }

  selectConnectionData(event) {
    this.connectionsTooltip = [];
    this.connectionsTooltip.push({ severity: 'info', summary: 'Data Selected', 'detail': this.connectionsData.datasets[event.element._datasetIndex].data[event.element._index] });
  }

  selectWhoViewedData(event) {
    this.whoViewedTooltip = [];
    this.whoViewedTooltip.push({ severity: 'info', summary: 'Data Selected', 'detail': this.whoViewedData.datasets[event.element._datasetIndex].data[event.element._index] });
  }

  ngOnInit() {
    this.userService.getEUDashboard(localStorage.getItem('USER_KEY'), localStorage.getItem('USER_MEMBER_ID')).subscribe((data) => {

      this.latestConnections = data.latestConnections;
      let connectionsLabels = [];

      for (let i = 0; i < 9; i++) {
        connectionsLabels.push(new Date(Date.now() - i * 604800000).getMonth() + 1 + '/' + new Date(Date.now() - i * 604800000).getDate());
      }

      connectionsLabels.reverse();

      this.connectionsData = {
        labels: connectionsLabels,
        datasets: [
          {
            label: ' Connections',
            data: data.connectionsData,
            fill: false,
            borderColor: '#4bc0c0'
          }
        ]
      }

      const whoViewedData = data.whoViewedData.chartData.sort((a, b) => {
        if (a.endTimestamp < b.endTimestamp) {
          return -1;
        }
        if (a.endTimestamp > b.endTimestamp) {
          return 1;
        }
        return 0;
      });;

      let whoViewedLabels = [];
      let whoViewedChartData = [];
      let whoViewedChangePercentage = [];

      for (let i = 0; i < whoViewedData.length; i++) {
        whoViewedLabels.push(new Date(whoViewedData[i].endTimestamp).getMonth() + 1 + '/' + new Date(whoViewedData[i].endTimestamp).getDate());
        whoViewedChartData.push(whoViewedData[i].numViews);
        whoViewedChangePercentage.push(whoViewedData[i].changePercentage);
      }

      this.totalNumViews = data.whoViewedData.totalNumViews;
      this.overallChangePercentage = whoViewedChangePercentage[whoViewedChangePercentage.length - 1] || 0;

      this.whoViewedData = {
        labels: whoViewedLabels,
        datasets: [
          {
            label: ' Viewed',
            data: whoViewedChartData,
            fill: true,
            borderColor: '#4bc0c0',
            backgroundColor: 'rgba(75, 192, 192, 0.3)',
          },
          {
            label: ' Percentage',
            data: whoViewedChangePercentage,
            fill: false,
            borderColor: '#4bc0c0',
            hidden: true,
          }
        ]
      }

      this.latestViewedProfileData = data.latestViewedProfileData;

      this.latestPxdataDataWithCompany = data.latestPxdataDataWithCompany;
      for (let i = 0; i < this.latestPxdataDataWithCompany.length; i++) {
        const imageStatus = this.imageExists(this.latestPxdataDataWithCompany[i].organization_domain_1);
        this.latestPxdataDataWithCompany[i].imageStatus = imageStatus;
      }

      const filteredArr = this.latestPxdataDataWithCompany.reduce((acc, current) => {
        const x = acc.find(item => item.organization_domain_1 === current.organization_domain_1);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      this.latestPxdataDataWithCompany = filteredArr.filter((item) => item.imageStatus).slice(0, 9);

      this.userDashboardMessage = data.userDashboardMessage;

      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get user pxdata: ', err);
    });
  }

  gotoConnectionsPage(company: any) {
    localStorage.setItem('ORGANIZATION_1', company.organization_1);
    this.router.navigate(['/connections']);
  }

  getDistance(distance: string) {
    let dis = '';
    switch (distance) {
      case 'DISTANCE_1':
        dis = '- 1st'
        break;
      case 'DISTANCE_2':
        dis = '- 2nd'
        break;
      case 'DISTANCE_3':
        dis = '- 3rd'
        break;
      default:
        dis = '';
    }

    return dis;
  }

  getReferrer(referrer: string) {
    let ref = '';

    switch (referrer) {
      case 'Homepage':
        ref = 'Found you via LinkedIn Homepage'
        break;
      case 'Groups':
        ref = 'Found you in a Group'
        break;
      case 'LinkedIn Search':
        ref = 'Found you via LinkedIn Search'
        break;
      case 'LinkedIn Company Pages':
        ref = 'Found you via LinkedIn Search'
        break;
      case 'LinkedIn Profile':
        ref = 'Found you via LinkedIn Profile'
        break;
      case 'Messaging':
        ref = 'Found you via Messaging'
        break;
      default:
        ref = '';
    }

    return ref;
  }

  imageExists(image_url) {
    var http = new XMLHttpRequest();

    try {
      http.open('HEAD', 'https://logo.clearbit.com/' + image_url, false);
      http.send();

      return http.status != 404;
    } catch {

      return false;
    }
  }
}
