import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { StrategyService } from 'src/app/services/strategy.service';

@Component({
  selector: 'app-strategy-type',
  templateUrl: './strategy-type.component.html',
  styleUrls: ['./strategy-type.component.scss']
})
export class StrategyTypeComponent implements OnInit {

  isLoading = true;
  strategyForm: FormGroup;
  clickByDoneButton = false;
  editMode = localStorage.getItem("EDIT_STRATEGY") ? true : false;

  research_options = [
    {
      id: "wizard",
      value: "Use RMD's wizard. (Most people select this)"
    },
    {
      id: "savedSearch",
      value: "Use a saved search in Sales Navigator."
    },
    // {
    //   id: "upload",
    //   value: "Upload a list."
    // }
  ];

  constructor(private fb: FormBuilder, public strategyService: StrategyService, private router: Router) {
    this.strategyForm = this.fb.group({
      userId: localStorage.getItem('USER_KEY'),
      research: this.fb.control('wizard'),
      savedSearchUrl: this.fb.control(''),
    });
  }

  ngOnInit(): void {
    const campaignInfo = localStorage.getItem('CAMPAIGN_INFO');

    if (!campaignInfo) {
      this.router.navigate(['campaigns']);
      return;
    }

    this.strategyService.getStrategy(JSON.parse(campaignInfo).strategyId).subscribe((res) => {
      console.log('existing strategy: ', res);
      if (res) {
        this.strategyForm = this.fb.group({
          strategyId: res._id,
          userId: localStorage.getItem('USER_KEY'),
          research: this.fb.control(res.research, Validators.required),
          savedSearchUrl: this.fb.control(res.savedSearchUrl),
        });
      }

      this.checkValidation();
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get user latest messages: ', err);
    });
  }

  strategyFormSubmit(): void {
    if (this.strategyForm.valid) {
      this.strategyService.submitStrategy(this.strategyForm.value).subscribe((res) => {
        console.log(res);
        if (this.clickByDoneButton) {
          this.router.navigate(['campaigns']);
          return;
        }
        if (res.research === 'wizard') {
          this.router.navigate(['new-strategy']);
        } else if (res.research === 'savedSearch') {
          this.router.navigate(['intro-message-sequence']);
        }
      }, (err) => {
        alert('error');
        console.log(err);
      });
    }
  }

  checkValidation(): void {
    this.strategyForm.value.research;

    if (this.strategyForm.value.research === "savedSearch") {
      this.strategyForm.get('savedSearchUrl').setValidators([Validators.required]);
    } else {
      this.strategyForm.get('savedSearchUrl').clearValidators();
    }

    this.strategyForm.controls["savedSearchUrl"].updateValueAndValidity();
  }

  cancelNewCampaign(): void {
    this.router.navigate(['campaigns']);
  }

  gotoCreateNewCampaign(): void {
    this.router.navigate(['create-new-campaign']);
  }

  clickDoneButton(): void {
    this.clickByDoneButton = true;
  }

}