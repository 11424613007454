import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import * as moment from "moment";
var CaReportsSentMsgComponent = /** @class */ (function () {
    function CaReportsSentMsgComponent(userService) {
        this.userService = userService;
        this.rows = [];
        this.select = '';
        this.isLoading = true;
        this.selectedRow = undefined;
        this.noDataText = 'No connections right now';
        this.columnDefs = [
            {
                sortable: true,
                field: 'userName',
                headerName: 'User Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                }
            },
            {
                sortable: true,
                field: 'companyName',
                headerName: 'Company Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                }
            },
            {
                sortable: true,
                field: 'firstName',
                headerName: 'First Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'lastName',
                headerName: 'Last Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'companyId',
                headerName: 'Company ID',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'title',
                headerName: 'Title',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'email',
                headerName: 'Email',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'phone',
                headerName: 'Phone',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'connectedOnDate',
                headerName: 'Connected On',
                filter: "agTextColumnFilter",
                cellClass: 'dateISO',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                valueFormatter: function (_a) {
                    var data = _a.data;
                    return moment(data.connectedOnDate).format('MM/DD/YYYY');
                },
                cellRenderer: function (_a) {
                    var data = _a.data;
                    return moment(data.connectedOnDate).format('MM/DD/YYYY');
                }
            },
            {
                sortable: true,
                field: 'memberId',
                headerName: 'MemberId',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'profileId',
                headerName: 'ProfileId',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'msgSent',
                headerName: 'Msgs Sent',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            }
        ];
    }
    CaReportsSentMsgComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.getCAUsers().subscribe(function (res) {
            _this.users = res.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            if (localStorage.getItem('CA_CONNECTIONS_KEY')) {
                _this.select = localStorage.getItem('CA_CONNECTIONS_KEY') + ',' + localStorage.getItem('CA_CONNECTIONS_LINKEDINID');
                _this.selectUser();
            }
            else {
                _this.isLoading = false;
            }
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get ca users: ', err);
        });
    };
    CaReportsSentMsgComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    };
    CaReportsSentMsgComponent.prototype.exportData = function () {
        this.gridApi.exportDataAsCsv({
            processCellCallback: function (params) {
                var colDef = params.column.getColDef();
                if (colDef.valueFormatter) {
                    var valueFormatterParams = tslib_1.__assign({}, params, { data: params.node.data, node: params.node, colDef: params.column.getColDef() });
                    return colDef.valueFormatter(valueFormatterParams);
                }
                return params.value;
            },
        });
    };
    CaReportsSentMsgComponent.prototype.selectUser = function () {
        var _this = this;
        if (this.select) {
            this.isLoading = true;
            var ids = void 0;
            var split = this.select.split(',');
            var v1 = split[0];
            var v2 = split[1];
            if (v1 == "all")
                ids = this.users.map(function (x) { return x._id; });
            else
                ids = [v1];
            this.userService.getUserSentMessages(ids).subscribe(function (messages) {
                _this.rows = messages;
                console.log('this.rows', _this.rows);
                _this.isLoading = false;
                //setTimeout(()=>this.gridApi!.exportDataAsCsv(),5000);
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to get user px data: ', err);
            });
        }
    };
    CaReportsSentMsgComponent.prototype.avatarShow = function (params) {
        var _this = this;
        var eDiv = document.createElement('div');
        if (!params.data.avatar) {
            eDiv.innerHTML = '';
        }
        else {
            eDiv.innerHTML =
                '<img src="' + params.data.avatar + '" style="width: 25px; height:25px; margin-top: -4.5px; cursor: pointer"/>';
        }
        eDiv.addEventListener('click', function () {
            _this.selectedRow = params.data;
            _this.showModalOnClick.show();
        });
        return eDiv;
    };
    return CaReportsSentMsgComponent;
}());
export { CaReportsSentMsgComponent };
