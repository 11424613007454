<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<p-table
  *ngIf="conversations.length"
  [columns]="cols"
  [value]="conversations"
  dataKey="thread"
  [paginator]="true"
  [rows]="25"
  [rowsPerPageOptions]="[5, 10, 15, 20, 25, 50]"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3em"></th>
      <th style="width: 3em"></th>
      <th style="width: 20%">Prospect</th>
      <th style="width: 3em">Msg</th>
      <th style="width: 10em">Lead Status</th>
      <th>Last Incoming Message</th>
      <th style="width: 10em">Date/Time</th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-expanded="expanded"
    let-columns="columns"
  >
    <tr>
      <td>
        <a href="#" [pRowToggler]="rowData">
          <i
            (click)="fetchAllThreads(rowData.thread, !expanded)"
            [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          ></i>
        </a>
      </td>
      <td>
        <a
        style="color: black; text-decoration: none"
        href="https://www.linkedin.com/messaging/thread/{{
          rowData?.thread
        }}/"
        target="_blank"
        [ngbTooltip]="'View this conversation on LinkedIn'"
        ><i class="fa fa-comments-o"></i
      ></a>
      </td>
      <td>
        <div style="display: flex; align-items: center;">
          <div style="margin-right: 10px;">
            <i class="fa-solid fa-droplet" 
               [ngStyle]="{
                 'color': rowData.from_avdata ? '#4285F4' : '#B4B4B4'
               }"
               [ngbTooltip]="rowData.from_avdata ? 'The prospect is from a RainMakerDot\'s campaign.' : 'The prospect is NOT from a RainMakerDot\'s campaign.'"
               tooltipClass="custom-tooltip">
            </i>
          </div>
          <div style="display: flex; flex-direction: column;">
            <div>
              <a href="{{ rowData.contactURL }}" class="contact">{{ rowData.contact }}</a>
            </div>
            <div *ngIf="rowData?.company">
              <a href="{{ rowData.company_url }}" target="_blank">
                <span>{{ rowData?.company }}</span>
              </a>
            </div>
          </div>
        </div>
      </td>
      <td >
        <ng-container *ngIf="rowData.from_avdata">
            <i
              *ngIf="rowData.from_avdata && rowData.pxDataId"
              [id]="rowData.id"
              [ngbTooltip]="
                rowData.stopmsg
                  ? 'The message sequence for this person has been stopped.'
                  : 'Click to stop all remaining messages in the sequence.'
              "
              [ngClass]="{ 'msg-red': rowData.stopmsg }"
              class="comment-slash fa-solid fa-comment-slash"
              (click)="stopMessaging(rowData)"
            >
            </i>
          </ng-container>
      </td>
      <td>
        <select
          class="custom-select"
          (change)="updateStatus(rowData.pxDataStatus, rowData.pxDataId)"
          [(ngModel)]="rowData.pxDataStatus"
        >
          <option
            *ngFor="let status of leadStatusOptions; let i = index"
            value="{{ i }}"
          >
            {{ status }}
          </option>
        </select>
      </td>
      <td>
        <ng-container *ngIf="rowData.body && rowData.body.trim().length > 0; else emptyBody">
          {{ rowData.body }}
        </ng-container>
        <ng-template #emptyBody>
          <span class="empty-body-message">
            <i class="fa-solid fa-circle-info" style="font-size: 0.9em; color: #aaa; margin-right: 5px;"></i>
            <em>this message may have contained a post or image that is not visible here</em>
          </span>
        </ng-template>
      </td>
      <!-- <td>
        {{ rowData.body }}
      </td> -->
      <td>
        <span>{{ rowData.tstamp | date : "MM/dd/yy hh:mm a" }}</span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
    <tr>
      <td [attr.colspan]="7">  <!-- Changed from 5 to 7 -->
        <p-table [value]="rowData.subRows">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 140px">Date/Time</th>
              <th style="width: 188px">Prospect</th>
              <th>Message</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-msg>
            <tr>
              <td>{{ msg.tstamp }}</td>
              <td>{{ msg.firstName_from }} {{ msg.lastName_from }}</td>
              <td>{{ msg.body }}</td>
            </tr>
          </ng-template>
        </p-table>
      </td>
    </tr>
  </ng-template>
</p-table>

<div mdbModal #confirmModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myBasicModalLabel">Confirm Stop Messaging</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideConfirmModal()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to stop messaging this prospect?
      </div>
      <div class="modal-footer">
        <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="onConfirmModalAction(false)" mdbWavesEffect>Cancel</button>
        <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="onConfirmModalAction(true)" mdbWavesEffect>Confirm</button>
      </div>
    </div>
  </div>
</div>

