import { OnInit } from '@angular/core';
// import { SlackService } from 'src/app/services/slack.services';
var ListPrepComponent = /** @class */ (function () {
    function ListPrepComponent(userService, router) {
        this.userService = userService;
        this.router = router;
        this.editId = '';
        this.deleteId = '';
        this.listName = '';
        this.users = [];
        this.userValue = '';
        this.dateValue = '60';
        this.showValue = '';
        this.isLoading = true;
        this.companyValue = '';
        this.isFinalized = false;
        this.stats = {
            totalCount: 0,
            totalApproved: 0,
            totalNotApproved: 0,
            percentageApproved: 0,
            totalInvited: 0,
            totalConnected: 0,
            totalResponded: 0,
            totalPositive: 0,
            totalConnectedPercentage: 0
        };
        this.sortOrder = 'desc';
        this.sortBy = 'finalized_on';
        this.sortByPrimary = 'finalized_on'; // Example default value
        this.sortOrderPrimary = 'desc';
        this.avlists = [];
        this.companies = [];
        this.listDescription = '';
        this.listPrepComplete = false;
        this.profileURLs = [];
        this.filteredAvlists = [];
    }
    ListPrepComponent.prototype.ngOnInit = function () {
        this.loadData();
    };
    ListPrepComponent.prototype.loadData = function () {
        var _this = this;
        this.isLoading = true;
        // this.userService.getCAUsers().subscribe((res) => {
        //   this.users = res.sort((a, b) => a.name.localeCompare(b.name));
        // }, (err) => {
        //   alert('error');
        //   console.log('error to get ca users: ', err);
        // });
        var listFor = localStorage.getItem('USER_KEY');
        this.userService.getAVlistPrep(listFor, 'all').subscribe(function (res) {
            _this.avlists = res.avlists;
            _this.companies = res.companies.sort(function (a, b) { return a.toLowerCase().localeCompare(b.toLowerCase()); });
            _this.users = res.users.sort(function (a, b) { return a.name.toLowerCase().localeCompare(b.name.toLowerCase()); });
            _this.initializeFilters();
            _this.filter();
            _this.isLoading = false;
            // this.updateStats(this.avlists);
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get dashboard: ', err);
        });
    };
    ListPrepComponent.prototype.initializeFilters = function () {
        this.showValue = localStorage.getItem('LIST_PREP_SHOW_FILTER') || '';
        this.companyValue = localStorage.getItem('LISTS_COMPANY_FILTER') || '';
        this.userValue = localStorage.getItem('LISTS_USER_FILTER') || '';
    };
    ListPrepComponent.prototype.resetFilter = function () {
        this.showValue = '';
        this.companyValue = '';
        this.userValue = '';
        localStorage.removeItem('LIST_PREP_SHOW_FILTER');
        localStorage.removeItem('LISTS_COMPANY_FILTER');
        localStorage.removeItem('LISTS_USER_FILTER');
        // localStorage.removeItem('LISTS_DATE_RANGE_FILTER');
        localStorage.removeItem('USER_PXDATA_CACHED_AT');
        localStorage.removeItem('USER_PXDATA');
        localStorage.removeItem('USER_LISTS_KEY');
        this.loadData();
    };
    ListPrepComponent.prototype.filter = function (isDaysTrigger) {
        var _this = this;
        if (isDaysTrigger === void 0) { isDaysTrigger = false; }
        this.filteredAvlists = this.avlists;
        if (this.showValue) {
            localStorage.setItem('LIST_PREP_SHOW_FILTER', this.showValue);
            if (this.showValue === 'in_progress') {
                this.filteredAvlists = this.filteredAvlists.filter(function (item) { return !item.research_finalized; });
            }
            else if (this.showValue === 'research_finalized') {
                this.filteredAvlists = this.filteredAvlists.filter(function (item) { return item.research_finalized && !item.listPrepComplete; });
            }
        }
        else {
            localStorage.removeItem('LIST_PREP_SHOW_FILTER');
            this.filteredAvlists = this.avlists.filter(function (item) { return !item.listPrepComplete; });
        }
        if (this.companyValue) {
            localStorage.setItem('LISTS_COMPANY_FILTER', this.companyValue);
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return item.company === _this.companyValue; });
        }
        else {
            localStorage.removeItem('LISTS_COMPANY_FILTER');
        }
        if (this.userValue) {
            localStorage.setItem('LISTS_USER_FILTER', this.userValue);
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return item.listFor === _this.userValue; });
        }
        else {
            localStorage.removeItem('LISTS_USER_FILTER');
        }
        if (this.dateValue) {
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return (Math.abs(new Date().getTime() - new Date(item.createdAt).getTime()) / 3600000) < parseInt(_this.dateValue) * 24; });
        }
        // Custom sort by `finalized_on` field
        this.filteredAvlists.sort(this.customSort.bind(this));
        return this.filteredAvlists;
    };
    ListPrepComponent.prototype.customSort = function (a, b) {
        var aFinalized = a.finalized_on ? new Date(a.finalized_on).getTime() : 0;
        var bFinalized = b.finalized_on ? new Date(b.finalized_on).getTime() : 0;
        if (aFinalized === 0 && bFinalized === 0) {
            return 0; // Both have no finalized_on
        }
        if (aFinalized === 0) {
            return this.sortOrderPrimary === 'asc' ? -1 : 1; // Handle empty dates
        }
        if (bFinalized === 0) {
            return this.sortOrderPrimary === 'asc' ? 1 : -1; // Handle empty dates
        }
        return this.sortOrderPrimary === 'asc' ? aFinalized - bFinalized : bFinalized - aFinalized; // Ascending or descending
    };
    ListPrepComponent.prototype.onSort = function (event) {
        if (event.sortBy === 'finalized_on') {
            // Toggle sort order
            this.sortOrderPrimary = this.sortOrderPrimary === 'asc' ? 'desc' : 'asc';
            this.filteredAvlists.sort(this.customSort);
        }
        else {
            // Reset sort order for finalized_on
            this.sortOrderPrimary = 'desc';
            // Apply default sorting logic for other columns
            this.filteredAvlists.sort(function (a, b) {
                var valueA = a[event.sortBy];
                var valueB = b[event.sortBy];
                return (valueA < valueB ? -1 : 1) * (event.sortOrder === 'asc' ? 1 : -1);
            });
        }
    };
    ListPrepComponent.prototype.updateListPrep = function (avlist, isFinalized) {
        if (isFinalized === void 0) { isFinalized = false; }
        this.editId = avlist._id;
        this.isFinalized = isFinalized;
        this.listName = avlist.listName;
        this.listDescription = avlist.listDescription;
        this.listPrepComplete = !avlist.listPrepComplete;
        console.log(avlist);
        this.editList();
    };
    ListPrepComponent.prototype.openEditModal = function (avlist) {
        this.editId = avlist._id;
        this.listName = avlist.listName;
        this.listDescription = avlist.listDescription;
        this.listPrepComplete = avlist.listPrepComplete;
        this.editModal.show();
    };
    ListPrepComponent.prototype.editList = function () {
        var _this = this;
        var data = { listName: this.listName, listDescription: this.listDescription };
        if (this.isFinalized) {
            data.research_finalized = true;
        }
        else {
            data.approvedOn = 0;
            data.listPrepComplete = this.listPrepComplete;
        }
        this.isFinalized = false;
        this.userService.editAVlist(this.editId, data).subscribe(function (res) {
            if (res) {
                _this.ngOnInit();
            }
            _this.isLoading = false;
            _this.editModal.hide();
        }, function (err) {
            _this.isLoading = false;
            _this.editModal.hide();
            alert('error');
            console.log('error to edit list: ', err);
        });
    };
    ListPrepComponent.prototype.openDeleteModal = function (deleteId) {
        this.deleteId = deleteId;
        this.deleteModal.show();
    };
    ListPrepComponent.prototype.deleteList = function () {
        var _this = this;
        this.userService.deleteAVlist(this.deleteId).subscribe(function (res) {
            if (res) {
                _this.ngOnInit();
            }
            _this.isLoading = false;
            _this.deleteModal.hide();
        }, function (err) {
            _this.isLoading = false;
            _this.deleteModal.hide();
            alert('error');
            console.log('error to delete list: ', err);
        });
    };
    ListPrepComponent.prototype.openProfileModal = function (avlist) {
        var _this = this;
        this.isLoading = true;
        this.selectedList = avlist;
        this.userService.getProfileURLs(avlist._id).subscribe(function (res) {
            if (res) {
                _this.profileURLs = res.map(function (value) { return value.profileURL; });
                _this.profileModal.show();
            }
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get profile URLs: ', err);
        });
    };
    ListPrepComponent.prototype.listContacts = function (list, type) {
        localStorage.removeItem('LIST_CONTACTS_VIEW_TYPE');
        if (list._id != localStorage.getItem('LIST_CONTACTS_KEY')) {
            localStorage.removeItem('LIST_AVDATA_CACHED_AT');
        }
        switch (type) {
            case 'all':
                localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', '');
                break;
            case 'approved':
                localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', 'Y');
                break;
            case 'not-approved':
                localStorage.setItem('LIST_CONTACTS_VIEW_TYPE', 'N');
                break;
        }
        localStorage.setItem('LIST_CONTACTS_KEY', list._id);
        localStorage.setItem('LIST_CONTACTS_NAME', list.name);
        localStorage.setItem('LIST_CONTACTS_COMPANY', list.company);
        localStorage.setItem('LIST_CONTACTS_LISTNAME', list.listName);
        //this.router.navigate(['/listContacts']);
        this.router.navigate(['/list-prep-prospects']);
    };
    ListPrepComponent.prototype.listReviewbylist = function (list) {
        if (list._id != localStorage.getItem('USER_REVIEW_LISTKEY')) {
            localStorage.removeItem('USER_AVDATA_CACHED_AT');
        }
        localStorage.setItem('USER_REVIEW_KEY', list.listFor);
        localStorage.setItem('USER_REVIEW_LISTKEY', list._id);
        localStorage.setItem('USER_REVIEW_NAME', list.name);
        // if (this.isEU) {
        //   return this.router.navigate(['/review']);
        // }
        this.router.navigate(['/userReview']);
    };
    ListPrepComponent.prototype.exportList = function (list) {
        var _this = this;
        localStorage.removeItem('LIST_AVDATA_CACHED_AT');
        this.userService.getAVDataByListId(list._id, localStorage.getItem('USER_KEY')).subscribe(function (res) {
            var con = [];
            var csvContent = 'data:text/csv;charset=utf-8,';
            Object.keys(res).forEach(function (data) {
                var val = res[data];
                con.push(val);
            });
            var title = "Status, Rejection Reason, Company Name, First Name, Last Name, Title, Profile Url, Email, Phone, Location, Company Start Date, Tenure, Connected On, Industry, Mutual Connections";
            csvContent += title + '\r\n';
            con.forEach(function (rowArray) {
                var row = "\"" + rowArray.approved + "\",\"" + (rowArray.rejectionReason || 'N/A') + "\",\"" + rowArray.companyName + "\",\"" + rowArray.firstName + "\",\"" + rowArray.lastName + "\",\"" + rowArray.title + "\",\"" + rowArray.profileURL + "\",\"" + rowArray.email + "\",\"" + rowArray.phone + "\",\"" + rowArray.location + "\",\"" + rowArray.yearsAtCompany + "\",\"" + rowArray.since1 + "\",\"" + rowArray.connectedOn + "\",\"" + rowArray.industry + "\",\"" + rowArray.mutualConnections + "\"";
                csvContent += row + '\r\n';
            });
            csvContent = csvContent.replace(/#/g, '');
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            var d = new Date();
            var ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            var mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            var da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            var _d = mo + "-" + da + "-" + ye;
            link.setAttribute('download', list.company + " - " + list.name + " (" + list.listName + ") - Exported on " + _d + ".csv");
            document.body.appendChild(link); // Required for FF
            link.click();
            _this.isLoading = false;
        }, function (err) {
            alert('error');
            console.log('error to get user av data: ', err);
        });
    };
    ListPrepComponent.prototype.isTwoDayGapCheck = function (date) {
        if (date === 0) {
            return false;
        }
        var diff = Math.abs(new Date().getTime() - new Date(date).getTime()) / 3600000;
        return diff > 48;
    };
    return ListPrepComponent;
}());
export { ListPrepComponent };
