import { OnInit } from '@angular/core';
var IntroMessageSequenceComponent = /** @class */ (function () {
    function IntroMessageSequenceComponent(router) {
        this.router = router;
    }
    IntroMessageSequenceComponent.prototype.ngOnInit = function () {
    };
    IntroMessageSequenceComponent.prototype.cancelNewCampaign = function () {
        this.router.navigate(['campaigns']);
    };
    IntroMessageSequenceComponent.prototype.back = function () {
        var campaignInfo = localStorage.getItem('CAMPAIGN_INFO');
        if (!campaignInfo) {
            this.router.navigate(['campaigns']);
        }
        else if (JSON.parse(campaignInfo).campaignType === "Message Only") {
            this.router.navigate(['campaigns']);
        }
        else if (JSON.parse(campaignInfo).research === "wizard") {
            this.router.navigate(['new-strategy']);
        }
        else if (JSON.parse(campaignInfo).research === "savedSearch") {
            this.router.navigate(['create-new-campaign']);
        }
    };
    IntroMessageSequenceComponent.prototype.gotoConnectionRequest = function () {
        this.router.navigate(['connection-request']);
    };
    return IntroMessageSequenceComponent;
}());
export { IntroMessageSequenceComponent };
