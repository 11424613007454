import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro-message-sequence',
  templateUrl: './intro-message-sequence.component.html',
  styleUrls: ['./intro-message-sequence.component.scss']
})
export class IntroMessageSequenceComponent implements OnInit {

  constructor(public router: Router) {
  }

  ngOnInit(): void {
  }

  cancelNewCampaign(): void {
    this.router.navigate(['campaigns']);
  }

  back(): void {
    const campaignInfo = localStorage.getItem('CAMPAIGN_INFO');

    if (!campaignInfo) {
      this.router.navigate(['campaigns']);
    } else if (JSON.parse(campaignInfo).campaignType === "Message Only") {
      this.router.navigate(['campaigns']);
    } else if (JSON.parse(campaignInfo).research === "wizard") {
      this.router.navigate(['new-strategy']);
    } else if (JSON.parse(campaignInfo).research === "savedSearch") {
      this.router.navigate(['create-new-campaign']);
    }
  }

  gotoConnectionRequest(): void {
    this.router.navigate(['connection-request']);
  }

}