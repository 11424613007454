import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { company } from '../models/company';


@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http: HttpClient) { }

  public getBaseUrl(): string {
    return environment.baseUrl;
  }

  protected getUrl(relativeUrl: string) {
    return this.getBaseUrl() + relativeUrl;
  }

  protected getOptions(): any {
    const AuthorizeToken = localStorage.getItem('USER_TOKEN');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + AuthorizeToken
    });

    const options = {
      headers: headers
    };

    return options;
  }

  addCompany(company: company): Observable<company> {
    const url = this.getUrl('/companies');

    return this.http.post(url, company, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        })
      )

  }

  getCompanies(): Observable<company[]> {
    const url = this.getUrl('/companies');

    return this.http.get(url, this.getOptions())
      .pipe(
        map((querySnapshot: any) => {
          const companies: company[] = [];
          querySnapshot.forEach((data) => {
            companies.push({
              _id: data._id,
              companyName: data.companyName
            });
          });
          return companies;
        }));
  }

  deleteCompany(company: company): Observable<company> {
    const url = this.getUrl('/companies/' + company._id);

    return this.http.delete(url, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  editCompany(company: company): Observable<company> {
    const url = this.getUrl('/companies/' + company._id);

    return this.http.put(url, company, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        })
      )
  }

  searchCompany(searchByCompany): Observable<company[]> {
    const url = this.getUrl('/companies');

    return this.http.get(url, this.getOptions())
      .pipe(
        map((querySnapshot: any) => {
          const companies: company[] = [];
          querySnapshot.forEach((data: any) => {
            if (data.companyName && data.companyName.toLowerCase().indexOf(searchByCompany.toLowerCase()) > -1) {
              companies.push({
                _id: data._id,
                companyName: data.companyName
              });
            }
          });

          return companies;
        }));
  }
}
