import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { getUserRole } from '../helpers/getUserRole';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (getUserRole() === '1') {
      return true;
    } else {
      if (getUserRole() === '3') {
        return this.router.navigate(['/caDashboard']);
      } else {
        // return this.router.navigate(['/euDashboard']);
        return this.router.navigate(['/messages']);
      }
    }
  }
}