import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { StrategyService } from 'src/app/services/strategy.service';

@Component({
  selector: 'app-new-strategy',
  templateUrl: './new-strategy.component.html',
  styleUrls: ['./new-strategy.component.scss']
})
export class NewStrategyComponent implements OnInit {

  isLoading = true;
  strategy: any = undefined;

  constructor(private router: Router, public strategyService: StrategyService) { }

  ngOnInit(): void {
    const campaignInfo = localStorage.getItem('CAMPAIGN_INFO');

    if (!campaignInfo) {
      this.router.navigate(['campaigns']);
      return;
    }

    this.strategyService.getStrategy(JSON.parse(campaignInfo).strategyId).subscribe((res) => {
      console.log('existing strategy: ', res);
      this.strategy = res;
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get user latest messages: ', err);
    });
  }

  setLoading(e: boolean): void {
    this.isLoading = e;
  }

}