import { getUserRole } from '../helpers/getUserRole';
import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
import * as i2 from "@angular/router";
var AdminGuard = /** @class */ (function () {
    function AdminGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    AdminGuard.prototype.canActivate = function (next, state) {
        if (getUserRole() === '1') {
            return true;
        }
        else {
            if (getUserRole() === '3') {
                return this.router.navigate(['/caDashboard']);
            }
            else {
                // return this.router.navigate(['/euDashboard']);
                return this.router.navigate(['/messages']);
            }
        }
    };
    AdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router)); }, token: AdminGuard, providedIn: "root" });
    return AdminGuard;
}());
export { AdminGuard };
