import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild } from '@angular/core';

import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  @ViewChild('basicModal', { static: true }) showModalOnClick: ModalDirective;

  modalRef;
  users: User[];
  userValue = '';
  roleValue = '';
  statusValue = '';
  isLoading = true;
  user = new User();
  companyValue = '';
  modal_type = 'add';
  companies: string[];
  dropdownUsers: User[];
  filteredUsers: User[];
  update_password = false;
  assignedResearchers: User[];

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.getUsers();
  }

  openToAdd(): void {
    this.user = new User();
    this.modal_type = 'add';
    this.user.role = "";
    this.user.assignedResearcher = "";
    this.showModalOnClick.show();
  }

  reset(): void {
    this.user = new User();
    localStorage.removeItem('PREV_LI_PID');
    this.getUsers();
  }

  getUsers(): void {
    this.userService.getAllUsers().subscribe((res) => {
      this.dropdownUsers = [...res.users].sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      this.users = [...res.users].sort((a, b) => {
        if (a.company.toLowerCase() < b.company.toLowerCase()) return -1;
        if (a.company.toLowerCase() > b.company.toLowerCase()) return 1;

        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      });

      this.companies = res.companies.sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        return 0;
      });

      this.filter();

      this.assignedResearchers = [...this.users].filter((user: User) => user.isResearcher === true).sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get all users: ', err);
    });
  }

  filter(): User[] {
    this.filteredUsers = this.users;

    if (this.companyValue) {
      this.filteredUsers = this.filteredUsers.filter((item: User) => item.company === this.companyValue);
    }
    if (this.userValue) {
      this.filteredUsers = this.filteredUsers.filter((item: User) => item._id === this.userValue);
    }
    if (this.roleValue) {
      this.filteredUsers = this.filteredUsers.filter((item: User) => item.role === this.roleValue);
    }
    if (this.statusValue === 'inactive') {
      this.filteredUsers = this.filteredUsers.filter((item: User) => item.isHidden === true);
    }
    if (this.statusValue === 'active') {
      this.filteredUsers = this.filteredUsers.filter((item: User) => item.isHidden === false);
    }

    return this.filteredUsers;
  }

  openToEdit(user: User): void {
    console.log(user);
    this.modal_type = 'edit';
    this.user._id = user._id;
    this.user.name = user.name;
    this.user.company = user.company;
    this.user.email = user.email;
    this.user.role = user.role;
    this.user.linkedInProfileId = user.linkedInProfileId;
    localStorage.setItem('PREV_LI_PID', user.linkedInProfileId);
    this.user.vm = user.vm || '';
    this.user.myId = user.myId;
    this.user.memberId = user.memberId;
    this.user.isHidden = user.isHidden;
    this.user.isLila = !!user.isLila;
    this.user.groups = !!user.groups;
    this.user.isViewedprofilesActive = !!user.isViewedprofilesActive;
    this.user.selfserve = user.selfserve;
    this.user.notifications = user.notifications;
    this.user.googleDocURL = user.googleDocURL;
    this.user.isResearcher = user.isResearcher;
    this.user.assignedResearcher = user.assignedResearcher || "";
    this.user.initials = user.initials;
    this.user.leadStatusOptions = (user.leadStatusOptions || [0, 1, 2, 3, 4]).join(",");
    this.showModalOnClick.show();
  }

  clearNewPassword(): void {
    this.user.password = '';
  }

  saveUser(): void {
    //console.log(this.user);return;
    if (!this.user.name) {
      alert('Please enter user name');
      return;
    }
    if (!this.user.company) {
      alert('Please enter user company');
      return;
    }
    if (!this.user.linkedInProfileId) {
      alert('Please enter linkedin profile ID');
      return;
    }
    if (!this.user.myId) {
      alert('Please enter My ID');
      return;
    }
    if (!this.user.memberId) {
      alert('Please enter Member ID');
      return;
    }
    if (!this.user.email) {
      alert('Please enter user email');
      return;
    }
    if (!this.user.password && this.modal_type === 'add') {
      alert('Please enter user password');
      return;
    }
    if (!this.user.role) {
      alert('Please select user role');
      return;
    }
    if (!this.user.password && this.modal_type === 'edit' && this.update_password) {
      alert("Please enter user's new password");
      return;
    }
    if (!this.user.leadStatusOptions) {
      alert('Please select lead code status');
      return;
    }
    if (!this.user._id) {
      this.user.leadStatusOptions = this.user.leadStatusOptions.split(",").map(x => x.trim());
      this.userService.addUser(this.user).subscribe((user) => {
        this.reset();
        this.showModalOnClick.hide();
      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to add new user: ', err);
      });
    } else {
      this.isLoading = true;
      this.showModalOnClick.hide();
      this.user.leadStatusOptions = this.user.leadStatusOptions.split(",").map(x => x.trim());
      if (localStorage.getItem('PREV_LI_PID') !== this.user.linkedInProfileId) {
        this.userService.changeUserLinkedinID(localStorage.getItem('PREV_LI_PID'), this.user.linkedInProfileId).subscribe(s => {
          this.userService.editUser(this.user).subscribe((user) => {
            this.reset();
            this.isLoading = false;
          }, (err) => {
            this.isLoading = false;
            alert('error');
            console.log('error to edit the user: ', err);
          });
        }, (err) => {
          this.isLoading = false;
          alert('error');
          console.log('error to update the user linkedin id: ', err);
        });
      } else {
        console.log(this.user);
        this.userService.editUser(this.user).subscribe((user) => {
          this.reset();
          this.isLoading = false;
        }, (err) => {
          this.isLoading = false;
          alert('error');
          console.log('error to edit the user: ', err);
        });
      }
    }
  }
}