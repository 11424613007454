export const validateImage = ()=>{ 
  setTimeout(()=>{        
    let images = Array.from(document.getElementsByClassName('liImg'));
    images.forEach(function (image: HTMLImageElement) {
        let src = image.getAttribute('src');
        image.setAttribute('real-src',src);
        if (typeof image.naturalWidth == "undefined" || image.naturalWidth == 0 ) {
          image.setAttribute('src', `../../assets/profile-image-blank.png`);
        }
    
    });
},1000)
}