export class AVLists {
  _id: string;
  name: string;
  status: string;
  listFor: string;
  company: string;
  listName: string;
  createdAt: string;
  uploadedOn: string;
  approvedOn: Number;
  approvedBy: string;
  notApproved: Number;
  invitedCount: Number;
  positiveCount: Number;
  uploadedCount: Number;
  approvedCount: Number;
  connectedCount: Number;
  respondedCount: Number;
  listDescription: string;
  daysSinceUpload: Number;
  invitedPercentage: Number;
  positivePercentage: Number;
  approvedPercentage: Number;
  connectedPercentage: Number;
  respondedPercentage: Number;
  unApprovedPercentage: Number;
  listPrepComplete: boolean;
  research_finalized: boolean;
  finalized_on:string;
  research_finalized_note:string;
  researcher:string;
  ext_rec_count:Number;
  emptyFields:Number;
}
