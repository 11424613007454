<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<h4>
    <fa name="user"></fa> {{userReviewName}} - Ready for Review Records
</h4>

<a href="javascript:void(0)" class="float-btns approve" *ngIf="rows && rows.length > 0 && selectedCount > 0" [disabled]="selectedCount === 0" data-toggle="modal" data-target="#basicExample" (click)="approveModal.show()" title="Approve Selected Prospects"
    mdbWavesEffect>
    <button mdbBtn type="button" mdbWavesEffect>
        <mdb-icon fas icon="check" class="mr-1"></mdb-icon>Approve
    </button>
</a>

<a href="javascript:void(0)" class="float-btns save" *ngIf="rows && rows.length > 0 && selectedCount > 0" [disabled]="selectedCount === 0" (click)="saveTempAvdata()" title="Save Selected Prospects" mdbWavesEffect>
    <button mdbBtn type="button" color="primary" mdbWavesEffect>
        <mdb-icon fas icon="save" class="mr-1"></mdb-icon>Save
    </button>
</a>

<ag-grid-angular style="width: 100%; height: calc(100% - 52px)" (gridReady)="onGridReady($event)" (firstDataRendered)="autoSizeAll()" [enableColResize]="true" [paginationAutoPageSize]="true" [pagination]="false" class="ag-theme-alpine" [rowData]="rows"
    [columnDefs]="columnDefs" [enableSorting]="true" [enableFilter]="true" [rowSelection]="rowSelection" [rowMultiSelectWithClick]="true" (selectionChanged)='onSelectionChanged($event)' [overlayNoRowsTemplate]='noDataText'>
</ag-grid-angular>

<!-- <button type="button" *ngIf="rows && rows.length > 0" mdbBtn color="primary" [disabled]="nonChecked" rounded="true"
    data-toggle="modal" data-target="#basicExample" (click)="approveModal.show()" mdbWavesEffect>Approve</button> -->

<div mdbModal #approveModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Approve</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="approveModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <div class="text-center">
                    <i class="fa fa-check fa-4x mb-3 animated rotateIn"></i>
                    <p>You are about to approve {{selectedCount}} of {{rows.length}} records.</p>
                </div>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" class="waves-light" mdbWavesEffect (click)="approve()">Approve
                    <i class="fa fa-check ml-1"></i>
                </a>
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="approveModal.hide()">Cancel</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>