import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SequenceService = /** @class */ (function () {
    function SequenceService(http) {
        this.http = http;
    }
    SequenceService.prototype.getBaseUrl = function () {
        return environment.baseUrl;
    };
    SequenceService.prototype.getUrl = function (relativeUrl) {
        return this.getBaseUrl() + relativeUrl;
    };
    SequenceService.prototype.getOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    SequenceService.prototype.submitSequence = function (formValue) {
        var url = this.getUrl('/z-messages');
        return this.http.post(url, formValue, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    SequenceService.prototype.getMessageTemplates = function () {
        var url = this.getUrl('/z-message-templates');
        return this.http.get(url, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    SequenceService.prototype.getMessages = function (sequenceId) {
        var url = this.getUrl('/z-messages/' + sequenceId);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    SequenceService.prototype.deleteMessage = function (_id) {
        var url = this.getUrl('/z-messages/' + _id);
        return this.http.delete(url, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    SequenceService.prototype.getMessage = function (_id) {
        var url = this.getUrl('/z-messages/individual/' + _id);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (res) {
            return res;
        }));
    };
    SequenceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SequenceService_Factory() { return new SequenceService(i0.ɵɵinject(i1.HttpClient)); }, token: SequenceService, providedIn: "root" });
    return SequenceService;
}());
export { SequenceService };
