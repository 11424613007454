<div class="loading" *ngIf="isLoading">Loading&#8230;</div>
<div class="control-bar">
    <h4 *ngIf="selectedListName">
        {{selectedListName}}
    </h4>
    <div>
        <a href="javascript:void(0)" class="float-btns approve" *ngIf="rows && rows.length > 0 && selectedCount > 0 && showGrid"
            [disabled]="selectedCount === 0" data-toggle="modal" data-target="#basicExample" (click)="approveModal.show()"
            title="Approve Selected Prospects" >
            <button mdbBtn type="button" mdbWavesEffect>
                <mdb-icon fas icon="check" class="mr-1"></mdb-icon>Approve
            </button>
        </a>
        
        <a href="javascript:void(0)" class="float-btns save" *ngIf="rows && rows.length > 0 && showGrid"
            [disabled]="selectedCount === 0" (click)="saveTempAvdata()" title="Save Selected Prospects" >
            <button mdbBtn type="button" color="primary" mdbWavesEffect>
                <mdb-icon fas icon="save" class="mr-1"></mdb-icon>Save
            </button>
        </a>
    </div>
</div>
<ag-grid-angular *ngIf="showGrid" style="width: 100%; height: calc(100% - 75px)" (gridReady)="onGridReady($event)"
    (firstDataRendered)="autoSizeAll()" [defaultColDef]="defaultColDef" [enableColResize]="true" [pagination]="false"
    class="ag-theme-alpine" [rowData]="rows" [columnDefs]="columnDefs" [enableSorting]="true" [enableFilter]="true"
    [rowSelection]="rowSelection" [rowMultiSelectWithClick]="true" [suppressRowClickSelection]="true"
    (rowSelected)='onSelectionChanged($event)' [overlayNoRowsTemplate]="noDataText">
</ag-grid-angular>


<div mdbModal #approveModal="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notify" role="document">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <p class="heading">Approve</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="approveModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> -->
            <div class="modal-body">
                <div class="approval-info">
                    <!-- <i class="fa fa-check fa-4x mb-3 animated rotateIn"></i> -->
                    <p>You are about to approve {{selectedCount}} of {{rows.length}} records.</p>
                    <textarea class="form-control" [(ngModel)]="note" placeholder="You can provide additional feedback here."></textarea>
                </div>
                <div class="button-group">
                    <a class="btn btn-primary" (click)="approve()">
                        Approve
                        <i class="fa fa-check ml-1"></i>
                    </a>
                    <a class="btn btn-outline-primary" (click)="approveModal.hide()">Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>