import { OnInit } from '@angular/core';
import { User } from '../../../models/user';
var CompanyPxdataComponent = /** @class */ (function () {
    function CompanyPxdataComponent(userService) {
        this.userService = userService;
        this.select = '';
        this.isLoading = true;
        this.user = new User();
    }
    CompanyPxdataComponent.prototype.ngOnInit = function () {
        this.getUsers();
    };
    CompanyPxdataComponent.prototype.getUsers = function () {
        var _this = this;
        this.userService.getAllUsers().subscribe(function (res) {
            _this.users = res.users;
            _this.uniqueData = Object.values(_this.users.reduce(function (r, o) { return (r[o.company.trim()] = o, r); }, {}));
            _this.uniqueData = _this.uniqueData.sort(function (a, b) {
                if (a.company.toLowerCase() < b.company.toLowerCase()) {
                    return -1;
                }
                if (a.company.toLowerCase() > b.company.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            _this.isLoading = false;
        }, function (err) {
            alert('error');
            console.log('error to get users: ', err);
        });
    };
    CompanyPxdataComponent.prototype.getUserLinkedinProfileID = function (_id) {
        var linkedinProfileID = '';
        for (var i = 0; i < this.users.length; i++) {
            if (this.users[i]._id === _id) {
                linkedinProfileID = this.users[i].linkedInProfileId;
            }
        }
        return linkedinProfileID;
    };
    CompanyPxdataComponent.prototype.download = function () {
        var _this = this;
        if (!this.select) {
            alert('Please select a company');
            return;
        }
        var selectedusers = [];
        this.isLoading = true;
        for (var i = 0; i < this.users.length; i++) {
            if (this.users[i].company === this.select) {
                if (this.users[i]._id) {
                    selectedusers.push(this.users[i]._id);
                }
            }
        }
        this.userService.getCompanyPXdata(selectedusers, this.connectedAfter).subscribe(function (companies) {
            var con = [];
            var csvContent = '';
            Object.keys(companies).forEach(function (data) {
                var val = companies[data];
                con.push(val);
            });
            var title = "Rep LI Profile ID, LI Profile ID, Company Name, First Name, Last Name, Title, Profile Url, Email, Phone, Location, Company Start Date, Connected On, Mutual Connections";
            csvContent += title + '\r\n';
            var that = _this;
            con.forEach(function (rowArray) {
                var row = '"' + that.getUserLinkedinProfileID(rowArray.user_id) + '","' + rowArray.id + '","' + rowArray.companyName + '","' + rowArray.firstName + '","'
                    + rowArray.lastName + '","' + rowArray.title + '","'
                    + rowArray.profileURL + '","' + rowArray.email + '","'
                    + rowArray.phone + '","' + rowArray.location + '","' +
                    rowArray.yearsAtCompany + '","' + rowArray.connectedOn
                    + '","' + rowArray.mutualConnections + '"';
                csvContent += row + '\r\n';
            });
            csvContent = csvContent.replace(/#/g, '');
            var blob = new Blob([csvContent], { type: "octet/stream" });
            var url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', _this.select + ' - PX Data.csv');
            document.body.appendChild(link); // Required for FF
            link.click();
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get company px data: ', err);
        });
    };
    CompanyPxdataComponent.prototype.exportCorporateEmails = function () {
        var _this = this;
        console.log(this.startDate, this.endDate);
        if (!this.startDate) {
            alert("Select start date!");
            return;
        }
        if (!this.endDate)
            this.endDate = "2099-01-01";
        var body = {
            startDate: this.startDate,
            endDate: this.endDate
        };
        this.isLoading = true;
        this.userService.getCorporateData(body).subscribe(function (response) {
            _this.isLoading = false;
            if (response.code === 200) {
                var csvContent_1 = '';
                var title = "Member ID, Full Name, Company Name, First Name, Last Name, Title, Public Id, Profile URL, Company Id, Organization Domain";
                csvContent_1 += title + '\r\n';
                response.data.forEach(function (rowArray) {
                    var row = '"' + rowArray.member_id + '","'
                        + rowArray.fullName + '","'
                        + rowArray.companyName + '","'
                        + rowArray.firstName + '","'
                        + rowArray.lastName + '","'
                        + rowArray.title + '","'
                        + rowArray.publicId + '","'
                        + rowArray.profileURL + '","'
                        + rowArray.companyId + '","'
                        + rowArray.organization_domain_1.toLowerCase().replace("www.", "") + '"';
                    csvContent_1 += row + '\r\n';
                });
                csvContent_1 = csvContent_1.replace(/#/g, '');
                var blob = new Blob([csvContent_1], { type: "octet/stream" });
                var url = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', _this.select + ("corporate-emails-needed-(" + new Date().toJSON().split("T")[0] + ").csv"));
                document.body.appendChild(link); // Required for FF
                link.click();
                _this.isLoading = false;
            }
            console.log(response);
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get corporate emails data: ', err);
        });
    };
    CompanyPxdataComponent.prototype.downloadApprovedContacts = function () {
        var _this = this;
        if (!this.select) {
            alert('Please select a company');
            return;
        }
        var selectedusers = [];
        this.isLoading = true;
        for (var i = 0; i < this.users.length; i++) {
            if (this.users[i].company === this.select) {
                if (this.users[i]._id) {
                    selectedusers.push(this.users[i]._id);
                }
            }
        }
        this.userService.getCompanyApprovedContacts(selectedusers, this.connectedAfter).subscribe(function (companies) {
            var con = [];
            var csvContent = '';
            Object.keys(companies).forEach(function (data) {
                var val = companies[data];
                con.push(val);
            });
            var title = "Rep LI Profile ID, LI Profile ID, Company Name, First Name, Last Name, Title, Profile Url, Email, Phone, Location, Company Start Date, Connected On, Mutual Connections";
            csvContent += title + '\r\n';
            var that = _this;
            con.forEach(function (rowArray) {
                var row = '"' + that.getUserLinkedinProfileID(rowArray.user_id) + '","' + rowArray.id + '","' + rowArray.companyName + '","' + rowArray.firstName + '","'
                    + rowArray.lastName + '","' + rowArray.title + '","'
                    + rowArray.profileURL + '","' + rowArray.email + '","'
                    + rowArray.phone + '","' + rowArray.location + '","' +
                    rowArray.yearsAtCompany + '","' + rowArray.connectedOn
                    + '","' + rowArray.since + '","' + rowArray.mutualConnections + '"';
                csvContent += row + '\r\n';
            });
            csvContent = csvContent.replace(/#/g, '');
            var blob = new Blob([csvContent], { type: "octet/stream" });
            var url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', _this.select + ' - Approved Contacts.csv');
            document.body.appendChild(link); // Required for FF
            link.click();
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get company approved contacts: ', err);
        });
    };
    return CompanyPxdataComponent;
}());
export { CompanyPxdataComponent };
