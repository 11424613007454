<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="profile-notifications">
    <h1>My Profile</h1>
    <div class="form-check">
        <input class="form-check-input" type="checkbox" id="notifications" [checked]="notifications" (click)="toggleNotifications()">
        <label class="form-check-label mr-3" for="notifications">
            Email Notifications
        </label>
    </div>
</div>
<br>

<div class="form-group col-sm-8">
    <label for="userName">Username</label>
    <input type="text" class="form-control" id="userName" [(ngModel)]="userName" placeholder="Enter name">
</div>

<div class="form-group col-sm-8">
    <label for="email">Email Address</label>
    <input type="email" class="form-control" id="email" [(ngModel)]="email" placeholder="Enter email">
</div>

<div class="form-group col-sm-8">
    <label for="password"> New password</label>
    <input type="password" class="form-control" id="password" [(ngModel)]="password" placeholder="Password">
</div>

<div class="form-group col-sm-8">
    <label for="confirmPassword">Confirm new password</label>
    <input type="password" class="form-control" id="confirmPassword" [(ngModel)]="confirmPassword" placeholder="Password">
</div>

<button type="button" (click)="saveChanges()" class="btn btn-primary">Save Changes</button>

<div mdbModal #successModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="successModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">Success</h4>
            </div>
            <div class="modal-body">
                Your profile has been updated successfully.
            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="successModal.hide()" mdbWavesEffect>Close</button>
            </div>
        </div>
    </div>
</div>