import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { StrategyService } from 'src/app/services/strategy.service';

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit {

  @Input() strategy: any;

  hasError = false;
  dropdownList: [];
  stepTwoForm: FormGroup;
  clickByDoneButton = false;
  dropdownSettings: IDropdownSettings;
  editMode = localStorage.getItem("EDIT_STRATEGY") ? true : false;

  constructor(private fb: FormBuilder, public strategyService: StrategyService, private router: Router) {
    this.stepTwoForm = this.fb.group({
      userId: localStorage.getItem('USER_KEY'),
      geoCountry: this.fb.control('', Validators.required),
      geoLocations: this.fb.control([]),
      geoLocationsMore: this.fb.control('', Validators.required)
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'geoCode',
      textField: 'geoName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  ngOnInit(): void {

    this.strategyService.getLocations().subscribe((locations) => {
      this.dropdownList = locations;
    }, (err) => {
      alert('error');
      console.log(err);
    });

    if (this.strategy) {
      this.stepTwoForm = this.fb.group({
        userId: localStorage.getItem('USER_KEY'),
        strategyId: this.fb.control(this.strategy._id, Validators.required),
        geoCountry: this.fb.control(this.strategy.geoCountry, Validators.required),
        geoLocations: this.fb.control(this.strategy.geoLocations),
        geoLocationsMore: this.fb.control(this.strategy.geoLocationsMore, Validators.required)
      });
    }

    this.geoCountryChange();

  }

  geoCountryChange() {
    const value = this.stepTwoForm.value.geoCountry;

    if (value === 'North America') {
      if (this.stepTwoForm.value.geoLocations.length === 0 && !this.stepTwoForm.value.geoLocationsMore) {
        this.stepTwoForm.get('geoLocations').setValidators([Validators.required]);
      } else {
        this.stepTwoForm.get('geoLocations').clearValidators();
      }
      this.stepTwoForm.get('geoLocationsMore').clearValidators();
    } else {
      this.stepTwoForm.patchValue({ geoLocations: [] });
      this.stepTwoForm.get('geoLocations').clearValidators();
      this.stepTwoForm.get('geoLocationsMore').setValidators([Validators.required]);
    }

    this.stepTwoForm.controls["geoLocations"].updateValueAndValidity();
    this.stepTwoForm.controls["geoLocationsMore"].updateValueAndValidity();

  }

  stepTwoSubmit() {
    console.log(this.stepTwoForm);

    if (this.stepTwoForm.valid) {
      this.strategyService.submitStrategy(this.stepTwoForm.value).subscribe((res) => {
        console.log(res);
        if (this.clickByDoneButton) {
          this.router.navigate(['campaigns']);
        }
      }, (err) => {
        alert('error');
        console.log(err);
      });
    }
  }

  onItemSelect(item: any) {
    // console.log(item);
    console.log(item)
  }

  onSelectAll(items: any) {
    // console.log(items);
  }

  back() {
    this.router.navigate(['strategy-type']);
  }

  cancelNewCampaign() {
    this.router.navigate(['campaigns']);
  }

  clickDoneButton() {
    this.clickByDoneButton = true;
  }
}
