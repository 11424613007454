import { OnInit } from '@angular/core';
import { dateFilterParams } from 'src/app/helpers/dateFilterParams';
import { validateImage } from 'src/app/helpers/common';
var UserConnectionsComponent = /** @class */ (function () {
    function UserConnectionsComponent(userService) {
        this.userService = userService;
        this.rows = [];
        this.isLoading = true;
        this.selectedRow = undefined;
        this.noDataText = 'No connections right now';
        this.userconnectionName = localStorage.getItem('USER_CONNECTIONS_NAME');
        this.columnDefs = [
            {
                width: 200,
                sortable: true,
                field: 'companyName',
                headerName: 'Company Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                    if (!valueA)
                        return -1;
                    if (!valueB)
                        return 1;
                    valueA = valueA.toLowerCase();
                    valueB = valueB.toLowerCase();
                    if (valueA == valueB)
                        return 0;
                    if (valueA > valueB)
                        return 1;
                    return -1;
                },
                cellRenderer: function (params) {
                    return "<a href=\"" + params.data.companyProfileURL + "\" target=\"_blank\">" + params.value + "</a>";
                }
            },
            {
                width: 100,
                sortable: true,
                field: 'firstName',
                headerName: 'First Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                width: 100,
                sortable: true,
                field: 'lastName',
                headerName: 'Last Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                width: 200,
                sortable: true,
                field: 'title',
                headerName: 'Title',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                width: 75,
                field: 'Avatar',
                headerName: 'Avatar',
                cellRenderer: this.avatarShow.bind(this),
            },
            {
                width: 75,
                headerName: 'View',
                field: 'profileURL',
                cellRenderer: function (params) {
                    return "<a href=\"" + params.data.profileURL + "\" target=\"_blank\">View</a>";
                }
            },
            {
                width: 75,
                sortable: true,
                field: 'since1',
                headerName: 'Tenure',
                filter: 'agNumberColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                comparator: function (number1, number2) {
                    if (number1 === null && number2 === null) {
                        return 0;
                    }
                    if (number1 === null) {
                        return -1;
                    }
                    if (number2 === null) {
                        return 1;
                    }
                    return number1 - number2;
                },
                cellRenderer: function (params) {
                    if (params.data.since1 === 0) {
                        return "<span><1</span>";
                    }
                    return "<span>" + params.data.since1 + "</span>";
                }
            },
            {
                width: 200,
                field: 'email',
                sortable: true,
                headerName: 'Email',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                cellRenderer: function (params) {
                    if (params.value)
                        return "<a href=\"mailto:" + params.value + "\">" + params.value + "<a>";
                    return '';
                }
            },
            {
                width: 125,
                field: 'phone',
                sortable: true,
                headerName: 'Phone',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                width: 150,
                sortable: true,
                field: 'location',
                headerName: 'Location',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                width: 150,
                sortable: true,
                field: 'mutualConnections',
                headerName: 'Mutual Connections',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                width: 150,
                sort: "desc",
                sortable: true,
                field: 'connectedOnDate',
                headerName: 'Connected on',
                filter: "agDateColumnFilter",
                filterParams: dateFilterParams,
                comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                    if (!valueA)
                        return -1;
                    if (!valueB)
                        return 1;
                    if (new Date(valueA) > new Date(valueB))
                        return 1;
                    if (new Date(valueA) < new Date(valueB))
                        return -1;
                    else
                        return 0;
                },
            },
            {
                width: 150,
                sortable: true,
                field: 'lastMessageDate1',
                headerName: 'Last Msg Date',
                filter: "agDateColumnFilter",
                filterParams: dateFilterParams,
                comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
                    if (!valueA)
                        return -1;
                    if (!valueB)
                        return 1;
                    if (new Date(valueA) > new Date(valueB))
                        return 1;
                    if (new Date(valueA) < new Date(valueB))
                        return -1;
                    else
                        return 0;
                },
                cellRenderer: function (params) {
                    return "" + params.data.lastMessageDate;
                }
            },
            {
                width: 125,
                field: 'convoUrl',
                headerName: 'View Convo',
                cellRenderer: function (params) {
                    if (params.value)
                        return "<a target=\"_blank\" href=\"" + params.value + "\">view convo</a>";
                    return '';
                }
            },
            {
                width: 150,
                sortable: true,
                field: 'lastMessage',
                headerName: 'Last Incoming Msg',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            }
        ];
    }
    UserConnectionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.getUserPXdata(localStorage.getItem('USER_CONNECTIONS_KEY')).subscribe(function (connections) {
            _this.rows = connections;
            _this.isLoading = false;
            validateImage();
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user connection px data: ', err);
        });
    };
    UserConnectionsComponent.prototype.onPaginationChanged = function ($evt) { validateImage(); };
    UserConnectionsComponent.prototype.avatarShow = function (params) {
        var _this = this;
        var eDiv = document.createElement('div');
        eDiv.innerHTML =
            '<img src="' + params.data.avatar + '" class="avatar-img liImg" style="width: 25px; height:25px; margin-top: -4.5px; cursor: pointer"/>';
        eDiv.addEventListener('click', function () {
            _this.selectedRow = params.data;
            _this.showModalOnClick.show();
        });
        return eDiv;
    };
    return UserConnectionsComponent;
}());
export { UserConnectionsComponent };
