import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
var CreateNewCampaignComponent = /** @class */ (function () {
    function CreateNewCampaignComponent(fb, campaignService, router) {
        this.fb = fb;
        this.campaignService = campaignService;
        this.router = router;
        this.isLoading = true;
        this.clickByDoneButton = false;
        this.editMode = localStorage.getItem("EDIT_CAMPAIGN") ? true : false;
        this.campaignForm = this.fb.group({
            userId: localStorage.getItem('USER_KEY'),
            campaignName: this.fb.control('', Validators.required),
            campaignType: this.fb.control('', Validators.required),
            campaignDescription: this.fb.control(''),
        });
    }
    CreateNewCampaignComponent.prototype.ngOnInit = function () {
        var _this = this;
        var campaignInfo = localStorage.getItem('CAMPAIGN_INFO');
        if (!campaignInfo) {
            this.isLoading = false;
            return;
        }
        this.campaignService.getCampaign(JSON.parse(campaignInfo)._id).subscribe(function (res) {
            console.log('existing campaign: ', res);
            if (res) {
                _this.campaignForm = _this.fb.group({
                    campaignId: res._id,
                    userId: localStorage.getItem('USER_KEY'),
                    campaignName: _this.fb.control(res.campaignName, Validators.required),
                    campaignType: _this.fb.control(res.campaignType, Validators.required),
                    campaignDescription: _this.fb.control(res.campaignDescription),
                });
            }
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user lastest messages: ', err);
        });
    };
    CreateNewCampaignComponent.prototype.campaignFormSubmit = function () {
        var _this = this;
        if (this.campaignForm.valid) {
            this.isLoading = true;
            this.campaignService.submitCampaign(this.campaignForm.value).subscribe(function (res) {
                localStorage.setItem('CAMPAIGN_INFO', JSON.stringify(res));
                if (_this.clickByDoneButton) {
                    _this.router.navigate(['campaigns']);
                    return; // Add return statement
                }
                if (res.campaignType === 'Message Only') {
                    _this.router.navigate(['message-send-who']);
                }
                else {
                    _this.router.navigate(['strategy-type']);
                }
                _this.isLoading = false;
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log(err);
                return; // Add return statement to handle error case
            });
        }
    };
    CreateNewCampaignComponent.prototype.cancelNewCampaign = function () {
        this.router.navigate(['campaigns']);
    };
    CreateNewCampaignComponent.prototype.clickDoneButton = function () {
        this.clickByDoneButton = true;
    };
    return CreateNewCampaignComponent;
}());
export { CreateNewCampaignComponent };
