import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, ViewChild, OnInit } from '@angular/core';

import { getUserRole } from 'src/app/helpers/getUserRole';

import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('modal', { static: true }) modal: ModalDirective;

  users: User[];
  userValue = '';
  isAdmin = false;
  isLoading = true;
  companyValue = '';
  selectedUser: User;
  companies: string[];
  dropdownUsers: User[];
  filteredUsers: User[];

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {

    localStorage.removeItem('USER_GROUPS_MEMBERID');

    if (getUserRole() === '1' || getUserRole() === '2') {
      this.isAdmin = true;

      this.userService.getUsersDashboard().subscribe((res: any) => {
        this.dropdownUsers = [...res.users].sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        this.users = [...res.users].sort((a, b) => {
          if (a.company.toLowerCase() < b.company.toLowerCase()) {
            return -1;
          }
          if (a.company.toLowerCase() > b.company.toLowerCase()) {
            return 1;
          }

          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }

          return 0;
        });

        this.filteredUsers = this.users;

        this.companies = res.companies.sort((a, b) => {
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
          }
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        this.isLoading = false;

      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to get dashboard: ', err)
      });

    } else {
      this.isLoading = false;
      this.isAdmin = false;
    }
  }

  openModal(user) {
    this.selectedUser = user;
    this.modal.show();
  }

  userReview(user) {
    if (user._id != localStorage.getItem('USER_REVIEW_KEY')) {
      localStorage.removeItem('USER_AVDATA_CACHED_AT');
    }
    localStorage.setItem('USER_REVIEW_KEY', user._id);
    localStorage.setItem('USER_REVIEW_NAME', user.name);
    this.router.navigate(['/userReview']);
  }

  userMessage(user) {
    if (user._id != localStorage.getItem('USER_MESSAGE_KEY')) {
      localStorage.removeItem('USER_MESSAGES_CACHED_AT');
    }
    localStorage.setItem('USER_MESSAGE_KEY', user._id);
    localStorage.setItem('USER_MESSAGE_NAME', user.name);
    this.router.navigate(['/userMessage']);
  }

  userLists(user) {
    localStorage.setItem('USER_LISTS_KEY', user._id);
    localStorage.setItem('LISTS_COMPANY_FILTER', user.company);
    localStorage.setItem('LISTS_USER_FILTER', user._id);
    this.router.navigate(['/lists']);
  }

  userConnections(user) {
    if (user._id != localStorage.getItem('USER_CONNECTIONS_KEY')) {
      localStorage.removeItem('USER_PXDATA_CACHED_AT');
    }
    localStorage.setItem('USER_CONNECTIONS_KEY', user._id);
    localStorage.setItem('USER_CONNECTIONS_NAME', user.name);
    localStorage.setItem('USER_CONNECTIONS_LINKEDINID', user.linkedInProfileId);
    this.router.navigate(['/userConnections']);
  }

  userGroups(user) {
    localStorage.setItem('USER_GROUPS_MEMBERID', user.memberId);
    this.router.navigate(['/groups']);
  }

  userReviewed(user) {
    if (user._id != localStorage.getItem('USER_REVIEWED_KEY')) {
      localStorage.removeItem('USER_REVIEWED_AVDATA_CACHED_AT');
    }
    localStorage.setItem('USER_REVIEWED_KEY', user._id);
    localStorage.setItem('USER_REVIEWED_NAME', user.name);
    this.router.navigate(['/userReviewed']);
  }

  userTargetProspect(user) {
    localStorage.setItem('USER_KEY', user._id);
    this.router.navigate(['/target-prospects']);
  }

  userMassagingStrategy(user) {
    localStorage.setItem('USER_KEY', user._id);
    this.router.navigate(['/message-strategy']);
  }

  userCampaigns(user) {
    localStorage.setItem('USER_CAMPAIGNS_KEY', user._id);
    localStorage.setItem('USER_CAMPAIGNS_NAME', user.name);
    this.router.navigate(['/client-campaigns']);
  }

  isEightDayGapCheck(date: number): boolean {
    if (date === 0) {
      return false;
    }

    let diff = Math.abs(new Date().getTime() - new Date(date).getTime()) / 3600000;
    if (diff > 192) { return true; }

    return false;
  }

  isTwoDayGapCheck(date: number): boolean {
    if (!date) {
      return false;
    }

    let diff = Math.abs(new Date().getTime() - new Date(date).getTime()) / 3600000;
    if (diff > 48) { return true; }

    return false;
  }

  isTwoDayGapWithR4RCheck(lastApprovedOn: number, r4rCount: number) {
    if (lastApprovedOn === 0 || r4rCount === 0) {
      return false;
    } else {
      let diff = Math.abs(new Date().getTime() - new Date(lastApprovedOn).getTime()) / 3600000;
      if (diff > 192) { return true; }

      return false;
    }
  }

  filter() {
    this.filteredUsers = this.users

    if (this.companyValue) {
      this.filteredUsers = this.filteredUsers.filter((item: User) => item.company === this.companyValue);
    }

    if (this.userValue) {
      this.filteredUsers = this.filteredUsers.filter((item: User) => item._id === this.userValue);
    }

    return this.filteredUsers;
  }

}
