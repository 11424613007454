import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { getUserRole } from 'src/app/helpers/getUserRole';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(authenticationService, router, userService) {
        this.authenticationService = authenticationService;
        this.router = router;
        this.userService = userService;
        this.directionS = true;
        this.directionI = false;
        this.isAdmin = false;
        this.groups = false;
        this.isViewedprofilesActive = false;
        this.userValue = 'all';
        this.isCollapsed = true;
        this.isSuperAdmin = false;
        this.isCompanyAdmin = false;
        this.userselfserve = false;
        this.isCompanyEndUser = false;
        this.dataValue = 'connections';
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        //temp
        localStorage.removeItem('USER_ROLE');
        if (getUserRole() === '4') {
            localStorage.setItem("USER_LISTS_KEY", localStorage.getItem("USER_KEY"));
        }
        this.authenticationService.sharedLoginStatus.subscribe(function (status) {
            _this.isLoggedIn = status;
            _this.authenticationService.check().subscribe(function (result) {
                if (result === null) {
                    _this.isLoggedIn = false;
                    if (location.pathname.includes('/reset-password/')) {
                    }
                    else {
                        _this.router.navigate(['/login']);
                    }
                }
                else {
                    _this.isLoggedIn = true;
                    _this.userName = '';
                    _this.isSuperAdmin = false;
                    _this.isAdmin = false;
                    _this.isCompanyAdmin = false;
                    _this.isCompanyEndUser = false;
                    _this.googleDocURL = localStorage.getItem('USER_GOOGLE_DOC_URL');
                    _this.setUserData(result);
                }
            });
        });
    };
    HeaderComponent.prototype.setUserData = function (user) {
        this.userName = user.name;
        this.groups = user.groups;
        this.isViewedprofilesActive = user.isViewedprofilesActive;
        console.log(user);
        if (user.role === '1') {
            this.isSuperAdmin = true;
        }
        else if (user.role === '2') {
            this.isAdmin = true;
        }
        else if (user.role === '3') {
            this.isCompanyAdmin = true;
        }
        else if (user.role === '4') {
            this.isCompanyEndUser = true;
            this.userValue = localStorage.getItem('USER_KEY');
        }
        if (user.googleDocURL != '') {
            this.havedocurl = true;
        }
        else {
            this.havedocurl = false;
        }
        // target
        if (user.selfserve == true) {
            this.userselfserve = true;
        }
    };
    HeaderComponent.prototype.logout = function () {
        var _this = this;
        this.authenticationService.logout().subscribe(function (result) {
            if (result) {
                _this.authenticationService.toggleLoginStatus(false);
                _this.isCollapsed = !_this.isCollapsed;
                _this.isLoggedIn = false;
                _this.router.navigate(['/login']);
            }
        }, function (err) {
            alert('error');
            console.log('error to logout: ', err);
        });
    };
    HeaderComponent.prototype.toggleNavbar = function () {
        if (this.navbaridRef.shown) {
            this.navbaridRef.toggle();
        }
    };
    HeaderComponent.prototype.clearListKey = function () {
        localStorage.removeItem('USER_LISTS_KEY');
    };
    HeaderComponent.prototype.clearReviewListKey = function () {
        localStorage.removeItem('USER_REVIEW_LISTKEY');
        localStorage.removeItem('USER_AVDATA_CACHED_AT');
    };
    HeaderComponent.prototype.openToAdd = function () {
        var _this = this;
        this.dataValue = 'connections';
        this.from = new Date(Date.now() - 12096e5).toJSON().slice(0, 10);
        this.to = new Date().toJSON().slice(0, 10);
        console.log('this.isCompanyAdmin', this.isCompanyAdmin);
        if (this.isCompanyAdmin) {
            this.userValue = 'all';
            this.userService.getCAUsers().subscribe(function (res) {
                _this.users = res.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
                _this.showModalOnClick.show();
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to get ca users: ', err);
            });
        }
        else {
            this.userValue = localStorage.getItem('USER_KEY');
            this.showModalOnClick.show();
        }
    };
    HeaderComponent.prototype.export = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var connections_1, con_1, csvContent_1, title, encodedUri, link, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.dataValue) {
                            alert('Please select data');
                            return [2 /*return*/, false];
                        }
                        if (!this.from) {
                            alert('You have to choose from date');
                            return [2 /*return*/, false];
                        }
                        if (!this.to) {
                            alert('You have to enter to date');
                            return [2 /*return*/, false];
                        }
                        this.showModalOnClick.hide();
                        this.isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.userService.getCompanyEUdata(this.userValue, this.from, this.to, this.users, this.directionI ? 1 : 0, 0).toPromise()];
                    case 2:
                        connections_1 = _a.sent();
                        con_1 = [];
                        csvContent_1 = 'data:text/csv;charset=utf-8,';
                        console.log(connections_1);
                        Object.keys(connections_1).forEach(function (data) {
                            var val = connections_1[data];
                            con_1.push(val);
                        });
                        title = "Company Name, First Name, Last Name, Title, Profile Url, Email, Phone, Location, Company Start Date, Connected On, Mutual Connections";
                        if (this.userValue === 'all') {
                            title = "User, " + title;
                        }
                        if (this.directionI) {
                            title = title + ", Last Msg Date, Last Msg";
                        }
                        csvContent_1 += title + '\r\n';
                        con_1.forEach(function (rowArray) {
                            var row = (_this.userValue == 'all' ? '"' + rowArray.user + '",' : "") + '"' + rowArray.companyName + '","' + rowArray.firstName + '","'
                                + rowArray.lastName + '","' + rowArray.title + '","'
                                + rowArray.profileURL + '","' + rowArray.email + '","'
                                + rowArray.phone + '","' + rowArray.location + '","' +
                                rowArray.yearsAtCompany + '","' + rowArray.connectedOn
                                + '","' + rowArray.mutualConnections
                                + (_this.directionI && rowArray.messages && Object.keys(rowArray.messages).length ? ('","' + rowArray.messages.tstamp + '","' + rowArray.messages.body) : "")
                                + '"';
                            csvContent_1 += row + '\r\n';
                        });
                        csvContent_1 = csvContent_1.replace(/#/g, '');
                        encodedUri = encodeURI(csvContent_1);
                        link = document.createElement('a');
                        link.setAttribute('href', encodedUri);
                        link.setAttribute('download', 'Exported Data.csv');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        this.isLoading = false;
                        return [2 /*return*/, true];
                    case 3:
                        err_1 = _a.sent();
                        alert('error');
                        console.log('error to get company eu data: ', err_1);
                        this.isLoading = false;
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.gotoGoogleDocURL = function () {
        this.userService.getUserByEmail(localStorage.getItem('USER_EMAIL')).subscribe(function (user) {
            console.log(user);
            window.open(user.googleDocURL, '_blank');
        }, function (err) {
            alert('error');
            console.log('error to get user by email: ', err);
        });
    };
    return HeaderComponent;
}());
export { HeaderComponent };
