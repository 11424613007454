import { OnInit } from '@angular/core';
var CaReportsComponent = /** @class */ (function () {
    function CaReportsComponent() {
        this.isLoading = false;
    }
    CaReportsComponent.prototype.ngOnInit = function () {
    };
    return CaReportsComponent;
}());
export { CaReportsComponent };
