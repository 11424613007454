import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NodeCache = require("node-cache");
var ViewedProfileService = /** @class */ (function () {
    function ViewedProfileService(http) {
        this.http = http;
        this.myCache = new NodeCache();
    }
    ViewedProfileService.prototype.getBaseUrl = function () {
        return environment.baseUrl;
    };
    ViewedProfileService.prototype.getUrl = function (relativeUrl) {
        return this.getBaseUrl() + relativeUrl;
    };
    ViewedProfileService.prototype.getOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    ViewedProfileService.prototype.getAll = function (memberId) {
        var url = this.getUrl('/viewed-profile/get-all/' + memberId);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (response) {
            return response;
        }));
    };
    ViewedProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ViewedProfileService_Factory() { return new ViewedProfileService(i0.ɵɵinject(i1.HttpClient)); }, token: ViewedProfileService, providedIn: "root" });
    return ViewedProfileService;
}());
export { ViewedProfileService };
