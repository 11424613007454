import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { User } from '../models/user';
import { PXData } from '../models/PXData';
import { Message } from '../models/message';
import { AVLists } from '../models/AVLists';
import { Message_Beta } from '../models/message_beta';
import { DashboardProps } from '../models/dashboardProps';
import { listsProps, listUser } from '../models/listsProps';
import { environment } from '../../environments/environment';

declare var require: any
const NodeCache = require("node-cache");

@Injectable({
	providedIn: 'root'
})
export class ViewedProfileService {
	myCache = new NodeCache();

	constructor(private http: HttpClient) {
	}

	public getBaseUrl(): string {
		return environment.baseUrl;
	}

	protected getUrl(relativeUrl: string) {
		return this.getBaseUrl() + relativeUrl;
	}

	protected getOptions(): any {
		const AuthorizeToken = localStorage.getItem('USER_TOKEN');

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache',
			'Authorization': 'Bearer ' + AuthorizeToken
		});

		const options = {
			headers: headers
		};

		return options;
	}

	getAll(memberId): Observable<User[]> {
		const url = this.getUrl('/viewed-profile/get-all/'+memberId);

		return this.http.get(url, this.getOptions())
			.pipe(
				map((response: any) => {
					return response
				})
			)
	}
}