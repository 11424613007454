<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div *ngIf="totalList.length ==0">
    <p style="display: flex;justify-content: center;margin-top: 10%;">Nothing to approve right now!</p>
</div>
<div *ngIf="totalList.length >=2 && !showGrid">
    <p>It looks like you have more than one list to review.</p>
    <div>Please select a list to review:</div>
    <div class="table-responsive">
        <table class="table table-striped" style="border:1px solid #c1c1c1" [mfData]="totalList" #mf="mfDataTable">
            <thead>
                <tr>
                    <th style="width: 80%;">
                        <mfDefaultSorter by="listName">List Name</mfDefaultSorter>
                    </th>
                    <th class="text-center custom-margin-right" style="width: 20%;">
                        <mfDefaultSorter by="uploadedOn">Created On</mfDefaultSorter>
                    </th>
                    <th class="text-center custom-margin-right" style="width:10%;">
                        <mfDefaultSorter by="uploadedCount">Count</mfDefaultSorter>
                    </th>
                    <th scope="col" style="width: 10%;">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let avlist of mf.data">
                    <td>
                        <span mdbTooltip="{{avlist.listName}}" placement="bottom" mdbWavesEffect>{{avlist.listName}}</span>
                    </td>
                    <td class="text-center" [title]="avlist.uploadedOn | date : 'MM/dd/yy hh:mm aaa'">
                        <span *ngIf="avlist.uploadedOn > 0">{{avlist.uploadedOn | date : 'MM/dd/yy'}}</span>
                    </td>
                    <td class="text-center">{{avlist.uploadedCount}}</td>
                    <td class="d-flex">
                        <i class="fa-solid fa-circle-chevron-right" style="cursor: pointer;" (click)="listChanged(avlist)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    
</div>

