import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { StrategyService } from 'src/app/services/strategy.service';
import { CampaignService } from 'src/app/services/campaign.service';

@Component({
  selector: 'app-message-send-who',
  templateUrl: './message-send-who.component.html',
  styleUrls: ['./message-send-who.component.scss']
})
export class MessageSendWhoComponent implements OnInit {

  campaigns = [];
  isLoading = true;
  strategyForm: FormGroup;
  clickByDoneButton = false;
  editMode = localStorage.getItem("EDIT_STRATEGY") ? true : false;

  msgTargetType_options = [
    {
      id: "msgTargetCampaign",
      value: "Connections from a campaign"
    },
    {
      id: "msgTargetList",
      value: "Connections from a list I created"
    },
    {
      id: "msgTargetNote",
      value: "Other"
    }
  ];

  constructor(private fb: FormBuilder, public strategyService: StrategyService, public campaignService: CampaignService, private router: Router) {
    this.strategyForm = this.fb.group({
      userId: localStorage.getItem('USER_KEY'),
      msgTargetType: this.fb.control('msgTargetCampaign'),
      msgTargetCampaign: this.fb.control('', [Validators.required]),
      msgTargetList: this.fb.control(''),
      msgTargetNote: this.fb.control(''),
    });
  }

  ngOnInit(): void {
    const campaignInfo = localStorage.getItem('CAMPAIGN_INFO');

    if (!campaignInfo) {
      this.router.navigate(['campaigns']);
      return;
    }

    this.strategyService.getStrategy(JSON.parse(campaignInfo).strategyId).subscribe((res) => {
      console.log('existing strategy: ', res);
      if (res) {
        this.strategyForm = this.fb.group({
          strategyId: res._id,
          userId: localStorage.getItem('USER_KEY'),
          msgTargetType: this.fb.control(res.msgTargetType ? res.msgTargetType : 'msgTargetCampaign'),
          msgTargetCampaign: this.fb.control(res.msgTargetCampaign),
          msgTargetList: this.fb.control(res.msgTargetList),
          msgTargetNote: this.fb.control(res.msgTargetNote),
        });
      }
      this.handleChange();
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get user latest messages: ', err);
    });

    this.campaignService.getCampaigns(localStorage.getItem('USER_KEY')).subscribe((res) => {
      this.campaigns = res;
      console.log(res);
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log(err);
    });
  }

  strategyFormSubmit(): void {
    if (this.strategyForm.valid) {
      this.strategyService.submitStrategy(this.strategyForm.value).subscribe((res) => {
        console.log(res);
        if (this.clickByDoneButton) {
          this.router.navigate(['campaigns']);
        } else {
          this.router.navigate(['message-template']);
        }
      }, (err) => {
        alert('error');
        console.log(err);
      });
    }
  }

  handleChange(): void {
    const value = this.strategyForm.value.msgTargetType;

    if (value === 'msgTargetCampaign') {
      this.strategyForm.get('msgTargetCampaign').setValidators([Validators.required]);
      this.strategyForm.get('msgTargetList').clearValidators();
      this.strategyForm.get('msgTargetNote').clearValidators();
    }

    if (value === 'msgTargetList') {
      this.strategyForm.get('msgTargetList').setValidators([Validators.required]);
      this.strategyForm.get('msgTargetCampaign').clearValidators();
      this.strategyForm.get('msgTargetNote').clearValidators();
    }

    if (value === 'msgTargetNote') {
      this.strategyForm.get('msgTargetNote').setValidators([Validators.required]);
      this.strategyForm.get('msgTargetCampaign').clearValidators();
      this.strategyForm.get('msgTargetList').clearValidators();
    }

    this.strategyForm.controls["msgTargetCampaign"].updateValueAndValidity();
    this.strategyForm.controls["msgTargetList"].updateValueAndValidity();
    this.strategyForm.controls["msgTargetNote"].updateValueAndValidity();
  }

  cancelNewCampaign(): void {
    this.router.navigate(['campaigns']);
  }

  gotoCreateNewCampaign(): void {
    this.router.navigate(['create-new-campaign']);
  }

  clickDoneButton(): void {
    this.clickByDoneButton = true;
  }
}