import { OnInit } from '@angular/core';
var UserReviewedComponent = /** @class */ (function () {
    function UserReviewedComponent(userService) {
        this.userService = userService;
        this.rows = [];
        this.isLoading = true;
        this.noDataText = 'No reviewed records right now';
        this.userReviewedName = localStorage.getItem('USER_REVIEWED_NAME');
        this.columnDefs = [
            {
                sortable: true,
                field: 'companyName',
                headerName: 'Company Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                comparator: function (valueA, valueB) {
                    if (!valueA)
                        return -1;
                    if (!valueB)
                        return 1;
                    valueA = valueA.toLowerCase();
                    valueB = valueB.toLowerCase();
                    if (valueA == valueB)
                        return 0;
                    if (valueA > valueB)
                        return 1;
                    return -1;
                },
                cellRenderer: function (params) {
                    return "<a href=\"" + params.data.companyProfileURL + "\" target=\"_blank\">" + params.data.companyName + "</a>";
                },
            },
            {
                sortable: true,
                field: 'firstName',
                headerName: 'First Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'lastName',
                headerName: 'Last Name',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                field: 'title',
                sortable: true,
                headerName: 'Title',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                field: 'View',
                headerName: 'View',
                cellRenderer: function (params) {
                    return "<a href=\"" + params.data.profileURL + "\" target=\"_blank\">View</a>";
                }
            },
            {
                field: 'since1',
                sortable: true,
                headerName: 'Tenure',
                filter: "agNumberColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
                comparator: function (number1, number2) {
                    if (number1 === null && number2 === null) {
                        return 0;
                    }
                    if (number1 === null) {
                        return -1;
                    }
                    if (number2 === null) {
                        return 1;
                    }
                    return number1 - number2;
                },
                cellRenderer: function (params) {
                    if (params.data.since1 === 0) {
                        return "<span><1</span>";
                    }
                    return "<span>" + params.data.since1 + "</span>";
                }
            },
            {
                sortable: true,
                field: 'location',
                headerName: 'Location',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'industry',
                headerName: 'Industry',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            },
            {
                sortable: true,
                field: 'mutualConnections',
                headerName: 'Mutual Connections',
                filter: "agTextColumnFilter",
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true
                },
            }
        ];
    }
    UserReviewedComponent.prototype.ngOnInit = function () {
        this.loadData();
    };
    UserReviewedComponent.prototype.loadData = function () {
        var _this = this;
        this.userService.getUserApprovedAVdata(localStorage.getItem('USER_REVIEWED_KEY')).subscribe(function (res) {
            _this.rows = res;
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user approved av data: ', err);
        });
    };
    UserReviewedComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
        // this.gridApi.sizeColumnsToFit();
    };
    UserReviewedComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    return UserReviewedComponent;
}());
export { UserReviewedComponent };
