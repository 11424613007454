var listUser = /** @class */ (function () {
    function listUser() {
    }
    return listUser;
}());
export { listUser };
var listsProps = /** @class */ (function () {
    function listsProps() {
    }
    return listsProps;
}());
export { listsProps };
