import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private http: HttpClient) { }

  public getBaseUrl(): string {
    return environment.baseUrl;
  }

  protected getUrl(relativeUrl: string) {
    return this.getBaseUrl() + relativeUrl;
  }

  protected getOptions(): any {
    const AuthorizeToken = localStorage.getItem('USER_TOKEN');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + AuthorizeToken
    });

    const options = {
      headers: headers
    };

    return options;
  }

  updateSetting(settingvalue): Observable<boolean> {
    const url = this.getUrl('/setting');

    const data = {
      approved_7day: settingvalue.approved_7day,
      approved_30day: settingvalue.approved_30day,
      message_7day: settingvalue.message_7day,
      message_30day: settingvalue.message_30day,
      connection_7day: settingvalue.connection_7day,
      connection_30day: settingvalue.connection_30day
    }

    return this.http.put(url, data, this.getOptions())
      .pipe(
        map((res: any) => {
          return true;
        })
      )
  }

  getSettingValue(): Observable<any> {
    const url = this.getUrl('/setting');

    return this.http.get(url, this.getOptions())
      .pipe(
        map((setting: any) => {
          if (setting.length === 1) {
            let data: Object = {};
            data['approved_7day'] = (setting[0].approved_7day) ? setting[0].approved_7day : '';
            data['approved_30day'] = (setting[0].approved_30day) ? setting[0].approved_30day : '';
            data['message_7day'] = (setting[0].message_7day) ? setting[0].message_7day : '';
            data['message_30day'] = (setting[0].message_30day) ? setting[0].message_30day : '';
            data['connection_7day'] = (setting[0].connection_7day) ? setting[0].connection_7day : '';
            data['connection_30day'] = (setting[0].connection_30day) ? setting[0].connection_30day : '';
            data['message'] = (setting[0].message) ? setting[0].message : '';

            return data;
          } else {
            return false;
          }
        }));
  }

  updateMessage(message): Observable<boolean> {
    const url = this.getUrl('/setting');

    const data = {
      message: message
    }

    return this.http.put(url, data, this.getOptions())
      .pipe(
        map((res: any) => {
          return true;
        })
      )
  }

}
