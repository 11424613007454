import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var GroupsService = /** @class */ (function () {
    function GroupsService(http) {
        this.http = http;
    }
    GroupsService.prototype.getBaseUrl = function () {
        return environment.baseUrl;
    };
    GroupsService.prototype.getUrl = function (relativeUrl) {
        return this.getBaseUrl() + relativeUrl;
    };
    GroupsService.prototype.getOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    GroupsService.prototype.getGroups = function (memberId) {
        var url = this.getUrl('/groups/' + memberId);
        return this.http.get(url, this.getOptions())
            .pipe(map(function (querySnapshot) {
            var groups = [];
            querySnapshot.forEach(function (data) {
                groups.push({
                    _id: data._id,
                    source: data.source,
                    joinedAt: data.joinedAt,
                    status: data.status,
                    groupId: data.groupId,
                    joinedOn: data.joinedOn,
                    memberId: data.memberId,
                    groupLogo: data.groupLogo,
                    groupName: data.groupName,
                    groupRules: data.groupRules,
                    memberCount: data.memberCount,
                    groupCreatedAt: data.groupCreatedAt,
                    groupCreatedOn: data.groupCreatedOn,
                    groupIndustries: data.groupIndustries,
                    groupDescription: data.groupDescription,
                    campaignDescription: data.campaignDescription,
                });
            });
            return groups;
        }));
    };
    GroupsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GroupsService_Factory() { return new GroupsService(i0.ɵɵinject(i1.HttpClient)); }, token: GroupsService, providedIn: "root" });
    return GroupsService;
}());
export { GroupsService };
