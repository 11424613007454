<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="container campaigns" *ngIf="!isLoading">
    <h3>Create a new Campaign</h3>
    <span>A campaign is made up of a strategy (the target prospects) and a message sequence.</span>

    <form [formGroup]="campaignForm" (ngSubmit)="campaignFormSubmit()" autocomplete="off" novalidate #formRef="ngForm">

        <div class="input">
            <label for="campaignName">Campaign Name <span>*</span></label>
            <input type="text" id="campaignName" placeholder="Enter Campaign Name" formControlName="campaignName" class="form-control form-control-lg" />
            <span class="error" *ngIf="campaignForm.controls['campaignName'].hasError('required') && formRef.submitted">
                Campaign Name is a required field.
            </span>
        </div>

        <div class="input">
            <label for="campaignType">Campaign Type </label>
            <select class="browser-default custom-select custom-select-lg" formControlName="campaignType">
                <option value="" selected></option>
                <option value="Invite, Connect and Message">Invite, Connect and Message</option>
                <option value="Message Only">Message Only</option>
            </select>
            <span class="error" *ngIf="campaignForm.controls['campaignType'].hasError('required') && formRef.submitted">Campaign Type is
                a required field
            </span>
        </div>

        <div class="input">
            <label for="campaignDescription">Campaign Description </label>
            <textarea class="form-control" id="campaignDescription" formControlName="campaignDescription" rows="7"></textarea>
        </div>

        <div class="buttons">
            <button type="submit" *ngIf="editMode" class="previous" (click)="clickDoneButton()">Done</button>
            <button type="button" *ngIf="!editMode" class="previous" (click)="cancelNewCampaign()">Cancel</button>
            <button type="submit">Next</button>
        </div>
    </form>
</div>