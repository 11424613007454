import { OnInit } from '@angular/core';
var NewStrategyComponent = /** @class */ (function () {
    function NewStrategyComponent(router, strategyService) {
        this.router = router;
        this.strategyService = strategyService;
        this.isLoading = true;
        this.strategy = undefined;
    }
    NewStrategyComponent.prototype.ngOnInit = function () {
        var _this = this;
        var campaignInfo = localStorage.getItem('CAMPAIGN_INFO');
        if (!campaignInfo) {
            this.router.navigate(['campaigns']);
            return;
        }
        this.strategyService.getStrategy(JSON.parse(campaignInfo).strategyId).subscribe(function (res) {
            console.log('existing strategy: ', res);
            _this.strategy = res;
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user latest messages: ', err);
        });
    };
    NewStrategyComponent.prototype.setLoading = function (e) {
        this.isLoading = e;
    };
    return NewStrategyComponent;
}());
export { NewStrategyComponent };
