import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private http: HttpClient) { }

  public getBaseUrl(): string {
    return environment.baseUrl;
  }

  protected getUrl(relativeUrl: string) {
    return this.getBaseUrl() + relativeUrl;
  }

  protected getOptions(): any {
    const AuthorizeToken = localStorage.getItem('USER_TOKEN');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + AuthorizeToken
    });

    const options = {
      headers: headers
    };

    return options;
  }

  getCampaigns(userId: string): Observable<any> {
    const url = this.getUrl('/z-campaigns/campaigns/' + userId);

    return this.http.get(url, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

  getCampaign(campaignId: string): Observable<any> {
    const url = this.getUrl('/z-campaigns/' + campaignId);

    return this.http.get(url, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

  updateCampaign(campaignId: string, data): Observable<any> {
    const url = this.getUrl('/z-campaigns/' + campaignId);

    return this.http.put(url, data, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

  startStopCampaign(campaignId: string, data): Observable<any> {
    const url = this.getUrl('/z-campaigns/start-stop-campaign/' + campaignId);

    return this.http.put(url, data, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

  submitCampaign(formValue): Observable<any> {
    const url = this.getUrl('/z-campaigns');

    return this.http.post(url, formValue, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

}
