<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<select class="browser-default custom-select" style="width: 250px;" [(ngModel)]="select" (change)="selectUser()">
    <option selected value=""> Choose user</option>
    <option *ngFor="let user of users" value="{{user._id}},{{user.linkedInProfileId}}">{{user.name}}</option>
</select>
<i class="fas fa-download export-icon" *ngIf="rows.length" (click)="exportData()"></i>
<div id="gridContainer">
    <ag-grid-angular (paginationChanged)="onPaginationChanged($event)" 
    (gridReady)="onGridReady($event)" *ngIf="select" 
    style="width: 100%; height: calc(100% - 17px)" 
    [enableColResize]="true" 
    [paginationAutoPageSize]="true" 
    [pagination]="true" 
    class="ag-theme-alpine" 
    [rowData]="rows" 
    [columnDefs]="columnDefs" 
    [enableSorting]="true" 
    [enableFilter]="true"
        [overlayNoRowsTemplate]='noDataText'>
    </ag-grid-angular>
</div>

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="+1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true" style="z-index: 10000;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" *ngIf="selectedRow">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{selectedRow.firstName}} {{selectedRow.lastName}}</h5>
                <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <img [src]="selectedRow.avatar" style="width: 100%;" />
            </div>
        </div>
    </div>
</div>