import { PXData } from 'src/app/models/PXData';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild } from '@angular/core';

import { getUserRole } from 'src/app/helpers/getUserRole';

import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-list-contacts',
  templateUrl: './list-contacts.component.html',
  styleUrls: ['./list-contacts.component.scss']
})
export class ListContactsComponent implements OnInit {

  @ViewChild('deleteModal', { static: true }) deleteModal: ModalDirective;

  rows = [];
  deleteId = '';
  private gridApi;
  private gridColumnApi;
  isLoading = false;
  defaultColDef = { 
    resizable: true,
    width: 150
  };
  isEU = getUserRole() === '4' ? true : false;
  isRSA = getUserRole() === '1' ? true : false;
  noRowsTemplate = `"<span">no rows to show</span>"`;
  viewType = localStorage.getItem('LIST_CONTACTS_VIEW_TYPE');
  listContactsName = localStorage.getItem('LIST_CONTACTS_NAME');
  listContactsCompany = localStorage.getItem('LIST_CONTACTS_COMPANY');
  listContactsListName = localStorage.getItem('LIST_CONTACTS_LISTNAME');
  loadingTemplate = `<span class="ag-overlay-loading-center">data is loading...</span>`;

  columnDefs = [
    {
      field: 'approved',
      width: 70,
      maxWidth: 80,
      sortable: true,
      headerName: 'Status',
      headerTooltip: 'Status',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      cellRenderer: function (params) {
        if (params.data.approved === 'Y') {
          return `<span style="color: #32cd32; font-weight: bold">${params.data.approved}</span>`;
        }
        if (params.data.approved === 'N') {
          return `<span style="color: red; font-weight: bold">${params.data.approved}</span>`;
        }
        return `<span>${params.data.approved || ''}</span>`; // Ensure a return value for other cases
      }
    },
    {
      field: 'companyName',
      width: 150,
      sortable: true,
      resizable: true,
      headerName: 'Company Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      cellRenderer: function (params) {
        return `<a href="${params.data.companyProfileURL}" target="_blank">${params.data.companyName}</a>`;
      }
    },
    {
      field: 'organization_website_1',
      width: 30,
      headerName: 'Web',
      headerTooltip: 'Company Website',
    
      cellRenderer: function (params) {
        console.log('params:', params);  // Log the params to see the data
    
        const iconStyle = `
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        `;
        if (params.value) {
          return `<a href="${params.value}" target="_blank" style="color: #007bff; ${iconStyle}">
                    <i class="fas fa-globe"></i>
                  </a>`;
        } else {
          return `<div style="${iconStyle}">
                    <i class="fas fa-globe" style="color: #ccc;"></i>
                  </div>`;
        }
      }
    },
    {
      field: 'fullName',
      width: 70,
      sortable: true, 
      headerName: 'Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      valueGetter: function (params) {
        return `${params.data.firstName} ${params.data.lastName}`;
      },
      cellRenderer: function (params) {
        return `<a href="${params.data.profileURL}" title="View Profile" target="_blank">${params.value}</a>`;
      }
    },
    {
      field: 'title',
      width: 100,
      sortable: true,
      headerName: 'Title',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },

    {
      field: 'location',
      width: 80,
      sortable: true,
      headerName: 'Prospect Location',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      }
    },
    {
      field: 'followers',
      width: 60,
      maxWidth: 80,
      sortable: true,
      headerTooltip: 'Connections',
      headerName: 'Conn.',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      valueFormatter: (params) => {
        return params.value ? params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
      },
      cellStyle: { 
        textAlign: 'right', 
        paddingRight: '30px'  // Add padding to the right
      }
    },
    {
      field: 'organization_industry_1',
      width: 60,
      sortable: true,
      headerName: 'Industry',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      }
    },
    {
      field: 'organization_location_1',
      width: 100,
      sortable: true,
      headerName: 'Company HQ',
      headerTooltip: 'Organization Headquarters',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
      field: 'organization_headcount_1',
      width: 60,
      maxWidth: 80,
      sortable: true,
      headerName: 'HC',
      headerTooltip: 'Organization Headcount',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      cellRenderer: function(params) {
        if (params.value || params.value === 0) {
          return Number(params.value).toLocaleString();
        } else {
          return '';
        }
      },
      cellStyle: { 
        textAlign: 'right', 
        paddingRight: '20px'  // Add padding to the right
      }
    },
    // {
    //   field: 'followers',
    //   width: 60,
    //   maxWidth: 60,
    //   sortable: true,
    //   headerTooltip: 'Connections',
    //   headerName: 'Conn.',
    //   filter: "agTextColumnFilter",
    //   filterParams: {
    //     buttons: ['reset', 'apply'],
    //     closeOnApply: true
    //   },
    //   valueFormatter: (params) => {
    //     return params.value ? params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
    //   }
    // },
    {
      field: 'rejectionReason',
      sortable: true,
      width: 100,
      headerName: 'Rejection Reason',
      filter: "agTextColumnFilter",
      cellRenderer: function (params) {
        console.log(params.data.rejectionReason)
        return params.data.rejectionReason
      }
      
    },
    {
      field: '',
      width: 50,
      colId: 'delete',
      headerName: 'Delete',
      cellRenderer: this.trashIconRender.bind(this),
    }
  ];

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.loadData();
  
    if (!this.isRSA) {
      this.columnDefs = this.columnDefs.filter(col => col.colId !== 'delete'); // Remove the delete column if the user is not RSA
    }
  }

  loadData() {
    this.userService.getAVDataByListId(localStorage.getItem('LIST_CONTACTS_KEY'), localStorage.getItem('USER_KEY')).subscribe((res) => {
      const sortedRes = res.sort((a, b) => {
        if (a.companyName < b.companyName) {
          return -1;
        }
        if (a.companyName > b.companyName) {
          return 1;
        }

        if (a.firstName < b.firstName) {
          return -1;
        }
        if (a.firstName > b.firstName) {
          return 1;
        }
        return 0;
      });
      console.log(sortedRes)
      this.rows = sortedRes.filter((item: PXData) => item.approved.includes(this.viewType));
      console.log(this.rows,this.viewType)
      if (this.rows.length === 0) {
        this.gridApi.showNoRowsOverlay();
      }

    }, (err) => {
      alert('error');
      this.gridApi.showNoRowsOverlay();
      console.log('error to get user av data: ', err);
    });
  }

  autoSizeAll() {
    var allColumnIds = [];

    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });

    this.gridColumnApi.autoSizeColumns(allColumnIds);
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridColumnApi = params.columnApi;
  }

  // autoSizeAll() {
  //   var allColumnIds = [];

  //   this.gridColumnApi.getAllColumns().forEach(function (column) {
  //     allColumnIds.push(column.colId);
  //   });

  //   this.gridColumnApi.autoSizeColumns(allColumnIds);
  //   this.gridApi.sizeColumnsToFit();
  // }

  // onGridReady(params) {
  //   this.gridApi = params.api;
  //   this.gridApi.sizeColumnsToFit();
  //   this.gridColumnApi = params.columnApi;
  // }

  trashIconRender(params) {
    const eDiv = document.createElement('span');
    eDiv.style.cursor = 'pointer';
    eDiv.innerHTML = `<i class="fa fa-trash-o"></i>`;

    eDiv.addEventListener('click', () => {
      this.openDeleteModal(params.data.id)
    });

    return eDiv;
  }

  openDeleteModal(deleteId) {
    this.deleteId = deleteId;
    this.deleteModal.show();
  }

  deleteRecord() {
    this.isLoading = true;

    this.userService.deleteRecord(this.deleteId).subscribe((res: any) => {
      if (res) {
        localStorage.removeItem('LIST_AVDATA_CACHED_AT');
        this.loadData();
      };

      this.isLoading = false;
      this.deleteModal.hide();

    }, (err) => {
      this.isLoading = false;
      this.deleteModal.hide();
      alert('error');
      console.log('error to delete list: ', err)
    });
  }

  exportData() {
    const con = [];

    let csvContent = 'data:text/csv;charset=utf-8,';

    Object.keys(this.rows).forEach((data) => {
      const val = this.rows[data];
      con.push(val);
    });

    const title = `Status, Rejection Reason, Company Name, First Name, Last Name, Title, Profile Url, Email, Phone, Location, Company Start Date, Tenure, Connected On, Industry, Mutual Connections`;
    csvContent += title + '\r\n';

    con.forEach(function (rowArray) {
      const row = '"' + rowArray.approved + '","' + (rowArray.rejectionReason||"N/A") + '","' + rowArray.companyName + '","' + rowArray.firstName + '","'
        + rowArray.lastName + '","' + rowArray.title + '","'
        + rowArray.profileURL + '","' + rowArray.email + '","'
        + rowArray.phone + '","' + rowArray.location + '","'
        + rowArray.yearsAtCompany + '","' + rowArray.since1 + '","' + rowArray.connectedOn
        + '","' + rowArray.industry + '","' + rowArray.mutualConnections + '"';
      csvContent += row + '\r\n';
    });

    csvContent = csvContent.replace(/#/g, '');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);

    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    const _d = `${mo}-${da}-${ye}`;

    if (this.viewType === 'Y') {
      link.setAttribute('download', `${this.listContactsCompany} - ${this.listContactsName} (${this.listContactsListName}) - Approved - Exported on ${_d}.csv`);
    } else if (this.viewType === 'N') {
      link.setAttribute('download', `${this.listContactsCompany} - ${this.listContactsName} (${this.listContactsListName}) - Rejected - Exported on ${_d}.csv`);
    } else {
      link.setAttribute('download', `${this.listContactsCompany} - ${this.listContactsName} (${this.listContactsListName}) - Exported on ${_d}.csv`);
    }

    document.body.appendChild(link); // Required for FF
    link.click();
    this.isLoading = false;
  }

}

