import { ModalDirective } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import * as moment from "moment";
@Component({
  selector: 'app-ca-reports-sent-msg',
  templateUrl: './ca-reports-sent-msg.component.html',
  styleUrls: ['./ca-reports-sent-msg.component.scss']
})
export class CaReportsSentMsgComponent implements OnInit {

  @ViewChild('basicModal', { static: true }) showModalOnClick: ModalDirective;
   gridApi:any;
  rows = [];
  select = '';
  users: User[];
  isLoading = true;
  selectedRow = undefined;
  noDataText = 'No connections right now';

  columnDefs = [
    {
      
      sortable: true,
      field: 'userName',
      headerName: 'User Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      }
    },
    {

      sortable: true,
      field: 'companyName',
      headerName: 'Company Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      }
    },
    
    
    {
      
      sortable: true,
      field: 'firstName',
      headerName: 'First Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
      
      sortable: true,
      field: 'lastName',
      headerName: 'Last Name',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {

      sortable: true,
      field: 'companyId',
      headerName: 'Company ID',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {

      sortable: true,
      field: 'title',
      headerName: 'Title',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {

      sortable: true,
      field: 'email',
      headerName: 'Email',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {

      sortable: true,
      field: 'phone',
      headerName: 'Phone',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {

      sortable: true,
      field: 'connectedOnDate',
      headerName: 'Connected On',
      filter: "agTextColumnFilter",
      cellClass: 'dateISO',
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
      valueFormatter: function ({ data }) { 
        return moment(data.connectedOnDate).format('MM/DD/YYYY')
      },
      cellRenderer: ({data}) => {
        return moment(data.connectedOnDate).format('MM/DD/YYYY')
      }
    },
    {

      sortable: true,
      field: 'memberId',
      headerName: 'MemberId',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {

      sortable: true,
      field: 'profileId',
      headerName: 'ProfileId',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    },
    {
     
      sortable: true,
      field: 'msgSent',
      headerName: 'Msgs Sent',
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true
      },
    }
    
  ];


  constructor(private userService: UserService) { }

  ngOnInit() {
    this.userService.getCAUsers().subscribe((res) => {
      this.users = res.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      if (localStorage.getItem('CA_CONNECTIONS_KEY')) {
        this.select = localStorage.getItem('CA_CONNECTIONS_KEY') + ',' + localStorage.getItem('CA_CONNECTIONS_LINKEDINID');
        this.selectUser();
      } else {
        this.isLoading = false;
      }

    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get ca users: ', err);
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    
    this.gridApi.sizeColumnsToFit();
    
  }
  exportData(){
    this.gridApi!.exportDataAsCsv({
      processCellCallback: (params) => {
        const colDef = params.column.getColDef()
        if (colDef.valueFormatter) {
          const valueFormatterParams = {
            ...params,
            data: params.node.data,
            node: params.node!,
            colDef: params.column.getColDef()
          };
          return colDef.valueFormatter(valueFormatterParams);
        }
        return params.value;
      },
    })
  }
  selectUser() {
    if (this.select) {
      this.isLoading = true;
      let ids:any;
      const split = this.select.split(',');
      const v1 = split[0];
      const v2 = split[1];
      if(v1=="all")ids = this.users.map(x=>x._id);
      else
      ids = [v1]
      this.userService.getUserSentMessages(ids).subscribe((messages) => {
        this.rows = messages;
        console.log('this.rows',this.rows)
        this.isLoading = false;
        //setTimeout(()=>this.gridApi!.exportDataAsCsv(),5000);
      }, (err) => {
        this.isLoading = false;
        alert('error');
        console.log('error to get user px data: ', err);
      });
    }
  }

  avatarShow(params) {
    const eDiv = document.createElement('div');

    if (!params.data.avatar) {
      eDiv.innerHTML = '';
    } else {
      eDiv.innerHTML =
        '<img src="' + params.data.avatar + '" style="width: 25px; height:25px; margin-top: -4.5px; cursor: pointer"/>';
    }

    eDiv.addEventListener('click', () => {
      this.selectedRow = params.data;
      this.showModalOnClick.show();
    });

    return eDiv;
  }
}
