import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SlackService = /** @class */ (function () {
    function SlackService(http) {
        this.http = http;
    }
    SlackService.prototype.getBaseUrl = function () {
        return environment.baseUrl;
    };
    SlackService.prototype.getUrl = function (relativeUrl) {
        return this.getBaseUrl() + relativeUrl;
    };
    SlackService.prototype.getOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    SlackService.prototype.stopSlackMessage = function (data) {
        var url = this.getUrl('/slack/stop-slack-msg');
        return this.http.put(url, data, this.getOptions()).pipe(map(function (res) {
            return res;
        }));
    };
    SlackService.prototype.sendSlackMessage = function (data) {
        var url = this.getUrl('/slack/send-slack-msg');
        return this.http.put(url, data, this.getOptions()).pipe(map(function (res) {
            return res;
        }));
    };
    SlackService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SlackService_Factory() { return new SlackService(i0.ɵɵinject(i1.HttpClient)); }, token: SlackService, providedIn: "root" });
    return SlackService;
}());
export { SlackService };
