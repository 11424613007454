<div class="container campaigns">
    <h3>Geography</h3>
    <span>What geographical area would you like to target?</span>
    <form [formGroup]="stepTwoForm" (ngSubmit)="stepTwoSubmit()" autocomplete="off" novalidate #formRef="ngForm">

        <div class="input">
            <label for="geoCountry">Countries / Regions </label>
            <select class="browser-default custom-select custom-select-lg" formControlName="geoCountry" (change)="geoCountryChange()">
                <option value="" selected></option>
                <option value="Antarctica">Antarctica</option>
                <option value="Asia">Asia</option>
                <option value="Europe">Europe</option>
                <option value="Latin America">Latin America</option>
                <option value="North America">North America</option>
                <option value="Oceania">Oceania</option>
            </select>
            <span class="error" *ngIf="stepTwoForm.controls['geoCountry'].hasError('required') && formRef.submitted">Please select a
                country or region from the dropdown menu.
            </span>
        </div>

        <div class="input" *ngIf="stepTwoForm.value.geoCountry === 'North America'">
            <label for="geoLocations">Locations</label>
            <ng-multiselect-dropdown [placeholder]="'Select options'" [settings]="dropdownSettings" [data]="dropdownList" formControlName="geoLocations" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" (onDropDownClose)="geoCountryChange()">
            </ng-multiselect-dropdown>
            <span class="error" *ngIf="stepTwoForm.controls['geoLocations'].hasError('required') && formRef.submitted">
                Please select at least one location from the dropdown menu or enter additional locations in the box
                below it.
            </span>
        </div>

        <div class="input">
            <label for="geoLocationsMore">Please include any additional locations. </label>
            <textarea class="form-control" id="geoLocationsMore" formControlName="geoLocationsMore" rows="7" (change)="geoCountryChange()"></textarea>
            <span class="error" *ngIf="stepTwoForm.controls['geoLocationsMore'].hasError('required') && formRef.submitted">
                Please include the specific areas you would like to target.
            </span>
        </div>

        <div class="buttons">
            <button type="submit" *ngIf="editMode" class="previous" (click)="clickDoneButton()">Done</button>
            <button type="button" *ngIf="!editMode" class="previous" (click)="cancelNewCampaign()">Cancel</button>
            <button type="button" class="previous" (click)="back()">Previous</button>
            <button type="submit" cdkStepperNext>Next</button>
        </div>
    </form>
</div>