import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { getUserRole } from 'src/app/helpers/getUserRole';
var ListsReportsComponent = /** @class */ (function () {
    function ListsReportsComponent(userService, router) {
        this.userService = userService;
        this.router = router;
        this.userValue = '';
        this.dateValue = '';
        this.isLoading = true;
        this.companyValue = '';
        this.sortOrder = 'desc';
        this.sortBy = 'uploadedOn';
        this.excludemostrecentlists = false;
        this.isEU = getUserRole() === '4' ? true : false;
        this.isCA = getUserRole() === '3' ? true : false;
        this.isRSA = getUserRole() === '1' ? true : false;
    }
    ListsReportsComponent.prototype.ngOnInit = function () {
        this.loadData();
    };
    ListsReportsComponent.prototype.loadData = function () {
        var _this = this;
        this.isLoading = true;
        if (this.isCA) {
            this.userService.getCAUsers().subscribe(function (res) {
                _this.users = res.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
            }, function (err) {
                alert('error');
                console.log('error to get ca users: ', err);
            });
        }
        var listFor = localStorage.getItem('USER_LISTS_KEY') ? localStorage.getItem('USER_LISTS_KEY') : 'all';
        this.userService.getAVReviewedlists(listFor).subscribe(function (res) {
            _this.avlists = res.avlists;
            _this.companies = res.companies.sort(function (a, b) {
                if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                }
                if (a.toLowerCase() > b.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            if (_this.isRSA) {
                _this.users = res.users.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (_this.isCA) {
                _this.avlists = _this.avlists.filter(function (item) { return item.company === localStorage.getItem('USER_COMPANY'); });
            }
            _this.filter();
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get dashboard: ', err);
        });
    };
    ListsReportsComponent.prototype.toggleExcludemostrecentlists = function () {
        this.excludemostrecentlists = !this.excludemostrecentlists;
        this.filter();
    };
    ListsReportsComponent.prototype.resetFilter = function () {
        this.userValue = '';
        this.dateValue = '';
        this.companyValue = '';
        this.excludemostrecentlists = false;
        localStorage.removeItem('USER_LISTS_KEY');
        localStorage.removeItem('USER_PXDATA_CACHED_AT');
        localStorage.removeItem('USER_PXDATA');
        this.loadData();
    };
    ListsReportsComponent.prototype.filter = function () {
        var e_1, _a;
        var _this = this;
        if (this.companyValue || this.userValue || this.dateValue) {
            localStorage.setItem('CA_CONNECTIONS_KEY', '');
            localStorage.setItem('CA_CONNECTIONS_NAME', '');
            localStorage.setItem('CA_CONNECTIONS_LINKEDINID', '');
            localStorage.removeItem('USER_PXDATA_CACHED_AT');
            localStorage.removeItem('USER_PXDATA');
        }
        this.filteredAvlists = this.avlists;
        if (this.companyValue) {
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return item.company === _this.companyValue; });
        }
        if (this.userValue) {
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return item.listFor === _this.userValue; });
        }
        if (this.dateValue !== '') {
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return (Math.abs(new Date().getTime() - new Date(item.createdAt).getTime()) / 3600000) < parseInt(_this.dateValue) * 24; });
        }
        if (this.excludemostrecentlists) {
            this.filteredAvlists = this.filteredAvlists.filter(function (item) { return (Math.abs(new Date().getTime() - new Date(item.createdAt).getTime()) / 3600000) > 7 * 24; });
        }
        var arrById = this.filteredAvlists.reduce(function (newArr, item) {
            // Group initialization
            if (!newArr[item.listFor]) {
                newArr[item.listFor] = [];
            }
            // Grouping
            newArr[item.listFor].push(item);
            return newArr;
        }, {});
        var finalArr = [];
        try {
            for (var _b = tslib_1.__values(Object.entries(arrById)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), key = _d[0], valueArr = _d[1];
                var temp = {};
                temp['listFor'] = key;
                temp['company'] = valueArr[0].company;
                temp['name'] = valueArr[0].name;
                temp['listCount'] = valueArr.length;
                temp['uploadedCount'] = 0;
                temp['approvedCount'] = 0;
                temp['notApproved'] = 0;
                temp['approvedPercentage'] = 0;
                temp['invitedCount'] = 0;
                temp['connectedCount'] = 0;
                temp['respondedCount'] = 0;
                temp['positiveCount'] = 0;
                for (var i = 0; i < valueArr.length; i++) {
                    temp['uploadedCount'] += valueArr[i].uploadedCount;
                    temp['approvedCount'] += valueArr[i].approvedCount;
                    temp['notApproved'] += valueArr[i].notApproved;
                    temp['approvedPercentage'] += valueArr[i].approvedPercentage;
                    temp['invitedCount'] += valueArr[i].invitedCount;
                    temp['connectedCount'] += valueArr[i].connectedCount;
                    temp['respondedCount'] += valueArr[i].respondedCount;
                    temp['positiveCount'] += valueArr[i].positiveCount;
                }
                if (isNaN(temp['invitedCount'])) {
                    temp['invitedCount'] = 0;
                }
                if (isNaN(temp['connectedCount'])) {
                    temp['connectedCount'] = 0;
                }
                if (isNaN(temp['respondedCount'])) {
                    temp['respondedCount'] = 0;
                }
                if (isNaN(temp['positiveCount'])) {
                    temp['positiveCount'] = 0;
                }
                temp['approvedPercentage'] = Math.round(temp['approvedPercentage'] / valueArr.length);
                temp['unApprovedPercentage'] = 100 - temp['approvedPercentage'];
                temp['invitedPercentage'] = Math.round(temp['invitedCount'] / temp['approvedCount'] * 100);
                temp['connectedPercentage'] = Math.round(temp['connectedCount'] / temp['invitedCount'] * 100);
                temp['respondedPercentage'] = Math.round(temp['respondedCount'] / temp['connectedCount'] * 100);
                temp['positivePercentage'] = Math.round(temp['positiveCount'] / temp['respondedCount'] * 100);
                if (temp['invitedPercentage'] === Infinity || isNaN(temp['invitedPercentage'])) {
                    temp['invitedPercentage'] = 0;
                }
                if (temp['connectedPercentage'] === Infinity || isNaN(temp['connectedPercentage'])) {
                    temp['connectedPercentage'] = 0;
                }
                if (temp['respondedPercentage'] === Infinity || isNaN(temp['respondedPercentage'])) {
                    temp['respondedPercentage'] = 0;
                }
                if (temp['positivePercentage'] === Infinity || isNaN(temp['positivePercentage'])) {
                    temp['positivePercentage'] = 0;
                }
                finalArr.push(temp);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return this.filteredAvlists = finalArr;
    };
    ListsReportsComponent.prototype.gotoListsWithCount = function (avlist) {
        localStorage.setItem('LISTS_SHOW_FILTER', 'reviewed');
        localStorage.setItem('LISTS_COMPANY_FILTER', avlist.company);
        localStorage.setItem('LISTS_USER_FILTER', avlist.listFor);
        localStorage.setItem('LISTS_DATE_RANGE_FILTER', this.dateValue);
        localStorage.setItem('LISTS_EXCLUDE_RECENT_FILTER', this.excludemostrecentlists ? '1' : '0');
        this.router.navigate(['/lists']);
    };
    return ListsReportsComponent;
}());
export { ListsReportsComponent };
