<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="container campaigns" *ngIf="!isLoading">
    <h3>Connection Request</h3>
    <div *ngIf="dropdownList.length > 0">
        <span [innerHTML]="dropdownList[3].bigMessage"></span><br>
        <span [innerHTML]="dropdownList[3].smallMessage"></span>
    </div>

    <form [formGroup]="stepTwoForm" (ngSubmit)="stepTwoSubmit()" autocomplete="off" novalidate #formRef="ngForm">

        <div class="input">
            <label>Message </label>
            <textarea class="form-control" formControlName="message" rows="7" maxlength="290"></textarea>
            <span>{{290 - stepTwoForm.value.message.length}} characters remaining</span>
            <br>
            <span class="error" *ngIf="stepTwoForm.controls['message'].hasError('required') && formRef.submitted">
                Please enter a message or select the option to return later.
            </span>
        </div>

        <div class="input">
            <mdb-checkbox formControlName="draft" (change)="getCheckboxesValue($event)">
                I'd like to come back and edit this message later
            </mdb-checkbox>
        </div>

        <div class="buttons">
            <button type="submit" *ngIf="editMode" class="previous" (click)="clickDoneButton()">Done</button>
            <button type="button" *ngIf="!editMode" class="previous" (click)="cancelNewCampaign()">Cancel</button>
            <button type="button" class="previous" (click)="gotoIntroMessage()">Previous</button>
            <button type="submit">Next</button>
        </div>

    </form>
</div>