import { HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NodeCache = require("node-cache");
var AVListService = /** @class */ (function () {
    function AVListService(http) {
        this.http = http;
        this.myCache = new NodeCache();
    }
    AVListService.prototype.getBaseUrl = function () {
        return environment.baseUrl;
    };
    AVListService.prototype.getUrl = function (relativeUrl) {
        return this.getBaseUrl() + relativeUrl;
    };
    AVListService.prototype.getOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    AVListService.prototype.getAll = function (user_id, listIds) {
        if (listIds === void 0) { listIds = []; }
        var url = this.getUrl('/avlists/get-selected-lists/' + user_id);
        return this.http.put(url, { listIds: listIds }, this.getOptions())
            .pipe(map(function (res) { return res; }));
    };
    AVListService.prototype.getUserReviewList = function (_id) {
        var _this = this;
        return new Observable(function (observer) {
            var url = _this.getUrl('/avlists/get-review-lists/' + _id);
            _this.http.get(url, _this.getOptions())
                .subscribe(function (querySnapshot) {
                observer.next(querySnapshot);
            });
        });
    };
    AVListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AVListService_Factory() { return new AVListService(i0.ɵɵinject(i1.HttpClient)); }, token: AVListService, providedIn: "root" });
    return AVListService;
}());
export { AVListService };
