<div class="container campaigns">
    <h3>Role & Tenure</h3>
    <span>Let's try to identify the most likely roles and titles for your prospects.</span>

    <form [formGroup]="stepFourForm" (ngSubmit)="stepFourSubmit()" autocomplete="off" novalidate #formRef="ngForm">

        <div class="input">
            <label for="jobTitles">Select the job titles used by your typical prospect.</label>
            <ng-multiselect-dropdown [placeholder]="'Select options'" [settings]="dropdownSettings" [data]="dropdownList" formControlName="jobTitles" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" (onDropDownClose)="jobTitlesChange()">
            </ng-multiselect-dropdown>
            <span class="error" *ngIf="stepFourForm.controls['jobTitles'].hasError('required') && formRef.submitted">
                Please select at least one job title from the dropdown menu or enter additional job titles in the box
                below it.
            </span>
        </div>

        <div class="input">
            <label for="jobTitlesMore">Additional Job Titles </label>
            <textarea class="form-control" id="jobTitlesMore" formControlName="jobTitlesMore" rows="7" (change)="jobTitlesChange()"></textarea>
            <span>Use the Enter key to add additional titles.</span>
        </div>

        <div class="input">
            <label for="companyTenureEnable">
                Would you like to limit the research based on the number of years the prospect has worked at their
                current company?
            </label>
            <ui-switch uncheckedLabel="No" checkedLabel="Yes" color="#306bff" formControlName="companyTenureEnable">
            </ui-switch>
        </div>

        <div class="input" *ngIf="stepFourForm.value.companyTenureEnable">
            <label for="companyTenure">
                Select the number of years the prospect has worked at their current company.
            </label>
            <mdb-checkbox *ngFor="let limit of limitResearchYearsOptions" (change)="getCompanyCheckboxesValue($event, limit)" [checked]="stepFourForm.value.companyTenure.includes(limit)">
                {{limit}}
            </mdb-checkbox>
            <span class="error" *ngIf="stepFourForm.value.companyTenure.length === 0 && formRef.submitted">
                Please make at least one selection or disable this option.
            </span>
        </div>

        <div class="input">
            <label for="positionTenureEnable">
                Would you like to limit the research based on the number of years the prospect has worked in their
                current position?
            </label>
            <ui-switch uncheckedLabel="No" checkedLabel="Yes" color="#306bff" formControlName="positionTenureEnable">
            </ui-switch>
        </div>

        <div class="input" *ngIf="stepFourForm.value.positionTenureEnable">
            <label for="positionTenure">
                Select the number of years the prospect has worked in their position.
            </label>
            <mdb-checkbox *ngFor="let limit of limitResearchYearsOptions" (change)="getPositionCheckboxesValue($event, limit)" [checked]="stepFourForm.value.positionTenure.includes(limit)">
                {{limit}}
            </mdb-checkbox>
            <span class="error" *ngIf="stepFourForm.value.positionTenure.length === 0 && formRef.submitted">
                Please make at least one selection or disable this option.
            </span>
        </div>

        <div class="buttons">
            <ng-template #templateNameDoneButton>
                <button type="submit" class="previous" *ngIf="(!stepFourForm.value.companyTenureEnable || stepFourForm.value.companyTenure.length > 0) && (!stepFourForm.value.positionTenureEnable || stepFourForm.value.positionTenure.length > 0); else templateNameDone"
                    (click)="clickDoneButton()">Done</button>
                <ng-template #templateNameDone>
                    <button type="submit" class="previous" (click)="clickDoneButton()">Done</button>
                </ng-template>
            </ng-template>
            <button type="button" class="previous" *ngIf="!editMode; else templateNameDoneButton" (click)="cancelNewCampaign()">Cancel</button>
            <button type="button" class="previous" cdkStepperPrevious>Previous</button>
            <button type="submit" *ngIf="(!stepFourForm.value.companyTenureEnable || stepFourForm.value.companyTenure.length > 0) && (!stepFourForm.value.positionTenureEnable || stepFourForm.value.positionTenure.length > 0); else templateName" cdkStepperNext>Next</button>
            <ng-template #templateName>
                <button type="submit">Next</button>
            </ng-template>
        </div>
    </form>
</div>