<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<h1>Settings</h1>
<br>

<div class="row">
    <div class="col-6 ml-2">
        <h4 class="mb-2">User Dashboard Message</h4>
        <ckeditor [editor]="Editor" [(ngModel)]="message" #myckeditor="ngModel" name="message" [config]=editorConfig>
        </ckeditor>
        <div>
            <button type="button" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect (click)="saveMessage()">Save</button>
        </div>
    </div>
</div>

<form [formGroup]="admin_setting" (ngSubmit)="onSubmit()">
    <div class="row p-3">
        <div class="col-3 border m-2">
            <h4 class="mb-2 mt-2">Approved</h4>
            <div class="form-group">
                <div class="input-group mb-2 mr-sm-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text">7 Days</div>
                    </div>
                    <input type="text" class="form-control" name="approved_7day" [OnlyNumber]="true" formControlName="approved_7day" id="approved_7day" placeholder="">
                    <div class="input-group-append">
                        <div class="input-group-text">%</div>
                    </div>
                </div>
                <div *ngIf="admin_setting.controls.approved_7day.invalid  && (admin_setting.controls.approved_7day.dirty || admin_setting.controls.approved_7day.touched)">
                    <div *ngIf="admin_setting.controls.approved_7day.errors.required" class="alert-danger">
                        This field is required.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group mb-2 mr-sm-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text">30 Days</div>
                    </div>
                    <input type="text" class="form-control" [OnlyNumber]="true" formControlName="approved_30day" id="approved_30day" placeholder="">
                    <div class="input-group-append">
                        <div class="input-group-text">%</div>
                    </div>
                </div>
                <div *ngIf="admin_setting.controls.approved_30day.invalid  && (admin_setting.controls.approved_30day.dirty || admin_setting.controls.approved_30day.touched)">
                    <div *ngIf="admin_setting.controls.approved_30day.errors.required" class="alert-danger">
                        This field is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 border m-2">
            <h4 class="mb-2 mt-2">Messages</h4>
            <div class="form-group">
                <div class="input-group mb-2 mr-sm-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text">7 Days</div>
                    </div>
                    <input type="text" class="form-control" [OnlyNumber]="true" formControlName="message_7day" id="message_7day" placeholder="">
                    <div class="input-group-append">
                        <div class="input-group-text">%</div>
                    </div>
                </div>
                <div *ngIf="admin_setting.controls.message_7day.invalid  && (admin_setting.controls.message_7day.dirty || admin_setting.controls.message_7day.touched)">
                    <div *ngIf="admin_setting.controls.message_7day.errors.required" class="alert-danger">
                        This field is required.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group mb-2 mr-sm-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text">30 Days</div>
                    </div>
                    <input type="text" class="form-control" [OnlyNumber]="true" formControlName="message_30day" id="message_30day" placeholder="">
                    <div class="input-group-append">
                        <div class="input-group-text">%</div>
                    </div>
                </div>
                <div *ngIf="admin_setting.controls.message_30day.invalid  && (admin_setting.controls.message_30day.dirty || admin_setting.controls.message_30day.touched)">
                    <div *ngIf="admin_setting.controls.message_30day.errors.required" class="alert-danger">
                        This field is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 border m-2">
            <h4 class="mb-2 mt-2">Connections</h4>
            <div class="form-group">
                <div class="input-group mb-2 mr-sm-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text">7 Days</div>
                    </div>
                    <input type="text" class="form-control" [OnlyNumber]="true" formControlName="connection_7day" id="connection_7day" placeholder="">
                    <div class="input-group-append">
                        <div class="input-group-text">%</div>
                    </div>
                </div>
                <div *ngIf="admin_setting.controls.connection_7day.invalid  && (admin_setting.controls.connection_7day.dirty || admin_setting.controls.connection_7day.touched)">
                    <div *ngIf="admin_setting.controls.connection_7day.errors.required" class="alert-danger">
                        This field is required.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group mb-2 mr-sm-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text">30 Days</div>
                    </div>
                    <input type="text" class="form-control" [OnlyNumber]="true" formControlName="connection_30day" id="connection_30day" placeholder="">
                    <div class="input-group-append">
                        <div class="input-group-text">%</div>
                    </div>
                </div>
                <div *ngIf="admin_setting.controls.connection_30day.invalid  && (admin_setting.controls.connection_30day.dirty || admin_setting.controls.connection_30day.touched)">
                    <div *ngIf="admin_setting.controls.connection_30day.errors.required" class="alert-danger">
                        This field is required.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="input-group mb-2 mr-sm-2">
            <button type="submit" mdbBtn [disabled]="!admin_setting.valid" class="btn btn-primary" mdbWavesEffect>Save</button>
        </div>
    </div>

</form>

<!--  -->