import { ToastService } from 'ng-uikit-pro-standard';
import { OnInit } from '@angular/core';
import { validateImage } from 'src/app/helpers/common';
var IncomingConnectionsComponent = /** @class */ (function () {
    function IncomingConnectionsComponent(document, userService, toastrService) {
        this.userService = userService;
        this.toastrService = toastrService;
        this.rows = [];
        this.isLoading = true;
    }
    IncomingConnectionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.getUserIncomingRequests(localStorage.getItem('USER_MEMBER_ID')).subscribe(function (connection) {
            console.log('connections', connection);
            if (connection.data) {
                _this.rows = connection.data;
                validateImage();
            }
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user pxdata: ', err);
        });
    };
    IncomingConnectionsComponent.prototype.doAction = function (actionTaken, record) {
        var _this = this;
        this.userService.actionOnRequest(localStorage.getItem('USER_MEMBER_ID'), { actionTaken: actionTaken, connectionId: record._id }).subscribe(function (connection) {
            console.log(connection);
            if (connection.code == 200) {
                for (var i in _this.rows) {
                    if (_this.rows[i]._id == connection.data._id) {
                        _this.rows[i] = connection.data;
                    }
                }
                _this.toastrService.success(connection.message);
            }
            else {
                _this.toastrService.error(connection.message);
            }
        });
    };
    return IncomingConnectionsComponent;
}());
export { IncomingConnectionsComponent };
