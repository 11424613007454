// Angular Core Imports
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';
import { CompanyGuard } from './guards/company.guard';

// Application Imports (sorted by length of path)
import { HomeComponent } from './pages/rsa/home/home.component';
import { UserComponent } from './pages/rsa/user/user.component';
import { ListsComponent } from './pages/eu/lists/lists.component';
import { GroupsComponent } from './pages/eu/groups/groups.component';
import { ReviewComponent } from './pages/eu/review/review.component';
import { ClientComponent } from './pages/rsa/client/client.component';
import { LoginComponent } from './pages/shared/login/login.component';
import { PxdataComponent } from './pages/rsa/pxdata/pxdata.component';
import { AvdataComponent } from './pages/rsa/avdata/avdata.component';
import { SettingComponent } from './pages/rsa/setting/setting.component';
import { MessagesComponent } from './pages/eu/messages/messages.component';
import { ProfileComponent } from './pages/shared/profile/profile.component';
import { CampaignsComponent } from './pages/eu/campaigns/campaigns.component';
import { ListPrepComponent } from './pages/rsa/list-prep/list-prep.component';
import { DashboardComponent } from './pages/rsa/dashboard/dashboard.component';
import { CompaniesComponent } from './pages/rsa/companies/companies.component';
import { CaReportsComponent } from './pages/ca/ca-reports/ca-reports.component';
import { CaMessagesComponent } from './pages/ca/ca-messages/ca-messages.component';
import { UserReviewComponent } from './pages/rsa/user-review/user-review.component';
import { ConnectionsComponent } from './pages/eu/connections/connections.component';
import { MessagedataComponent } from './pages/rsa/messagedata/messagedata.component';
import { EcEmailDataComponent } from './pages/rsa/ecemaildata/ecemaildata.component';
import { EuDashboardComponent } from './pages/eu/eu-dashboard/eu-dashboard.component';
import { CaDashboardComponent } from './pages/ca/ca-dashboard/ca-dashboard.component';
import { NewStrategyComponent } from './pages/eu/new-strategy/new-strategy.component';
import { UserMessageComponent } from './pages/rsa/user-message/user-message.component';
import { ListContactsComponent } from './pages/eu/list-contacts/list-contacts.component';
import { ListsReportsComponent } from './pages/eu/lists-reports/lists-reports.component';
import { MessagesComponentBeta } from './pages/eu/messages-beta/messages-beta.component';
import { StrategyTypeComponent } from './pages/eu/strategy-type/strategy-type.component';
import { UserReviewedComponent } from './pages/rsa/user-reviewed/user-reviewed.component';
import { CaConnectionsComponent } from './pages/ca/ca-connections/ca-connections.component';
import { SpecialAvdataComponent } from './pages/rsa/special-avdata/special-avdata.component';
import { CompanyPxdataComponent } from './pages/rsa/company-pxdata/company-pxdata.component';
import { ViewedProfilesComponent } from './pages/eu/viewedprofiles/viewedprofiles.component';
import { CaMessagesNewComponent } from './pages/ca/ca-messages-new/ca-messages-new.component';
import { RequestedEmailComponent } from './pages/rsa/requested-email/requestedEmail.component';
import { ResetPasswordComponent } from './pages/shared/reset-password/reset-password.component';
import { MessageSendWhoComponent } from './pages/eu/message-send-who/message-send-who.component';
import { UnapprovedListsComponent } from './pages/eu/unapproved-lists/unapproved-lists.component';
import { UserConnectionsComponent } from './pages/rsa/user-connections/user-connections.component';
import { ClientCampaignsComponent } from './pages/rsa/client-campaigns/client-campaigns.component';
import { CampaignsSummaryComponent } from './pages/eu/campaigns-summary/campaigns-summary.component';
import { IncomingMessagesComponent } from './pages/eu/incoming-messages/incoming-messages.component';
import { IncomingConnectionsComponent } from './pages/eu/incoming-requests/incomingrequests.component';
import { CaReportsSentMsgComponent } from './pages/ca/ca-reports-sent-msg/ca-reports-sent-msg.component';
import { ListPrepProspectComponent } from './pages/rsa/list-prep-prospects/list-prep-prospects.component';
import { MessagePreviewComponent } from './pages/eu/message-sequence/message-preview/message-preview.component';
import { MessageTemplateComponent } from './pages/eu/message-sequence/message-template/message-template.component';
import { CreateNewCampaignComponent } from './pages/eu/campaigns/create-new-campaign/create-new-campaign.component';
import { ConnectionRequestComponent } from './pages/eu/message-sequence/connection-request/connection-request.component';
import { IntroMessageSequenceComponent } from './pages/eu/message-sequence/intro-message-sequence/intro-message-sequence.component';
import { ReviewListComponent } from './pages/eu/review-list/review-list.component';


const routes: Routes = [

  // No Auth
  { path: '', redirectTo: '/login', pathMatch: 'full' },

  // Routes with End User Guard
  { path: 'campaigns', component: CampaignsComponent, canActivate: [AuthGuard] },
  { path: 'campaigns-summary', component: CampaignsSummaryComponent, canActivate: [AuthGuard] },
  { path: 'clients', component: ClientComponent, canActivate: [AuthGuard] },
  { path: 'companies', component: CompaniesComponent, canActivate: [AuthGuard] },
  { path: 'connection-request', component: ConnectionRequestComponent, canActivate: [AuthGuard] },
  { path: 'connections', component: ConnectionsComponent, canActivate: [AuthGuard] },
  { path: 'create-new-campaign', component: CreateNewCampaignComponent, canActivate: [AuthGuard] },
  { path: 'euDashboard', component: EuDashboardComponent, canActivate: [AuthGuard] },
  { path: 'groups', component: GroupsComponent, canActivate: [AuthGuard] },
  { path: 'incoming-messages', component: IncomingMessagesComponent, canActivate: [AuthGuard] },
  { path: 'incomingrequests', component: IncomingConnectionsComponent, canActivate: [AuthGuard] },
  { path: 'intro-message-sequence', component: IntroMessageSequenceComponent, canActivate: [AuthGuard] },
  { path: 'listContacts', component: ListContactsComponent, canActivate: [AuthGuard] },
  { path: 'lists', component: ListsComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'message-preview', component: MessagePreviewComponent, canActivate: [AuthGuard] },
  { path: 'message-send-who', component: MessageSendWhoComponent, canActivate: [AuthGuard] },
  { path: 'message-template', component: MessageTemplateComponent, canActivate: [AuthGuard] },
  { path: 'messages', component: MessagesComponentBeta, canActivate: [AuthGuard] },
  { path: 'messages-old', component: MessagesComponent, canActivate: [AuthGuard] },
  { path: 'new-strategy', component: NewStrategyComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'reset-password/:reset-token', component: ResetPasswordComponent },
  { path: 'review', component: ReviewComponent, canActivate: [AuthGuard] },
  { path: 'review-list', component: ReviewListComponent, canActivate: [AuthGuard] },
  { path: 'strategy-type', component: StrategyTypeComponent, canActivate: [AuthGuard] },
  { path: 'userConnections', component: UserConnectionsComponent, canActivate: [AuthGuard] },
  { path: 'userMessage', component: UserMessageComponent, canActivate: [AuthGuard] },
  { path: 'userReview', component: UserReviewComponent, canActivate: [AuthGuard] },
  { path: 'userReviewed', component: UserReviewedComponent, canActivate: [AuthGuard] },
  { path: 'viewedprofiles', component: ViewedProfilesComponent, canActivate: [AuthGuard] },

  // Routes with Admin Guard
  { path: 'avdata', component: AvdataComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'client-campaigns', component: ClientCampaignsComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'clientOverview', component: DashboardComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'dashboard', component: HomeComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'ecemaildata', component: EcEmailDataComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'exportdata', component: CompanyPxdataComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'import-requested-emails', component: RequestedEmailComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'list-prep', component: ListPrepComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'list-prep-prospects', component: ListPrepProspectComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'listsReports', component: ListsReportsComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'messagedata', component: MessagedataComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'pxdata', component: PxdataComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'settings', component: SettingComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'specialAvdata', component: SpecialAvdataComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'unapprovedLists', component: UnapprovedListsComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'users', component: UserComponent, canActivate: [AuthGuard, AdminGuard] },

  // Routes with Company Admin Guard
  { path: 'caConnections', component: CaConnectionsComponent, canActivate: [AuthGuard, CompanyGuard] },
  { path: 'caDashboard', component: CaDashboardComponent, canActivate: [AuthGuard, CompanyGuard] },
  { path: 'caMessages', component: CaMessagesComponent, canActivate: [AuthGuard, CompanyGuard] },
  { path: 'caMessagesNew', component: CaMessagesNewComponent, canActivate: [AuthGuard, CompanyGuard] },
  { path: 'caReports', component: CaReportsComponent, canActivate: [AuthGuard, CompanyGuard] },
  { path: 'caReports/sentMessageCount', component: CaReportsSentMsgComponent, canActivate: [AuthGuard, CompanyGuard] },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }