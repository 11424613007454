<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<div class="alert alert-warning" role="alert" *ngIf="warningMessages && warningMessages.length>0">
    <ul>
        <li *ngFor="let i of warningMessages">{{i.filename}} <strong style="margin-left: 20px;">{{i.message}}</strong>
        </li>
    </ul>
</div>

<ngfFormData [files]="files" postName="file" [(FormData)]="sendableFormData"></ngfFormData>

<ngfUploadStatus [(percent)]="progress" [httpEvent]="httpEvent"></ngfUploadStatus>

<div class="inline-block">
    <h3 style="margin-bottom: 20px;">Import Requested Emails.</h3>
    <p>Please select a csv file that contains a column labeled memberID.</p>
    <div class="inline-block">
        <input ngfSelect type="file" [(files)]="files" [accept]="accept" />
    </div>
</div>
<div>
    <h3>Drop Files</h3>
    <div class="inline-block">
        <div ngfDrop (fileOver)="hasBaseDropZoneOver=$event" [(files)]="files" class="well my-drop-zone" (filesChange)="lastFileAt=getDate()">
            Base drop zone
        </div>
    </div>
</div>

<div style="margin-bottom: 40px">
    <h3>{{ files.length }} Queued Files</h3>
    <table id="filesTable" class="table">
        <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Size</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of files;let i=index">
                <td>
                    <div *ngIf="['image/gif','image/png','image/jpeg'].indexOf(item.type)>=0">
                        <div class="previewIcon" [ngfBackground]="item"></div>
                    </div>
                    <strong>{{ item.name }}</strong>
                </td>
                <td nowrap>
                    {{ item.type }}
                </td>
                <td nowrap>
                    {{ item.size/1024 | number:'.2' }} KB
                </td>
                <td nowrap>
                    <button type="button" class="btn btn-danger btn-sm" (click)="files.splice(i,1)">
                        <i class="fas fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>

    <div>
        <div>
            Queue progress:
            <div class="progress" style="">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': progress + '%' }"></div>
            </div>
        </div>

        <ng-container *ngIf="lastFileAt">
            <p>
                <strong>Last file(s) selected At:</strong> {{ lastFileAt | date : 'longTime' }}
            </p>
        </ng-container>

        <i *ngIf="progress==100" class="glyphicon glyphicon-ok"></i>

        <button type="button" class="btn btn-primary btn-s" (click)="uploadFiles(files)" [disabled]="!files.length">
            <span class="glyphicon glyphicon-upload"></span> Import
        </button>

        <!-- <button type="button" class="btn btn-warning btn-s" (click)="cancel()" [disabled]="!httpEmitter">
      <span class="glyphicon glyphicon-ban-circle"></span> Cancel all
    </button> -->
        <button type="button" class="btn btn-danger btn-s" (click)="files.length=0" [disabled]="!files.length">
            <span class="glyphicon glyphicon-trash"></span> Remove all
        </button>
    </div>
</div>