import jwt_decode from 'jwt-decode';

import { User } from 'src/app/models/user';

export const getUserRole = () => {
    if (localStorage.getItem('USER_TOKEN')) {
        const user_data: User = jwt_decode(localStorage.getItem('USER_TOKEN'));
        return user_data.role;
    }

    return "0";
}

export const getUserselfserve = () => {
    if (localStorage.getItem('USER_TOKEN')) {
        const user_data: User = jwt_decode(localStorage.getItem('USER_TOKEN'));
        return user_data.selfserve;
    }

    return false;
}