import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { StrategyService } from 'src/app/services/strategy.service';
import { SequenceService } from 'src/app/services/sequence.service';
import { CampaignService } from 'src/app/services/campaign.service';

@Component({
  selector: 'app-campaigns-summary',
  templateUrl: './campaigns-summary.component.html',
  styleUrls: ['./campaigns-summary.component.scss']
})
export class CampaignsSummaryComponent implements OnInit {

  strategy;
  campaignInfo;
  previewMessages;
  isLoading = true;
  messageToWhoCampaignName = "";

  constructor(private router: Router, public strategyService: StrategyService, public sequenceService: SequenceService, private _location: Location, public campaignService: CampaignService) { }

  ngOnInit() {
    this.campaignInfo = localStorage.getItem('CAMPAIGN_INFO');
  
    if (!this.campaignInfo) {
      this.router.navigate(['campaigns']);
      return; // Add return statement
    }
  
    this.campaignInfo = JSON.parse(this.campaignInfo);
  
    this.strategyService.getStrategy(this.campaignInfo.strategyId).subscribe((res) => {
      console.log('existing strategy: ', res);
      this.strategy = res;
      if (this.strategy.msgTargetType === 'msgTargetCampaign') {
        this.campaignService.getCampaign(this.strategy.msgTargetCampaign).subscribe((cam) => {
          this.messageToWhoCampaignName = cam.campaignName;
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    }, (err) => {
      this.isLoading = false;
      alert('error');
      console.log('error to get user lastest messages: ', err);
      return; // Add return statement to handle error case
    });
  
    this.sequenceService.getMessages(this.campaignInfo.sequenceId).subscribe((res) => {
      console.log("previewMessages: ", res);
      this.previewMessages = res;
    }, (err) => {
      alert('error');
      console.log(err);
    });
  }

  backClicked() {
    this._location.back();
  }

}
