import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StrategyService {

  constructor(private http: HttpClient) { }

  public getBaseUrl(): string {
    return environment.baseUrl;
  }

  protected getUrl(relativeUrl: string) {
    return this.getBaseUrl() + relativeUrl;
  }

  protected getOptions(): any {
    const AuthorizeToken = localStorage.getItem('USER_TOKEN');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + AuthorizeToken
    });

    const options = {
      headers: headers
    };

    return options;
  }

  protected getFormDataOptions(): any {
    const AuthorizeToken = localStorage.getItem('USER_TOKEN');

    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + AuthorizeToken
    });

    const options = {
      headers: headers
    };

    return options;
  }

  //Get industries list
  getIndustries() {
    const url = this.getUrl('/codes-industries');

    return this.http.get(url, this.getOptions())
      .pipe(
        map((querySnapshot: any) => {
          return querySnapshot
        }));
  }

  //Get locations list
  getLocations() {
    const url = this.getUrl('/codes-locations');

    return this.http.get(url, this.getOptions())
      .pipe(
        map((querySnapshot: any) => {
          return querySnapshot
        }));
  }

  //Get positions list
  getPositions() {
    const url = this.getUrl('/codes-positions');

    return this.http.get(url, this.getOptions())
      .pipe(
        map((querySnapshot: any) => {
          return querySnapshot
        }));
  }

  // Get Strategy Date
  getStrategyDate(_id) {
    const url = this.getUrl('/users/' + _id);

    return this.http.get(url, this.getOptions())
      .pipe(
        map((querySnapshot: any) => {
          return querySnapshot
        }));
  }

  // Action Massage
  submitMessagingStrategy(InvationMasssage, NurtureMasssage, ActionMasssage, _id): Observable<boolean> {
    const url = this.getUrl('/users/' + _id);

    return this.http.put(url, {
      strategy_invation_massage: InvationMasssage,
      strategy_nurture_massage: NurtureMasssage,
      strategy_action_massage: ActionMasssage,
    }, this.getOptions())
      .pipe(
        map((res: any) => {
          return (true);
        }));
  }


  submitMessagingStrategyLog(_id, data) {
    const url = this.getUrl('/logs');

    data['user_id'] = _id;
    return this.http.post(url, data, this.getOptions())
      .pipe(
        map((res: any) => {
          return true;
        })
      )
  }

  submitStrategy(formValue): Observable<any> {
    const url = this.getUrl('/z-strategies');

    return this.http.post(url, formValue, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

  getStrategy(strategyId): Observable<any> {
    const url = this.getUrl('/z-strategies/' + strategyId);

    return this.http.get(url, this.getOptions())
      .pipe(
        map((res: any) => {
          return res;
        }));
  }

}
