import { OnInit } from '@angular/core';
var MessagePreviewComponent = /** @class */ (function () {
    function MessagePreviewComponent(router, sequenceService) {
        this.router = router;
        this.sequenceService = sequenceService;
        this.deleteId = "";
        this.isLoading = true;
        this.previewMessages = [];
    }
    MessagePreviewComponent.prototype.ngOnInit = function () {
        this.loadMessages();
    };
    MessagePreviewComponent.prototype.loadMessages = function () {
        var _this = this;
        var campaignInfo = localStorage.getItem('CAMPAIGN_INFO');
        this.sequenceService.getMessages(JSON.parse(campaignInfo).sequenceId).subscribe(function (res) {
            console.log(res);
            _this.previewMessages = res;
            _this.isLoading = false;
        }, function (err) {
            alert('error');
            console.log(err);
        });
    };
    MessagePreviewComponent.prototype.openDeleteMessageModal = function (_id) {
        this.deleteId = _id;
        this.deleteModal.show();
    };
    MessagePreviewComponent.prototype.deleteMessage = function () {
        var _this = this;
        this.sequenceService.deleteMessage(this.deleteId).subscribe(function (res) {
            console.log(res);
            _this.deleteModal.hide();
            _this.loadMessages();
        }, function (err) {
            alert('error');
            console.log(err);
        });
    };
    MessagePreviewComponent.prototype.gotoCampaigns = function () {
        this.router.navigate(['campaigns']);
    };
    MessagePreviewComponent.prototype.addAnotherMessage = function () {
        localStorage.removeItem("TEMPLATE_MESSAGE_INFO");
        this.router.navigate(['message-template']);
    };
    MessagePreviewComponent.prototype.back = function () {
        this.router.navigate(['message-template']);
    };
    MessagePreviewComponent.prototype.editMessage = function (_id, messageType) {
        var _this = this;
        if (messageType === "Connection Request") {
            this.sequenceService.getMessage(_id).subscribe(function (res) {
                console.log(res);
                localStorage.setItem("CONNECTION_MESSAGE_INFO", JSON.stringify(res));
                _this.router.navigate(['connection-request']);
            }, function (err) {
                alert('error');
                console.log(err);
            });
        }
        else {
            this.sequenceService.getMessage(_id).subscribe(function (res) {
                console.log(res);
                localStorage.setItem("TEMPLATE_MESSAGE_INFO", JSON.stringify(res));
                _this.router.navigate(['message-template']);
            }, function (err) {
                alert('error');
                console.log(err);
            });
        }
    };
    return MessagePreviewComponent;
}());
export { MessagePreviewComponent };
