import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SettingService = /** @class */ (function () {
    function SettingService(http) {
        this.http = http;
    }
    SettingService.prototype.getBaseUrl = function () {
        return environment.baseUrl;
    };
    SettingService.prototype.getUrl = function (relativeUrl) {
        return this.getBaseUrl() + relativeUrl;
    };
    SettingService.prototype.getOptions = function () {
        var AuthorizeToken = localStorage.getItem('USER_TOKEN');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Authorization': 'Bearer ' + AuthorizeToken
        });
        var options = {
            headers: headers
        };
        return options;
    };
    SettingService.prototype.updateSetting = function (settingvalue) {
        var url = this.getUrl('/setting');
        var data = {
            approved_7day: settingvalue.approved_7day,
            approved_30day: settingvalue.approved_30day,
            message_7day: settingvalue.message_7day,
            message_30day: settingvalue.message_30day,
            connection_7day: settingvalue.connection_7day,
            connection_30day: settingvalue.connection_30day
        };
        return this.http.put(url, data, this.getOptions())
            .pipe(map(function (res) {
            return true;
        }));
    };
    SettingService.prototype.getSettingValue = function () {
        var url = this.getUrl('/setting');
        return this.http.get(url, this.getOptions())
            .pipe(map(function (setting) {
            if (setting.length === 1) {
                var data = {};
                data['approved_7day'] = (setting[0].approved_7day) ? setting[0].approved_7day : '';
                data['approved_30day'] = (setting[0].approved_30day) ? setting[0].approved_30day : '';
                data['message_7day'] = (setting[0].message_7day) ? setting[0].message_7day : '';
                data['message_30day'] = (setting[0].message_30day) ? setting[0].message_30day : '';
                data['connection_7day'] = (setting[0].connection_7day) ? setting[0].connection_7day : '';
                data['connection_30day'] = (setting[0].connection_30day) ? setting[0].connection_30day : '';
                data['message'] = (setting[0].message) ? setting[0].message : '';
                return data;
            }
            else {
                return false;
            }
        }));
    };
    SettingService.prototype.updateMessage = function (message) {
        var url = this.getUrl('/setting');
        var data = {
            message: message
        };
        return this.http.put(url, data, this.getOptions())
            .pipe(map(function (res) {
            return true;
        }));
    };
    SettingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingService_Factory() { return new SettingService(i0.ɵɵinject(i1.HttpClient)); }, token: SettingService, providedIn: "root" });
    return SettingService;
}());
export { SettingService };
