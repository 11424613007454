/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./messages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/ag-grid-angular/ag-grid-angular.ngfactory";
import * as i4 from "ag-grid-angular";
import * as i5 from "./messages.component";
import * as i6 from "../../../services/user.service";
var styles_MessagesComponent = [i0.styles];
var RenderType_MessagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessagesComponent, data: {} });
export { RenderType_MessagesComponent as RenderType_MessagesComponent };
function View_MessagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading\u2026"]))], null, null); }
export function View_MessagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagesComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-alpine"], ["style", "width: 100%; height: calc(100% - 17px)"]], [[8, "enableColResize", 0], [8, "enableSorting", 0], [8, "enableFilter", 0]], [[null, "columnResized"], [null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("columnResized" === en)) {
        var pd_0 = (_co.onColumnResized($event) !== false);
        ad = (pd_0 && ad);
    } if (("gridReady" === en)) {
        var pd_1 = (_co.onGridReady($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_AgGridAngular_0, i3.RenderType_AgGridAngular)), i1.ɵprd(512, null, i4.AngularFrameworkOverrides, i4.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i4.AngularFrameworkComponentWrapper, i4.AngularFrameworkComponentWrapper, []), i1.ɵdid(5, 4898816, null, 1, i4.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i4.AngularFrameworkOverrides, i4.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"], overlayNoRowsTemplate: [2, "overlayNoRowsTemplate"], paginationPageSize: [3, "paginationPageSize"], suppressHorizontalScroll: [4, "suppressHorizontalScroll"], pagination: [5, "pagination"] }, { columnResized: "columnResized", gridReady: "gridReady" }), i1.ɵqud(603979776, 1, { columns: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_4 = _co.rows; var currVal_5 = _co.columnDefs; var currVal_6 = _co.noDataText; var currVal_7 = 30; var currVal_8 = true; var currVal_9 = true; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_1 = true; var currVal_2 = true; var currVal_3 = true; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_MessagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-messages", [], null, null, null, View_MessagesComponent_0, RenderType_MessagesComponent)), i1.ɵdid(1, 114688, null, 0, i5.MessagesComponent, [i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessagesComponentNgFactory = i1.ɵccf("app-messages", i5.MessagesComponent, View_MessagesComponent_Host_0, {}, {}, []);
export { MessagesComponentNgFactory as MessagesComponentNgFactory };
