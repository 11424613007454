import { OnInit } from '@angular/core';
import * as moment from 'moment';
var CaMessagesNewComponent = /** @class */ (function () {
    function CaMessagesNewComponent(slackService, document, userService, router) {
        this.slackService = slackService;
        this.userService = userService;
        this.router = router;
        this.rows = [];
        this.select = '';
        this.totalRecords = 0;
        this.skip = 0;
        this.count = 25;
        this.cars = [];
        this.isLoading = true;
        this.leadStatusOptions = [];
        this.userLatestMessage = [];
        this.noDataText = 'No messages right now';
        this.users = [];
        this.cols = [
            { field: 'prospect', header: 'Prospect' },
            { field: 'last_incoming_message', header: 'Last Incoming Message' },
            { field: 'createdAt', header: 'Date/Time' },
        ];
    }
    CaMessagesNewComponent.prototype.ngOnInit = function () {
        var _this = this;
        //this.fetchConversations()
        this.userService.getUserByKey(localStorage.getItem('USER_KEY')).subscribe(function (user) {
            if (user.leadStatusOptions.length == 0) {
                _this.leadStatusOptions = ["", 0, 1, 2, 3, 4];
            }
            else
                _this.leadStatusOptions = [""].concat(user.leadStatusOptions);
        });
        ;
        this.userService.getCAUsers().subscribe(function (res) {
            _this.users = res.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
            if (localStorage.getItem('CA_MESSAGE_KEY')) {
                _this.select = localStorage.getItem('CA_MESSAGE_KEY'); // + ',' + localStorage.getItem('CA_MESSAGE_LINKEDINID');
                _this.selectUser();
            }
            else {
                _this.isLoading = false;
            }
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get ca users: ', err);
        });
    };
    CaMessagesNewComponent.prototype.selectUser = function () {
        var _this = this;
        if (this.select) {
            this.isLoading = true;
            this.fetchConversations();
            return;
            this.userService.getUserMessages(this.select).subscribe(function (messages) {
                _this.cars = messages;
                localStorage.setItem('CA_MESSAGE_KEY', '');
                localStorage.setItem('CA_MESSAGE_NAME', '');
                localStorage.setItem('CA_MESSAGE_LINKEDINID', '');
                localStorage.removeItem('USER_MESSAGES_CACHED_AT');
                localStorage.removeItem('USER_MESSAGES');
                _this.isLoading = false;
            }, function (err) {
                _this.isLoading = false;
                alert('error');
                console.log('error to get user latest messages: ', err);
            });
        }
    };
    CaMessagesNewComponent.prototype.fetchConversations = function () {
        var _this = this;
        this.isLoading = true;
        var d = this.select.split("-");
        console.log(this.select);
        this.userService.getUserBetaMessages(d[0], d[1], this.skip, this.count).subscribe(function (data) {
            _this.isLoading = false;
            _this.cars = data;
            //this.totalRecords = parseInt(data.totalRecords);
        }, function (err) {
            _this.isLoading = false;
            alert('error');
            console.log('error to get user lastest messages: ', err);
        });
    };
    CaMessagesNewComponent.prototype.sendStopMessageSlack = function (message) {
        this.slackService.stopSlackMessage({ "text": message, "channel": 'messaging-alerts', "username": 'Messaging Bot' }).subscribe();
    };
    CaMessagesNewComponent.prototype.onPage = function (evt) {
        console.log(evt);
        this.skip = parseInt(evt.first);
        this.count = parseInt(evt.rows);
        this.fetchConversations();
    };
    CaMessagesNewComponent.prototype.actionOnStopMessage = function (isYes) {
        if (isYes) {
            var elem = document.getElementById(this.rowData.id);
            var data = {};
            data.clientCompany = localStorage.getItem('USER_COMPANY');
            data.clientName = localStorage.getItem('USER_NAME');
            data.prospectName = this.rowData.contact;
            data.prospectLinkedinUrl = this.rowData.contactURL;
            elem.classList.add('msg-red');
            var msg = "*** Stop Messaging Sequence ***\n      " + data.clientCompany + " - " + data.clientName + "\n      " + data.prospectName + " - " + data.prospectLinkedinUrl + "\n      Requested at: " + moment().format('MM/DD/YYYY hh:mm:ss A');
            this.sendStopMessageSlack(msg);
            this.rowData.stopmsg = true;
            this.userService.stopMessage(this.rowData.pxDataId).subscribe(function (res) {
                console.log(res);
            });
        }
        this.showConfirmModalOnClick.hide();
    };
    CaMessagesNewComponent.prototype.stopMessaging = function (rowData) {
        this.rowData = rowData;
        console.log(rowData);
        if (!this.rowData.stopmsg)
            this.showConfirmModalOnClick.show();
        else {
            this.showInformModalOnClick.show();
        }
    };
    return CaMessagesNewComponent;
}());
export { CaMessagesNewComponent };
