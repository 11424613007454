<div class="loading" *ngIf="isLoading">Loading&#8230;</div>

<select class="browser-default custom-select" style="width: 250px;" [(ngModel)]="select" (change)="selectUser()">
  <option selected value=""> Choose user</option>
  <option *ngFor="let user of users" value="{{user._id}}">{{user.name}}</option>
</select>

<div id="gridContainer">
    <ag-grid-angular *ngIf="select" style="width: 100%; height: calc(100% - 17px)" 
    [enableColResize]="true" 
    [paginationAutoPageSize]="true" 
    [pagination]="true" class="ag-theme-alpine" 
    [rowData]="rows" [columnDefs]="columnDefs" 
    [enableSorting]="true"
    [enableFilter]="true"
    [suppressHorizontalScroll]="true" (columnResized)="onColumnResized($event)" (gridReady)="onGridReady($event)" 
    [overlayNoRowsTemplate]='noDataText'>
    </ag-grid-angular>
</div>