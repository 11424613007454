/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ca-reports.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "ng-uikit-pro-standard";
import * as i5 from "./ca-reports.component";
var styles_CaReportsComponent = [i0.styles];
var RenderType_CaReportsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaReportsComponent, data: {} });
export { RenderType_CaReportsComponent as RenderType_CaReportsComponent };
function View_CaReportsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading\u2026"]))], null, null); }
export function View_CaReportsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaReportsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reports"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["href", "sentMessageCount"], ["mdbWavesEffect", ""], ["routerLink", "sentMessageCount"], ["style", "text-decoration: underline;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).click($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(6, 16384, null, 0, i4.WavesDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, ["Sent Message Count"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_3 = "sentMessageCount"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 5).target; var currVal_2 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_CaReportsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ca-reports", [], null, null, null, View_CaReportsComponent_0, RenderType_CaReportsComponent)), i1.ɵdid(1, 114688, null, 0, i5.CaReportsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaReportsComponentNgFactory = i1.ɵccf("app-ca-reports", i5.CaReportsComponent, View_CaReportsComponent_Host_0, {}, {}, []);
export { CaReportsComponentNgFactory as CaReportsComponentNgFactory };
